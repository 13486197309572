import actions from "./actions";
import globalMutators from "./mutations";

/**
 * ! This is a function so we can support multiple instances
 * * We also export this so we can reuse the same state in multiple modules
 * @type {(() => import('types/store').IRootState)}
 * @returns {import('types/store').IRootState}
 */
export const state = () => ({
  nav: {
    hidden: false,
    fixed: true,
    minified: false
  },
  header: {
    fixed: true
  },
  theme: "light"
});

/**
 * @type {import("vuex").Module}
 */
const mod = {
  state,
  namespaced: true,
  actions: actions,
  mutations: globalMutators
};

export default mod;
