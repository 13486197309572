<template>
  <div class="state-display relative h-full w-full">
    <resize-observer @notify="handleResize" />
    <svg width="100%" height="100%">
      <!-- State Border -->
      <path class="state-path" :stroke-width="stateBorderWidth" :d="geoPath(mesh)" />

      <!-- All counties merged -->
      <path :d="geoPath(features)" :stroke-width="countyBorderWidth" fill="none" />

      <!-- Active / Highlighted Counties -->
      <path
        v-for="(item, index) in matchedCounties.features"
        :key="index"
        :stroke-width="countyBorderWidth"
        class="active"
        :class="getCountyClass(item.properties.name)"
        :d="geoPath(item)"
      >
        <title>{{ item.properties.name }}</title>
      </path>
    </svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import { mesh, feature } from "topojson-client";
import { findState } from "./geo";

// ! This is a large item, consider loading asynchronously
import UsData from "us-atlas/counties-albers-10m.json";

export default {
  name: "StateCountyViewer",
  props: {
    /**
     * Counties to be active
     * @type {Vue.PropOptions<string[]>}
     */
    counties: {
      type: Array,
      default: () => []
    },

    /**
     * Counties to be highlighted
     * @type {Vue.PropOptions<string[]>}
     */
    highlightedCounties: {
      type: Array,
      default: () => []
    },

    /**
     * State name or abbreviation
     * @type {Vue.PropOptions<string>}
     */
    state: {
      type: String,
      default: "Florida",
      validator(value) {
        return findState(value) != null;
      }
    },

    /**
     * State Border
     * @type {Vue.PropType<number>}
     */
    stateBorderWidth: {
      type: Number,
      default: 3
    },

    /**
     * County Border
     * @type {Vue.PropOptions<number>}
     */
    countyBorderWidth: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    /** @type {Geo.RootObject} */
    us: Object.freeze(UsData),

    width: 200,
    height: 300
  }),
  computed: {
    /**
     * The target state object
     * @returns {Geo.Geometry2}
     */
    usState() {
      return this.us.objects.states.geometries.find((s) => {
        const [, fullStateName] = findState(this.state);

        const {
          properties: { name }
        } = s;

        return name.toLowerCase() === fullStateName.toLowerCase();
      });
    },

    /**
     * Topography that is filtered to only contain data for selected state
     */
    filteredUs() {
      /** @type {Geo.Objects} */
      const { counties, nation, states } = this.us.objects;

      return {
        ...this.us,
        objects: {
          counties: {
            ...counties,
            geometries: counties.geometries.filter((c) => {
              const id = c.id.slice(0, 2);

              return id === this.usState.id;
            })
          },
          nation,
          states
        }
      };
    },

    /**
     * Main projection method to translate coordinates
     * @returns {d3.GeoIdentityTransform}
     */
    projection() {
      // const { x, y } = this.translate

      //  add extent based on bounds maybe

      const p = d3.geoIdentity();

      p.fitSize([this.width, this.height], this.features);

      // p.fitExtent([[20, 20], [960, 640]], this.us)

      return p;
    },

    /** @returns {d3.GeoPath} */
    geoPath() {
      return d3.geoPath().projection(this.projection);
    },

    /** @returns {d3.ExtendedGeometryCollection} */
    mesh() {
      if (!this.us) {
        return [];
      }
      // return mesh(this.us, this.us.objects.states, (a, b) => a !== b)
      return mesh(this.us, this.usState);
    },

    /** @returns {d3.ExtendedFeature} */
    features() {
      if (!this.us) {
        return [];
      }
      return feature(this.us, this.filteredUs.objects.counties);
    },

    /**
     * Contains renderable data for active counties
     * @returns {d3.ExtendedFeatureCollection}
     */
    matchedCounties() {
      if (!this.features.features) return {};
      const features = this.features.features.filter((g) => {
        const name = g.properties.name.toUpperCase();

        return this.counties.includes(name);
      });

      return {
        type: this.features.type,
        features
      };
    }
  },
  async mounted() {
    this.updateDimensions();
  },
  methods: {
    /**
     * handles resize
     */
    handleResize() {
      this.updateDimensions();
    },

    /**
     * handles resize
     */
    updateDimensions() {
      const { width, height } = this.$el.getBoundingClientRect();

      this.width = width;
      this.height = height;
    },

    /**
     * handles resize
     */
    getCountyClass(name) {
      return {
        highlight: this.highlightedCounties.some((v) => v.toLowerCase() === name.toLowerCase())
      };
    }
  }
};
</script>

<style scoped lang="scss">
svg {
  overflow: visible;

  path {
    fill: white;
    stroke: black;

    &.state-path {
      fill: none;
      stroke: rgba(#000, 0.5);
      transform: translateY(1px);
    }

    &.active {
      fill: #6aafaa;
      transition: fill 1s ease;

      &:hover {
        fill: lighten(#6aafaa, 10);
        transition-duration: 0ms;
      }

      .theme-commercial & {
        fill: #6aafaa;

        &:hover {
          fill: darken(#6aafaa, 10);
        }
      }
      .theme-earthquake & {
        fill: rgb(255, 136, 121);
        &:hover {
          fill: rgb(255, 192, 184);
        }
      }
    }

    &.highlight {
      fill: yellow;
      transition-duration: 0ms;
    }
  }
}
</style>
