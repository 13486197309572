<template>
  <header
    class="header shadow-lg"
    :class="{
      jsColorBorder: isEarthquake,
      '!border-b-4 !border-deepsea-medium !border-solid': !isEarthquake
    }"
  >
    <!-- Logo -->
    <a
      :href="Constants.BASE_TRITON_UI_URL"
      title="Neptune Flood | Poseidon"
      class="header__logo h-full inline-flex items-center"
      data-test="topHeader.logo"
    >
      <!-- Commercial Logo -->
      <img
        v-if="[2, 3, 7, 8].includes($store.getters['policy/productNo'])"
        src="@/assets/pngs/Icon_DeepSea.png"
      />

      <!-- Residential Logo -->
      <img v-else src="@/assets/pngs/Icon_DeepSea.png" />

      <h2 v-if="isEarthquake" style="color:rgb(255,97,76)" data-test="topHeader.eqTitle">JumpStart Parametric Earthquake</h2>
      <h2 v-else>
        <span class="text-deepsea-full" data-test="topHeader.floodTitle">{{ title }}</span>
        <span
          v-if="[6, 7, 8].includes($store.getters['policy/productNo'])"
          class="bg-pink-100 text-pink-900 inline-flex rounded-full px-2 py-0.5 mr-1 border border-pink-400"
          data-test="topHeader.excessFlag"
          >Excess
        </span>
      </h2>
    </a>
    <!-- </div> -->

    <span class="spacer" />

    <!-- Settings Button -->
    <div v-if="!isInsured && !$readonly" class="header__group">
      <el-popover
        placement="bottom"
        :offset="50"
        transition="el-zoom-in-top"
        width="200"
        trigger="click"
      >
        <a slot="reference" class="header__group cursor-pointer">
          <span data-test="topHeader.profileName">{{ username }}</span>
          <span class="el-icon-arrow-down" data-test="topHeader.profileButton" />
        </a>

        <template v-if="!isInsured" #default>
          <div class="menu-container flex flex-col">
            <router-link to="/profile" target="_blank" data-test="topHeader.userProfile">
              User Profile
            </router-link>
            <router-link v-if="isInternal" :to="{ name: 'bulkUpload' }" data-test="topHeader.bulkUpload">
              Bulk Upload
            </router-link>

            <a v-if="userAllowed" data-test="topHeader.changePassword" @click="$root.$emit('openPasswordResetModal')">Change Password</a>
          </div>
        </template>
      </el-popover>
    </div>

    <div v-else class="header__group">
      <div v-if="isInsured" class="header__group">
        <span data-test="topHeader.username">{{ username }}</span>
      </div>
    </div>
  </header>
</template>

<script>
import { Constants } from "../../config/index";
export default {
  data() {
    return {
      submitting: false,
      error: null,
      Constants
    };
  },
  computed: {
    isEarthquake() {
      const productNo = this.$store?.state?.policy?.transactions[0]?.productId;
      return (
        productNo === 4 ||
        productNo === 5 ||
        this.$store?.getters["policy/productNo"] === 4 ||
        this.$store?.getters["policy/productNo"] === 5
      );
    },
    passwordPassesValidation() {
      return this.$refs.password_form.passwordPassesValidation;
    },
    isInsured() {
      return this.$store.getters["userdata/type"] === "insured";
    },
    username() {
      const { first, last } = this.$store.state.user;
      const { Company } = this.$store.state.auth.data || {};

      if (Company) return Company;

      if (this.$store.getters["userdata/type"] === "agent")
        return this.$store.state.agencyInfo.company;

      return `${first} ${last}`;
    },

    userEmail() {
      /** @type {neptune.IRootState} */
      const s = this.$store.state;

      const { email: userEmail } = s.user;

      const { Email: authEmail } = s.auth.data || {};

      return authEmail || userEmail;
    },
    isProduction() {
      const val = Constants.ENVIRONMENT;
      return val === "PROD";
    },

    title() {
      const productNo = this.$store?.state?.auth?.validateReturn?.policyTransaction?.productId;

      if (
        productNo === 4 ||
        productNo === 5 ||
        this.$store?.getters["policy/productNo"] === 4 ||
        this.$store?.getters["policy/productNo"] === 5
      ) {
        return "";
      } else if ([3, 8].includes(this.$store?.getters["policy/productNo"])) {
        return "Neptune Flood - RCBAP";
      } else if ([1, 6].includes(this.$store?.getters["policy/productNo"])) {
        return "Neptune Flood - Residential";
      } else if ([2, 7].includes(this.$store?.getters["policy/productNo"])) {
        return "Neptune Flood - Commercial";
      } else {
        return "Neptune Flood";
      }
    },

    agent() {
      return this.$store.getters["userdata/type"] === "agent";
    },

    userAllowed() {
      return this.agent && Constants.ENVIRONMENT !== "PRODUCTION";
    },
    isInternal() {
      return this.$store.getters["auth/internal"] === true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/_variables.scss";

.header {
  @media print {
    display: none;
  }
  position: fixed;
  height: $header-height;
  background-color: #f5f7fa;
  z-index: 1000;
  display: flex;
  grid-area: topnav;
  padding: 4px;
  top: 0;
  left: 0;
  width: 100%;
  user-select: none;
  transition: padding 200ms ease;

  .sidenav-hidden & {
    padding-left: 0;
  }

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    height: 100%;
    min-width: $header-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000, 0.25);
    }

    // &:focus {
    //    background-color: rgba(#000, 0.35);
    // }
  }

  &__logo {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 17px;
    color: #016ba5;
    flex-shrink: 0;
    &,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &:hover {
      color: lighten(#016ba5, 20);
    }

    .theme-commercial & {
      color: #616161;

      &:hover {
        color: lighten(#4bbd95, 1);
      }
    }

    > * {
      font-size: inherit;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    img {
      height: 100%;
      padding: 0 4px;
      width: auto;
    }
  }

  &__group {
    display: flex;
    align-items: center;

    > * {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.menu-container {
  a {
    cursor: pointer;
    margin-bottom: 0.6rem;
  }
}

button {
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  outline: none !important;
}

.spacer {
  flex: 1;
}

.avatar {
  ::v-deep img {
    max-height: 35px;
  }

  .theme-commercial & {
    border-color: #4bbd95;
  }

  .theme-neptune & {
    border-color: $color-neptune--hot-pink;
  }
}
.jsColorBorder {
  border-bottom-color: #ff614c !important;
}
.jsColorText {
  color: #016ba5;
}
</style>
