<template>
  <div
    :class="{
      'text-blue-100 bg-blue-900 rounded': cardFallback,
      'py-1 max-w-min': brand === 'bank'
    }"
  >
    <img
      v-if="brand === 'visa'"
      class="h-6 w-9 rounded"
      :alt="`${brand} icon`"
      src="@/assets/img/cards/visa.png"
    />
    <img
      v-else-if="brand === 'mastercard'"
      class="h-6 w-9 rounded"
      :alt="`${brand} icon`"
      src="@/assets/img/cards/mastercard.png"
    />
    <img
      v-else-if="brand === 'amex'"
      class="h-6 w-9 rounded"
      :alt="`${brand} icon`"
      src="@/assets/img/cards/amex.png"
    />
    <img
      v-else-if="brand === 'diners'"
      class="h-6 w-9 rounded"
      :alt="`${brand} icon`"
      src="@/assets/img/cards/diners.png"
    />
    <img
      v-else-if="brand === 'jcb'"
      class="h-6 w-9 rounded"
      :alt="`${brand} icon`"
      src="@/assets/img/cards/jcb.png"
    />
    <img
      v-else-if="brand === 'discover'"
      class="h-6 w-9 rounded"
      :alt="`${brand} icon`"
      src="@/assets/img/cards/discover.png"
    />
    <svg
      v-else-if="brand === 'bank'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="w-9 h-6"
    >
      <path
        d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z"
      />
      <path
        fill-rule="evenodd"
        d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z"
        clip-rule="evenodd"
      />
      <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
    </svg>

    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-6 w-9"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
      />
    </svg>
  </div>
</template>

<script>
import { computed } from "vue";

const SUPPORTED_CARDS = ["visa", "mastercard", "jcb", "discover", "amex", "diners", "bank"];

export default {
  props: {
    brand: {
      type: String,
      required: true,
      validator(v) {
        return SUPPORTED_CARDS.includes(v);
      }
    }
  },
  setup(props) {
    const cardFallback = computed(() => {
      return !SUPPORTED_CARDS.includes(props.brand) || props.brand === "bank";
    });

    return {
      cardFallback
    };
  }
};
</script>
