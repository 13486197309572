// @ts-check

import actions from "./actions";
import mutations from "./mutations";

/**
 * @type {neptune.commercial.ICommercialState}
 */
export const state = {
  documentsWithSignatures: [],
  isPolicyPaid: false,
  isPolicySigned: false,

  /** @type {neptune.commercial.IBindParams} */
  bindData: {
    MessageId: "00000000-0000-0000-0000-000000000000",
    payload: {
      policy: {
        policyId: 83663,
        previousPolicyId: null,
        policyNo: null,
        sequence: 1,
        inceptionDate: null,
        originalTransactionType: null,
        originalPostDate: null,
        policyTransactionId: null,
        agencyId: null,
        userId: null,
        policyHolderId: 30,
        isDirectToConsumer: false,
        createdAt: "",
        updatedAt: ""
      },

      // Pass to saving documents
      policyDocStatus: {
        documentsWithSignatures: [],
        isPolicySigned: false,
        isPolicyPaid: false
      }
    },
    StatusCode: 200
  }
};

/** @type {import('vuex').Module<neptune.commercial.ICommercialState, neptune.IRootState>} */
const mod = {
  state,
  actions,
  // getters,
  mutations,
  namespaced: true
};

export default mod;
