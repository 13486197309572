<template>
  <el-form label-position="top" :model="model" @submit.native.prevent>
    <div class="row">
      <p v-if="!canChangeUseNameText" class="text-xs italic mb-5 mx-3">
        In order to Apply the signatures, make sure all fields are filled in completely. Type your
        name and initials in the top fields. Sign your name and initials using your mouse for the
        lower fields.
      </p>

      <div class="col-sm-8">
        <el-form-item label="Your full name" :class="{ marginClass: !canChangeUseNameText }">
          <ValidationProvider v-slot="{ errors }" name="Name" rules="required|max:50">
            <input
              v-model="model.name"
              auto-complete="name"
              data-test="sigFormFullName"
              class="block rounded-md border border-gray-300 bg-white px-4 py-2 !text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-deepsea-medium focus:ring-offset-2 w-full"
              @input="updateInitials"
            />
            <FadeTranslate>
              <p v-if="errors[0]" class="text-red-500 text-left text-sm m-0">
                {{ errors[0] }}
              </p>
            </FadeTranslate>
          </ValidationProvider>
        </el-form-item>

        <el-form-item label="Signature">
          <div class="relative">
            <!-- Signature Options -->
            <div class="signature-options">
              <el-switch
                v-if="canChangeUseNameText"
                v-model="settings.useNameText"
                active-text="Use Text"
                data-test="sigFormTextSignature"
                active-color="#004C9D"
              />
              <button
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-deepsea-full hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium space-x-1"
                data-test="sigFormClear"
                @click="clearSignature()"
              >
                Clear
              </button>
            </div>
            <SignaturePad
              ref="name"
              class="drawing-pad"
              data-test="sigFormDrawingPad"
              :text="nameText"
              @newImage="model.nameBitmap = $event"
            />
          </div>
        </el-form-item>
      </div>
      <hr />
      <div class="col-sm-4" :class="{ marginClass: !canChangeUseNameText }">
        <el-form-item label="Your initials">
          <input
            v-model="model.initials"
            data-test="sigFormInitials"
            class="block rounded-md border border-gray-300 bg-white px-4 py-2 !text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-deepsea-medium focus:ring-offset-2 w-full"
          />
        </el-form-item>

        <el-form-item label="Signature">
          <div class="relative">
            <!-- Initials Options -->
            <div class="signature-options">
              <el-switch
                v-if="canChangeUseNameText"
                v-model="settings.useNameText"
                data-test="sigFormTextInitials"
                active-text="Use Text"
                active-color="#004C9D"
              />

              <button
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-deepsea-full hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium space-x-1"
                data-test="sigFormClearInitials"
                @click="clearInitials()"
              >
                Clear
              </button>
            </div>
            <SignaturePad
              ref="initials"
              class="drawing-pad"
              data-test="sigFormInitials"
              :text="signatureText"
              @newImage="model.initialBitmap = $event"
            />
          </div>
        </el-form-item>
      </div>
    </div>
    <div class="flex justify-end">
      <div v-if="$scopedSlots.footer" class="flex" :class="{ absolute: !canChangeUseNameText }">
        <slot name="footer" :notice-checked="noticeChecked" v-bind="$vnode.componentInstance" />
      </div>

      <div v-if="!canChangeUseNameText" class="elany-notice">
        <el-checkbox v-model="noticeChecked" class="notice-check" data-test="sigFormElanyCheck" />
        <p data-test="sigFormElanyNotice">
          In order for this policy to be compliant with the Excess Line Association of New York
          (ELANY) you must sign your full name in the signature field using your mouse. ELANY will
          reject typed names, initials, or anything that is not your full signature. If you decide
          to upload a signed Part-C to the policy instead, it must be a wet signature.
        </p>
      </div>
    </div>
  </el-form>
</template>

<script>
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";
import { ValidationProvider } from "vee-validate";
import SignaturePad from "./SignaturePad.vue";
export default {
  components: {
    SignaturePad,
    FadeTranslate,
    ValidationProvider
  },
  inheritAttrs: false,
  props: {
    canChangeUseNameText: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      model: {
        name: null,
        initials: null,
        nameBitmap: null,
        initialBitmap: null
      },
      settings: {
        useNameText: true
      },
      noticeChecked: false
    };
  },
  computed: {
    nameText() {
      return this.settings.useNameText ? this.model.name : null;
    },
    signatureText() {
      return this.settings.useNameText ? this.model.initials : null;
    },
    valid() {
      return this.canChangeUseNameText
        ? Object.values(this.model).every((str) => str)
        : Object.values(this.model).every((str) => str) && this.noticeChecked;
    }
  },
  watch: {
    model: {
      handler(val) {
        this.$emit("update:model", val);
      },
      deep: true
    }
  },
  mounted() {
    // NY cannot use text input to generate a signature
    if (!this.canChangeUseNameText) {
      this.settings.useNameText = false;
    }
  },
  methods: {
    /**
     * Clear initials
     */
    clearInitials() {
      Object.assign(this.model, {
        initials: null,
        initialBitmap: null
      });

      this.$refs.initials.clear();
    },

    clearSignature() {
      Object.assign(this.model, {
        name: null,
        nameBitmap: null
      });

      this.$refs.name.clear();
    },

    updateInitials() {
      this.model.initials = this.model.name
        .split(" ")
        .map((w) => w.substring(0, 1))
        .join("")
        .toUpperCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.drawing-pad {
  position: relative;
  border: 2px solid #ccc;
  height: 150px;
  margin-bottom: 0.5rem;
}

.signature-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

::v-deep .el-form-item {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  // justify-content: stretch;
}

::v-deep .el-form-item__label {
  line-height: normal;
  margin-bottom: 0;
  font-weight: bold;
}
.marginClass {
  margin-top: 20px;
}

.footer-class {
  position: absolute;
}

.elany-notice {
  padding-left: 27%;
  color: red;
  font-size: 12px;
  margin: -15px 5px;
  display: flex;
  p {
    margin-left: 20px;
  }
}
.notice-check {
  padding: 20px 5px 10px;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #c8cacc;
  background-color: #f3f4f6;
}
::v-deep .el-switch__label.is-active {
  color: #004c9d !important;
}
</style>
