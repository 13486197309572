<template>
  <div class="flex items-center">
    <button class="hover:bg-gray-200 hover:text-pink-600 px-6 py-5 mr-auto" @click="toggleNav()">
      <span v-if="$store.state.settings.ui.nav.hidden" class="fa fa-bars" />
      <span v-else class="fa fa-chevron-left" />
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    toggleNav(forceVal) {
      /** @type {import('../../types/store').IRootState} */
      const {
        settings: {
          ui: {
            nav: { hidden }
          }
        }
      } = this.$store.state;

      this.$store.commit("settings/ui/SET_PROP", {
        path: "nav.hidden",
        value: forceVal || !hidden
      });
    }
  }
};
</script>

<style></style>
