// import Axios from 'axios'
// import Vue from "vue";
import { authAxios } from "../auth";
import { Constants } from "../../../config";

/** @type {import("vuex").ActionTree<any, neptune.IRootState>} */
export const actions = {
  async searchPolicies(_param, query) {
    const { data } = await authAxios.get(Constants.TRITON_SEARCH_POLICIES_URL, {
      params: query || {
        AgentNumber: _param.rootState.auth.data?.AgentId,
        QuoteNumber: "",
        InsuredName: "",
        Address: "",
        sortField: "",
        sortDirection: "",
        SearchScope: 2,
        searchMode: 1,
        take: 50,
        skip: 5,
        page: 2,
        pageSize: 50
      }
    });

    return data;
  },

  /**
   * Get a collection of transactions for a given policy
   * @param {import('vuex').ActionContext<neptune.IRootState>} param0
   * @param {string} policyNo
   */
  async getActiveTransactions({ commit }, [policyNo]) {
    let transactionCancel;
    if (typeof transactionCancel === "function") {
      console.log("cancelling token");
      transactionCancel();
    }

    //     console.log(authAxios)

    try {
      /** @type {import('axios').AxiosResponse<{payload: IPolicyTransaction[]}>} */
      const {
        data: { payload: data }
      } = await authAxios.get(
        Constants.GET_TRANSACTIONS_URL.replace("{{policyNo}}", policyNo),
        // `/api/v1/policies/${policyNo}/policytransactions`,
        {
          cancelToken: transactionCancel
        }
      );

      if (Array.isArray(data)) {
        data.sort((a, b) => {
          const aDate = Date.parse(a.postDate);
          const bDate = Date.parse(b.postDate);

          return aDate - bDate;
        });
      }

      commit("SET_PROP", {
        path: "transactions",
        value: data
      });

      return data;
    } catch (error) {
      this.$router.replace({
        name: "Error",
        path: "/error",
        params: {
          error: error,
          policyNo: policyNo
        }
      });
      // Vue.prototype.$alert(error, "Error", {});
    }
  },

  /**
   * Get a collection of documents for a given policy
   * @param {import('vuex').ActionContext<neptune.IRootState>} param0
   * @param {string} policyNo
   */
  async getDocuments(_, policyNo) {
    const {
      data: { payload }
    } = await authAxios.get(
      Constants.GET_DOCUMENTS_PDF_URL.replace("{{policyNo}}", policyNo),
      // `/api/v1/documents/policy/${policyNo}/pdfs`,
      {
        // cancelToken: transactionCancel
      }
    );

    return payload;
  }
};

export default actions;
