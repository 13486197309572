<template>
  <!-- Transaction Overlay -->
  <div as="template">
    <div as="div" class="relative z-10">
      <div
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <div
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 min-w-[800px] max-w-6xl"
            >
              <div>
                <div class="flex justify-end -mt-3 mb-2 cursor-pointer" @click="closeModal()">
                  <!-- Heroicon name: solid/x-circle -->
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="overflow-hidden bg-white sm:rounded-lg">
                  <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                      Endorsement for {{ policyNo }}
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                      Policy transaction ID: {{ selectedTransaction.policyTransactionId }} | Post
                      date: {{ selectedTransaction.postDate | shortDateSimpleWithTime }}
                    </p>
                  </div>
                  <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Deductible Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.deductibleDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage A Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageADelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage B Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageBDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage D Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageDDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage E Change</dt>
                        <dd
                          v-if="selectedTransaction.coverageEDelta !== 0"
                          class="mt-1 text-sm text-gray-900"
                        >
                          {{ selectedTransaction.coverageEDelta === 1 ? "Added" : "Removed" }}
                        </dd>
                        <dd v-else class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageEDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage F Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageFDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage G Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageGDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage H Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageHDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage I Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageIDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage J Change</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageJDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage K Change</dt>
                        <dd
                          v-if="selectedTransaction.coverageKDelta !== 0"
                          class="mt-1 text-sm text-gray-900"
                        >
                          {{ selectedTransaction.coverageKDelta === 1 ? "Added" : "Removed" }}
                        </dd>
                        <dd v-else class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageKDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">Coverage L Change</dt>
                        <dd
                          v-if="selectedTransaction.coverageLDelta !== 0"
                          class="mt-1 text-sm text-gray-900"
                        >
                          {{ selectedTransaction.coverageLDelta === 1 ? "Added" : "Removed" }}
                        </dd>
                        <dd v-else class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.coverageLDelta | currencyNoCents }}
                        </dd>
                      </div>
                      <div class="sm:col-span-2">
                        <dt class="text-sm font-medium text-gray-500">Transaction Reason</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{ selectedTransaction.transactionReason }}
                        </dd>
                      </div>
                      <div class="sm:col-span-2">
                        <dt class="text-sm font-medium text-gray-500">Transaction Note</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                          {{
                            selectedTransaction.transactionNotes
                              ? selectedTransaction.transactionNotes
                              : "-"
                          }}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NumberFiltersMixin, DateFiltersMixin } from "./policy-wizard/utils/mixins/formatting";

export default {
  mixins: [NumberFiltersMixin, DateFiltersMixin],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    transaction: {
      type: Object
    }
  },
  data() {
    return {
      selectedTransaction: null
    };
  },
  computed: {
    policyNo() {
      return this.$store?.state?.policy?.confirmationData?.policyNo;
    }
  },
  mounted() {
    const result = this.$store?.state?.policy?.transactionSummary?.transactions.find(
      (x) => x.policyTransactionId === this.transaction?.policyTransactionId
    );

    this.selectedTransaction = result;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>
