/* eslint-disable no-unused-vars */
import { authAxios } from "../auth";
import { Constants } from "../../../config";

const actions = {
  async getAllClaims({ commit }, policyNo) {
    const response = await authAxios.get(`${Constants.GET_ALL_CLAIMS}?policyNo=${policyNo}`);
    commit("SET_PROP", { path: "claims", value: response?.data?.payload });
    return response.data.payload;
  },
  async getClaimByID({ commit }, claimID) {
    const url = Constants.GET_CLAIM_BY_ID.replace("{{claimID}}", claimID);
    const response = await authAxios.get(url);
    commit("SET_PROP", { path: "claim", value: response?.data });
    return response.data;
  },
  async updateClaim({ _ }, { claimID, claimBody }) {
    const url = Constants.GET_CLAIM_BY_ID.replace("{{claimID}}", claimID);
    const response = await authAxios.put(url, claimBody);
    return response.data;
  },
  async postNewClaim({ _ }, claimObject) {
    const response = await authAxios.post(Constants.CREATE_CLAIM, claimObject);
    return response;
  },
  async getAllClaimDocs({ _ }, claimID) {
    const response = await authAxios.get(
      Constants.CLAIMS_DOCUMENTS_URL.replace("{{claimID}}", claimID)
    );
    return response.data.payload;
  },
  async downloadClaimDocument({ _ }, { claimID, docID }) {
    const docs = await authAxios.get(
      Constants.CLAIMS_DOCUMENTS_URL.replace("{{claimID}}", claimID)
    );
    const filtered = docs.data.payload.filter((x) => x.id === docID);
    const response = await authAxios.get(
      Constants.DOWNLOAD_OR_DELETE_CLAIM_DOCUMENT.replace("{{claimID}}", claimID).replace(
        "{{claimDocumentID}}",
        docID
      ),
      {
        responseType: "blob"
      }
    );

    const blobUrl = URL.createObjectURL(response.data);
    const fileExt = filtered[0].fileName.split(".").pop();
    if (fileExt === "pdf") {
      window.open(blobUrl);
    }
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `${filtered[0].fileName}`);
    link.click();
    link.remove();

    URL.revokeObjectURL(blobUrl);

    return response.data;
  },
  async deleteDoc({ _ }, { claimID, docID }) {
    const response = await authAxios.delete(
      Constants.DOWNLOAD_OR_DELETE_CLAIM_DOCUMENT.replace("{{claimID}}", claimID).replace(
        "{{claimDocumentID}}",
        docID
      )
    );
    return response;
  },
  async getAllClaimNotes({ _ }, claimID) {
    const response = await authAxios.get(
      Constants.GET_ALL_CLAIMS_NOTES.replace("{{claimID}}", claimID)
    );
    return response.data.payload;
  },
  async createClaimNote({ _ }, body) {
    const response = await authAxios.post(Constants.CREATE_CLAIM_NOTE, body);
    return response.data;
  },
  async deleteNote({ _ }, id) {
    const response = await authAxios.delete(Constants.DELETE_CLAIM_NOTE.replace("{{noteID}}", id));
    return response;
  },
  async updateClaimNote({ _ }, { id, newNote }) {
    const response = await authAxios.put(
      Constants.UPDATE_CLAIM_NOTE.replace("{{noteID}}", id),
      newNote
    );
    return response;
  },
  async getClaimsOptions() {
    const response = await authAxios.get(Constants.GET_ALL_CLAIMS);
    return response.data.payload;
  }
};

export default actions;
