<template>
  <div class="inline-block min-w-full py-2 align-middle overflow-y-auto max-h-[400px]">
    <table class="min-w-full divide-y divide-gray-300">
      <caption class="sr-only">
        Grouped Endorsement Grid
      </caption>
      <thead class="sticky top-0 bg-white">
        <tr>
          <th scope="col" class="relative py-3.5 pl-3 pr-4">
            <span class="sr-only">View</span>
          </th>
          <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
            Post Date
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Effective Date
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Description
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            Total Premium
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            User Description
          </th>
          <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
            User Name
          </th>
        </tr>
      </thead>

      <tbody class="divide-y divide-gray-200 bg-white">
        <tr v-for="(transaction, index) in rowData" :key="index">
          <td
            v-if="transaction.transactionType === 'EB'"
            class="relative whitespace-nowrap py-4 px-3 text-left text-sm font-medium cursor-pointer"
            data-test="transactionGrid.view"
          >
            <a @click="show(transaction)">View</a>
          </td>
          <td v-else></td>
          <td
            class="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900"
            data-test="transactionGrid.postDate"
          >
            {{ transaction.postDate | shortDateSimple }}
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
            data-test="transactionGrid.effectiveDate"
          >
            {{ transaction.effectiveDate | shortDateSimple }}
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
            data-test="transactionGrid.type"
          >
            {{ formatType(transaction.transactionType) }}
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
            data-test="transactionGrid.totalPremium"
          >
            {{ transaction.totalPremium | currency }}
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
            data-test="transactionGrid.userDescription"
          >
            {{ transaction.userDescription }}
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
            data-test="transactionGrid.userDescription"
          >
            {{ transaction.userName }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { DateFiltersMixin, NumberFiltersMixin } from "../../policy-wizard/utils/mixins/formatting";
import { getTransactionDescription } from "@/utils/transactionUtils";
export default {
  mixins: [DateFiltersMixin, NumberFiltersMixin],
  inheritAttrs: false,
  model: {
    prop: "rowData",
    event: "change"
  },
  props: {
    rowData: {
      type: Array,
      required: true
    }
  },
  computed: {},
  methods: {
    formatType(val) {
      return getTransactionDescription(val);
    },
    show(transaction) {
      this.$root.$emit("toggleEndorsementTransactionModal", transaction);
      this.$emit("close");
    }
  }
};
</script>
