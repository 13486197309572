import { Constants } from "@/config";
import { Logger } from "./utils";

export default {
  adaReady: false,
  controller: new AbortController(),
  enabled: false,
  debug: process.env.NODE_ENV === "development",
  logger: new Logger("Neptune Ada Plugin"),
  queue: [],
  sensitiveQueue: [],
  meta: {
    quote_number: null,
    policy_number: null
  },
  sensitiveMeta: {
    agent_email: null,
    agent_phone: null,
    agent_name: null,
    agent_number: null,
    environment: Constants.ENV,
    is_policy_holder: false,
    policyholder_email: null,
    policyholder_name: null,
    policyholder_phone: null,
    auth_token: null
  },
  cleanup() {
    if (this.debug) {
      this.logger.log("cleaning up");
    }

    this.controller.abort();
  },
  flushQueues() {
    if (this.debug) {
      this.logger.log("Queue Count", this.queue.length);
      this.logger.log("SensitiveQueue Count", this.sensitiveQueue.length);
    }

    for (const m of this.queue) {
      const updatedMeta = { ...this.meta, ...m };
      window.adaEmbed.setMetaFields(updatedMeta);
      this.meta = updatedMeta;
    }

    for (const m of this.sensitiveQueue) {
      const updatedSensitiveMeta = { ...this.sensitiveMeta, ...m };
      window.adaEmbed.setSensitiveMetaFields(updatedSensitiveMeta);
      this.sensitiveMeta = updatedSensitiveMeta;
    }

    this.queue = [];
    this.sensitiveQueue = [];
  },
  install(VueInstance, { enabled } = {}) {
    const plugin = this;
    if (typeof enabled !== "undefined") {
      plugin.enabled = Boolean(enabled);
    }

    window.addEventListener(
      "adaready",
      () => {
        if (plugin.debug) {
          plugin.logger.log("READY");
        }

        plugin.adaReady = true;
        plugin.flushQueues();
      },
      {
        signal: plugin.controller.signal
      }
    );

    window.addEventListener("beforeunload", () => {
      plugin.cleanup();
    });

    VueInstance.$ada = VueInstance.prototype.$ada = {
      toggle() {
        window.adaEmbed.toggle();
      },

      setMetaFields(meta) {
        if (!plugin.enabled) {
          return;
        }

        if (plugin.debug) {
          plugin.logger.table("setting meta", meta);
        }

        const updatedMeta = {
          ...plugin.meta,
          ...meta
        };

        if (plugin.adaReady || window.adaReady) {
          window.adaEmbed.setMetaFields(updatedMeta);
        } else {
          plugin.queue.push(updatedMeta);
        }

        // update plugin meta to keep track of all meta across
        // various updates. This allows for partial updates from plugin
        // consumers
        plugin.meta = updatedMeta;
      },
      setSensitiveMetaFields(meta) {
        if (!plugin.enabled) {
          return;
        }

        if (plugin.debug) {
          plugin.logger.table("setting sensitiveMeta", meta);
        }

        const updatedSensitiveMeta = {
          ...plugin.sensitiveMeta,
          ...meta
        };

        if (plugin.adaReady || window.adaReady) {
          window.adaEmbed.setSensitiveMetaFields(updatedSensitiveMeta);
        } else {
          plugin.sensitiveQueue.push(updatedSensitiveMeta);
        }

        plugin.sensitiveMeta = updatedSensitiveMeta;
      }
    };
  }
};
