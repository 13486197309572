import Vue from "vue";
import Vuex from "vuex";

import smartystreets from "./modules/smartystreets";
import settings from "./modules/settings";
import quoting from "./modules/quoting";
import auth from "./modules/auth";
import policy from "./modules/policy";
import user from "./modules/user";
import payment from "./modules/payment";
import document from "./modules/document";
import commissions from "./modules/commissions";
import moratoriums from "./modules/moratoriums";
import commercial from "./modules/commercial";
import claims from "./modules/claims";
import widgets from "./modules/widgets";
import VuexPersistence from "vuex-persist";
import axiosInstance from "./modules/auth/axiosConfig";
import { diff } from "deep-object-diff";
import { globalMutations } from "@/components/policy-wizard/store-module/mutations";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,

  /**
   * @type {(state: neptune.IRootState) => neptune.IRootState}
   */
  reducer: (state) => {
    const { auth, newQuote } = state;

    return {
      auth,
      newQuote
    };
  },
  strictMode: process.env.NODE_ENV === "development"
});

Vue.config.devtools = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

// TODO: Add vuex-pathify and vuex local storage

const otherMutations = {
  RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
  ...globalMutations
};

/** @type {import("vuex").Store<neptune.IRootState>} */
export const store = new Vuex.Store({
  strict: process.env.NODE_ENV === "development",
  state: {
    user: {
      email: "",
      first: "",
      last: "",
      company: ""
    },
    agencyInfo: {},
    registration: {},
    historicalPolicies: []
  },
  mutations: {
    ADD_HISTORICAL_POLICIES(state, historicalPolicies) {
      state.historicalPolicies = historicalPolicies;
    },
    SET_AGENCY_INFO(state, info) {
      state.agencyInfo = info;
    },
    /**
     *
     * @param {*} state
     * @param {Coordinates} coords
     */
    SET_CORDS(state, coords) {
      const { latitude, longitude } = coords;
      Object.assign(state.coordinates, {
        latitude,
        longitude
      });
    },

    ...otherMutations
  },

  actions: {
    /**
     * @type {() => Promise<Coordinates>}
     */
    requestLocation: async ({ commit }) => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (cb) => {
            commit("SET_CORDS", cb.coords);
            resolve(cb.coords);
          },
          (ecb) => {
            reject(ecb.message);
          }
        );
      });
    }
  },
  modules: {
    moratoriums,
    commissions,
    smartystreets,
    settings,
    quoting,
    policy,
    payment,
    auth,
    commercial,
    document,
    userdata: user,
    claims,
    widgets
  },
  getters: {
    /**
     *
     * @param {neptune.IRootState} state
     */
    policyNo(state, getters, rootState, rootGetters) {
      return (
        (rootGetters["auth/policyNo"] ||
        state.policy?.policyNo ||
        state.policy?.confirmationData?.policyNo)
      );
    }
  },
  plugins: [vuexLocal.plugin]
});

// Assign token to axios default if one is available
if (store.state.auth?.token) {
  axiosInstance.defaults.headers.Authorization = store.state.auth?.token;
}

if (process.env.NODE_ENV === "development") {
  let s = null;
  window.checkDiffs = () => {
    if (!s) {
      s = JSON.parse(JSON.stringify(store.state));
      console.log("first recording");
    } else {
      const s2 = JSON.parse(JSON.stringify(store.state));
      console.log(JSON.stringify(diff(s, s2), null, " "));

      s = s2;
    }
  };
}

export default store;
