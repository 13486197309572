import { authAxios } from "../auth";
import { Constants } from "../../../config";

export const actions = {
  async getPolicyDetails({ dispatch, commit, state, getters, rootState }, policyNo) {
    const policyNumber =
      policyNo || getters.allData.policyNo || rootState.auth.validateReturn.policy.policy.policyNo;

    const {
      data: { payload }
    } = await authAxios.get(Constants.GET_CONFIRMATION_URL.replace("{{policyNo}}", policyNumber));

    const transactionSummary = await dispatch("getPolicyTransactionsAndSummary", policyNumber);
    const { reasonCode } = transactionSummary;

    commit("SET_PROP", {
      path: "confirmationData",
      value: {
        ...payload,
        reasonCode
      }
    });

    commit("SET_PROP", {
      path: "transactionSummary",
      value: transactionSummary
    });

    await dispatch("getLienholders", policyNumber);
    await dispatch("document/load", policyNumber, {
      root: true
    });

    const {
      email,
      firstName: first,
      lastName: last,
      companyName: company,
      phone
    } = getters.allData;

    commit(
      "SET_PROP",
      {
        path: "user",
        value: {
          email,
          first,
          last,
          company,
          phone
        }
      },
      {
        root: true
      }
    );

    return state.confirmationData;
  },

  /**
   * TODO: remove reference to last document
   */
  async getDocumentsWithSignatures({ state, dispatch }) {
    const pn = state.confirmationData.policyNo;

    return dispatch("document/getDocuments", pn, {
      root: true
    });
  },

  /**
   * TODO: remove reference to last document
   */
  async getCancellationDocument({ state, dispatch }) {
    const pn = state.confirmationData.policyNo;

    /** @type {neptune.documents.DocumentWithSignature[]} */
    const signatures = await dispatch("document/getDocuments", pn, {
      root: true
    });

    return signatures.find((v) => v.document.docName.toLowerCase() === "cancel");
  },

  /**
   * Get a collection of transactions for a given policy
   * @param {import('vuex').ActionContext<neptune.IRootState>} param0
   * @param {string} policyNo
   */
  async getActiveTransactions({ rootState, commit }, [policyNo]) {
    // Cancel Token
    let transactionCancel;
    if (typeof transactionCancel === "function") {
      // @ts-ignore
      transactionCancel();
    }

    try {
      /** @type {import('axios').AxiosResponse<{payload: neptune.policyholder.IPolicyTransactionItem[]}>} */
      const {
        data: { payload: data }
      } = await authAxios.get(Constants.GET_TRANSACTIONS_URL.replace("{{policyNo}}", policyNo), {
        cancelToken: transactionCancel,
        headers: {
          Authorization: rootState.auth.token
        }
      });

      /**
       * If it is an array of transactions
       * sort them chronologically
       */
      if (Array.isArray(data)) {
        data.sort((a, b) => {
          const aDate = Date.parse(a.postDate);
          const bDate = Date.parse(b.postDate);

          return aDate - bDate;
        });
      }

      commit("SET_PROP", {
        path: "transactions",
        value: data
      });

      return data;
    } catch (error) {
      this.$router.replace({
        name: "Error",
        path: "/error",
        params: {
          error: error,
          policyNo: policyNo
        }
      });
    }
  },

  async getPolicyTransactionsAndSummary({ rootState }, policyNo) {
    const po = policyNo || rootState.policy.confirmationData.policyNo;

    let canceller;
    if (typeof canceller === "function") {
      // @ts-ignore
      canceller();
    }

    /** @type {ApiResponseWithPayload<neptune.payloads.TransactionPayload>} */
    const { data } = await authAxios.get(
      Constants.GET_TRANSACTIONSANDSUMS_URL.replace("{{policyNo}}", po),
      {
        cancelToken: canceller
      }
    );

    return data.payload;
  },

  /**
   * Get a collection of documents for a given policy
   * @param {import('vuex').ActionContext<neptune.IRootState>} param0
   * @param {string} policyNo
   */
  async getDocuments(_, policyNo) {
    const {
      data: { payload }
    } = await authAxios.get(
      Constants.GET_DOCUMENTS_PDF_URL.replace("{{policyNo}}", policyNo),
      // `/api/v1/documents/policy/${policyNo}/pdfs`,
      {
        // cancelToken: transactionCancel
      }
    );

    return payload;
  },

  // async getBillingInfo(_, policyNo) {

  // },

  async getLienholders({ commit }, policyNo) {
    const { data } = await authAxios.get(
      `${Constants.BASE_PSDN_URL}/api/v1/policies/${policyNo}/PolicyLiens`
    );
    commit("SET_PROP", {
      path: "lienholderInfo",
      value: data.payload
    });
  },

  async getInvoices({ commit }, policyNo) {
    const { data } = await authAxios.get(
      `${Constants.BASE_PSDN_URL}/api/v1/${policyNo}/invoiceDetails`
    );
    commit("SET_PROP", {
      path: "invoiceInfo",
      value: data.payload
    });
  },

  /**
   * This will cancel the policy.
   * If policyNo is undefined, will cancel current
   */
  async cancelPolicy({ dispatch }, cancelPayload) {
    /** @type {import('axios').AxiosResponse<neptune.IPayloadResponse>} */
    const {
      data: { message, payload, statusCode }
    } = await authAxios.post(
      `${Constants.BASE_PSDN_URL}/api/v1/policies/cancelPolicy`,
      cancelPayload,
      { headers: { "Content-Type": "application/json" } }
    );

    if (message) {
      if (statusCode === 500)
        return Promise.reject(new Error("Policy is already in Pending Cancel or Cancelled."));
      return Promise.reject(new Error(message));
    }

    await dispatch("getPolicyDetails", cancelPayload.PolicyNumber);

    return payload;
  },
  // async getPolicyDetails

  async finalizeCancellation({ dispatch }, cancelPayload) {
    /** @type {import('axios').AxiosResponse<neptune.IPayloadResponse>} */
    const {
      data: { message, payload }
    } = await authAxios.post(
      `${Constants.BASE_PSDN_URL}/api/v1/policies/FinalizeCancelPolicy`,
      cancelPayload
    );

    if (message) {
      return Promise.reject(new Error(message));
    }

    await dispatch("getPolicyDetails", cancelPayload.PolicyNumber);

    return payload;
  },

  // eslint-disable-next-line no-unused-vars
  async sendNewLinkAxios({ getters }, { policyNo, email }) {
    const url = Constants.RESEND_POLICY_LINK.replace("{{policyNo}}", policyNo);
    const response = await authAxios.get(`${url}/email-link?email=${email}`);
    return response;
  },

  async getPolicyNotes({ commit }, policyNo) {
    const url = Constants.GET_POLICY_NOTES.replace("{{policyNo}}", policyNo);
    const response = await authAxios.get(url);
    commit("SET_PROP", { path: "notes", value: response.data?.payload?.data });
    return response.data.payload.data;
  },

  async getDefaultPaymentMethod({ commit }, policyNo) {
    const url = Constants.GET_DEFAULT_PAYMENT_METHOD.replace("{{policyNo}}", policyNo);
    const response = await authAxios.get(url);

    commit("SET_PROP", { path: "defaultPaymentMethod", value: response.data?.payload });
    return response.data.payload;
  },

  // eslint-disable-next-line no-unused-vars
  async upsertPolicyNote({ commit }, { policyNo, noteBody, isNotePrivate, type, meta, reason }) {
    const url = Constants.UPSERT_POLICY_NOTE.replace("{{policyNo}}", policyNo);
    const response = await authAxios.post(url, {
      note: noteBody,
      private: isNotePrivate,
      type,
      meta,
      reason
    });

    commit("SET_PROP", { path: "notes", value: response.data?.payload?.data });
    return response.data.payload.data;
  },
  async updatePolicyNote(
    { commit },
    {
      policyNoteId,
      policyId,
      noteBody,
      private1,
      policyTransactionId,
      created_At,
      createdBy_UserId,
      policyNo,
      type,
      meta,
      reason
    }
  ) {
    const url = Constants.UPSERT_POLICY_NOTE.replace("{{policyNo}}", policyNo);
    const response = await authAxios.post(`${url}`, {
      policyId: policyId,
      note: noteBody,
      private: private1,
      policyTransactionId: policyTransactionId,
      policyNoteId: policyNoteId,
      created_At: created_At,
      createdBy_UserId: createdBy_UserId,
      type,
      meta,
      reason
    });

    commit("SET_PROP", { path: "notes", value: response.data?.payload?.data });
    return response.data.payload.data;
  },

  // eslint-disable-next-line no-unused-vars
  async deletePolicyNote({ getters, rootState }, { policyNo, note }) {
    const url = Constants.DELETE_POLICY_NOTE.replace("{{policyNo}}", policyNo);

    const response = await authAxios.delete(`${url}`, {
      data: note
    });
    return response;
  },
  async getPolicyNotifications({ commit }, policyNo) {
    /** @type {ApiResponseWithPayload<neptune.payloads.TransactionPayload>} */
    const { data } = await authAxios.get(
      Constants.GET_TRANSACTIONSANDSUMS_URL.replace("{{policyNo}}", policyNo)
    );

    commit("SET_PROP", {
      path: "transactions",
      value: data.payload.transactions
    });

    const url = Constants.GET_POLICY_NOTIFICATIONS.replace("{{policyNo}}", policyNo);
    const response = await authAxios.get(`${url}`);

    return response.data.payload;
  },

  async getPolicyNotice({ rootState }, { policyNo, path }) {
    const url = Constants.GET_POLICY_NOTICE.replace("{{policyNo}}", policyNo).replace(
      "{{pdfPath}}",
      path
    );

    const response = await authAxios.get(`${url}`, {
      headers: {
        contentType: "application/pdf",
        Authorization: rootState.auth.token
      },
      responseType: "arraybuffer"
    });

    const pdfBlob = new Blob([response.data], { type: "application/pdf" });

    const blobUrl = window.URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");

    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", "letter.pdf");
    link.click();
    link.remove();
    URL.revokeObjectURL(blobUrl);
    return response.data;
  },
  async getPolicyTemplate({ rootState, commit }, { policyNo, template, id, version }) {
    /** @type {ApiResponseWithPayload<neptune.payloads.TransactionPayload>} */
    const { data } = await authAxios.get(
      Constants.GET_TRANSACTIONSANDSUMS_URL.replace("{{policyNo}}", policyNo)
    );

    commit("SET_PROP", {
      path: "transactions",
      value: data.payload.transactions
    });

    const url = Constants.GET_POLICY_TEMPLATE.replace("{{policyNo}}", policyNo).replace(
      "{{emailTemplate}}",
      template
    );
    let response;
    if (version) {
      response = await authAxios.get(`${url}/${id}/${version}`, {
        headers: {
          contentType: "application/pdf",
          Authorization: rootState.auth.token
        }
      });
    } else {
      response = await authAxios.get(`${url}/${id}`, {
        headers: {
          contentType: "application/pdf",
          Authorization: rootState.auth.token
        }
      });
    }
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async reinstatePolicyAction({ commit }, { policyNo, suppress }) {
    const url = Constants.REINSTATE_POLICY.replace("{{policyNo}}", policyNo);
    const response = await authAxios.post(`${url}/${suppress}`);
    return response;
  },
  setAddressDataLoading({ commit }) {
    commit("SET_ADDRESS_DATA", { loading: true });
  },
  async getAddressData({ commit, getters }) {
    try {
      commit("SET_ADDRESS_DATA", { loading: true });

      const addressID = getters.addressID;
      const addressDataVersion = getters.addressDataVersion;
      const url = Constants.GET_ADDRESS_DATA.replace("{{addressID}}", addressID).replace(
        "{{addressDataVersion}}",
        addressDataVersion
      );

      const response = await authAxios.get(url);
      commit("SET_ADDRESS_DATA", {
        ...response.data,
        loading: false
      });
    } catch (e) {
      console.error(e);
      commit("SET_ADDRESS_DATA", null);
    }
  }
};

export default actions;
