<template>
  <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class=" text-gray-900 text-lg  m-0 leading-none">
        <span class="font-medium" data-test="widgetRegistrationTitle"
          >{{ registrationIndex !== -1 ? "Modify Registration" : "New Registration" }}
        </span>
      </h1>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form class="mt-4" @submit.prevent="handleSubmit(saveRegistrationNew)">
          <div class="mb-4 flex flex-col gap-2">
            <label for="Logo" class="text-sm text-gray-700 leading-none">Logo</label>
            <div class="relative flex flex-row items-end">
              <img
                v-if="registrationEdit.Logo"
                :src="registrationEdit.Logo"
                class="mr-2 image-sizer pr-1 pt-1"
                alt="Logo"
              />
              <div class="flex flex-row items-center justify-start gap-2">
                <label
                  class="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md text-white bg-deepsea-full hover:bg-deepsea-full/75 focus:outline-none cursor-pointer"
                  data-test="widgetLogoChooseFile"
                >
                  <input
                    type="file"
                    accept="image/*"
                    data-test="widgetLogoInput"
                    class="hidden"
                    @change="onLogoSelected"
                  />
                  Choose File
                </label>
                <div class="text-xs" data-test="widgetLogoFileName">{{ selectedFileName }}</div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label for="Widget Name" class="text-sm text-gray-700 leading-none">Widget Name</label>
            <div class="relative">
              <input
                v-model="registrationEdit.Name"
                name="Widget Name"
                class="flex items-center gap-2 focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                data-test="widgetNameEdit"
              />
            </div>
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            class="w-full"
            :rules="'required|email'"
            name="Referral Email"
          >
            <div class="mb-2">
              <label for="Referral Email" class="text-sm text-gray-700  leading-none"
                ><span class="text-red-400">*</span> Referral Email</label
              >
              <label
                class="flex items-center gap-2 focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
              >
                <!-- Heroicon name: outline/paper-airplane -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
                <input
                  v-model="registrationEdit.ReferralEmail"
                  name="Referral Email"
                  type="text"
                  placeholder="widget@widget.com"
                  data-test="widgetEmailEdit"
                  class="grow"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                />
              </label>
              <FadeTranslate>
                <p v-if="errors[0]" class="text-red-500 text-sm m-0">{{ errors[0] }}</p>
              </FadeTranslate>
            </div>
          </ValidationProvider>
          <div class="mb-4">
            <label class="relative flex items-center justify-start">
              <input
                v-model="registrationEdit.UseReferralEmailOnLeads"
                type="checkbox"
                class="focus:ring-2 ring-sky-600 mx-2 rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2 accent-sky-600"
                data-test="widgetReferralCheckbox"
              />
              <span class="text-xs text-gray-900  leading-none"
                >Use referral email address to receive emails about leads and initial policy
                binds</span
              >
            </label>
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            class="w-full"
            :rules="'required|phoneValidator'"
            name="Referral Phone Number"
          >
            <div class="mb-4">
              <label for="Referral Phone Number" class="text-sm text-gray-700  leading-none"
                ><span class="text-red-400">*</span> Referral Phone Number</label
              >
              <label
                class="flex items-center gap-2 focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
              >
                <!-- Heroicon name: outline/phone -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
                <input
                  v-model="registrationEdit.ReferralPhone"
                  v-mask="'###-###-####'"
                  name="Referral Phone Number"
                  type="text"
                  placeholder="777-777-7777"
                  data-test="widgetPhoneEdit"
                  class="grow"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                />
              </label>
              <FadeTranslate>
                <p v-if="errors[0]" class="text-red-500 text-sm m-0">{{ errors[0] }}</p>
              </FadeTranslate>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            class="w-full"
            :rules="'websiteValidator'"
            name="Referral Website"
          >
            <div class="mb-4">
              <label for="Referral Website" class="text-sm text-gray-700  leading-none"
                >Referral Website</label
              >
              <label
                class="flex items-center gap-2 focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
              >
                <!-- Heroicon name: outline/computer-desktop -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                  />
                </svg>
                <input
                  v-model="registrationEdit.ReferralWebsite"
                  name="Referral Website"
                  type="text"
                  placeholder="https://neptuneflood.com"
                  data-test="widgetUrlEdit"
                  class="grow"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                />
              </label>
              <FadeTranslate>
                <p v-if="errors[0]" class="text-red-500 text-sm m-0">{{ errors[0] }}</p>
              </FadeTranslate>
            </div>
          </ValidationProvider>
          <div class="mb-4">
            <label for="Disclosure Text" class="text-sm text-gray-700 leading-none"
              >Disclosure Text</label
            >
            <div class="relative">
              <textarea
                v-model="registrationEdit.Disclosure"
                name="Disclosure Text"
                class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                data-test="widgetDisclosureEdit"
              />
            </div>
          </div>
          <div class="mb-4">
            <label class="text-sm text-gray-700 leading-none">Logo Alignment</label>
            <div class="relative flex gap-2 py-2 px-2">
              <label class="flex items-center gap-2 text-sm text-gray-700 leading-none">
                <input
                  v-model="computedFormat"
                  data-test="widgetCenterEdit"
                  name="Logo Alignment"
                  value="center"
                  type="radio"
                  class="focus:ring-2 dark:focus:ring-2 ring-sky-600 text-sky-600"
                />
                Center
              </label>
              <label class="flex items-center gap-2 text-sm text-gray-700 leading-none"
                ><input
                  v-model="computedFormat"
                  data-test="widgetRightEdit"
                  name="Logo Alignment"
                  value="right"
                  type="radio"
                  class="focus:ring-2 dark:focus:ring-2 ring-sky-600 text-sky-600"
                />
                Right</label
              >
            </div>
          </div>
          <FadeTranslate>
            <p v-if="saveError" class="text-red-500 my-4 text-sm m-0">{{ saveError }}</p>
          </FadeTranslate>
          <div slot="footer" class="flex justify-end gap-2">
            <button
              data-test="widgetCancelEdit"
              :disabled="saving"
              class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              @click.prevent="$emit('close')"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="inline-flex justify-items-center items-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none"
              data-test="widgetCancelSave"
              :class="{
                'cursor-not-allowed opacity-60 hover:bg-deepsea-full':
                  loading ||
                  !canSaveRegistration ||
                  invalidEmail ||
                  invalidPhoneNumber ||
                  invalidWebsite
              }"
              :disabled="
                !canSaveRegistration || invalidEmail || invalidPhoneNumber || invalidWebsite
              "
            >
              <span v-if="!loading">Confirm</span>
              <svg
                v-else
                class="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from "vee-validate";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";
import { mapActions, mapGetters } from "vuex";
setInteractionMode("eager");

extend("websiteValidator", (value) => {
  return WebsiteRegex.test(value) || "Please enter a valid url";
});
extend("phoneValidator", (value) => {
  return PhoneRegex.test(value) || "Please enter a valid phone number";
});
const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PhoneRegex = /^\d{3}-\d{3}-\d{4}$/;
const WebsiteRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FadeTranslate
  },
  props: {
    registrationIndex: Number
  },
  data() {
    return {
      registrationEdit: {},
      loading: false,
      saving: null,
      saveError: null,
      selectedFileName: ""
    };
  },
  computed: {
    ...mapGetters("widgets", ["getRegistrationAtIndex"]),
    registration() {
      return this.getRegistrationAtIndex(this.registrationIndex);
    },
    agentNo() {
      return this.$store.state.widgets.registration.AgentNo;
    },
    computedFormat: {
      get() {
        if (Object.keys(this.registrationEdit).length === 0 || !this.registrationEdit?.Format) {
          return null;
        }
        try {
          const formatValue = JSON.parse(this.registrationEdit?.Format);
          return formatValue?.align;
        } catch (e) {
          return null;
        }
      },
      set(value) {
        const formatValue = { align: value };
        this.registrationEdit.Format = JSON.stringify(formatValue);
      }
    },
    canSaveRegistration() {
      const { ReferralEmail, ReferralPhone } = this.registrationEdit;
      return !this.saving && EmailRegex.test(ReferralEmail) && PhoneRegex.test(ReferralPhone);
    },
    invalidEmail() {
      return !EmailRegex.test(this.registrationEdit.ReferralEmail);
    },
    invalidPhoneNumber() {
      return !PhoneRegex.test(this.registrationEdit.ReferralPhone);
    },
    invalidWebsite() {
      return (
        this.registrationEdit.ReferralWebsite &&
        !WebsiteRegex.test(this.registrationEdit.ReferralWebsite)
      );
    }
  },
  mounted() {
    if (this.registrationIndex !== -1)
      this.registrationEdit = {
        ...this.registration,
        Format: this.registration?.Format ?? '{ "align" : "center" }'
      };
    else this.registrationEdit = { Format: '{ "align" : "center" }' };
  },

  methods: {
    ...mapActions("widgets", ["setRegistration", "updateRegistration"]),
    onLogoSelected(e) {
      const [file] = e.target.files;
      const reader = new FileReader();
      this.selectedFileName = e.target.files[0].name;
      reader.addEventListener("load", () => {
        this.registrationEdit.Logo = reader.result;
      });
      reader.readAsDataURL(file);
    },
    async saveRegistrationNew() {
      this.loading = true;
      try {
        await this.updateRegistration({
          ...this.registrationEdit,
          agentNo: this.agentNo,
          index: this.registrationIndex
        });
        this.saveError = null;
        this.$emit("close");
      } catch (e) {
        this.saveError = e.message;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
