export const docTypesArray = [
  { type: "Policy", id: 1 },
  { type: "Application", id: 2 },
  { type: "HUD-1", id: 3 },
  { type: "Elevation Certificate", id: 4 },
  { type: "Dilligent Effort", id: 5 },
  { type: "Supporting Documents", id: 6 },
  { type: "Closing Document", id: 7 },
  { type: "Cancellation Document", id: 8 },
  { type: "Property Sold", id: 9 },
  { type: "Contents Sold", id: 10 },
  { type: "Endorsement Agreement", id: 11 },
  { type: "Flat Cancel Document", id: 12 },
  { type: "DNOC Document", id: 14 },
  { type: "Endorsement Tax Notice", id: 16 },
  { type: "Supporting Cancel Documents", id: 17 },
  { type: "Signed Part C", id: 19 },
  { type: "Rollover Declarations Page", id: 21 }
];

export const nyDocTypesArray = [
  { type: "Policy", id: 1 },
  { type: "Application", id: 2 },
  { type: "HUD-1", id: 3 },
  { type: "Elevation Certificate", id: 4 },
  { type: "Dilligent Effort", id: 5 },
  { type: "Supporting Documents", id: 6 },
  { type: "Closing Document", id: 7 },
  { type: "Cancellation Document", id: 8 },
  { type: "Property Sold", id: 9 },
  { type: "Contents Sold", id: 10 },
  { type: "Endorsement Agreement", id: 11 },
  { type: "Flat Cancel Document", id: 12 },
  { type: "NY Cancellation Endorsement", id: 13 },
  { type: "DNOC Document", id: 14 },
  { type: "NY Endorsement", id: 15 },
  { type: "Endorsement Tax Notice", id: 16 },
  { type: "Supporting Cancel Documents", id: 17 },
  { type: "Signed Part C", id: 19 },
  { type: "Rollover Declarations Page", id: 21 }
];

export default { docTypesArray, nyDocTypesArray };
