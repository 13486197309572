<template>
  <div id="main-bg" class="error__page-not-found">
    <div class="text-center max-w-screen-lg">
      <h2>
        Whoops, we've hit rough waters! Our server is currently overwhelmed, just like a boat in a
        storm. Please navigate back later.
      </h2>
      <h3>
        In the meantime, feel free to reach out to our support team at
        <a
          href="mailto:support@neptuneflood.com"
          class="text-deepsea-full hover:text-deepsea-full/75"
          >support@neptuneflood.com</a
        >
      </h3>
      <div
        class="flex flex-col md:flex-row md:space-x-12 md:justify-center md:items-center min-h-[15vh]"
      >
        <button
          class="!px-4 !py-2 rounded bg-lightair-full border-deepsea-full text-deepsea-full hover:bg-lightair-900"
          @click="goBack()"
        >
          Try Again
        </button>
        <button
          class="!px-4 !py-2 rounded bg-deepsea-full border-deepsea-full text-white hover:bg-deepsea-900"
          @click="goHome()"
        >
          Go Home
        </button>
      </div>
      <p v-if="error">What exactly happened? <br />{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { Constants } from "../config";

export default {
  data() {
    return {
      error: ""
    };
  },
  mounted() {
    this.error = this.$route.params.error;
  },
  methods: {
    goBack() {
      window.history.back();
    },
    goHome() {
      window.location.replace(Constants.BASE_TRITON_UI_URL);
    }
  }
};
</script>

<style lang="scss" scoped>
iframe {
  pointer-events: none;
}
.error__page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  height: 100%;
  color: antiquewhite;
  @apply bg-lightair-light;
}
#main-bg {
  background: url("/static/images/backgrounds/updated_waves.webp") repeat;
  background-size: 100% 100%;
}
.button-height {
  height: 40px;
  margin-top: 80px;
  margin-left: 150px;
  margin-right: 150px;
}
.middle-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.left {
  background-color: #16abe2;
  color: antiquewhite;
  &:hover,
  &:focus {
    color: antiquewhite;
    background-color: #16abe2;
    filter: brightness(110%);
  }
}
.right {
  background-color: #ec1f80;
  color: antiquewhite;
  &:hover,
  &:focus {
    color: antiquewhite;
    background-color: #ec1f80;
    filter: brightness(110%);
  }
}
</style>
