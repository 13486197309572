/** @type {import('vuex').GetterTree<any, neptune.IRootState>} */
const getters = {
  // user id is not needed anywhere
  // UserId(_,__,rootState) {
  //     return rootState.payment.UserId
  // }
  policyState(state, getters, rootState, rootGetters) {
    return rootState[rootGetters.policyNo];
  }
};

export default getters;
