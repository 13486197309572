<template>
  <!-- Transfer Modal -->
  <div
    title="Transfer Payment to Another Policy"
    class="fixed inset-0 z-[2000] flex items-center justify-center px-4"
  >
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class="font-bold text-gray-900 text-lg  m-0 leading-none">
        Transfer payment to another policy
      </h1>
      <div class="mt-6">
        <label class="text-sm mb-1">
          Transferring From:
        </label>
        <div class="bg-gray-100 p-3 rounded-md">
          <div class="text-base font-bold">{{ $route.params.policyNo }}</div>

          <p class="text-sm leading-snug text-gray-600">{{ activePolicyItem.addr1 }}</p>
          <p class="text-sm leading-snug text-gray-600">
            {{ activePolicyItem.city }}, {{ activePolicyItem.state }}
            {{ activePolicyItem.zip }}
          </p>
        </div>
      </div>

      <div class="flex flex-col mt-4">
        <label class="text-sm"> Max amount to transfer: {{ transferAmountMax | currency }}</label>
        <input
          v-model="amount"
          type="number"
          placeholder="Amount to transfer..."
          class="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 px-3 border"
          @blur="setTwoNumberDecimal"
        />
      </div>

      <div class="mt-4">
        <h2 class="text-sm">Transferring To:</h2>
        <input
          v-model="recipientPolicyNo"
          placeholder="Policy Number..."
          type="text"
          label="Select a payment to transfer from the grid above."
          class="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md py-2 px-3 border"
          @keyup="populatePolicy"
        />

        <div v-if="recipientComputed" class="bg-gray-100 p-3 rounded-md mt-1">
          <p class="text-sm leading-snug text-gray-600">{{ recipientComputed.addr1 }}</p>
          <p class="text-sm leading-snug text-gray-600">
            {{ recipientComputed.city }}, {{ recipientComputed.state }}
            {{ recipientComputed.zip }}
          </p>
        </div>
      </div>

      <div class="mt-4 text-right space-x-2">
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium"
          @click="$emit('close')"
        >
          Cancel
        </button>
        <button
          type="primary"
          :disabled="transferDisabled"
          :loading="loading"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-deepsea-full hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-deepsea-medium"
          :class="{ 'opacity-50 cursor-not-allowed': transferDisabled }"
          @click="sendTransfer(selectedTransferPayment, recipientPolicyNo)"
        >
          Transfer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { Constants } from "@/config";
import { NumberFiltersMixin } from "@/components/policy-wizard/utils/mixins/formatting";

export default {
  mixins: [NumberFiltersMixin],
  props: {
    selectedTransferPayment: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      amount: 0,
      recipient: null,
      currentIndex: 0,
      recipientPolicyNo: "",
      loading: false,
      transferAmountMax: null
    };
  },
  computed: {
    ...mapState("quoting", ["transactions"]),
    recipientComputed() {
      if (!this.recipient) return null;

      return {
        addr1: this.recipient?.addr1,
        city: this.recipient?.city,
        state: this.recipient?.state,
        zip: this.recipient?.zip
      };
    },
    activePolicyItem() {
      if (this.transactions && this.transactions.length)
        return this.transactions[this.currentIndex];

      return {};
    },
    policyData() {
      return this.$store.getters["policy/allData"];
    },
    transferDisabled() {
      return (
        this.amount <= 0 ||
        !this.recipientPolicyNo ||
        this.loading ||
        this.transferAmountMax < this.amount
      );
    }
  },
  mounted() {
    if (this.policyData.totalDue > 0) {
      const payments = this.policyData.payments.filter(
        (payment) => payment.paymentId === this.selectedTransferPayment.paymentId
      );
      let total = 0;
      payments.map((payment) => (total = payment.amount + total));
      this.transferAmountMax = total;
    } else this.transferAmountMax = this.selectedTransferPayment.amount;
  },
  methods: {
    ...mapActions("payment", ["transferPayment"]),
    ...mapActions("policy", {
      upsertPolicyNote: "upsertPolicyNote"
    }),

    populatePolicy() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(async () => {
        try {
          const result = await axios.get(
            Constants.GET_TRANSACTIONS_URL.replace("{{policyNo}}", this.recipientPolicyNo),
            {
              headers: {
                Authorization: this.$store.state.auth.token,
                "Content-Type": "application/json"
              }
            }
          );
          this.recipient = result.data.payload[0];
        } catch {
          this.recipient = null;
        }
      }, 150);
    },
    setTwoNumberDecimal() {
      this.amount = ((this.amount * 100) / 100).toFixed(2);
    },
    async sendTransfer(selectedTransferPayment, recipientPolicyNo) {
      try {
        this.loading = true;
        const { paymentId } = selectedTransferPayment;
        const payment = this.policyData.payments.find((payment) => payment.paymentId === paymentId);
        const res = await this.transferPayment({
          body: payment,
          amountParam: this.amount,
          curPolicyNo: this.$route.params.policyNo,
          nextPolicyNo: recipientPolicyNo
        });

        this.loading = false;
        this.$store.dispatch("policy/getPolicyDetails", this.$route.params.policyNo);

        if (res.message)
          this.$notify.error({
            title: "Error",
            message: `${res.message}`
          });
        else
          this.$notify({
            title: "Success",
            message: "Transfer has been applied successfully!",
            type: "success"
          });

        await this.upsertPolicyNote({
          policyNo: this.$route.params.policyNo,
          noteBody: `Payment has been transferred to ${recipientPolicyNo}`,
          isNotePrivate: false
        });
        await this.upsertPolicyNote({
          policyNo: recipientPolicyNo,
          noteBody: `Payment has been transferred from ${this.$route.params.policyNo}`,
          isNotePrivate: false
        });

        this.$emit("close");
      } catch (error) {
        throw new Error(error);
      } finally {
        this.loading = false;
        this.recipient = null;
        this.amount = 0;
        this.recipientPolicyNo = "";
      }
    }
  }
};
</script>
