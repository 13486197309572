import http from "./axiosInstance";
import set from "lodash/set";

export const globalMutations = {
  SET_STATE(state, data) {
    Object.assign(state, data);
  },
  SET_PROP(state, { path, value }) {
    set(state, path, value);
  }
};

/** @type {import("vuex").MutationTree<any>} */
const mutations = {
  SET_TOKEN(state, token) {
    // http.defaults.headers['Authorization'] = token
    http.defaults.headers.Authorization = token;
  }
};

export default mutations;
