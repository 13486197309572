<template>
  <div v-loading="loading" class="h-full flex flex-col">
    <div id="payment-element">
      <!-- Elements will create form elements here -->
    </div>
    <div slot="footer" class="mt-4">
      <button
        v-if="!loading"
        class="bg-deepsea-full hover:bg-deepsea-full/75 text-white p-2 rounded md:ml-3 w-full md:w-auto"
        data-test="updateCardButton"
        @click.prevent="sendClick"
      >
        Update Card
      </button>
    </div>
  </div>
</template>

<script>
import { Constants } from "@/config";
import { authAxios } from "@/store/modules/auth";

export default {
  props: {
    policy: Object,
    isEarthquake: {
      type: Boolean,
      default: false
    },
    paymentInterval: {
      type: String
    },
    optInAutopayRenewal: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      client_secret: "",
      elementOptions: {
        appearance: {}
      },
      confirmParams: {
        return_url: window.location.href
      },
      elements: null,
      stripe: null
    };
  },
  async mounted() {
    try {
      this.loading = true;
      const url = Constants.CREATE_SETUP_INTENT_WITH_KEY;
      const res = await authAxios.post(url, {
        stripeCustomerId: null,
        firstname: this.$store.getters["policy/summaryData"].firstName,
        lastName: this.$store.getters["policy/summaryData"].lastName,
        email: this.$store.getters["policy/summaryData"].email,
        phone: this.$store.getters["policy/summaryData"].phone,
        policyNo: this.policy.policyNo,
        metadata: `{"email": ${this.$store.getters["policy/summaryData"].email},"phone": ${this.$store.getters["policy/summaryData"].phone}}`
      });

      this.stripe = await window.Stripe(res.data.payload.stripeKey);
      this.client_secret = res.data.payload.data.client_secret;

      if (this.client_secret) {
        this.elements = await this.stripe.elements({
          clientSecret: this.client_secret
        });

        const paymentElement = await this.elements.create("payment");
        paymentElement.mount("#payment-element");
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async sendClick() {
      try {
        this.loading = true;
        const { setupIntent } = await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: this.confirmParams,
          redirect: "if_required"
        });

        const url = Constants.UPDATE_PAYMENT_METHOD;
        const body = {
          PolicyNo: this.policy.policyNo,
          PaymentMethodId: setupIntent.payment_method,
          Default: true,
          ChargeAfterUpdate:
            // matching previous logic for showing update payment method for EQ policies
            this.isEarthquake &&
            (this.paymentInterval === "monthly" || this.paymentInterval === "Monthly"),
          EmailReceipt: false,
          OptInAutoPayRenewal: this.optInAutopayRenewal
        };

        await authAxios.post(url, body);

        this.$notify({
          title: "Success",
          message: "Payment method was successfully updated!",
          type: "success"
        });
      } catch (e) {
        this.$notify({
          title: "Failure",
          type: "error",
          message: "There was an error updating the payment method."
        });
        throw new Error(e);
      } finally {
        this.$emit("done");
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#payment-element {
  border: none;
}
</style>
