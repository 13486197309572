<template>
  <!-- <svg width="100%"
       :height="height"> -->

  <path :d="lineGen(_points())" />
</template>

<script>
import { range, curveNatural, area } from "d3";
export default {
  props: {
    segments: {
      type: Number,
      default: 5
    },

    /**
     * Main offset (effects all y positions)
     * @type {Vue.PropType<number>}
     */
    offset: {
      type: Number,
      default: 0
    },

    /**
     * Wave offset in degrees
     * @type {Vue.PropType<number>}
     */
    stepOffset: {
      type: Number,
      default: 20
    },

    /**
     * The fill color of the wave
     * @type {Vue.PropType<number>}
     */
    fill: {
      type: String,
      default: "#16aae1"
    },

    /**
     * Height of entire path
     * @type {Vue.PropType<number>}
     */
    height: {
      type: Number,
      default: 100
    },

    /**
     * Height of a single wave
     * @type {Vue.PropType<number>}
     */
    waveHeight: {
      type: Number,
      default: 50
    }
  },

  data: () => ({
    dimensions: {
      width: 100,
      height: 100
    },
    time: 0
  }),

  computed: {
    /**
     * Points for the line generator
     * @returns {{x: number, y: number}[]}
     */
    _points() {
      const xMul = this.dimensions.width / (this.segments - 1);

      const val = range(this.segments).map((n, i) => {
        const x = n * xMul;

        // Current degree of rotation
        const deg = this.offset + this.stepOffset * i;

        // in radians
        const rad = deg * (Math.PI / 180);

        // debugger
        const y = Math.sin(rad) * this.waveHeight + this.waveHeight;

        return {
          x,
          y
        };
      });

      return () => val;
    },

    /**
     * Points that fill the entire area
     * @returns {{x: number, y: number}[]}
     */
    _fillPoints: {
      get() {
        const bottomPoints = [...this.points].reverse().map((v) => ({
          ...v,
          y: v.y + this.height
        }));
        return () => [...this.points, ...bottomPoints];
      },
      cache: false
    },

    lineGen() {
      // return line()
      //   .x(v => v.x)
      //   .y(v => v.y)
      //   .curve(curveNatural)

      return area()
        .x((v) => v.x)
        .y1((v) => v.y + this.height)
        .y0((v) => v.y)
        .curve(curveNatural);
    }
  },

  mounted() {
    this.updateDimensions();
  },

  methods: {
    updateDimensions() {
      const { width, height } = this.$parent
        ? this.$parent.$el.getBoundingClientRect()
        : this.$el.getBoundingClientRect();

      Object.assign(this.dimensions, {
        width,
        height
      });
    }
  }
};
</script>

<style></style>
