<template>
  <div class="signature-form-container">
    <!-- Signatures -->
    <div ref="scroller" class="signature-container">
      <div
        v-for="(sig, i) in model.signatures"
        :key="'s' + i"
        class="signature-inner"
        :class="{ active: activeIndex === i }"
        data-test="cancelSigModal"
      >
        <DocumentSignatureSection
          ref="signature"
          :signature-image="sig.signedImage"
          :text="sig.docText"
          class="signature"
          @sign="onSectionSign(sig, i)"
          @signed="focusNext($event.$el, i)"
        />
      </div>
    </div>

    <!-- Popup -->
    <div v-if="showPopup" class="popup">
      <slot name="popup" v-bind="$data">
        <el-card class="card my-auto" header="Create a Signature">
          <party-signature-form
            ref="signatureForm"
            :name="activeSignature.signedByName || ''"
            :initials="activeSignature.ignedByInitials || ''"
            :can-change-use-name-text="canChangeUseNameText"
            @sign="applySignatureModelToSection"
            @update:model="updateActive"
          >
            <template #footer="{valid}">
              <div class="space-x-2">
                <button class="btn btn-default" data-test="sigFormCancel" @click="showPopup = null">
                  Cancel
                </button>
                <button
                  :disabled="!valid"
                  class="btn btn-primary"
                  data-test="sigFormApply"
                  @click="applySignature"
                >
                  Apply
                </button>
              </div>
            </template>
          </party-signature-form>
        </el-card>
      </slot>
    </div>
  </div>
</template>

<script>
import DocumentSignatureSectionVue from "./DocumentSignatureSection.vue";
import PartySignatureFormVue from "./PartySignatureForm.vue";
import cloneDeep from "lodash/cloneDeep";
import { TweenMax } from "gsap";
import { findScrollableParent } from "../../utils/dom";

export default {
  components: {
    DocumentSignatureSection: DocumentSignatureSectionVue,
    PartySignatureForm: PartySignatureFormVue
  },
  props: {
    /** @type {Vue.PropOptions<neptune.getters.Signature[]>} */
    signatures: {
      type: Array,
      required: true
    },
    canChangeUseNameText: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showPopup: false,
      model: {
        signatures: cloneDeep(this.signatures)
      },
      activeIndex: -1,

      /** @type {{[key: number]: neptune.documents.ISignaturePartyModel}} */
      partyModels: {},

      /** @type {neptune.documents.ISignaturePartyModel} */
      inputModel: {
        name: null,
        initials: null,
        nameBitmap: null,
        initialBitmap: null
      }
    };
  },
  computed: {
    /** @type {Vue.ComputedOptions<neptune.getters.Signature>} */
    activeSignature: {
      get() {
        return this.model.signatures[this.activeIndex];
      },

      set(val) {
        Object.assign(this.model.signatures[this.activeIndex], val);
      }
    },

    /** @type {Vue.ComputedOptions<boolean>} */
    fullySigned() {
      /** @type {neptune.getters.Signature[]} */
      const sigs = this.model.signatures;
      return sigs.every((s) => !!s.signedByName);
    }
  },
  watch: {
    fullySigned: {
      handler(val) {
        if (val) {
          this.$emit("complete", JSON.parse(JSON.stringify(this.model)));
        }
      },
      immediate: false
    }
  },
  mounted() {
    window.findScrollableParent = findScrollableParent;
  },
  methods: {
    /**
     * Called whenever signature user input changes
     * @type {(model: neptune.documents.ISignaturePartyModel) => void}
     */
    updateActive(model) {
      this.inputModel = model;
    },

    /**
     * Apply input values to active signature
     * @type {() => void}
     */
    applySignature() {
      const party = this.activeSignature.party;

      this.partyModels[party] = this.activeSignature;

      /** @type {neptune.documents.Signature} */
      const m = {
        signedAt: new Date().toJSON(),
        signedByName: this.inputModel.name,
        signedByInitials: this.inputModel.initials,
        signedImage: this.inputModel.nameBitmap
      };

      Object.assign(this.model.signatures[this.activeIndex], m);
      this.$emit("changed", this.model);

      //   this.inputModel = null

      this.showPopup = false;
    },

    /**
     * @type {(data: neptune.documents.Signature, index: number) => void}
     */
    onSectionSign(data, index) {
      this.activeIndex = index;
      const party = this.model.signatures[index].party;
      //   this.activeSignature = cloneDeep(data)
      if (this.partyModels[party]) {
        this.applySignatureModelToSection(this.partyModels[party], index);
      } else {
        this.showPopup = true;
      }
    },

    /**
     * @type {(data: neptune.documents.ISignaturePartyModel, index: number) => void}
     */
    applySignatureModelToSection(data, index) {
      /** @type {neptune.documents.Signature} */
      const m = {
        signedAt: new Date().toJSON(),
        signedByName: data.signedByName,
        signedByInitials: data.signedByInitials,
        signedImage: data.signedImage
      };

      Object.assign(this.model.signatures[index], m);

      this.$emit("changed", this.model);
    },

    /**
     * @type {(el: HTMLElement, index: number) => void}
     */
    async focusNext(el) {
      await this.$nextTick();

      /** @type {HTMLDivElement} */
      const scroller = findScrollableParent(el || this.$el);
      const middleOffset = scroller.getBoundingClientRect().height / 2;

      /** @type {HTMLButtonElement} */
      const btn = this.$el.querySelector("button");

      if (btn) {
        const btnY = btn.getBoundingClientRect().y + scroller.scrollTop;

        TweenMax.to(scroller, 0.25, {
          scrollTop: btnY - middleOffset
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
  z-index: 1000;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.title {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 3rem;

  p {
    margin: 1rem 0;
  }
}

.card {
  width: 100%;
  max-width: 800px;
  align-self: center;
}

.signature {
  &-container {
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }
  &-inner {
    padding: 1rem;
    background-color: #fff;
    font-size: 0.95rem;
    color: lighten(#000, 1);
    box-shadow: 0 0 5px rgba(#000, 0.5);
    margin: 3vh 0;
  }
  &-form {
    &-container {
      position: relative;
    }
  }
}
</style>
