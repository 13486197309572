import Vue from "vue";
import { isMobile } from "ismobilejs";
import VueResize from "vue-resize";
import "vue-resize/dist/vue-resize.css";
import Axios from "axios";
import VueAxios from "vue-axios";
import { mask } from "vue-the-mask";
import store from "@/store";

Vue.directive("mask", mask);
const f = Axios;
f.defaults.headers.Authorization = process.env.VUE_APP_AUTH_KEY;
Vue.config.productionTip = false;

// Add a global property called 'debug'
Vue.mixin({
  computed: {
    $debug() {
      return process.env.NODE_ENV === "development";
    },
    $mobile() {
      return this.$root.mobile || isMobile().any;
    },
    $internal() {
      return store.getters["auth/internal"];
    },
    $insured() {
      return store.getters["auth/accountType"] === "policyholder";
    },
    $agent() {
      return store.getters["userdata/type"] === "agent";
    },
    $readonly() {
      return store.getters["auth/roles"].includes("readonly");
    },
    $isResidential() {
      return [1, 6, 8].includes(store.getters["policy/productNo"]);
    },
    $isCommercial() {
      return [2, 3, 7].includes(store.getters["policy/productNo"]);
    },
    $isEarthquake() {
      return store.getters["policy/productNo"] === 4 || store.getters["policy/productNo"] === 5;
    }
  }
});

Vue.use(VueResize);
Vue.use(VueAxios, Axios);
