<template>
  <div class="flex">
    <aside
      class="!hidden sm:fixed sm:inset-y-0 sm:z-50 sm:!flex sm:w-72 sm:flex-col bg-white transition duration-200"
      :class="{ '-translate-x-full': $store.state.settings.ui.nav.hidden }"
    >
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <button
          class="absolute left-full w-8 h-8 bg-white shadow-md rounded-br-lg flex items-center justify-center hover:bg-gray-100"
          @click="toggleNav"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 transition duration-150 text-gray-600"
            :class="{ 'rotate-180': $store.state.settings.ui.nav.hidden }"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </button>

        <!-- Logo Area -->
        <div class="cms-logo z-10">
          <slot name="cms-logo">
            #cms-logo
          </slot>
        </div>

        <!-- Sidebar Content -->
        <div>
          <ul role="list" class="-mx-2 mt-2 space-y-1">
            <li>
              <router-link
                to="/policies"
                class="text-gray-700 hover:text-sky-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                x-state:on="Current"
                x-state:off="Default"
              >
                <span
                  class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white text-gray-400 border-gray-200 group-hover:border-sky-600 group-hover:text-sky-600"
                  >P</span
                >
                <span class="truncate">Policies</span>
              </router-link>
            </li>

            <li>
              <router-link
                to="/commissions"
                class="text-gray-700 hover:text-sky-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
              >
                <span
                  class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white text-gray-400 border-gray-200 group-hover:border-sky-600 group-hover:text-sky-600"
                  >C</span
                >
                <span class="truncate">Commissions</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="/overrides"
                class="text-gray-700 hover:text-sky-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
              >
                <span
                  class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white text-gray-400 border-gray-200 group-hover:border-sky-600 group-hover:text-sky-600"
                  >C</span
                >
                <span class="truncate">Commission Overrides</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="/moratoriums"
                class="text-gray-700 hover:text-sky-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                x-state-description="undefined: &quot;bg-gray-50 text-sky-600&quot;, undefined: &quot;text-gray-700 hover:text-sky-600 hover:bg-gray-50&quot;"
              >
                <span
                  class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white text-gray-400 border-gray-200 group-hover:border-sky-600 group-hover:text-sky-600"
                  >M</span
                >
                <span class="truncate">Moratoriums</span>
              </router-link>
            </li>
          </ul>
        </div>

        <!-- billing -->
        <div>
          <div class="text-xs font-semibold leading-6 text-gray-400">Billing</div>
          <ul role="list" class="-mx-2 mt-2 space-y-1">
            <li v-for="billingItem in billingItems" :key="billingItem.text">
              <router-link
                :to="billingItem.path"
                class="text-gray-700 hover:text-sky-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center space-x-1"
              >
                <div class="text-gray-400" v-html="billingItem.icon"></div>

                <span>
                  {{ billingItem.text }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- end billing -->
        <!-- profile -->
        <li class="-mx-6 mt-auto list-none">
          <router-link
            to="/profile"
            class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 bg-gray-100 hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-8 w-8 rounded-full text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>

            <span class="sr-only">Your profile</span>
            <span aria-hidden="true">Profile</span>
          </router-link>
        </li>
        <!-- end profile -->
      </div>
    </aside>
    <section
      class="transition-all duration-200 w-full"
      :class="{
        'lg:pl-0': $store.state.settings.ui.nav.hidden,
        'lg:pl-72': !$store.state.settings.ui.nav.hidden
      }"
    >
      <main>
        <div
          class="p-4 lg:p-12
        "
        >
          <!-- Breadcrumbs -->
          <div v-if="$scopedSlots.breadcrumbs" class="breadcrumbs">
            <slot name="breadcrumbs">
              <ol class="breadcrumbs p-3 text-sm w-full">
                <li>#breadcrumbs</li>
              </ol>
            </slot>
          </div>

          <!-- Subheader -->
          <div v-if="$scopedSlots.subheader" class="subheader p-3 text-2xl">
            <slot name="subheader">
              #subheader
            </slot>
          </div>

          <!-- Content -->
          <transition
            name="fade"
            mode="out-in"
            appear
            @before-appear="updateClasses()"
            @enter="updateClasses()"
            @before-enter="updateClasses()"
            @after-leave="updateClasses()"
          >
            <div :key="$route.fullPath">
              <slot name="default">
                <keep-alive>
                  <router-view />
                </keep-alive>
              </slot>
            </div>
          </transition>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    navHidden: Boolean,
    navFixed: Boolean,
    navMinified: Boolean,
    headerFixed: Boolean,
    theme: {
      type: String,
      default: "light"
    }
  },
  data() {
    return {
      scroll: {
        x: 0,
        y: 0
      },
      billingItems: [
        {
          text: "Payments",
          path: "/billing/payments",
          icon: `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                  />
                </svg>`
        },
        {
          text: "Lockbox Adjustments",
          path: "/lockbox-reprocessing",
          icon: `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>`
        }
      ]
    };
  },
  computed: {
    /** @returns {{[key: string]: string}} */
    classes() {
      return {
        "nav-hidden": this.navHidden,
        "nav-fixed": this.navFixed,
        "nav-minified": this.navMinified,
        "header-fixed": this.headerFixed,
        "is-desktop": !this.$mobile,
        "is-mobile": this.$mobile,
        ["theme-" + this.theme]: true
      };
    }
  },
  mounted() {
    this.onMount();
  },
  beforeMount() {
    this.w = this.$watch(
      "classes",
      () => {
        this.updateClasses();
      },
      {
        immediate: true
      }
    );
  },

  methods: {
    toggleNav() {
      const {
        settings: {
          ui: {
            nav: { hidden }
          }
        }
      } = this.$store.state;

      this.$store.commit("settings/ui/SET_PROP", {
        path: "nav.hidden",
        value: !hidden
      });
    },
    async onMount() {
      console.log("mounted");

      onscroll = () => {
        this.scroll = {
          x: pageXOffset,
          y: pageYOffset
        };
      };

      this.$once("hook:beforeDestroy", () => {
        onscroll = null;
      });

      await this.$nextTick();

      this.updateClasses();
    },
    updateClasses() {
      const entries = Object.entries(this.classes);
      const cl = document.body.classList;

      cl.forEach((v) => {
        if (v.startsWith("theme-") && !v.endsWith(this.theme)) {
          cl.remove(v);
        }
      });

      entries.forEach(([k, v]) => {
        if (v && !cl.contains(k)) {
          document.body.classList.add(k);
        } else if (!v && cl.contains(k)) {
          document.body.classList.remove(k);
        }
      });
    }
  }
};
</script>
