/** @type {import("vuex").GetterTree<neptune.policyholder.IDocumentState, neptune.IRootState>} */
const getters = {
  /**
   * TODO: probably get rid of this
   */
  documentsWithSignatures(state) {
    return state.documents || [];
  },

  /**
   * Who has completely signed all required documents
   * @returns {{[key: string]: boolean}}
   */
  isSigned(_state, getters, _rootState, rootGetters) {
    /** @type {neptune.documents.DocumentWithSignature[]} */
    const allDocs = getters.documentsWithSignatures;

    const allSignatures = allDocs.flatMap((v) => v.signatures);

    const agentSigs = allSignatures.filter((s) => s.party === 1);
    const insuredSigs = allSignatures.filter((s) => s.party === 2);

    const allAgent = agentSigs.every((s) => !!s.signedByName);
    const allInsured = insuredSigs.every((s) => !!s.signedByName);

    return {
      agent: allAgent,
      insured: allInsured,
      current: rootGetters["userdata/type"] === "agent" ? allAgent : allInsured
    };
  },

  /**
   * A collection of required uploads that have not been added yet
   * @returns {neptune.PolicyCancelDoc[]}
   */
  missingUploads(state, _getters, _rootState, rootGetters) {
    /** @type {neptune.PolicyCancelDoc[]} */
    const cancels = rootGetters["policy/cancelReason"]?.policyCancelDocs || [];

    /** @type {neptune.IPolicyPdfDocument[]} */
    const current = state.pdfs;

    const missingCancels = cancels.filter((c) => {
      return current.every((p) => p.docType !== c.docTypeId);
    });
    // More to come im sure

    return [...missingCancels];
  },

  /**
   * A collection of all documents with a missing signature from either party
   */
  unsignedDocs(state) {
    const populatedDocs = state.documents.filter((d) => {
      return !!d.signatures?.length;
    });

    return populatedDocs.filter((d) => {
      return d.signatures.some((s) => !s.signedAt);
    });
  },

  /**
   * A collection of all signature item blocks merged together
   */
  missingSignatures(_state, getters) {
    const signatures = getters.unsignedDocs.flatMap((d) => d.signatures);

    return signatures.filter((s) => !s.signedAt);
  }
};

export default getters;
