import Axios from "axios";

let cancel;

/** @type {import("vuex").ActionTree} */
export const actions = {
  async lookup({ commit }, addr) {
    if (typeof cancel === "function") {
      cancel("cancelled previous request");
    }

    try {
      const { data } = await Axios.get("https://autocomplete-api.smartystreets.com/suggest", {
        cancelToken: new Axios.CancelToken((c) => {
          cancel = c;
        }),
        params: {
          "auth-id": 19033897084078356,
          prefix: addr,
          suggestions: 10,
          geolocate: true,
          geolocate_precision: "state",
          prefer: "FL"
        }
      });

      if (Array.isArray(data.suggestions)) {
        commit("SET_STATE", {
          addresses: data.suggestions
        });
      }

      return data.suggestions;
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled", error);
      }
    }
  },
  async clear({ commit }) {
    commit("SET_STATE", {
      addresses: []
    });
  }
};

export default actions;
