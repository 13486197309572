/** @type {import('vuex').GetterTree<neptune.policyholder.IAuthState, neptune.IRootState>} */
const getters = {
  policyNo(state) {
    return state.summary?.policyNo;
  },
  accountType(state) {
    return state.accountType;
  },
  agentNo(state) {
    return state.data?.AgentId;
  },
  agentToken(state) {
    return state.data?.Token;
  },
  policyHolderToken(state) {
    return state.validateReturn.jwt;
  },
  roles(state) {
    return (state.data?.Roles || "").split(",").map((v) => v.toLowerCase());
  },
  internal(state, getters) {
    return (getters.roles || []).includes("internal");
  },
  cvcOptional(state, getters) {
    return (getters.roles || []).includes("cvc-optional");
  },
  noPayOptIn(state, getters) {
    return (getters.roles || []).includes("nopayoptin");
  }
};

export default getters;
