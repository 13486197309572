<template>
  <div class="flex__row">
    <div class="text-center font-bold italic mb-4" data-test="check.lbxTitle">
      Please make your check payable to Neptune Flood Incorporated
      <br />
      and send your check to the mailing address below
    </div>

    <div class="bg-gray-100 flex flex-col items-center rounded-lg">
      <div class="py-8 text-black font-bold text-lg flex flex-col items-center">
          <p data-test="check.lbxName">{{ address.lbxName }}</p>
          <p data-test="check.lbxAddr1">{{ address.lbxAddress1 }}</p>
          <p data-test="check.lbxAddr2">{{ address.lbxAddress2 }}</p>
          <p data-test="check.lbxAddr3">
          {{ address.lbxCity }}, {{ address.lbxState }} {{ address.lbxZip }}
          </p>
      </div>

      <div class="bg-white text-black py-4 flex flex-col items-center w-full rounded-b-lg" data-test="check.courierTitle">
        <p>Address for Courier Deposits (FedEx, UPS, etc.)</p>
        <p>**Deposits received by courier may not post same day**</p>
        <div class="font-bold py-2 flex flex-col items-center">
          <div v-if="address.depositToAccountRef === '735653'" class="flex flex-col items-center" data-test="check.courierName">
              <p>JPMORGAN CHASE</p>
              <p>ATTN: NEPTUNE FLOOD INCORPORATED {{ address.depositToAccountRef }}</p>
          </div>
          <div v-else class="flex flex-col items-center" data-test="check.courierName">
              <p>DALLAS REGIONAL LOCKBOX</p>
              <p>NEPTUNE FLOOD INCORPORATED</p>
          </div>
          <p data-test="check.courierAddr1">{{ address.lbxCourierAddress1 }}</p>
          <p data-test="check.courierAddr2">{{ address.lbxCourierAddress2 }}</p>
          <p data-test="check.courierAddr3">{{ address.lbxCourierCity }}, {{ address.lbxCourierState }} {{ address.lbxCourierZip }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NumberFiltersMixin } from "../../utils/mixins/formatting";
import { mapActions } from "vuex";

export default {
  mixins: [NumberFiltersMixin],
  props: {
    state: Object
  },
  data() {
    return {
      address: []
    };
  },
  computed: {
    totalPremium() {
      return this.state?.context?.confirmation?.totalPremium;
    }
  },
  async mounted() {
    this.getLockBoxAddress(this.state?.context?.policyNo).then((res) => {
      this.address = res;
    });
  },
  methods: {
    ...mapActions("payment", ["getLockBoxAddress"]),
    viewPolicy() {
      this.policyWizard.$emit("gotoPolicy", this.policyWizard.summary);
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-check {
  text-align: center;
  // max-width: 300px;
  // max-height: 300px;
  // min-width: 600px;

  // width: 525px;

  .btn {
    // margin-left: auto;
    margin-top: 2rem;
  }

  &__addresses {
    display: flex;
    margin-bottom: 2rem;
  }

  .addr {
    flex: 1;

    h4 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;

      .small {
        font-weight: bold;
        font-style: italic;
        font-size: 0.8rem;
      }
    }

    p {
      font-size: 1.2rem;
    }

    &__small {
      h4 {
        font-size: 0.9rem;
        font-weight: 800;
        display: flex;
        align-items: baseline;
        margin-bottom: 0;

        > * {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
      display: flex;
      flex-flow: column;
      align-items: center;
      font-style: italic;
      font-size: 0.7rem;

      p {
        line-height: normal;
        margin-bottom: 5px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}

.addr {
  max-width: 100%;
  > * {
    margin: 0;
    line-height: normal;
  }
}

.bottom-align {
  align-items: flex-end;
}

.text-center {
  margin-bottom: 1rem;
  font-weight: bold;
  font-style: italic;
}
</style>
