<template>
  <div class="policy-holder">
    <!-- Content -->
    <div class="policy-holder-content">
      <div class="content__inner m-auto relative">
        <slot>
          router view goes here
        </slot>
      </div>

      <!-- Wave -->
      <div v-if="false" class="wave-container">
        <svg width="100%" height="200px">
          <wave-path class="wave" v-bind="waveOptions" :waveHeight="50" />
          <wave-path class="wave2" v-bind="waveOptions" :waveHeight="30" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import WavyFillPathVue from "../../../views/splash/WavyFillPath.vue";
export default {
  components: {
    WavePath: WavyFillPathVue
  },
  data: () => ({
    waveOptions: {
      offset: 0.1,
      height: 400
    }
  }),
  mounted() {
    this.updateOffset();
  },
  methods: {
    updateOffset() {
      this.waveOptions.offset += 0.75;
      requestAnimationFrame(this.updateOffset);
    }
  }
};
</script>

<style lang="scss" scoped>
.display-none {
  display: none;
}

.header {
  text-align: center;
  padding: 2rem;
}

.policy-holder {
  display: flex;
  flex: 1;
  flex-flow: column;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  // padding-bottom: 250px;
  background-color: #e0eaf3;
  color: #fff;
  // background-image: url('/static/images/backgrounds/dark2.jpg');
  // background-size: cover;
  // background-position: center center;

  &-content {
    flex: 1;
    align-self: stretch;
    position: relative;
  }

  .content__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;

    ::v-deep .centered-card {
      margin: auto;
      width: 600px;
      max-width: 100%;
      padding: 0 2rem;
    }
  }
}

.policy-summary {
  width: 320px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 1rem;
  // width: 30%;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(#000, 0.25);
}

.card {
  min-width: 400px;
}

.wave-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}

.wave {
  fill: #2a4365;
}

.wave2 {
  fill: lighten(#2a4365, 20);
  transform: translateY(1rem);
  background-blend-mode: darken;
  opacity: 0.76;
}
</style>
