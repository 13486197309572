<template>
  <div class="document-signature-section">
    <div class="text-area">
      <div class="prose" v-html="text" />
    </div>
    <div class="toolbar relative">
      <!-- Accept Button / Signature Image -->

      <button
        v-if="!signatureImage"
        class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-deepsea-full bg-lightair-medium hover:bg-lightair-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 space-x-1"
        data-test="docSignSignatureButton"
        @click="$emit('sign', $props)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-deepsea-full"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
        <span> Sign Document</span>
      </button>

      <div v-else class="signature__wrapper" data-test="docSignSignatureImg">
        <img :src="signatureImage | pathFix" class="h-full" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    /** @param {string} path */
    pathFix(path) {
      if (path.startsWith("data:image/png;base64,")) {
        return path;
      } else {
        return "data:image/png;base64," + path;
      }
    }
  },
  props: {
    text: {
      type: String,
      required: true
    },
    signatureImage: {
      type: String
    }
  },
  watch: {
    signatureImage(val) {
      if (val) {
        this.$emit("signed", this);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep h2 {
  font-size: 1.5rem !important;
  color: #2c5282;
  font-weight: bold;
  margin-bottom: 1rem;
}

::v-deep ol {
  @apply space-y-4;
  @apply pl-4;
  list-style: decimal;
}

.signature {
  &__wrapper {
    height: 45px;
  }
}

.toolbar {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.sign-button {
  font-size: 2.2rem;
}
</style>
