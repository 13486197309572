import { authAxios } from "../auth";
import { Constants } from "../../../config";

const actions = {
  // eslint-disable-next-line no-unused-vars
  async getMoratoriumEvents() {
    const response = await authAxios.get(`${Constants.GET_MORATORIUM_EVENTS}`);
    return response.data.payload;
  },
  async getMoratoriumActiveEvents() {
    const response = await authAxios.get(`${Constants.GET_MORATORIUM_ACTIVE_EVENTS}`);
    return response.data.payload;
  },
  async getMoratoriumCounties() {
    const response = await authAxios.get(`${Constants.GET_MORATORIUM_COUNTIES}`);
    return response.data.payload;
  },
  async postMoratorium(_, finalBody) {
    const response = await authAxios.post(`${Constants.POST_MORATORIUM}`, finalBody);
    return response.data.payload;
  },
  async updateMoratorium(_, finalBody) {
    const response = await authAxios.put(`${Constants.POST_MORATORIUM}`, finalBody);
    return response.data.payload;
  }
};

export default actions;
