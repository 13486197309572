import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import ach from "./modules/ach";
import cc from "./modules/cc";

/** @type {stripe.Stripe} */
// eslint-disable-next-line no-undef
// const stripeInstance = Stripe(process.env.VUE_APP_STRIPE_KEY)

/**
 * @type {neptune.payment.IPaymentState}
 */
export const state = {
  UserId: 2,
  submitting: false
};

/** @type {import('vuex').Module<any,any>} */
const mod = {
  state,
  actions,
  getters,
  mutations,
  modules: {
    ach,
    cc
  },
  namespaced: true
};

export default mod;
