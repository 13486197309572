<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-lightair-full">
    <div class="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-12 h-12 mx-auto text-deepsea-full"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
        />
      </svg>

      <h1 class="text-4xl font-bold text-deepsea-full mb-4">Unauthorized</h1>
      <p class="text-lg text-gray-700 mb-2">You do not have permission to view this page or your session may have expired.</p>
      <p class="text-lg text-gray-700 mb-8 max-w-screen-sm">
        Please log in again to continue. You will be redirected to the homepage in 5 seconds. If you are not redirected, please
        <a href="#" class="text-blue-500 hover:text-deepsea-full" @click="handleRedirect">
          click here </a
        >.
      </p>
      <button
        class="px-6 py-3 bg-deepsea-full text-white rounded-md hover:bg-deepsea-full/75"
        @click="handleRedirect"
      >
        Go to Login Page
      </button>
    </div>
  </div>
</template>

<script setup>
import { usePolicyWizard } from "../use-policy-wizard";

const { send } = usePolicyWizard();

// eslint-disable-next-line no-unused-vars
function handleRedirect() {
  send("REDIRECT");
}
</script>
