<template>
  <el-form
    ref="form"
    :model="ruleForm"
    class="authentication"
    :class="[status]"
    :rules="rules"
    :disabled="submitting"
    @submit.native.prevent.stop="validate()"
  >
    <!-- Phone -->
    <el-form-item label="Phone" prop="phone" required>
      <el-input
        ref="phone"
        v-model="ruleForm.phone"
        v-mask="'###-###-####'"
        auto-complete="phone"
        autofocus
        placeholder="Phone Number"
        prefix-icon="el-icon-phone"
      />
    </el-form-item>

    <!-- Zip -->
    <el-form-item required prop="zip" label="Insured Property Zip Code">
      <el-input
        v-model="ruleForm.zip"
        placeholder="Zipcode"
        auto-complete="postal-code"
        :maxlength="5"
        prefix-icon="fa fa-map-marker"
      />
    </el-form-item>

    <!-- Buttons -->
    <el-form-item>
      <el-button native-type="submit" round type="primary">
        Validate
      </el-button>
      <el-button round type="success" :disabled="empty" @click="reset()">
        Clear Form
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    deepLink: {
      type: String,
      required: true
    },
    validator: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    rules: {
      phone: [
        {
          required: true,
          message: "Please input Phone Number",
          trigger: "blur"
        }
      ],
      zip: [
        {
          required: true,
          message: "Please input zipcode",
          trigger: "blur"
        }
      ]
    },
    ruleForm: {
      phone: null,
      zip: null
    },
    submitting: false,
    status: "idle"
  }),
  computed: {
    /**
     * @returns {boolean}
     */
    empty() {
      return !this.ruleForm.phone ?? !this.ruleForm.zip;
    }
  },
  methods: {
    async validate() {
      this.submitting = true;
      const data = {
        deepLink: this.deepLink,
        ...this.ruleForm
      };
      this.$refs.form.validate(async (valid) => {
        try {
          if (valid) {
            try {
              await this.validator(data);
              this.$emit("submit", data);
              this.submitting = false;
              this.status = "success";
              return false;
            } catch (error) {
              this.submitting = false;
              this.status = "error";
            }
          } else {
            console.log("error");
            this.submitting = false;
            this.status = "error";
            return false;
          }
        } catch (error) {
          console.log("error");
          this.submitting = false;
          this.status = "error";
          return Promise.reject(new Error(error));
        }
      });
    },
    reset() {
      this.ruleForm = {
        phone: null,
        zip: null
      };

      this.$refs.phone.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.idle {
  // border: 1px solid transparent;
}

.error {
  // border: 1px solid red;
}

.success {
  // border: 1px solid green;
}
</style>
