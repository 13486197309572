/** @type {import('vuex').GetterTree<neptune.payment.IPaymentState, neptune.IRootState>} */
const getters = {
  totalPremium(_, __, rootState) {
    const totalPremium = rootState.currentQuote?.quote.policy
      ? rootState.currentQuote?.quote.policy.totalPremium
      : 0;
    return +totalPremium.toFixed(2);
  }
};

export default getters;
