<template>
  <transition
    :css="false"
    leave-class="c-leave"
    leave-to-class="c-leave-to"
    leave-active-class="c-leave-active"
    v-bind="$attrs"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    @leave-cancelled="leaveCancelled"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
  >
    <slot />
  </transition>
</template>

<script>
import { TweenMax } from "gsap";
// eslint-disable-next-line no-unused-vars
import { easeBackOut, easeCircleInOut, easeCubicOut } from "d3";
export default {
  inheritAttrs: false,
  props: {
    /**
     * Duration in seconds
     * @type {Vue.PropOptions<number>}
     */
    duration: {
      type: Number,
      default: 0.5
    }
  },
  data: () => ({
    height: null
  }),
  methods: {
    // --------
    // LEAVING
    // --------
    /** @param {HTMLElement} el */
    beforeLeave: function(el) {
      // el.style.willChange = 'height'
      el.style.height = "";
      // if (this.height == null) {
      const { height } = el.getBoundingClientRect();

      Object.assign(this, {
        height
      });
      // }
      el.style.height = `${this.height}px`;
      el.style.overflow = "hidden";

      // console.log('beforeLeave')
    },
    // the done callback is optional when
    // used in combination with CSS
    leave: function(el, done) {
      // ...
      // console.log('leave')
      TweenMax.to(el, this.duration, {
        height: "0px",
        ease: easeCubicOut,
        onComplete() {
          done();
        }
      });

      // done()
    },
    afterLeave: function(el) {
      // ...
      // console.log('afterLeave')
      el.style.height = "";
      el.style.overflow = "";
      // el.style.willChange = ''
    },
    // leaveCancelled only available with v-show
    leaveCancelled: function(el) {
      // ...
      // console.log('leaveCancelled')
      el.style.height = "";
      // el.style.willChange = ''
      TweenMax.killTweensOf(el);
    },

    // --------
    // ENTERING
    // --------
    /**
     * @param {HTMLElement} el
     */
    beforeEnter: function(el) {
      el.style.height = "0px";
      el.style.overflow = "hidden";
      // el.style.willChange = 'height'
    },
    // the done callback is optional when
    // used in combination with CSS
    enter: function(el, done) {
      // ...
      // done()
      el.style.height = 0;
      TweenMax.to(el, this.duration, {
        height: this.height,
        ease: easeCubicOut,
        onComplete: done
      });

      // console.log('enter')
    },

    /** @param {HTMLElement} el */
    afterEnter: function(el) {
      // ...
      // console.log('afterEnter')
      // el.style.height = null
      el.style.height = "";
      // el.style.willChange = ''
      el.style.overflow = "";
    },
    enterCancelled: function(el) {
      // ...
      // console.log('afterCancelled')
      // el.style.willChange = ''
      el.style.overflow = "";
      TweenMax.killTweensOf(el);
    }
  }
};
</script>
