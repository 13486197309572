import { authAxios } from "../auth";
import { Constants } from "../../../config";

const actions = {
  // eslint-disable-next-line no-unused-vars
  async getStatements({ getters }, { month, year }) {
    const response = await authAxios.get(`${Constants.GET_STATEMENTS}?year=${year}&month=${month}`);
    return response.data.payload.data;
  },
  // eslint-disable-next-line no-unused-vars
  async getStatementById({ getters }, statementId) {
    const response = await authAxios.get(
      Constants.GET_STATEMENT_BY_ID.replace("{{statementId}}", statementId)
    );
    return response.data.payload.data;
  },
  // eslint-disable-next-line no-unused-vars
  async getTransactions({ getters }, statementId) {
    const response = await authAxios.get(
      Constants.GET_COMMISSIONS_TRANSACTIONS.replace("{{statementId}}", statementId)
    );
    return response.data.payload.data;
  },
  // eslint-disable-next-line no-unused-vars
  async getAdjustments({ getters }, statementId) {
    const response = await authAxios.get(
      Constants.GET_COMMISSIONS_ADJUSTMENTS.replace("{{statementId}}", statementId)
    );
    return response.data.payload.data;
  },
  // eslint-disable-next-line no-unused-vars
  async postAdjustment({ commit }, { statementId, description, adjustmentAmount, privateNotes }) {
    const response = await authAxios.post(Constants.POST_ADJUSTMENT, {
      statementId: statementId,
      description: description,
      adjustmentAmount: adjustmentAmount,
      privateNotes
    });
    return response.data.payload;
  },
  async deleteAdjustment(
    // eslint-disable-next-line no-unused-vars
    { getters },
    { adjustementId, statementId, description, adjustmentAmount, privateNotes }
  ) {
    const body = {
      adjustementId: adjustementId,
      statementId: statementId,
      description: description,
      adjustmentAmount: adjustmentAmount,
      privateNotes: privateNotes
    };
    const response = await authAxios.delete(Constants.DELETE_ADJUSTMENT, { data: body });
    return response;
  }
};

export default actions;
