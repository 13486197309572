<template>
  <div class="coverage-info">
    <table class="text-xs lg:text-sm">
      <tr>
        <th data-test="coverageHeader">Coverage</th>
        <th data-test="descriptionHeader">Description</th>
        <th class="quick-align" data-test="amountHeader">Amount</th>
        <th class="quick-align" data-test="premiumHeader">Premium</th>
      </tr>
      <tr v-if="!isEarthquake">
        <td data-test="coverageA">
          <span v-if="[6, 7, 8].includes($store.getters['policy/productNo'])">Excess </span>
          <span>Coverage A</span>
        </td>
        <td data-test="coverageADesc">Building Property</td>
        <td class="quick-align" data-test="coverageALimit">
          {{ summary.coverageALimit | currency }}
        </td>
        <td class="quick-align" data-test="coverageAPremium">
          {{ calculatePremium("coverageAPremium") | currency }}
        </td>
      </tr>
      <tr v-if="!isEarthquake">
        <td data-test="coverageB">
          <span v-if="[6, 7, 8].includes($store.getters['policy/productNo'])">Excess </span>
          <span>Coverage B</span>
        </td>
        <td v-if="propertyType" data-test="coverageBPersonalDesc">Personal Property</td>
        <td v-else data-test="coverageBBusinessDesc">Business Personal Property</td>
        <td class="quick-align" data-test="coverageBLimit">
          {{ summary.coverageBLimit | currency }}
        </td>
        <td class="quick-align" data-test="coverageBPremium">
          {{ calculatePremium("coverageBPremium") | currency }}
        </td>
      </tr>
      <!-- only show if not earthquake or excess flood product -->
      <tr v-if="[1, 2, 3].includes($store.getters['policy/productNo'])">
        <td data-test="coverageC">Coverage C</td>
        <td data-test="coverageC1Desc">Debris Removal</td>
        <td />
        <td class="quick-align" data-test="coverageC1Premium">Included</td>
      </tr>
      <tr v-if="[1, 2, 3].includes($store.getters['policy/productNo'])">
        <td>&nbsp;</td>
        <td data-test="coverageC2Desc">Sandbags, Supplies, and Labor</td>
        <td class="quick-align" data-test="coverageC2Limit">$1,000.00</td>
        <td class="quick-align" data-test="coverageC2Premium">Included</td>
      </tr>
      <tr v-if="[1, 2, 3].includes($store.getters['policy/productNo'])">
        <td>&nbsp;</td>
        <td data-test="coverageC3Desc">Property Removed to Safety</td>
        <td class="quick-align" data-test="coverageC3Limit">$1,000.00</td>
        <td class="quick-align" data-test="coverageC3Premium">Included</td>
      </tr>
      <tr v-if="[2].includes($store.getters['policy/productNo'])">
        <td>&nbsp;</td>
        <td data-test="coverageC4Desc">Pollution Damage</td>
        <td class="quick-align" data-test="coverageC4Limit">$10,000.00</td>
        <td class="quick-align" data-test="coverageC4Premium">Included</td>
      </tr>
      <tr v-if="[1, 2, 3, 4, 5].includes($store.getters['policy/productNo'])">
        <td data-test="coverageD">Coverage D</td>
        <td v-if="isEarthquake" data-test="coverageDDesc">Earthquake Insurance</td>
        <td v-else data-test="coverageDDesc">Increased Cost of Compliance</td>

        <td v-if="isEarthquake" class="quick-align" data-test="coverageDLimit">
          {{ summary.coverageDLimit | currency }}
        </td>
        <td v-else-if="showNoCovD" class="quick-align" data-test="coverageDLimit">Not Included</td>

        <td v-else class="quick-align" data-test="coverageDLimit">$30,000.00</td>
        <td v-if="isEarthquake" class="quick-align" data-test="coverageDPremium">
          {{ summary.coverageDPremium | currency }}
        </td>

        <td v-else-if="showNoCovD" class="quick-align" data-test="coverageDPremium">$0.00</td>

        <td v-else class="quick-align" data-test="coverageDPremium">Included</td>
      </tr>
      <tr v-if="[1, 6].includes($store.getters['policy/productNo'])">
        <td v-if="summary.coverageELimit === 1" data-test="coverageE">Coverage E</td>
        <td v-if="summary.coverageELimit === 0" data-test="coverageEDecl">Coverage Declined</td>
        <td data-test="coverageEDesc">Replacement Costs on Contents</td>
        <td v-if="summary.coverageELimit === 1" class="quick-align" data-test="coverageELimit-a">
          Yes
        </td>
        <td v-else class="quick-align" data-test="coverageELimit-b">Not Included</td>
        <td v-if="summary.coverageELimit === 1" class="quick-align" data-test="coverageEPremium-a">
          {{ calculatePremium("coverageEPremium") | currency }}
        </td>
        <td v-else class="quick-align" data-test="coverageEPremium-b">$0.00</td>
      </tr>

      <tr v-if="showBasementCoverages">
        <td v-if="summary.coverageFLimit > 0" data-test="coverageF">
          Coverage F
        </td>
        <td v-else data-test="coverageFDecl">
          Coverage Declined
        </td>

        <td data-test="coverageFDesc">Basement Contents</td>
        <td v-if="summary.coverageFLimit > 0" class="quick-align" data-test="coverageFLimit-a">
          {{ summary.coverageFLimit | currency }}
        </td>
        <td v-else class="quick-align" data-test="coverageFLimit-b">Not Included</td>
        <td v-if="summary.coverageFLimit > 0" class="quick-align" data-test="coverageFPremium-a">
          {{ calculatePremium("coverageFPremium") | currency }}
        </td>
        <td v-else class="quick-align" data-test="coverageFPremium-b">$0.00</td>
      </tr>

      <!-- CoverageG -->
      <tr v-if="showPoolCoverages">
        <td v-if="summary.coverageGLimit > 0" data-test="coverageG">Coverage G</td>
        <td v-else data-test="coverageGDecl">Coverage Declined</td>
        <td data-test="coverageGDesc">Pool Repair and Refill</td>
        <td v-if="summary.coverageGLimit > 0" class="quick-align" data-test="coverageGLimit-a">
          {{ summary.coverageGLimit | currency }}
        </td>
        <td v-else class="quick-align" data-test="coverageGLimit-b">Not Included</td>
        <td v-if="summary.coverageGLimit > 0" class="quick-align" data-test="coverageGPremium-a">
          {{ calculatePremium("coverageGPremium") | currency }}
        </td>
        <td v-else class="quick-align" data-test="coverageGPremium-b">$0.00</td>
      </tr>
      <!-- /CoverageG -->

      <!-- CoverageH -->
      <tr v-if="[1, 6].includes($store.getters['policy/productNo'])">
        <td v-if="summary.coverageHLimit > 0" data-test="coverageH">Coverage H</td>
        <td v-else data-test="coverageHDecl">Coverage Declined</td>
        <td data-test="coverageHDesc">Unattached Structures</td>
        <td v-if="summary.coverageHLimit > 0" class="quick-align" data-test="coverageHLimit-a">
          {{ summary.coverageHLimit | currency }}
        </td>
        <td v-else class="quick-align" data-test="coverageHLimit-b">Not Included</td>
        <td v-if="summary.coverageHLimit > 0" class="quick-align" data-test="coverageHPremium-a">
          {{ calculatePremium("coverageHPremium") | currency }}
        </td>
        <td v-else class="quick-align" data-test="coverageHPremium-b">$0.00</td>
      </tr>
      <!-- /CoverageH -->

      <!-- CoverageI -->
      <tr v-if="[1, 2, 6, 7].includes($store.getters['policy/productNo'])">
        <td v-if="summary.coverageILimit > 0" data-test="coverageI">Coverage I</td>
        <td v-else-if="summary.coverageILimit === 0" data-test="coverageIDecl">
          Coverage Declined
        </td>
        <td v-if="propertyType" data-test="coverageIDesc-a">
          {{
            summary.propertyTypeDesc === "IT IS A RENTAL AND I AM THE OWNER" &&
            !$store.state.policy.confirmationData.policyNo.includes("RNR")
              ? "Loss of Rental Income"
              : "Temporary Living Expense"
          }}
        </td>
        <td v-else data-test="coverageIDesc-b">Business Interruption</td>

        <td class="quick-align" data-test="coverageILimit-a">
          {{ summary.coverageILimit | currency }}
        </td>

        <td v-if="summary.coverageILimit === 0" class="quick-align" data-test="coverageIPremium-a">
          $0.00
        </td>
        <td v-else class="quick-align" data-test="coverageIPremium-b">
          {{ calculatePremium("coverageIPremium") | currency }}
        </td>
      </tr>

      <!-- CoverageJ -->
      <tr
        v-if="![1, 3, 4, 5].includes(summary.productId) && summary.propertyTypeDesc === 'Apartment'"
      >
        <td v-if="summary.coverageJLimit >= 1" data-test="coverageJ">Coverage J</td>
        <td v-else-if="summary.coverageJLimit === 0" data-test="coverageJDecl">
          Coverage Declined
        </td>
        <td data-test="coverageJDesc">Rental Interruption Coverage</td>

        <td class="quick-align" data-test="coverageJLimit">
          {{ summary.coverageJLimit | currency }}
        </td>

        <td class="quick-align" data-test="coverageJPremium">
          {{ summary.coverageJPremium | currency }}
        </td>
      </tr>
      <!-- / CoverageJ -->

      <!-- CoverageK -->
      <tr
        v-if="
          ([2, 7].includes(productId) && !isEarthquake) ||
            ([1, 6].includes(productId) &&
              summary.propertyTypeDesc === 'IT IS A SECONDARY OR SEASONAL HOME')
        "
      >
        <td data-test="coverageKTitle">Coverage K</td>
        <td data-test="coverageKDesc">Replacement Cost Building</td>
        <td class="quick-align" data-test="coverageKLimit">
          {{ summary.coverageKLimit === 1 ? "Yes" : "No" }}
        </td>
        <td class="quick-align" data-test="coverageKPremium">
          {{ summary.coverageKPremium | currency }}
        </td>
      </tr>
      <!-- / Coverage K -->

      <!-- Coverage L -->
      <tr v-if="showCoverageL">
        <td data-test="coverageLTitle">Coverage L</td>
        <td data-test="coverageLDesc">Building Betterments and Improvements</td>
        <td class="quick-align" data-test="coverageLLimit">
          {{ summary.coverageLLimit === 1 ? "Yes" : "No" }}
        </td>
        <td class="quick-align" data-test="coverageLPremium">
          {{ summary.coverageLPremium | currency }}
        </td>
      </tr>
      <!-- / Coverage L -->

      <tr v-if="[1, 2, 3].includes($store.getters['policy/productNo'])">
        <td data-test="deductible">Deductible</td>
        <td data-test="deductibleDesc">Selected Deductible</td>
        <td class="quick-align" data-test="deductibleAmount">
          {{ summary.deductible | currency }}
        </td>
        <td class="quick-align" data-test="deductiblePremium">
          {{ summary.deductibleAdjustment | currency }}
        </td>
      </tr>
    </table>

    <table class="text-xs lg:text-sm">
      <tr>
        <td />
        <td />
        <td style="text-align:right; font-weight: bold; padding-right:34px;" data-test="subtotalTitle">
          Subtotal
        </td>
        <td style="width: 16%; text-align:right;" data-test="subtotal">
          {{ summary.grossPremium | currency }}
        </td>
      </tr>
      <tr style="background: transparent; border:none;">
        <td />
        <td />
        <td style="text-align:right; font-weight: bold; padding-right:34px;" data-test="policyFeeTitle">
          Policy Fee
        </td>
        <td style="border-top: 3px black;  width: 16%;text-align:right;" data-test="policyFee">
          {{ summary.policyFee | currency }}
        </td>
      </tr>
      <tr style="background: transparent; border:none">
        <td />
        <td />
        <td style="text-align:right; font-weight: bold; padding-right:34px;" data-test="taxesTitle">
          Taxes
        </td>
        <td style="border-top: 3px black; width: 16%; text-align: right;" data-test="taxes">
          {{ summary.subtotalTaxes | currency }}
        </td>
      </tr>
      <tr style="background: transparent; border:none;">
        <td />
        <td />
        <td style="text-align:right; font-weight: bold; padding-right:34px;" data-test="totalPremiumTitle">
          Total
        </td>
        <td
          style="border-top: 5px double black;  width: 16%;text-align:right !important"
          class="quick-align"
          data-test="totalPremium"
        >
          {{ summary.totalPremium | currency }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { NumberFiltersMixin } from "../../policy-wizard/utils/mixins/formatting";
import cloneDeep from "lodash/cloneDeep";
export default {
  mixins: [NumberFiltersMixin],
  computed: {
    showCoverageL() {
      return (
        ([1, 6].includes(this.$store.getters["policy/productNo"]) &&
          this.summary.propertyTypeDesc === "IT IS A RENTAL AND I AM THE TENANT") ||
        ([2, 7].includes(this.$store.getters["policy/productNo"]) &&
          this.summary.occupancyTypeDesc === "CommercialTenant")
      );
    },
    showBasementCoverages() {
      if ([1, 2, 3, 6, 7, 8].includes(this.$store.getters["policy/productNo"])) {
        if (![1, 6].includes(this.$store.getters["policy/productNo"])) {
          return ["basement", "commercialHasBasement", "hasBasement", "Basement"].includes(
            this.$store.state.policy?.transactionSummary?.foundationTypeDesc
          );
        }
        return true;
      } else return false;
    },
    showPoolCoverages() {
      if ([1, 2, 3, 6, 7, 8].includes(this.$store.getters["policy/productNo"])) {
        if (![1, 6].includes(this.$store.getters["policy/productNo"])) {
          return this.summary.occupancyTypeDesc !== "CommercialTenant";
        }
        return (
          this.summary.propertyTypeDesc !== "IT IS A RENTAL AND I AM THE TENANT" &&
          this.summary.occupancyTypeDesc !== "Condo Which Floor"
        );
      } else return false;
    },

    showNoCovD() {
      return (
        this.summary.coverageALimit === 0 || this.summary.occupancyTypeDesc === "Condo Which Floor"
      );
    },
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    },
    policyData() {
      /** @type {neptune.IRootState} */
      const {
        policy: { confirmationData }
      } = this.$store.state;
      return confirmationData || {};
    },
    productId() {
      return this.$store.state?.quoting?.transactions[0]?.productId;
    },
    propertyType() {
      return [1, 6].includes(this.$store.state?.quoting?.transactions[0]?.productId);
    },
    agent() {
      return this.$route.meta?.userType !== 2;
    },
    sortedTransactions() {
      /** @type {neptune.IRootState} */
      const {
        quoting: { transactions }
      } = this.$store.state;

      return cloneDeep(transactions).sort(
        (b, a) => Date.parse(a.postDate) - Date.parse(b.postDate)
      );
    },
    summary() {
      return this.$store.state.policy.transactionSummary;
    }
  },

  methods: {
    calculatePremium(key) {
      /** @type {neptune.IRootState} */
      const {
        quoting: { transactions }
      } = this.$store.state;

      const sorted = cloneDeep(transactions).sort(
        (b, a) => Date.parse(a.postDate) - Date.parse(b.postDate)
      );

      return sorted.reduce((a, b) => a + (b[key] || 0), 0);
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;

  th {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
  }

  tr + tr {
    background-color: darken(#fff, 1);

    border-top: 1px solid darken(#fff, 15);

    &:nth-child(even) {
      background-color: darken(#fff, 3);
    }
  }

  td,
  th {
    padding: 5px 8px;
  }
}
.quick-align {
  text-align: right;
}
</style>
