<template>
  <div>
    <div class="notes-container gap-4 pt-4">
      <div
        v-if="$internal && !editing"
        class="text-sm flex items-center gap-2 text-gray-500"
        data-test="notes.typeTitle"
      >
        Note Type
        <select
          v-model="noteType"
          class="text-sm focus:ring-deepsea-medium focus:border-deepsea-medium !rounded-md border border-gray-300 px-1.5 py-1.5 text-gray-900"
        >
          <option value="note" data-test="notes.noteOption">Note</option>
          <option value="callLog" data-test="notes.callLogOption">Call Log</option>
        </select>
      </div>
      <FadeTranslate>
        <div v-if="noteType === 'callLog'" class="notes-container gap-4">
          <div class="flex gap-3 items-center h-7">
            <label
              class="text-sm text-gray-700 leading-none font-bold"
              data-test="notes.callDirectionTitle"
              >Call Direction:</label
            >
            <label class="flex items-center gap-2 text-sm text-gray-700 leading-none">
              <input
                v-model="IsInboundCall"
                :value="true"
                type="radio"
                class="focus:ring-2 dark:focus:ring-2 ring-deepsea-medium text-deepsea-full"
                data-test="notes.callDirectionInbound"
              />
              Inbound
            </label>
            <label class="flex items-center gap-2 text-sm text-gray-700 leading-none">
              <input
                v-model="IsInboundCall"
                :value="false"
                type="radio"
                class="focus:ring-2 dark:focus:ring-2 ring-deepsea-medium text-deepsea-full"
                data-test="notes.callDirectionOutbound"
              />
              Outbound
            </label>
          </div>
          <div class="flex gap-3 items-center h-7">
            <label class="text-sm text-gray-700 leading-none font-bold" data-test="notes.callerName"
              >Caller Name:</label
            >
            <input
              v-model="CallerName"
              type="text"
              class="focus:ring-deepsea-medium focus:border-deepsea-medium block rounded-none !rounded-md sm:text-sm border border-gray-300 pl-1.5 py-0.5"
              data-test="notes.callerNameInput"
            />
          </div>
          <ValidationProvider v-slot="{ errors }" rules="phone">
            <div class="flex gap-3 items-center h-7">
              <label
                class="text-sm text-gray-700 leading-none font-bold"
                data-test="notes.callerPhoneTitle"
                >Caller Phone Number:</label
              >
              <input
                v-model="CallerPhone"
                v-mask="'###-###-####'"
                type="text"
                class="focus:ring-deepsea-medium focus:border-deepsea-medium block rounded-none !rounded-md sm:text-sm border border-gray-300 pl-1.5 py-0.5"
                data-test="notes.callerPhoneInput"
              />
            </div>
            <FadeTranslate>
              <div v-if="errors[0]" class="text-sm text-red-500 mt-1" data-test="notes.errors">
                {{ errors[0] }}
              </div>
            </FadeTranslate>
          </ValidationProvider>
          <div class="flex gap-3 items-center h-7">
            <label
              class="text-sm text-gray-700 leading-none font-bold"
              data-test="notes.callerTypeTitle"
              >Caller Type:</label
            >
            <label
              v-for="type in ['insured', 'agent', 'lender']"
              :key="type"
              class="flex items-center gap-2 text-sm text-gray-700 leading-none"
            >
              <input
                v-model="CallerType"
                :value="type"
                type="radio"
                name="callerType"
                class="focus:ring-2 dark:focus:ring-2 ring-deepsea-medium text-deepsea-full"
                :data-test="`notes.callerType-${type[0]}`"
              />
              {{ type[0].toUpperCase() + type.slice(1) }}
            </label>
          </div>
          <FadeTranslate>
            <div v-if="CallerType && CallerType !== 'insured'" class="flex gap-3 items-center h-7">
              <label
                class="text-sm text-gray-700 leading-none font-bold"
                data-test="notes.callerInfoTitle"
                >{{
                  CallerType === "agent"
                    ? "Agency Name"
                    : CallerType === "lender"
                    ? "Bank Name"
                    : "Organization Name"
                }}:</label
              >
              <input
                v-model="OrganizationName"
                type="text"
                class="focus:ring-deepsea-medium focus:border-deepsea-medium block rounded-none !rounded-md sm:text-sm border border-gray-300 pl-1.5 py-0.5"
                data-test="notes.callerInfoInput"
              />
            </div>
          </FadeTranslate>
        </div>
      </FadeTranslate>
      <FadeTranslate>
        <div v-if="noteType === 'callLog'" class="notes-container gap-1">
          <div class="flex gap-3 items-center h-7">
            <label
              class="text-sm text-gray-700 leading-none font-bold"
              data-test="notes.callTagsTitle"
              >Call Tags:</label
            >
          </div>
          <div class="flex gap-2 h-fit flex-wrap flex-1">
            <div
              v-for="tag in tagOptions"
              :key="tag"
              class="px-2 py-1 select-none hover:scale-105 text-xs font-bold rounded-full text-center whitespace-nowrap cursor-pointer transition-all"
              :class="{
                'bg-lightair-light text-deepsea-full': tagsSelected.includes(tag),
                'bg-gray-200 text-gray-800': !tagsSelected.includes(tag)
              }"
              :data-test="`notes.callTag-${tag}`"
              @click="selectTag(tag)"
            >
              {{ tag }}
            </div>
          </div>
        </div>
      </FadeTranslate>
      <textarea
        v-if="!$readonly"
        v-model="newNoteBody"
        type="textarea"
        class=" block h-[70px] focus:ring-deepsea-medium focus:border-deepsea-medium block w-full rounded-none !rounded-md border border-gray-300 pl-2.5 py-2 text-sm"
        placeholder="Add a note..."
        data-test="addNoteInput"
      />
      <FadeTranslate>
        <div
          v-if="!canSubmit && noteType === 'callLog'"
          class="text-sm"
          data-test="notes.requiredWarning"
        >
          * All fields are required.
        </div>
      </FadeTranslate>
      <div v-if="!$readonly" class="create-note-button flex gap-4 items-center justify-end">
        <el-button v-if="editing" data-test="cancelNoteEditButton" @click="cancelNoteEdit">
          Cancel
        </el-button>

        <el-switch
          v-if="$internal && !editing && noteType !== 'callLog'"
          v-model="isNotePrivate"
          active-text="Private"
          active-color="#004C9D"
          data-test="privateNoteToggle"
        />
        <button
          v-loading="loading"
          type="primary"
          data-test="addNoteButton"
          class="text-white !py-2 px-4 rounded-md text-sm"
          :class="{
            'bg-deepsea-full': !$isEarthquake,
            'bg-brand-orange': $isEarthquake,
            'opacity-70 cursor-auto hover:bg-opacity-80': !canSubmit,
            'hover:bg-opacity-80': canSubmit
          }"
          :disabled="loading"
          @click="createNewNote"
        >
          {{ editing ? "Update Note" : "Add Note" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { ValidationProvider, extend } from "vee-validate";
import { formatDate } from "@/utils/TextFormatting";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";

const PhoneRegex = /^\d{3}-\d{3}-\d{4}$/;
extend("phone", (value) => {
  return PhoneRegex.test(value) || "Please enter a valid phone number";
});

export default {
  name: "Notes",
  components: {
    FadeTranslate,
    ValidationProvider
  },
  filters: {
    /** @type {(date: number | string) => string} */
    dateFormat: (value) => {
      if (value.endsWith("Z")) {
        const noTimezoneValue = value.substring(0, value.length - 1);
        return formatDate(noTimezoneValue);
      }
      return formatDate(value);
    }
  },
  props: {
    notes: {
      type: Array
    },
    policyNo: {
      type: String
    },
    editing: Boolean,
    noteToEdit: { type: Object }
  },
  data() {
    return {
      newNoteBody: "",
      isNotePrivate: false,
      editNoteHold: "",
      noteIndex: 0,
      loading: false,
      noteType: "note",
      IsInboundCall: undefined,
      CallerType: undefined,
      OrganizationName: "",
      CallerName: "",
      CallerPhone: "",
      tagsSelected: []
    };
  },
  computed: {
    tagOptions() {
      return [
        "Make a payment",
        "Policy/payment status",
        "Endorsements",
        "Cancel policy",
        "Premium/coverage/policy review",
        "Document requests",
        "Commissions/Accounting/Billing",
        "Other"
      ];
    },
    canSubmit() {
      if (this.noteType === "note") return this.newNoteBody !== "";
      // is a call log
      return (
        this.newNoteBody !== "" &&
        this.IsInboundCall !== undefined &&
        this.CallerType !== undefined &&
        this.CallerName !== "" &&
        this.CallerPhone.length === "###-###-####".length &&
        this.tagsSelected.length > 0 &&
        (this.CallerType === "insured" || this.OrganizationName !== "")
      );
    },
    agentNo() {
      return this.$store.state.auth.data?.AgentId;
    },
    tempNote() {
      if (this.editing) return undefined;
      return {
        IsInboundCall: this.IsInboundCall,
        CallerType: this.CallerType,
        OrganizationName: this.OrganizationName,
        CallerName: this.CallerName,
        CallerPhone: this.CallerPhone,
        newNoteBody: this.newNoteBody,
        tagsSelected: this.tagsSelected,
        noteType: this.noteType,
        isNotePrivate: this.isNotePrivate,
        policyNo: this.policyNo,
        agentNo: this.agentNo
      };
    }
  },
  watch: {
    noteToEdit() {
      if (this.editing === true) {
        this.newNoteBody = this.noteToEdit.note;
        this.noteType = this.noteToEdit.type === "policy-call-log" ? "callLog" : "note";
        if (this.noteToEdit.type === "policy-call-log") {
          this.IsInboundCall = this.noteToEdit.IsInboundCall;
          this.CallerType = this.noteToEdit.CallerType;
          this.OrganizationName = this.noteToEdit.OrganizationName;
          this.CallerName = this.noteToEdit.CallerName;
          this.CallerPhone = this.noteToEdit.CallerPhone;
          this.tagsSelected = this.noteToEdit.reason.split(", ") || null;
        }
      } else {
        this.newNoteBody = "";
      }
    },
    tempNote() {
      this.setTempNote(this.tempNote);
    }
  },
  mounted() {
    if (window.localStorage.getItem("tempNote")) {
      const tempNote = JSON.parse(window.localStorage.getItem("tempNote"));
      if (tempNote.agentNo !== this.agentNo || tempNote.policyNo !== this.policyNo) {
        window.localStorage.removeItem("tempNote");
        return;
      }
      this.IsInboundCall = tempNote.IsInboundCall;
      this.CallerType = tempNote.CallerType;
      this.OrganizationName = tempNote.OrganizationName;
      this.CallerName = tempNote.CallerName;
      this.CallerPhone = tempNote.CallerPhone;
      this.newNoteBody = tempNote.newNoteBody;
      this.tagsSelected = tempNote.tagsSelected;
      this.noteType = tempNote.noteType;
      this.isNotePrivate = tempNote.isNotePrivate;
    }
  },
  methods: {
    cancelNoteEdit() {
      this.$emit("cancelNote");
      this.resetVars();
    },
    ...mapActions("policy", {
      deletePolicyNote: "deletePolicyNote",
      getPolicyNotes: "getPolicyNotes",
      updatePolicyNote: "updatePolicyNote",
      upsertPolicyNote: "upsertPolicyNote"
    }),
    setTempNote() {
      window.localStorage.setItem("tempNote", JSON.stringify(this.tempNote));
    },
    clearTempNote() {
      window.localStorage.removeItem("tempNote");
    },
    selectTag(tag) {
      if (this.tagsSelected.includes(tag))
        this.tagsSelected = this.tagsSelected.filter((t) => t !== tag);
      else this.tagsSelected.push(tag);
    },
    updateNote() {
      this.updatePolicyNote({
        policyNoteId: this.notes[this.noteIndex].policyNoteId,
        policyId: this.notes[this.noteIndex].policyId,
        noteBody: this.editNoteHold,
        private1: this.notes[this.noteIndex].private,
        policyTransactionId: this.notes[this.noteIndex].policyTransactionId,
        created_At: this.notes[this.noteIndex].created_At,
        createdBy_UserId: this.notes[this.noteIndex].createdBy_UserId,
        policyNo: this.policyNo
      })
        .then((res) => (this.notes[this.noteIndex].note = res.note))
        .then((this.showNoteModal = false));
    },
    deleteNote(note) {
      this.notes = this.notes.filter((n) => n.policyNoteId !== note.policyNoteId);
      this.deletePolicyNote({ policyNo: this.policyNo, note });
    },
    resetVars() {
      this.newNoteBody = "";
      this.IsInboundCall = undefined;
      this.CallerType = undefined;
      this.OrganizationName = "";
      this.CallerName = "";
      this.CallerPhone = "";
      this.tagsSelected = [];
      this.noteType = "note";
      this.clearTempNote();
    },
    async createNewNote() {
      if (!this.canSubmit) return;
      if (this.editing) {
        try {
          this.loading = true;
          const updateBody = {
            policyNoteId: this.noteToEdit.policyNoteId,
            policyId: this.noteToEdit.policyId,
            noteBody: this.newNoteBody,
            private1: this.noteToEdit.private,
            policyTransactionId: this.noteToEdit.policyTransactionId,
            created_At: this.noteToEdit.created_At,
            createdBy_UserId: this.noteToEdit.createdBy_UserId,
            policyNo: this.policyNo,
            type: this.noteType
          };
          if (this.noteType === "callLog") {
            const meta = {
              IsInboundCall: this.IsInboundCall,
              CallerType: this.CallerType,
              OrganizationName: this.OrganizationName,
              CallerName: this.CallerName,
              CallerPhone: this.CallerPhone
            };
            updateBody.meta = JSON.stringify(meta);
            updateBody.reason = this.tagsSelected.join(", ") || null;
            updateBody.private1 = true;
          }
          await this.updatePolicyNote(updateBody);
          this.resetVars();
          this.$emit("refresh");
        } catch (e) {
          throw new Error(e);
        } finally {
          this.loading = false;
        }
      } else {
        try {
          this.loading = true;
          const upsertBody = {
            policyNo: this.policyNo,
            noteBody: this.newNoteBody,
            isNotePrivate: this.isNotePrivate,
            type: this.noteType
          };
          if (this.noteType === "callLog") {
            const meta = {
              IsInboundCall: this.IsInboundCall,
              CallerType: this.CallerType,
              OrganizationName: this.OrganizationName,
              CallerName: this.CallerName,
              CallerPhone: this.CallerPhone
            };
            upsertBody.meta = JSON.stringify(meta);
            upsertBody.reason = this.tagsSelected.join(", ") || null;
            upsertBody.isNotePrivate = true;
          }
          await this.upsertPolicyNote(upsertBody);
          this.resetVars();
          this.$emit("refresh");
        } catch (e) {
          throw new Error(e);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>
<style lang="scss">
.notes-link {
  color: #26a97b !important;
  &:hover {
    text-decoration: underline;
  }
}
</style>
<style lang="scss" scoped>
.notes-container {
  display: flex;
  flex-direction: column;

  .note-body-class {
    word-break: break-all;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 10px 15px;
    // margin: 10px 0px;
    border: 1px solid lightgray;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    &:hover {
      box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
  .note-createdat-class {
    text-align: right;
    // margin-bottom: 20px;
    font-size: 11px;
    color: grey;
  }
}
.notes-sub {
  & .buttons-container {
    max-width: 0;
    transition: 0.5s;
  }
  & .deleteButton {
    opacity: 0;
    height: 30px;
    margin-right: 10px;
    transition: 0.7s;
  }
  & .editButton {
    opacity: 0;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 8px;
    height: 30px;
    transition: 0.7s;
  }
  &:hover {
    & .buttons-container {
      max-width: 999px;
    }
    & .deleteButton {
      display: flex;
      opacity: 1;
      height: 30px;
      margin-right: 10px;
    }
    & .editButton {
      display: flex;
      opacity: 1;
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 8px;
      height: 30px;
    }
  }
}
.notes-sub {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}
::v-deep .dialog-class {
  margin-bottom: auto;
}
::v-deep .el-switch__label.is-active {
  color: #004c9d !important;
}
</style>
