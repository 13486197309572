import { camelCase, kebabCase, lowerCase, snakeCase, startCase, upperCase } from "lodash";

export const casingMixins = {
  filters: {
    camelCase,
    kebabCase,
    lowerCase,
    snakeCase,
    startCase,
    upperCase
  },
  methods: {
    camelCase,
    kebabCase,
    lowerCase,
    snakeCase,
    startCase,
    upperCase
  }
};
