<template>
  <!-- Upload area -->
  <div class="upload__area">
    <!-- Hidden file uploader -->
    <input
      ref="fileElem"
      type="file"
      style="display: none;"
      v-bind="$props"
      @change="handleFiles"
    />

    <!-- Browse Button -->
    <el-button
      id="fileSelect"
      class="!bg-deepsea-full hover:!bg-deepsea-full/75 !text-white"
      size="mini"
      :disabled="disabled"
      @click="browseFiles"
    >
      <slot>
        Browse
      </slot>
    </el-button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    disabled: {
      type: [String, Object, Boolean]
    },
    multiple: {
      type: [Object, String]
    },
    webkitDirectory: {
      type: [Object, String, Boolean],
      default: false
    }
  },
  methods: {
    /** @param {Event} ev */
    handleFiles(ev) {
      const {
        target: { files }
      } = ev;

      const extracted = [];

      for (const f of files) {
        extracted.push(f);
      }

      this.$emit("change", extracted);
    },
    browseFiles() {
      this.$refs.fileElem.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.upload__area {
  display: inline-block;
}
</style>
