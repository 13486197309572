export default [
  {
    name: "billing-policy-detail",
    path: "/billing/policies/:id",
    meta: {
      title: "Policy Detail",
      isHiddenFromSideNav: true
    },
    component: () =>
      import(/* webpackChunkName: "PaymentDetailPage" */ "./pages/PolicyDetailPage.vue")
  }
];
