<template>
  <div class="pay-step relative">
    <div class="max-w-[666px] w-full pt-12 text-center">
      <img
        v-if="isEarthquake"
        src="@/assets/jumpstart.png"
        class="max-w-[220px] mx-auto"
        data-test="earthquakePayLogo"
        alt="Jumpstart Logo"
      />
      <img
        v-else
        src="@/assets/pngs/Neptune_Horizon_JetBlack.png"
        class="w-full max-w-[24rem] mb-4 mx-auto"
        alt="Neptune Logo"
      />

      <div v-if="[6, 7, 8].includes(productId)" class="mb-4">
        <span
          class="text-sm font-bold bg-pink-100 text-pink-900 inline-flex rounded-full px-2 py-0.5"
        >
          Excess Flood
        </span>
      </div>

      <div :class="{ 'bg-black p-4 rounded bg-opacity-30 mt-8': isEarthquake }">
        <h2 data-test="paymentTitle">Payment</h2>

        <p v-if="isPaid" data-test="alreadyPaidText">
          You have already paid for this policy
        </p>
        <p v-else data-test="paymentSubtitle">
          Please choose your method of payment.
        </p>
      </div>
    </div>
    <div
      v-if="!isPaid"
      class="menu"
      :class="{ 'bg-black p-4 rounded bg-opacity-30': isEarthquake }"
    >
      <span
        v-for="(item, key) in paymentOptions"
        :key="item.title"
        class="menu-item space-x-2 lg:space-x-0 transition-transform"
        :class="{
          'text-deepsea-full scale-125': activeComponent === key && !isEarthquake,
          'text-brand-orange scale-125': activeComponent === key && isEarthquake,
          'text-jetblack-full/75 hover:text-deepsea-full scale-90':
            activeComponent !== key && !isEarthquake,
          'text-gray-300 hover:text-white scale-90': activeComponent !== key && isEarthquake
        }"
        :data-test="`paymentTypeBox-${key}`"
        @click="onPaymentTypeClick(key)"
      >
        <span :class="[item.icon]" :data-test="`paymentIcon-${key}`" />

        <h3 class="title" :data-test="`paymentType-${key}`">{{ item.title }}</h3>
        <!-- <p class="text-xs">{{ item.description }}</p> -->
      </span>
      <span
        v-if="canSeeCheckOption"
        class="menu-item transition-transform"
        :class="{
          'text-deepsea-full scale-125': activeComponent === 'applyCheck' && !isEarthquake,
          'text-brand-orange scale-125': activeComponent === 'applyCheck' && isEarthquake,
          'text-jetblack-full/75 hover:text-deepsea-full scale-90':
            activeComponent !== 'applyCheck' && !isEarthquake,
          'text-gray-300 hover:text-white scale-90':
            activeComponent !== 'applyCheck' && isEarthquake
        }"
        data-test="applyCheckDatePicker"
        @click="onPaymentTypeClick('applyCheck')"
      >
        <i class="el-icon-money icon text-3xl" data-test="applyCheckIcon"></i>
        <h3 class="title" data-test="applyCheckText">Apply Check</h3>
      </span>
    </div>

    <div v-if="!isPaid" class="content">
      <payment-mortgagee v-if="state.matches('payment.loaded.mortgagee_payment')" />
      <payment-ach
        v-else-if="state.matches('payment.loaded.automated_check_payment')"
        :state="state"
        @startACH="triggerPayment"
      />
      <payment-cc
        v-else-if="state.matches('payment.loaded.credit_card_payment')"
        :state="state"
        @ccComplete="ccComplete"
      />
      <payment-check
        v-else-if="state.matches('payment.loaded.lockbox_check_payment')"
        :state="state"
      />
      <cc-message v-else-if="state.matches('payment.loaded.cc_message')" />

      <!-- <component
        :is="activeComponent"
        v-if="activeComponent"
        :key="activeComponent"
        v-bind="{ ...$attrs, ...$props }"
        :payments="{ paymentMethods }"
        v-on="$listeners"
        :context="context"
        @achComplete="achEmit"
        @update:loading="setLoading"
      /> -->
      <el-card v-else-if="state.matches('payment.loaded.apply_check_payment')">
        <h3 style="text-align: center">
          Apply Check to Policy: <b data-test="applyCheckNo">{{ policyNo }}</b>
        </h3>
        <el-form :model="checkBody">
          <el-form-item label="Check Number" :required="true">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="'min_value:1|required'"
              name="Check Number"
            >
              <el-input v-model="checkBody.checkNo" type="text" data-test="checkNo"></el-input>
              <p
                v-show="errors && errors.length"
                data-test="checkNumberError"
                class="text-xs text-red-500 font-bold select-none animated fadeIn"
              >
                Please provide a valid check number.
              </p>
            </ValidationProvider>
          </el-form-item>
          <el-form-item label="Remitter Name" :required="true">
            <ValidationProvider v-slot="{ errors }" :rules="'required'" name="Remitter Name">
              <el-input v-model="checkBody.payeeName" type="text" data-test="checkName"></el-input>
              <p
                v-show="errors && errors.length"
                data-test="remitterNameError"
                class="text-xs text-red-500 font-bold select-none animated fadeIn"
              >
                Remitter name is required.
              </p>
            </ValidationProvider>
          </el-form-item>
          <el-form-item label="Memo" :required="true">
            <ValidationProvider v-slot="{ errors }" :rules="'required'" name="Memo">
              <el-input
                v-model="checkBody.memo"
                type="text"
                class="focus:!border focus:!border-jetblack-full"
                data-test="checkMemo"
              ></el-input>
              <p
                v-show="errors && errors.length"
                data-test="remitterNameError"
                class="text-xs text-red-500 font-bold select-none animated fadeIn"
              >
                Memo is required.
              </p>
            </ValidationProvider>
          </el-form-item>
          <el-form-item label="Amount" :required="true">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="'min_value:1|required'"
              name="Check Number"
            >
              <el-input v-model="checkBody.amount" type="text" data-test="checkAmount"></el-input>
              <p
                v-show="errors && errors.length"
                data-test="paymentAmountError"
                class="text-xs text-red-500 font-bold select-none animated fadeIn"
              >
                Please enter an amount greater than 0
              </p>
            </ValidationProvider>
          </el-form-item>
          <div class="flex justify-between">
            <div>
              <el-form-item label="Check Deposit Date" :required="true">
                <ValidationProvider v-slot="{ errors }" :rules="'required'" name="Deposit Date">
                  <el-date-picker
                    v-model="checkBody.createdAt"
                    type="date"
                    placeholder="Select a Deposit Date"
                    data-test="checkDatePicker"
                  />
                  <p
                    v-show="errors && errors.length"
                    data-test="dateError"
                    class="text-xs text-red-500 font-bold select-none animated fadeIn"
                  >
                    Please select a date.
                  </p>
                </ValidationProvider>
              </el-form-item>
            </div>
            <div class="mt-10">
              <el-button
                type="success"
                class="!bg-deepsea-full"
                :disabled="errorCheck"
                data-test="applyCheckButton"
                @click="applyCheck()"
              >
                Apply Check
              </el-button>
            </div>
          </div>
        </el-form>
      </el-card>
      <div v-show="loading" key="preloader" class="content-loading">
        <preloader-spinner path="/static/lottie/preloader3.json" :loop="true" />
      </div>
    </div>
    <action-button
      v-if="showViewPolicy"
      data-test="paymentViewPolicyButton"
      :earthquake="isEarthquake"
      :class="{
        buttonOverride: isEarthquake,
        '!bg-deepsea-full !border !border-deepsea-medium hover:!bg-deepsea-full/75': !isEarthquake
      }"
      @click="goToPolicy"
    >
      View Policy
    </action-button>
  </div>
</template>

<script>
import PaymentCheckVue from "./PaymentCheck.vue";
import ActionButtonVue from "../../components/ActionButton.vue";
import { stripeInstance } from "../../store-module/modules/payments";
import { mapActions } from "vuex";
import LottieViewerVue from "@/components/policy-wizard/components/LottieViewer.vue";
import { NumberFiltersMixin } from "../../../policy-wizard/utils/mixins/formatting";
import PaymentMortgagee from "./PaymentMortgagee.vue";
import CcMessage from "./CCMessage.vue";
import { ValidationProvider } from "vee-validate";
import { isAfter } from "date-fns";

export default {
  name: "PaymentScreen",
  components: {
    PaymentAch: () => import(/* webpackChunkName: "ACH" */ "./ach/PaymentACH.vue"),
    PaymentCc: () => import(/* webpackChunkName: "CC" */ "./cc/index.vue"),
    PaymentCheck: PaymentCheckVue,
    // SavedPayments: () => import(/* webpackChunkName: "ACH" */ "./PaymentSaved.vue"),
    ActionButton: ActionButtonVue,
    PreloaderSpinner: LottieViewerVue,
    PaymentMortgagee,
    CcMessage,
    ValidationProvider
  },
  mixins: [NumberFiltersMixin],
  inheritAttrs: false,
  props: {
    payments: Object,
    // context: Object
    state: Object,
    showViewPolicy: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    loading: false,
    checkBody: {
      createdAt: "",
      checkNo: "",
      payeeName: "",
      memo: "",
      amount: ""
    },
    activeComponent: "PaymentAch"
  }),

  computed: {
    productId() {
      return this.state.context.transactions[this.state.context.transactions.length - 1].productId;
    },
    canSeeCheckOption() {
      return (
        this.$store?.state?.auth?.data?.AgentId === "FL0008" ||
        this.$store?.state?.auth?.data?.AgentId === "FL53596" ||
        this.$store?.state?.auth?.data?.AgentId === "FL50267" ||
        this.$store?.state?.auth?.data?.AgentId === "FL53597"
      );
    },
    isEarthquake() {
      return (
        this.state.context.transactions[this.state.context.transactions.length - 1].productId ===
          4 ||
        this.state.context.transactions[this.state.context.transactions.length - 1].productId === 5
      );
    },
    errorCheck() {
      return (
        parseInt(this.checkBody.amount) <= 0 ||
        this.checkBody.amount === "" ||
        parseInt(this.checkBody.checkNo) <= 0 ||
        this.checkBody.checkNo === "" ||
        this.checkBody.payeeName === "" ||
        this.checkBody.memo === "" ||
        this.checkBody.createdAt === ""
      );
    },
    amountAlert() {
      return parseInt(this.checkBody.amount) <= 0;
    },
    isInternal() {
      return this.$store.getters["auth/internal"] === true;
    },
    policyNo() {
      return this.state.context.policyNo;
    },

    isCommercial() {
      return [2, 3, 7, 8].includes(
        this.state.context.transactions[this.state.context.transactions.length - 1].productId
      );
    },

    canShowMortgageeLienholder() {
      const [latestTransaction] = this.state.context.transactions.sort((a, b) => {
        return isAfter(new Date(a.postDate), new Date(b.postDate)) ? -1 : 1;
      });

      if (latestTransaction.productId !== 1) {
        return false;
      }

      let billOn;
      if (latestTransaction.transactionType === "RN") {
        billOn = latestTransaction.billOnRenewal;
      } else {
        billOn = latestTransaction.billInitially;
      }

      return billOn === "Mortgage lienholder";
    },

    isPaid: {
      get() {
        return this.state.context.confirmation.isPaid || false;
      },

      set(val) {
        this.$store.commit(`${this.policyNo}/SET_PROP`, {
          path: "policyData.isPaid",
          value: val
        });
      }
    },

    paymentOptions() {
      let ops = {};
      const [latestTransaction] = this.state.context.transactions.sort((a, b) => {
        return isAfter(new Date(a.postDate), new Date(b.postDate)) ? -1 : 1;
      });

      if (
        this.state?.context?.confirmation?.paymentInterval === "Monthly" &&
        (latestTransaction?.productId === 4 || latestTransaction?.productId === 5)
      ) {
        ops = {
          PaymentMortgagee: {
            icon: "fa fa-university !text-2xl",
            title: "Mortgagee Lienholder"
          },
          PaymentCc: {
            icon: "el-icon-bank-card text-3xl",
            title: "Credit Card",
            description: "Pay by credit card"
          }
        };
      } else if (
        this.state?.context?.confirmation?.paymentInterval !== "Monthly" &&
        (latestTransaction?.productId === 4 || latestTransaction?.productId === 5)
      ) {
        ops = {
          PaymentMortgagee: {
            icon: "fa fa-university !text-2xl",
            title: "Mortgagee Lienholder"
          },
          PaymentAch: {
            icon: "el-icon-office-building text-3xl",
            title: "ACH",
            description: "Pay by ACH"
          },
          PaymentCc: {
            icon: "el-icon-bank-card text-3xl",
            title: "Credit Card",
            description: "Pay by credit card"
          }
        };
      } else if (
        this.state.context.confirmation.totalDue > 5000 &&
        latestTransaction?.productId === 1
      ) {
        ops = {
          PaymentMortgagee: {
            icon: "fa fa-university !text-2xl",
            title: "Mortgagee Lienholder"
          },
          PaymentAch: {
            icon: "el-icon-office-building text-3xl",
            title: "ACH",
            description: "Pay by ACH"
          },
          CCMessage: {
            icon: "el-icon-bank-card text-3xl",
            title: "Credit Card"
          },
          PaymentCheck: {
            icon: "el-icon-postcard text-3xl",
            title: "Pay by Check",
            description: "Pay by check"
          }
        };
      } else {
        ops = {
          PaymentMortgagee: {
            icon: "fa fa-university !text-xl",
            title: "Mortgagee Lienholder"
          },
          PaymentAch: {
            icon: "el-icon-office-building text-3xl",
            title: "ACH",
            description: "This method does this"
          },
          PaymentCc: {
            icon: "el-icon-bank-card text-3xl",
            title: "Credit Card",
            description: "This method does they"
          },
          PaymentCheck: {
            icon: "el-icon-postcard text-3xl",
            title: "Pay by Check",
            description: "This method does other"
          }
        };
      }
      if (!this.paymentMethods) {
        delete ops.SavedPayments;
      }

      if (!this.canShowMortgageeLienholder) {
        delete ops.PaymentMortgagee;
      }

      return ops;
    },
    paymentMethods() {
      return this.payments;
    }
  },
  beforeMount() {
    stripeInstance.default = window.Stripe(this.state.context?.confirmation?.stripeKey);
  },
  mounted() {
    if (this.canShowMortgageeLienholder) {
      this.activeComponent = "PaymentMortgagee";
    }
  },
  methods: {
    ...mapActions("payment", ["postCheckPayment"]),
    goToPolicy() {
      if (this.$route.name !== "policyWelcome") {
        this.$emit("clickToClose");
      } else {
        this.$router.push({
          name: "policyHolderDetail",
          params: {
            policyNo: this.policyNo
          }
        });
      }
    },
    onPaymentTypeClick(key) {
      this.activeComponent = key;
      this.$emit("togglePaymentType", key);
    },
    triggerPayment(data) {
      this.$emit("startACH", data);
    },
    open1() {
      this.loading = false;
      this.$notify({
        title: "Success",
        message: "Check has been applied successfully!",
        type: "success"
      });
    },
    async applyCheck() {
      try {
        this.loading = true;
        await this.postCheckPayment({ policyNo: this.policyNo, checkBody: this.checkBody });
        this.$emit("checkComplete");
        this.open1();
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    ccComplete(data) {
      this.$emit("ccComplete", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-thing {
  grid-area: side;
}

.pay-step {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;

  > * {
    margin-bottom: 2rem;
  }
}

.menu {
  display: flex;
  margin: 0.5rem 0 1.5rem;
  flex-flow: row wrap;
  justify-content: space-around;

  .menu-item {
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    padding: 1rem 2.5rem;
    white-space: nowrap;

    @media screen and (max-width: 1199px) {
      padding: 1rem 1rem;

      flex-flow: row;
      justify-content: center;
      align-items: center;
    }

    &:not(.active) {
      opacity: 1;

      &:hover {
        opacity: 1.4;
      }
    }

    .title {
      margin: 1rem 0;
      font-size: 1.1rem;
    }
  }
}

.content {
  width: 100%;
  max-width: 700px;
  padding: 1rem;

  ::v-deep .el-card {
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f8f8f8, #eaeaea);
    a {
      font-weight: normal;
      color: #0071bd;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: #177dc2;
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }
}
.warning-text {
  color: red;
}
.logo-class {
  max-height: 450px;
  max-width: 450px;
}
.jsText {
  margin-top: -100px;
  background-color: #00000025;
  padding: 1rem;
  border-radius: 4px;
}
.js-background {
  background-color: #00000025;
  padding: 1rem;
  border-radius: 4px;
}
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}
// http://localhost:8080/#/policyholder/new/VXoNtiZ8G9f%2bEy5XzCpPoM93ngiKqF7Fh8nyNErtbzRyLxYqiItSbXJT4LwKYPcbiXMQVIMbz52Q4SutV89mxxSB9EUa0wbREL0wdFkTbAYxZPMZ7GdohwpQ3UmXzzb8BcYSBaTy3VyvpO5bUclswvD6%2bI%2fKoA6SvMfBcYsLMSw%3d
</style>
