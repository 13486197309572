/** @type {import("vuex").MutationTree} */
export const mutations = {
    REMOVE_REGISTRATION(state, { index }) {
        state.registrations.splice(index, 1);
    },
    UPDATE_REGISTRATION(state, { index, newRegistration }) {
        if (index === -1) state.registrations.push(newRegistration);
        else state.registrations.splice(index, 1, newRegistration);
    },
    SET_REGISTRATION(state, data) {
        state.registration = data;
    },
    SET_AGENT_NO(state, agentNo) {
        state.registration = {
            ...state.registration,
            AgentNo: agentNo
        };
      }

};

export default mutations;
