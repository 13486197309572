<template>
  <collapser-section class="diff-collapser" v-bind="$attrs">
    <template #title>
      <el-badge
        class="text-xs ml-2"
        type="danger"
        size="mini"
        :hidden="diffedObjects.length <= 0 || true"
        :max="99"
        :value="diffedObjects.length"
      />
    </template>

    <slot v-for="item in timelineObjects" name="list" v-bind="item" />

    <slot
      name="default"
      v-bind="{ timelineObjects, keyedObject, diffedObjects, context: $vnode }"
    />
  </collapser-section>
</template>

<script>
import CollapserSectionVue from "./CollapserSection.vue";
export default {
  components: {
    CollapserSection: CollapserSectionVue
  },
  inheritAttrs: false,
  props: {
    /**
     * MUST be an array of objects
     * with the same set of keys
     * @type {Vue.PropType<{[key: string]: any}[]>}
     */
    objects: {
      type: Array,
      default() {
        return [
          {
            a: 1,
            b: 2,
            c: 3
          },
          {
            a: 1,
            b: 6,
            c: 1
          },
          {
            a: 1,
            b: 6,
            c: 1
          }
        ];
      }
    },
    currentIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    /**
     * @type {Vue.ComputedOptions<any>}
     */
    activeObject() {
      return this.objects[this.currentIndex];
    },

    /**
     * Converts all objects into their key / value pairs
     * with values as an array of the same property
     * Should only include the lastest and porevious entry
     * @type {Vue.ComputedOptions<any[]>}
     */
    timelineObjects() {
      const keys = Object.keys(this.objects[0]);

      /**
       * Object of raw object values as arrays
       */
      const rawObjectValues = keys.reduce((prev, cur) => {
        prev[cur] = this.objects.map((v) => v[cur]);
        return prev;
      }, {});

      /**
       * convert obj into array of objects with additional data
       */
      const values = Object.entries(rawObjectValues).map(([key, val]) => {
        const newVal = val[this.currentIndex];
        const oldVal = val[this.currentIndex - 1];

        return {
          key,
          values: {
            old: oldVal,
            new: newVal
          },
          diff: this.currentIndex === 0 ? false : oldVal !== newVal,
          raw: [key, val]
        };
      });

      return values;
    },

    /**
     * Same as timelineObjects, but in object format
     * keys are from the collection of objects
     * @type {Vue.ComputedOptions<{[key: string]: any}>}
     * @returns {{[key: string]: any}}
     */
    keyedObject() {
      return this.timelineObjects.reduce((prev, cur) => {
        const { key } = cur;
        prev[key] = cur;
        return prev;
      }, {});
    },

    diffedObjects() {
      return this.timelineObjects.filter((v) => v.diff);
    }
  }
};
</script>

<style lang="scss" scoped>
.diff-collapser {
  // border-left: 1px solid green;
}
</style>
