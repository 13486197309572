// @ts-check

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

/**
 * @type {neptune.payment.ICreditCardState}
 */
export const state = {
  invoiceDetails: {
    Amount: 0,
    Invoice: {
      CreatedAt: null,
      CreatedByUserId: null,
      InvoiceId: null,
      PolicyId: null,
      PolicyTransactionId: null,
      UpdatedAt: null,
      UpdatedByUserId: null
    },
    InvoiceItems: [],
    IsPaid: false
  },
  // stripe payment intent
  stripePaymentData: null,
  // client secret
  paymentIntentToken: null,
  // saved payment methods
  paymentMethodData: []
};

/** @type {import('vuex').Module<any,any>} */
const mod = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
};

export default mod;
