<template>
  <div class="text-center h-screen flex items-center justify-center px-4 lg:px-6">
    <div>
      <img
        v-if="isEarthquake"
        src="@/assets/jumpstart.png"
        class="mb-6 max-w-[300px] mx-auto"
        alt="Jumpstart Logo"
      />
      <img
        v-else
        src="@/assets/pngs/Neptune_Horizon_DeepSea.png"
        class="w-[420px] mb-4 mx-auto"
        alt="Neptune Logo"
      />
      <div :class="{ jsText: isEarthquake }">
        <h2 class="text-5xl te">
          All your tasks are complete!
        </h2>
        <p class="text-lg">
          <strong>Thank you for doing business with us!</strong>
        </p>
      </div>
      <!-- If everything is signed -->
      <div>
        <action-button
          v-if="isModal"
          data-test="gotoPolicy"
          :earthquake="isEarthquake"
          :class="{
            buttonOverride: isEarthquake,
            '!bg-deepsea-full !border !border-deepsea-medium hover:!bg-deepsea-full/75 mt-8': !isEarthquake
          }"
          @click="gotoPolicy"
        >
          View Policy
        </action-button>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButtonVue from "../components/ActionButton.vue";
export default {
  components: {
    ActionButton: ActionButtonVue
  },
  props: {
    state: Object,
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isEarthquake() {
      return (
        this.state.context.transactions[this.state.context.transactions.length - 1].productId ===
          4 ||
        this.state.context.transactions[this.state.context.transactions.length - 1].productId === 5
      );
    },
    isRenewal() {
      return this.state.context.confirmation.isSigned.every((x) => x.requiresSignatures === false);
    },
    policyNo() {
      return this.state.context.policyNo;
    },
    agentSigned() {
      return this.state.context.confirmation.isSigned[0]?.isSigned;
    },
    insuredSigned() {
      return this.state.context.confirmation.isSigned[1].isSigned;
    },

    isCommercial() {
      return (
        this.state.context.transactions[this.state.context.transactions.length - 1].productId ===
          2 ||
        this.state.context.transactions[this.state.context.transactions.length - 1].productId === 3
      );
    },
    requiresSigs() {
      const signatureStatus = this.state.context.confirmation.isSigned.find(
        (s) => s.party === this.state.context.party
      );
      const { requiresSignatures } = signatureStatus;
      return requiresSignatures;
    },
    completelySigned() {
      return this.state.context.confirmation.isSigned.every(
        (v) => v.isSigned === true && this.state.context.party === v.party
      );
    },
    paid() {
      return this.state.context.confirmation.isPaid;
    }
  },
  methods: {
    gotoPolicy() {
      this.$root.$emit("completePayment");

      if (this.$route.fullPath.includes("/policyholder/welcome")) {
        this.$router.push({
          name: "policyHolderDetail",
          params: {
            policyNo: this.policyNo
          }
        });
      } else {
        this.$emit("clickToClose");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.buttonOverride {
  color: rgb(255, 97, 76);
  background: white;
  border: 1px solid rgb(255, 97, 76);
  text-shadow: none;
  &:hover,
  &:active,
  &:focus {
    filter: tint(75%);
    color: white;
  }
}
</style>
