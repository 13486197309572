// @ts-check

// import Axios from 'axios'
import { authAxios } from "@/store/modules/auth";
import { Constants } from "@/config";

/**
 * @type {import('vuex').ActionTree<neptune.payment.ICreditCardState, neptune.IRootState>}
 */
const actions = {
  async getPaymentMethodData({ commit, rootGetters }) {
    // const { UserId } = getters;
    const { data } = await authAxios.get(
      // `${Constants.SAVED_PAYMENTMETHODS_URL}`
      Constants.SAVED_PAYMENTMETHODS_URL.replace(
        "{{policyNo}}",
        rootGetters["policy/allData"].policyNo
      )
    );

    commit("SET_PROP", {
      path: "paymentMethodData",
      value: data.payload || ""
    });

    return data;
  },

  async createPaymentIntent({ commit, rootState }, existingPaymentMethodId) {
    /* SAMPLE Request...pass the existing payment method if an existing method is being used
  {
    "PolicyNo": "{{PolicyNo}}",
    // null if using new payment information
    // pass ID of existing payment method
    "ExistingPaymentMethod": "pm_1FsyalCgOaHoiNklJS0725gh",
    "testme": true
  }

  * Sample Response
    {
      "statusCode": 500,
      "message": "Nothing to pay."
    }
  * Sample response
    {
      "payload": {
          "policyNo": "NAA2000564",
          "totalPremium": 0.0000,
          "taxes": 0.0000,
          "policyFee": 0.0000,
          "adjustments": 0.0,
          "grossPremium": 0.0000,
          "totalInvoiceAmount": 3681.6500,
          "totalPayments": 0.0000,
          "totalDue": 3681.6500,
          "email": "jose@neptuneflood.com",
          "unpaidInvoices": [
              {
                  "invoiceNo": "1120",
                  "invoiceId": 1120,
                  "date": "2019-12-27T03:40:38.9945347",
                  "total": 3681.6500,
                  "paid": 0.0000,
                  "due": 3681.6500
              }
          ],
          "clientSecret": "pi_1FvT2cCgOaHoiNklfa1ZuMw4_secret_E0czghOFJyoHpS1B448BViNiU",
          "intentId": "pi_1FvT2cCgOaHoiNklfa1ZuMw4",
          "success": true,
          "message": null
      },
      "statusCode": 200
  }
 */
    const {
      auth: {
        validateReturn: {
          policy: {
            policy: { policyNo }
          }
        }
      }
    } = rootState;

    try {
      const { data } = await authAxios.post(
        Constants.INTENT_URL, // '/api/v1/payments/intent',
        {
          PolicyNo: policyNo,
          ExistingPaymentMethod: existingPaymentMethodId
        }
      );

      // check to see if the call failed
      if (data.statusCode !== 200) {
        debugger;
        return Promise.reject(new Error(data.payload));
      }

      commit("SET_PROP", {
        path: "paymentIntentToken",
        value: data.payload.clientSecret
      });

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * @deprecated Use confirmCardPayment Instead
   * https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
   * @param {import('vuex').ActionContext<neptune.payment.ICreditCardState, neptune.IRootState>} param0
   * @param {neptune.payment.IStripePaymentParams} param1
   */
  async handleStripeCardPayment({ commit, state }, { stripe, card }) {
    const { paymentIntentToken } = state;

    const res = await stripe.handleCardPayment(paymentIntentToken, card, {});

    if (res.error) {
      return Promise.reject(res.error?.message ?? res.error);
    }

    commit("SET_PROP", {
      path: "stripePaymentData",
      value: res.paymentIntent || res
    });

    return res;
  },

  // todo: test to see if the payment was successful before calling this
  async savePaymentData({ state, rootState }, remember) {
    const email = rootState.currentQuote?.quote?.application?.email;
    const intentId = rootState.payment.cc.stripePaymentData.id;
    const amount_received = rootState.payment.cc.stripePaymentData.amount_received;
    const paymentMethodId = rootState.payment.cc.stripePaymentData.payment_method;
    const policyNo = rootState.auth.validateReturn.policy.policy.policyNo;
    const intentObject = state.stripePaymentData;

    /** @type {neptune.payment.ISavePaymentParams} */
    const payload = {
      policyNo: policyNo,
      amount: amount_received,
      intentId: intentId,
      paymentMethodId: paymentMethodId,
      email: email,
      saveForReuse: remember,
      responseData: intentObject
    };

    try {
      const res = await authAxios.post(
        Constants.PAY_CARD_URL, // '/api/v1/payments/card',
        payload
      );
      // commit('SET_PROP', {
      //  path: 'savePaymentParams',
      //  value: obj
      // });
      return res.data;
    } catch (error) {
      return new Error(error);
    }
  }
};

export default actions;
