import { format, timeFormat } from "d3";

/**
 * Format a string to phone number format
 * @param {string | number} val
 */
export const formatPhone = (val) => {
  const cleaned = ("" + val).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return val;
};

export const formatMoney = (val) => {
  const f = format("[[fill]align][sign][symbol][0][width][,][.precision][~][type]");

  return f(val);
};

export const formatDate = (date) => {
  // const _ = Intl.DateTimeFormat('en-US', {
  //   weekday: 'short',
  //   year:    'numeric',
  //   month:   'short',
  //   day:     'numeric'
  // })

  const f2 = {
    format: timeFormat("%B %d, %Y")
  };

  if (typeof date === "number" && !isNaN(date)) {
    return f2.format(date);
  }

  if (typeof date === "string") {
    const utc = date.endsWith("Z");

    const dateMS = Date.parse(utc ? date : `${date}`);

    if (!isNaN(dateMS)) {
      return f2.format(dateMS);
    }
  }

  return date;
};
export const formatLongDate = (date) => {
  const f2 = Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  });

  if (typeof date === "number" && !isNaN(date)) {
    return f2.format(date);
  }

  if (typeof date === "string") {
    const dateMS = Date.parse(date);

    if (!isNaN(dateMS)) {
      return f2.format(dateMS);
    }
  }

  return date;
};

export default {
  formatPhone,
  formatMoney,
  formatDate
};
