<template>
  <div class="flex flex-col step3">
    <div>
      <div>
        <div class="title">
          <h3 data-test="cancelDocMessage">
            {{ reason.reasonName }} requires additional document uploads for cancellation.
          </h3>
          <hr class="my-3" />
          <p v-if="reason.reasonName === 'Building Sold'" class="mb-3">
            Please provide the closing disclosure, settlement statement, or HUD-1 document from the
            sale of the property before continuing. If proper documents are not received, the
            cancellation will not be valid.
          </p>
          <p v-else class="mb-3">
            Please provide supporting documents before continuing.
          </p>
        </div>
        <div class="documents my-8">
          <div v-for="(doc, index) in fileMeta" :key="index" class="file mb-3">
            <div class="doc-item flex justify-between">
              <document-uploader
                ref="uploader"
                :url="getUploadUrl(doc)"
                :doc-type="doc.docTypeId"
                :policy-no="$store.getters['policy/allData'].policyNo"
                data-test="cancelDocUpload"
                class="mr-2"
                @change="addFile($event, index)"
              />
              <div v-if="doc.file">
                <!-- <div v-if="!doc.file">{{ doc.name }}</div> -->
                <div v-for="(item, index1) in doc.file" :key="index1" data-test="cancelDocName">
                  {{ item.name }}
                  <span v-if="!doc.file" class="el-icon-paperclip" />
                  <span v-else-if="!uploading && !uploaded" class="el-icon-document-checked" />
                  <span v-else-if="!doc.uploaded && uploaded" class="el-icon-error text-red-500" />
                  <span v-else class="el-icon-circle-check text-green-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Constants } from "../../../../config";
import DocumentUploaderVue from "../../../molecules/inputs/DocumentUploader.vue";
import { authAxios } from "../../../../store/modules/auth";

// import camelCase from 'lodash/camelCase'
export default {
  components: {
    DocumentUploader: DocumentUploaderVue
  },
  props: {
    /** @type {Vue.PropType<neptune.ICancellationReason} */
    reason: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    filesToUpload: [],
    uploadedFileData: [],
    uploading: false,
    uploaded: false
  }),
  computed: {
    /**
     * If the load is completed for ALL documents
     * @type {() => Vue.ComputedOptions<boolean>}
     */
    headerInfo() {
      return {
        Authorization: this.$store.state.auth.token
      };
    },

    policyNo() {
      return this.$store.getters["policy/allData"]?.policyNo;
    },

    /** @type {() => neptune.PolicyCancelDoc[]} */
    files() {
      return this.reason.policyCancelDocs;
    },
    fileNames() {
      return this.files.map((f) => f.docType.type);
    },
    fileMeta() {
      const uploadedFiles = this.filesToUpload;
      const result = this.files.map((f, i) => ({
        name: f?.docType?.type,
        file: uploadedFiles[i],
        docTypeId: f.docTypeId,
        uploaded: this.uploadedFileData[i]
      }));
      return result.flat();
    },
    valid() {
      return this.fileMeta.every((f) => !!f.file);
    },
    cancelReasonDocType() {
      return this.$store.getters["policy/cancelReason"]?.policyCancelDocs[0]?.docTypeId;
    }
  },
  watch: {
    valid(val) {
      this.$emit("success", val);
    }
  },
  beforeMount() {
    this.$emit("success", true);
  },
  methods: {
    onChange(file, filesToUpload) {
      this.filesToUpload = filesToUpload;
      this.uploaded = false;
    },
    onSuccess(response, file, filesToUpload) {
      console.log({
        response,
        file,
        filesToUpload
      });
    },

    /** @type {(doc: neptune.PolicyCancelDoc) => string} */
    getUploadUrl(doc) {
      const policyNo = this.policyNo;
      const url = Constants.UPLOAD_URL.replace("{{policyNo}}", policyNo).replace(
        "{{docType}}",
        doc.docTypeId
      );
      return url;
    },

    onError(err, file, filesToUpload) {
      console.log("Error uploading file", {
        err,
        file,
        filesToUpload
      });
    },

    async submit() {
      const url = Constants.UPLOAD_URL.replace("{{policyNo}}", this.policyNo).replace(
        "{{docType}}",
        this.cancelReasonDocType
      );
      try {
        this.uploading = true;
        this.uploaded = false;
        const filesArr = this.filesToUpload.flat();

        const fd = new window.FormData();

        filesArr.forEach((x) => fd.append(x.name, x.file, x.name));

        const res = await authAxios.post(url, fd);
        await this.$store.dispatch("document/getPdfs", this.$route.params.policyNo);
        return res;
      } catch (e) {
        console.log("error", e);
      } finally {
        this.uploading = false;
        this.uploaded = true;
      }
    },

    addFile(file, index) {
      this.filesToUpload.splice(index, 1, file);
      this.$emit("updatedDocuments");
    },
    removeFile(item, index) {
      this.$refs.uploader.removeFile(item, index);
    }
  }
};
</script>

<style lang="scss" scoped>
.step3 {
  min-width: 500px;
  max-width: 600px;

  .file-item {
    border-bottom: 1px solid rgba(#000, 0.3);
    background-color: darken(#fff, 5);
  }
}
</style>
