<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="flex flex-col">
          <ValidationProvider v-slot="{ errors, validate }" rules="required" name="File">
            <input
              ref="file"
              type="file"
              class="file:mr-4 file:py-2 file:px-4 file:rounded-full file:!border-2 file:!border file:!border-sky-200 file:text-sm file:font-semibold file:bg-sky-50 file:text-sky-700 file:border-solid hover:file:bg-sky-100 mt-5 text-slate-500 cursor-pointer"
              :class="{
                'border-red-500': errors[0],
                'border-gray-300': !errors[0]
              }"
              data-test="fileSelector"
              @change="selectFile($event, validate)"
            />
            <FadeTranslate>
              <p
                v-if="errors && errors.length && !selectedFile"
                class="text-xs text-red-500 text-xs"
              >
                {{ errors[0] }}
              </p>
            </FadeTranslate>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" :rules="'required'" name="Document Type">
            <label class="text-sm text-gray-700 leading-none block mt-5 mb-1">
              <span>Select document type</span><span class="text-red-500">*</span>
            </label>

            <select
              v-if="isEarthquake"
              id="selectDocType"
              v-model="docType"
              class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
              :class="{
                'border-red-500': errors[0],
                'border-gray-300': !errors[0]
              }"
              placeholder="Document Type"
              data-test="docTypeSelector-a"
            >
              <option
                v-for="(item, index) in jsDocTypesArray"
                :key="index"
                :value="item.type"
                :data-test="`docTypeA-${item.type}`"
              >
                {{ item.type }}
              </option>
            </select>
            <select
              v-else
              id="selectDocType"
              v-model="docType"
              class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
              :class="{
                'border-red-500': errors[0],
                'border-gray-300': !errors[0]
              }"
              placeholder="Document Type"
              data-test="docTypeSelector-a"
            >
              <option
                v-for="(item, index) in filteredDocumentTypes"
                :key="index"
                :value="item.type"
                :data-test="`docTypeA-${item.type}`"
              >
                {{ item.type }}
              </option>
            </select>
            <FadeTranslate>
              <p v-if="errors && errors.length" class="text-xs text-red-500 text-xs">
                {{ errors[0] }}
              </p>
            </FadeTranslate>
          </ValidationProvider>
        </div>
        <div class="mt-2 mb-5">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="Document Name"
            class="mb-5"
          >
            <label class="text-sm text-gray-700 leading-none block mt-5 mb-1">
              <span>Enter a document name</span><span class="text-red-500">*</span>
            </label>

            <input
              id="filename"
              v-model="friendlyName"
              type="text"
              class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
              :class="{
                'border-red-500': errors[0],
                'border-gray-300': !errors[0]
              }"
              data-test="documentNameInput"
            />
            <FadeTranslate>
              <p v-if="errors && errors.length" class="text-xs text-red-500 text-xs">
                {{ errors[0] }}
              </p>
            </FadeTranslate>
          </ValidationProvider>
        </div>

        <div class="flex space-x-2 justify-end mt-4">
          <button
            data-test="cancelDocButton"
            :class="{
              'focus:ring-brand-darkorange': isEarthquake
            }"
            class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>
          <button
            :disabled="loading"
            type="primary"
            :class="{
              '!text-brand-orange !bg-brand-orange !border !border-brand-orange hover:!bg-brand-darkorange !text-white focus:ring-brand-darkorange': isEarthquake,
              'cursor-not-allowed': loading
            }"
            class="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 min-w-[69px] justify-center"
            data-test="uploadDocButton"
            @click="handleSubmit(submit)"
          >
            <span v-if="!loading">Upload</span>
            <svg
              v-else
              class="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { docTypesArray, nyDocTypesArray } from "@/utils/documents/docTypes.js";
import { Constants } from "@/config";
import { authAxios } from "@/store/modules/auth";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    FadeTranslate
  },
  props: {
    selectedDocumentType: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      loading: false,
      docTypesArray,
      nyDocTypesArray,
      docType: "",
      friendlyName: "",
      selectedFile: null,
      fileExtension: "",
      jsDocTypesArray: [
        { type: "Application", id: 2 },
        { type: "Dilligent Effort", id: 5 },
        { type: "Supporting Doc", id: 6 },
        { type: "Cancellation Document", id: 8 },
        { type: "Endorsement Agreement", id: 11 },
        { type: "DNOC Document", id: 14 }
      ]
    };
  },
  computed: {
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    },
    policyNo() {
      return this.$store.getters.policyNo;
    },
    filteredDocTypeId(type) {
      if (this.$store?.state?.policy?.confirmationData?.state === "NY") {
        return this.nyDocTypesArray.filter((x) => x.type === type);
      } else {
        return this.docTypesArray.filter((x) => x.type === type);
      }
    },
    allFieldsFilled() {
      return this.selectedFile && this.docType && this.friendlyName;
    },
    isClaimUpload() {
      return this.$route.fullPath.includes("claims");
    },
    filteredDocumentTypes() {
      if (this.$store?.state?.policy?.confirmationData?.state === "NY") {
        return this.nyDocTypesArray;
      } else return this.docTypesArray;
    }
  },
  mounted() {
    if (this.selectedDocumentType === "Elevation Certificate")
      this.docType = "Elevation Certificate";
    else if (this.selectedDocumentType === "HUD-1") this.docType = "HUD-1";
    else if (this.selectedDocumentType === "Declarations Page") this.docType = "Declarations Page";
  },
  methods: {
    selectFile(event, validate) {
      validate(event.target.files[0]);
      this.selectedFile = event.target.files[0];
      this.friendlyName = this.selectedFile.name;
    },
    async submit() {
      try {
        this.loading = true;
        let filteredDocType;
        if (this.$store?.state?.policy?.confirmationData?.state === "NY") {
          filteredDocType = this.nyDocTypesArray.filter((x) => x.type === this.docType);
        } else {
          filteredDocType = this.docTypesArray.filter((x) => x.type === this.docType);
        }
        let url = "";

        url = Constants.UPLOAD_URL.replace("{{policyNo}}", this.policyNo).replace(
          "{{docType}}",
          filteredDocType[0].id
        );
        const newFile = new File([this.selectedFile], this.selectedFile.name.replace(/ /g, "_"));
        const fd = new window.FormData();

        fd.append(this.friendlyName, newFile, this.friendlyName);
        await authAxios.post(url, fd);
        Swal.fire({
          text: "Document uploaded successfully",
          icon: "success",
          confirmButtonColor: "#004C9D"
        });
        this.$root.$emit("docSuccess");
        this.$emit("close");
      } catch (err) {
        console.log(err);
        let text = err?.response?.data?.message;
        if (err?.response?.data?.message.includes("ErrorCode: BlobAlreadyExists"))
          text = "This document has already been uploaded to the policy.";
        Swal.fire({
          title: "Error uploading document",
          text,
          icon: "error",
          confirmButtonColor: "#004C9D"
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
