<template>
  <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white w-3/4 relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        data-test="widgetEmbed.close"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <div class="flex flex-col gap-1">
        <div class="font-semibold" data-test="widgetEmbed.title">{{ registration.Name ? registration.Name : 'Untitled' }}</div>
        <div class="flex gap-2 text-sm text-gray-600">
          <div class="flex items-center gap-1">
            <!-- Heroicon name: outline/phone -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>
            <div data-test="widgetEmbed.phone">{{ registration.ReferralPhone }}</div>
          </div>
          <div class="flex items-center gap-1">
            <!-- Heroicon name: outline/paper-airplane -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
            </svg>
            <div data-test="widgetEmbed.email">{{ registration.ReferralEmail }}</div>
          </div>
          <div v-if="registration.ReferralWebsite" class="flex items-center gap-1">
            <!-- Heroicon name: outline/computer-desktop -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
            </svg>
            <div data-test="widgetEmbed.website">{{ registration.ReferralWebsite }}</div>
          </div>
        </div>
      </div>
      <div class="text-md mt-2 mb-1" data-test="widgetEmbed.widgetCodeTitle">
        You can include the widget on your agency's website by integrating the HTML code below. You can optionally add customization to the widget by including the following values:
          <div class="text-sm p-2">
            <p><span class="font-bold">Primary Color</span> - input the hex code of the color you want to use on the quote button.</p>
            <p><span class="font-bold">Button Text</span> - input the text you want to appear on the  quote button.</p>
            <p><span class="font-bold">Input Placeholder Text</span> - input the text you want to appear in the white section where the address is typed.</p>
          </div>
        </div>
      <div class="text-sm relative rounded-md bg-gray-800 text-white p-4 overflow-x-auto font-mono whitespace-nowrap pb-12">
        <div ref="codeText">
          <p>{{ scriptTag }}</p>
          <p>{{ styleTag }}</p>
          <p>{{ demoDiv }}</p>
          <p v-for="line, index in demoCodeLines" :key="index" :class="{'ml-4' : index > 1 && index < demoCodeLines.length - 2, 'ml-2': index === 1 || index === demoCodeLines.length - 2 }">{{ line }}</p>
        </div>
      </div>
      <div class="flex justify-end h-0 pointer-events-none">
        <button style="transform: translate(-40%, -140%);" class="inline-flex group relative pointer-events-auto justify-center items-center rounded-md border border-gray-300 bg-white w-7 h-7 text-sm text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 cursor-pointer"
          data-test="widgetEmbed.widgetCodeCopy"
          @click="copyCodeToClipboard"
        >
          <!-- Heroicon: outline/clipboard-document-list -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
          </svg>
          <div class="text-white text-xs px-1.5 py-0.5 !rounded-md !shadow-sm bg-black bg-opacity-75 absolute top-[110%] pointer-events-none opacity-0 group-hover:opacity-100 scale-75 group-hover:scale-100 transition ease-out duration-200 right-0 origin-top text-center z-10 whitespace-nowrap">
            {{ codeClipboardText }}
          </div>
        </button>
      </div>
      <div class="mb-2 mt-4 text-md" data-test="widgetEmbed.uniqueURLTitle">
        Use this unique URL in your marketing strategy and include it in social media links - all
        conversions will be attributed to you.
      </div>
      <div class="flex flex-col gap-2 rounded-md bg-gray-800 text-white p-4 overflow-x-auto whitespace-nowrap pb-12" data-test="widgetEmbed.uniqueURL">
        {{ encodedSource }}
      </div>
      <div class="flex justify-end h-0 pointer-events-none">
        <button style="transform: translate(-40%, -140%);" class="inline-flex group relative pointer-events-auto justify-center items-center rounded-md border border-gray-300 bg-white w-7 h-7 text-sm text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 cursor-pointer"
          data-test="widgetEmbed.uniqueURLCopy"
          @click="copyURLToClipboard"
        >
          <!-- Heroicon: outline/clipboard-document-list -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
          </svg>
          <div class="text-white text-xs px-1.5 py-0.5 !rounded-md !shadow-sm bg-black bg-opacity-75 absolute top-[110%] pointer-events-none opacity-0 group-hover:opacity-100 scale-75 group-hover:scale-100 transition ease-out duration-200 right-0 origin-top text-center z-10 whitespace-nowrap">
            {{ urlClipboardText }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Constants } from "@/config";
import { mapGetters } from "vuex";

export default {
  props: {
    registrationIndex: Number
  },
  data() {
    return {
      serverError: "",
      deleting: false,
      codeClipboardText: "Copy to Clipboard",
      urlClipboardText: "Copy to Clipboard"
    };
  },
  computed: {
    ...mapGetters('widgets', ['getRegistrationAtIndex']),
    registration() {
      return this.getRegistrationAtIndex(this.registrationIndex);
    },
    encodedSource() {
      return `${Constants.BASE_DTC_WIDGET_URL}/consumer-app/?source=${encodeURIComponent(
        this.registration.Source
      )}`;
    },
    scriptTag() {
      // eslint-disable-next-line no-useless-escape
      return `<script src="${Constants.BASE_DTC_WIDGET_URL}/dtc-widget/NeptuneWidget.umd.js"><\/script>`;
    },
    styleTag() {
      return `<link rel="stylesheet" href="${Constants.BASE_DTC_WIDGET_URL}/dtc-widget/NeptuneWidget.css">`;
    },
    demoCode() {
      return `<script>
  NeptuneWidget.init({
    element: "#app",
    source: "${this.registration.Source}",
    primaryColor:"",
    buttonText:"",
    inputPlaceholderText: "Enter address here"
  });
<\/script>`; // eslint-disable-line
    },
    demoCodeLines() {
      return this.demoCode.split("\n");
    },
    demoDiv() {
      return `<div id="app" style="margin-left:auto;margin-right:auto;width:50%;margin-top:20px;padding:20px;"/>`;
    }
  },
  methods: {
    copyCodeToClipboard() {
      const code = `${this.scriptTag}\n${this.styleTag}\n${this.demoDiv}\n${this.demoCode}`;
      this.copyToClipboard(code).then(() => {
        this.codeClipboardText = "Copied!";
      });
    },
    copyURLToClipboard() {
      const url = this.encodedSource;
      this.copyToClipboard(url).then(() => {
        this.urlClipboardText = "Copied!";
      });
    },
    async copyToClipboard(text) {
      return await navigator.clipboard.writeText(text);
    }
  }
};
</script>
