<template>
  <div class="sig-graph">
    <span class="font-render">hello</span>

    <keep-alive>
      <VueSignaturePad
        v-if="!text && !isNewAgent"
        key="pad"
        ref="pad"
        :options="drawingPadOptions"
        save-type="image/png"
        @hook:activated="updateSize"
      />

      <div v-else-if="text !== ''" key="text" class="font-signature">
        <!-- <small>{{ text }}</small> -->
        <svg ref="svg" height="100%" v-bind="svgAttrs" width="100%">
          <text ref="text" fill="currentColor">{{ text }}</text>
        </svg>
      </div>
    </keep-alive>
  </div>
</template>

<script>
import { trimBitmap, scaleBitmap } from "../../utils/bitmap";
export default {
  props: {
    /**
     * Use text to generate image
     * if null, will use the drawing pad bitmap
     * @type {Vue.PropOptions<string?>}
     */
    text: {
      type: String
    },
    isNewAgent: {
      type: Boolean
    }
  },
  data() {
    return {
      drawingPadOptions: {
        onBegin: this.onDrawBegin,
        onEnd: this.onDrawEnd
      },
      drawing: false,
      svgAttrs: {
        viewBox: null,
        preserveAspectRatio: "xMidYMid meet"
      },
      isEmpty: true
    };
  },
  watch: {
    text: {
      async handler(val) {
        if (val && val.length > 0) {
          this.fitText();

          if (this.$listeners.newImage) {
            this.isEmpty = false;
            this.$emit("newImage", await this.createDataUrl());
          }
          return;
        }

        if (!val || val === "") {
          // debugger
          if (this.$listeners.newImage) {
            this.$emit("newImage", await this.createDataUrl());
          }
        }
      }
    }
  },

  methods: {
    onDrawBegin() {
      this.drawing = true;
    },
    async onDrawEnd() {
      this.drawing = false;
      this.isEmpty = false;
      this.$emit("newImage", await this.createDataUrl());
    },

    /**
     * Update svg viewBox to contain only text
     */
    fitText() {
      this.$nextTick(() => {
        /** @type {SVGTextElement} */
        const t = this.$refs.text;

        const { x, y, width: w, height: h } = t.getBBox();

        this.svgAttrs.viewBox = `${x} ${y} ${w} ${h}`;
      });
    },

    updateSize() {
      if (this.$refs.pad) {
        this.$refs.pad.resizeCanvas();
      }
    },

    clear() {
      if (this.$refs.pad) {
        this.$refs.pad.clearSignature();
      }

      this.$emit("newImage", null);
      this.isEmpty = true;
    },

    async createDataUrl(scaleHeight = 120) {
      await this.$nextTick();

      // ! Generate Drawing
      if (!this.text) {
        /** @type {HTMLCanvasElement} */
        const sigCanvas = this.$refs.pad.$refs.signaturePadCanvas;
        const empty = this.$refs.pad.signaturePad._isEmpty;
        if (empty) {
          return null;
        }
        const trimmed = trimBitmap(sigCanvas);
        const scaled = scaleBitmap(trimmed, scaleHeight / trimmed.height);
        return scaled.toDataURL();
      }

      // ! Generate Test
      if (this.text) {
        /** @type {HTMLCanvasElement} */
        const textCanvas = document.createElement("canvas");

        // document.body.append(textCanvas)
        const ctx = textCanvas.getContext("2d");

        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.font = `${scaleHeight}pt Alex Brush`;

        let { width: w } = ctx.measureText(this.text);

        w += 100; // !padding

        // debugger

        textCanvas.width = w;
        textCanvas.height = w;

        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.font = `${scaleHeight}pt Alex Brush`;

        ctx.clearRect(0, 0, w, w);
        ctx.fillText(this.text, w * 0.5, w * 0.5, w);

        const trimmedText = trimBitmap(textCanvas);
        const scaledText = scaleBitmap(trimmedText, scaleHeight / trimmedText.height);
        return scaledText.toDataURL();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sig-graph {
  width: 100%;
  height: 100%;
  position: relative;
}

.font-signature {
  font-family: "Alex Brush", Tahoma;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

svg {
  user-select: none;
}

.font-render {
  font-family: "Alex Brush", Tahoma;
  opacity: 0;
  user-select: none;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
}
</style>
