var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-full",class:{
    loading: _setup.loading,
    'is-agent': _setup.party === 1,
    jswizard: _setup.isEarthquake,
    'flex bg-lightair-full text-jetblack-full': !_setup.isEarthquake
  }},[_c('div',{staticClass:"flex-grow relative",class:{
      'overflow-auto max-h-[100vh]': !_setup.signatureModal.open,
      'overflow-hidden': _setup.signatureModal.open
    }},[(_setup.loading && !_setup.errorCheck)?_c('div',{staticClass:"auto-bind"},[_c('div',{staticClass:"lottie animated fadeIn faster"},[_c(_setup.LottieViewer,{attrs:{"loop":"","path":"static/lottie/preloader3.json"}}),_c('div',{class:{ loadingText: _setup.isEarthquake }},[_c('div',{staticClass:"status"},[_vm._v("Loading")]),_c('div',{staticClass:"status"},[_vm._v("Please wait...")])])],1)]):_vm._e(),(_vm.isModal)?_c('div',{staticClass:"absolute top-0 right-2 p-4 cursor-pointer z-10",on:{"click":function($event){return _setup.$root.$emit('closeModal')}}},[_c('svg',{staticClass:"h-5 w-5 transition duration-100",class:{
          'text-black text-black/75': _setup.isEarthquake,
          'text-deepsea-full hover:text-deepsea-full/75': !_setup.isEarthquake
        },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z","clip-rule":"evenodd"}})])]):_vm._e(),(_setup.errorCheck)?_c('div',{staticClass:"error-container"},[_c('h3',{attrs:{"data-test":"paymentError.whoops"}},[_vm._v("Whoops! Something went wrong")]),_c('p',{staticClass:"px-4 text-base mt-5",attrs:{"data-test":"paymentError.error"}},[_vm._v(_vm._s(_setup.errorCheck))]),_vm._m(0),_c('button',{staticClass:"!px-4 !py-2 rounded bg-deepsea-medium text-white hover:bg-deepsea-900",staticStyle:{"margin-top":"35px"},attrs:{"data-test":"paymentError.retry"},on:{"click":_setup.sendRetry}},[_vm._v(" Retry ")])]):(_setup.showSignatureScreen)?_c(_setup.SignDocumentsModal,{attrs:{"party":_setup.context.party,"documents":_setup.policyDocuments,"loading":_setup.docLoading,"policy-data":_setup.context},on:{"submitStart":_setup.loading,"signaturesSubmission":_setup.finalSubmit,"skipToPayment":_setup.skipToPay}}):(_setup.showPayScreen)?_c(_setup.PayWiz,{staticClass:"px-4 lg:px-6",attrs:{"state":_setup.state,"show-view-policy":_vm.isModal},on:{"togglePaymentType":_setup.onTogglePaymentType,"startACH":_setup.makeACHPayment,"ccComplete":_setup.ccComplete,"checkComplete":_setup.checkComplete,"clickToClose":_setup.clickToClose}}):(_setup.showFinishScreen)?_c(_setup.FinishScreen,{attrs:{"state":_setup.state,"is-modal":_vm.isModal},on:{"clickToClose":_setup.clickToClose}}):(_setup.isUnauthorized)?_c(_setup.Unauthorized):_vm._e()],1),_c('div',{staticClass:"sidebar md:!block flex-shrink-0"},[(_setup.showSummary)?_c(_setup.SideBar,{attrs:{"is-modal":_vm.isModal,"model":_setup.state},on:{"clickToClose":_setup.clickToClose}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{staticClass:"mt-5 mb-5",attrs:{"data-test":"paymentError.supportContact"}},[_vm._v(" Feel free to retry or contact customer support at "),_c('a',{attrs:{"href":"mailto:support@neptuneflood.com"}},[_vm._v("support@neptuneflood.com")])])
}]

export { render, staticRenderFns }