<template>
  <div class="rounded-md bg-green-50 border-l-4 border-green-400 p-4" data-test="tasksCompleteBox">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          class="h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3">
        <h3
          class="text-green-800 text-base leading-none font-bold mb-0.5"
          data-test="tasksComplete"
        >
          {{ title }}
        </h3>
        <p class="text-sm font-medium text-green-800" data-test="noRemainingTasks">{{ body }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "Tasks Complete"
    },
    body: {
      default: "You have no more remaining tasks"
    }
  }
};
</script>
