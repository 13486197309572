import actions from "./actions";
import mutations from "./mutations";

import ui from "./modules/ui";
import { globalMutations } from "@/components/policy-wizard/store-module/mutations";

export const state = () => ({
  options: {
    agentTypes: []
  }
});

/**
 * @type {import("vuex").Module}
 */
const mod = {
  state,
  namespaced: true,
  actions: actions,
  mutations: {
    ...mutations,
    ...globalMutations
  },
  modules: {
    ui
  }
};

export default mod;
