import actions from "./actions";
import globalMutators from "../settings/modules/ui/mutations";
import getters from "./getters";
import mutations from "./mutations";
import authConfig from "./axiosConfig";
// import router from '@/router/index'

export const authAxios = authConfig;

// authAxios.interceptors.response.use(
//   (value) => value,

//   error => {
//     // const err = { ...error }
//     // console.log('error', err)
//     debugger
//     if (error.isAxiosError && error.response.status === 401) {
//       router.push({
//         name:  'login',
//         query: {
//           returnUrl: router.currentRoute.fullPath
//         }
//       })
//     }

//     return true
//   }
// )

/**
 * @type {neptune.policyholder.IAuthState}
 */
export const initialState = {
  accountType: null,
  // token is the JWT received from the backend
  token: null,
  // deepLink is the encrypted payload an
  // insured user receives in their email
  deepLink: null,
  user: {},
  data: {},
  validateReturn: {
    policy: {
      policyNo: null
    },
    policyDocStatus: {
      documentsWithSignatures: []
    },
    invoiceDetails: {
      InvoiceItems: []
    }
  }
};

/**
 * @returns {neptune.policyholder.IAuthState}
 */
export const state = () => initialState;

/**
 * @type {import("vuex").Module}
 */
const mod = {
  state,
  namespaced: true,
  actions: actions,
  getters,
  mutations: {
    ...globalMutators,
    ...mutations
  }
};

export default mod;
