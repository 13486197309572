<template>
  <canvas
    ref="canvas"
    class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 scale-50 pointer-events-none select-none"
  ></canvas>
</template>

<script>
export default {
  props: {
    rewardLevel: {
      type: Object
    }
  },
  mounted() {
    const canvas = this.$refs.canvas;
    const c = canvas.getContext("2d");

    // multiply each by two, then scale down in CSS for HD particles
    const scale = 2;
    canvas.width = 500 * scale;
    canvas.height = 500 * scale;
    const gravity = 0.018 * scale;
    const friction = 0.981;

    class Particle {
      constructor({ position, velocity, color = "blue" }) {
        this.position = position;
        this.velocity = velocity;
        this.color = color;
        this.alpha = 1;

        const min = 0.5 * scale;
        const max = 2.75 * scale;
        this.width = Math.random() * (max - min + 1) + min;

        this.radians = 0;
        this.iteration = 0;
      }

      draw() {
        c.save();
        c.beginPath();
        c.globalAlpha = this.alpha;
        c.translate(this.position.x + this.width / 2, this.position.y + this.width / 2);
        c.rotate(this.radians);
        c.translate(-this.position.x - this.width / 2, -this.position.y - this.width / 2);
        c.fillStyle = this.color;
        c.fillRect(this.position.x, this.position.y, this.width, this.width);
        c.restore();
      }

      update() {
        this.draw();
        this.radians += 0.02;
        this.velocity.y += gravity;
        this.position.x += this.velocity.x;
        this.position.y += this.velocity.y;
        this.velocity.x *= friction;
        this.velocity.y *= friction;
        this.alpha -= 0.007;
      }
    }

    const particles = [];
    const colors = ["rgb(125, 211, 252)"];
    const power = 2;
    let animationId;

    for (let i = 0; i < 100; i++) {
      const color = colors[Math.floor(Math.random() * colors.length)];

      particles.push(
        new Particle({
          position: {
            x: canvas.width / 2 + 100,
            y: canvas.height / 2
          },
          velocity: {
            x: (Math.random() + 0.5) * scale * power,
            y: (Math.random() * -1 - 1) * scale * power
          },
          color
        })
      );
    }

    for (let i = 0; i < 100; i++) {
      const color = colors[Math.floor(Math.random() * colors.length)];

      particles.push(
        new Particle({
          position: {
            x: canvas.width / 2 - 100,
            y: canvas.height / 2
          },
          velocity: {
            x: -(Math.random() + 0.5) * scale * power,
            y: (Math.random() * -1 - 1) * scale * power
          },
          color
        })
      );
    }

    function animate() {
      animationId = requestAnimationFrame(animate);
      c.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = particles.length - 1; i >= 0; i--) {
        const particle = particles[i];
        particle.update();

        if (particle.alpha <= 0) {
          const direction = Math.round(Math.sign(Math.random() - 0.5));
          particle.position.x = canvas.width / 2 + 100 * direction;
          particle.position.y = canvas.height / 2;
          particle.velocity.x = direction * (Math.random() + 0.5) * scale * power;
          particle.velocity.y = (Math.random() * -1 - 1) * scale * power;
          particle.alpha = 1;
          particle.iteration++;
        }
      }

      if (particles[0].iteration === 3) {
        cancelAnimationFrame(animationId);
      }
    }

    setTimeout(() => {
      animate();
    }, 200);
  }
};
</script>
