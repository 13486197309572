export class Logger {
  constructor(name) {
    this.prefix = name;
  }

  log(msg, ...data) {
    console.log(`[${this.prefix}]: ${msg}`, ...data);
  }

  warn(msg) {
    console.warn(`[${this.prefix}]: ${msg}`);
  }

  error(msg) {
    console.error(`[${this.prefix}]: ${msg}`);
  }

  info(msg) {
    console.info(`[${this.prefix}]: ${msg}`);
  }

  table(msg, data) {
    console.log(`[${this.prefix}]: ${msg}`);
    console.table(data);
  }
}
