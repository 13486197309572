<template>
  <div class="sign-and-pay">
    <policy-wizard
      class="h-full"
      :party="party"
      :step.sync="activeStep"
      :policy-no="policyNo"
      :auth-token="token"
      :account-type="accountType"
      @gotoPolicy="gotoPolicy"
      @signComplete="onSign"
      @payComplete="onPay"
    />
  </div>
</template>

<script>
/**
 * This is a container for the policy-wizard component
 * Although this is in the poseidon project,
 * we can create another one quickly.
 *
 * We must pass four properties to the wizard.
 * *party: Number - the party of the current viewer
 * *step: Number - the index of the current screen
 * *policyNo: String - The policy we want to load info for
 * *authToken: String - The token to be used in headers
 *
 * This will emit events when certain steps have completed.
 * the params are of type policyData
 * @param {neptune.IPolicyData} policyData
 *
 * @gotoPolicy - when user can navigate to the policy
 * @signComplete - When the signatures for the current party are signed
 * @payComplete - When the payment is complete for the policy
 *
 */
import PolicyWizard from "@/components/policy-wizard/index.vue";

export default {
  components: {
    PolicyWizard
  },
  props: {
    step: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeStep: this.step,
      party: 2
    };
  },
  computed: {
    policyNo() {
      return this.$store?.state?.policy?.policyNo;
    },

    token() {
      /** @type {neptune.getters.IRootGetters} */
      const {
        "auth/agentToken": agentToken,
        "auth/policyHolderToken": policyToken
      } = this.$store.getters;

      return this.party === 1 ? agentToken : policyToken;
    },
    accountType() {
      return this.$store.state.auth.accountType;
    }
  },
  watch: {
    step(val) {
      this.activeStep = val;
    }
  },
  mounted() {
    if (process.env.NODE_ENV === "development") {
      document.addEventListener("keydown", this.keyNav);

      this.$on("hook:beforeDestroy", () => {
        document.removeEventListener("keydown", this.keyNav);
      });
    }

    let meta = {};

    // update meta with agent / policyholder info in case
    // user arrived from embed link
    const {
      accountType,
      data: { AgentId, Company, Email, FirstName, LastName, Phone }
    } = this.$store.state.auth;
    if (accountType !== "policyholder") {
      meta = {
        ...meta,
        agent_name: FirstName && LastName ? `${FirstName} ${LastName}` : Company,
        agent_number: AgentId,
        agent_email: Email,
        agent_phone: Phone,
        is_policy_holder: false
      };
    } else {
      const { policyNo } = this.$store.state?.auth.validateReturn;
      const { email, insuredName } = this.$store.state.auth.validateReturn.policy;
      meta = {
        policy_number: policyNo,
        policyholder_email: email,
        policyholder_name: insuredName,
        is_policy_holder: true
      };
    }

    this.$ada.setSensitiveMetaFields(meta);
    this.$ada.setMetaFields({
      policy_number: this.$store?.state?.policy?.policyNo
    });
  },
  methods: {
    /**
     * @param {KeyboardEvent} ev
     */
    keyNav(ev) {
      // debugger
      if (ev.key === "ArrowLeft") {
        this.activeStep--;
      }
      if (ev.key === "ArrowRight") {
        this.activeStep++;
      }
    },

    // TODO: Fix this, it is broken
    gotoPolicy(policyData) {
      window.open(`#/policyholder/policies/${policyData.policyNo}`, "_self");
    },

    /**
     * @type {(policyData: neptune.IPolicyData) => void}
     */
    async onSign(policyData) {
      console.log(policyData);

      const paid = policyData.isPaid;

      const routeName = paid ? "policyPaymentsComplete" : "policyPayments";

      this.$router.push({
        name: routeName
      });

      if (paid) {
        this.activeStep = 4;
      }
    },

    onPay() {
      this.activeStep = 3;
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-and-pay {
  flex: 1;
  overflow: hidden;
}
</style>
