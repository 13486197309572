<template>
  <div class="cancel-wizard">
    <div data-test="cancelChooseMsg">
      Please choose the reason for cancelling this policy
    </div>

    <el-form size="mini" label-position="top" class="selection">
      <!-- Select Reason -->
      <el-form-item class="options mt-5">
        <el-select
          v-model="selection"
          class="w-full"
          data-test="cancelReasonSelector"
          value-key="reasonName"
          filterable
          placeholder="Choose Reason..."
        >
          <!-- If Internal -->
          <template v-if="$internal">
            <el-option-group
              v-for="(group, label) in groupedReasons"
              :key="label"
              :label="startCase(label)"
            >
              <el-option
                v-for="(item, index) in group"
                :key="index"
                :label="item.reasonName"
                :value="item"
                :data-test="`cancelReasonInternal-${item.reasonName}`"
              >
                {{ item.reasonCode }} - {{ item.reasonName }}
              </el-option>
            </el-option-group>
          </template>

          <!-- Not Internal -->
          <template v-else>
            <el-option
              v-for="(item, index) in groupedReasons['non-internal']"
              :key="index"
              :label="item.reasonName"
              :value="item"
              :data-test="`cancelReasonNoninternal-${item.reasonName}`"
            >
              {{ item.reasonCode }} - {{ item.reasonName }}
            </el-option>
          </template>
        </el-select>
      </el-form-item>

      <div v-if="selection">
        <div class="reason font-bold" data-test="cancelReasonDescription">
          {{ selection.description }}
        </div>

        <hr class="my-5" />

        <div class="options">
          <!-- Optional Date -->
          <el-form-item
            v-if="selection.effectiveDateRequired"
            :label="selection.effectiveDateLabel || 'No label provided'"
          >
            <el-date-picker
              v-model="buildingSoldDate"
              format="MMMM d, yyyy"
              :picker-options="pickerOptions"
              placeholder="Choose the date this building was sold"
              class="w-full"
              data-test="cancelDateSelector"
            />
          </el-form-item>

          <!-- Notes -->
          <el-form-item label="Notes (Required)" data-test="cancelNotesLabel">
            <el-input
              v-model="notes"
              type="textarea"
              placeholder="Include some notes..."
              data-test="cancelNotesInput"
              :rows="5"
            />
          </el-form-item>
          <div v-if="$internal" class="flex">
            <el-switch
              v-model="suppressValue"
              class="mr-5"
              active-color="#004C9D"
              data-test="cancelNotificationsSwitch"
            />
            <p v-if="!suppressValue" class="margin-nudge" data-test="cancelNotificationsSuppressed">
              Notifications suppressed
            </p>
            <p v-else class="margin-nudge" data-test="cancelNotificationsActive">
              Notifications active
            </p>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { partition } from "lodash";
import { casingMixins } from "@/utils/mixins/casing";
import { addYears, parseISO } from "date-fns";
export default {
  mixins: [casingMixins],
  data() {
    return {
      selection: null,
      buildingSoldDate: new Date(),
      notes: null,
      suppressValue: true
    };
  },
  computed: {
    /** @type {() => neptune.IPolicyData} */
    allData() {
      return this.$store.getters["policy/allData"];
    },

    /** @type {() => neptune.CancelReason[]} */
    cancelReasons() {
      /**
       * @type {neptune.IRootState}
       */
      const s = this.$store.state;

      return s.policy?.confirmationData?.cancelReasons;
    },
    groupedReasons() {
      if (this.cancelReasons) {
        const [internal, n] = partition(this.cancelReasons, (cr) =>
          (cr.allowedRoles || "").toLowerCase().includes("internal")
        );

        return {
          internal,
          "non-internal": n
        };
      }
    },
    dropdownGroupedOptions() {
      return [
        {
          label: "Public",
          options: this.groupedReasons["non-internal"]
        },
        {
          label: "Internal",
          options: this.groupedReasons.internal
        }
      ];
    },
    valid() {
      const hasReason = !!this.selection;
      const hasDate = !!this.buildingSoldDate;
      const hasNotes = !!this.notes;

      return this.$internal ? hasReason && hasDate && hasNotes : hasReason && hasDate;
    },
    ...mapGetters("auth", {
      internal: "internal"
    }),
    cancelPayload() {
      return {
        PolicyNumber: this.$store.getters["policy/allData"].policyNo,
        ReasonCode: this.selection,
        NoteInfo: {
          Note: this.notes,
          Private: false
        },
        EffectiveDate: this.buildingSoldDate.toISOString(),
        SuppressNotification: !this.suppressValue
      };
    },

    /** @type {() => neptune.payloads.ConfirmationUIConfiguration} */
    uiConfig() {
      return this.allData.uiConfiguration;
    },

    pickerOptions() {
      return {
        disabledDate: (date) => {
          const { inceptionDate } = this.allData;
          const { effectiveDateMin } = this.selection;
          let startDate = new Date(this.$internal ? inceptionDate : effectiveDateMin);

          if (this.selection.reasonCode === "06") {
            const startHold = this.$store.state.policy.confirmationData.cancelReasons.find(
              (x) => x.reasonCode === "06"
            );
            startDate = new Date(startHold.effectiveDateMin.slice(0, -1));
          }

          const endDate = addYears(parseISO(inceptionDate), 1);

          return date < startDate || date > endDate;
        }
      };
    }
  },

  watch: {
    valid(val) {
      this.$emit("success", val);
    },
    selection: {
      handler(val) {
        this.$emit("reason", val);
        this.selectDefault();
      }
    }
  },

  methods: {
    async submit() {
      try {
        return this.$store.dispatch("policy/cancelPolicy", this.cancelPayload);
      } catch (error) {
        this.$msgbox({
          title: "Error",
          type: "error",
          message: error
        }).catch(() => {});

        return error;
      }
    },
    selectDefault() {
      if (this.selection.reasonCode === "06") {
        let startDate = this.$store.state.policy.confirmationData.cancelReasons.find(
          (x) => x.reasonCode === "06"
        );
        startDate = new Date(startDate.effectiveDateMin.slice(0, -1));
        this.buildingSoldDate = startDate;
      } else {
        this.buildingSoldDate = new Date();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cancel-wizard {
  padding: 5px 15px;
  display: flex;
  flex-flow: column;
  max-width: 800px;
  white-space: initial;
  word-break: initial;

  .options {
    > * {
      width: 100%;
    }
  }
}

.reason {
  max-width: 400px;
}

.w-full {
  width: 100% !important;
}
.margin-nudge {
  margin-top: -1px;
}
</style>
