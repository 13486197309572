import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import Axios from "axios";

import cc from "./modules/cc";
import ach from "./modules/ach";
import { globalMutations } from "../../mutations";
// import { Constants } from '@/config'

export const http = Axios.create();

/** @type {stripe.Stripe} */
// eslint-disable-next-line no-undef
export const stripeInstance = {
  default: null
};

export const state = {
  /** @type {neptune.payment.IPaymentMethod[]} */
  paymentMethods: null
};

/**
 * @type {import("vuex").Module<any, neptune.IRootState>}
 */
const mod = {
  state() {
    return state;
  },
  namespaced: true,
  actions: actions,
  mutations: {
    ...mutations,
    ...globalMutations
  },
  modules: {
    cc,
    ach
  },
  getters
};

export default mod;
