<template>
  <section
    class="collapser w-full group-hover:rounded-lg"
    :class="{ 'has-icon': icon, expanded: !collapsed }"
  >
    <!-- Section Title -->
    <div
      class="hover:bg-gray-100 px-6 py-4 flex items-center cursor-pointer group"
      @click="collapsed = !collapsed"
    >
      <!-- Icon -->
      <span v-if="icon != null" class="icon fa fa-fw text-gray-500 mr-1" :class="icon" />

      <!-- text -->
      <h4
        v-if="title"
        class="text-base font-bold text-gray-600"
        :data-test="`collapseTitle-${title}`"
      >
        {{ title }}
      </h4>

      <span class="title-slot-container flex-1 flex px-2">
        <slot name="title" :data-test="`collapseSubTitle-${title}`" @click.stop />
      </span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 hidden group-hover:!block text-gray-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    <!-- Section Content -->
    <collapse-transition>
      <div v-show="!collapsed" class="collapse-area">
        <div class="content px-6 pb-6 pt-1.5" :data-test="`sectionArea-${title}`">
          <div ref="content-container" class="content-container">
            <slot name="default">
              put something here (mandatory)
            </slot>
          </div>
        </div>
      </div>
    </collapse-transition>
  </section>
</template>

<script>
import CollapseTransitionVue from "@/components/transitions/CollapseTransition.vue";
export default {
  components: {
    CollapseTransition: CollapseTransitionVue
  },
  props: {
    title: String,
    icon: String,
    collapseSection: Boolean
  },
  data() {
    return {
      collapsed: false
    };
  },
  computed: {
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    }
  },
  mounted() {
    this.collapsed = this.collapseSection;
  }
};
</script>

<style lang="scss" scoped>
.collapser {
  .title {
    font-size: 1.25rem;
    white-space: nowrap;
    user-select: none;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding-right: 15px;
    height: 40px;
    cursor: pointer;

    transition: border 500ms ease;

    &:hover {
      background-color: #fbfafa;
      // border: 1px solid rgba(0, 0, 0, 0.16);

      [class*="el-icon"] {
        .theme-commercial & {
          color: #43ae8a;
        }
      }
    }

    .icon {
      font-size: 28px;
      line-height: normal;

      [class*="el-icon"] {
        .theme-commercial & {
          color: #828282;
        }
      }
    }

    .h4 {
      font-size: 1em;
    }
  }

  &.expanded {
    .title {
      .theme-earthquake & {
        border-color: #faca43;
      }
      .theme-commercial & {
        border-color: #43ae8a;
      }
      .theme-neptune & {
        border-color: #43ae8a;
      }
    }
  }
}

.content {
  position: relative;

  &-container {
    position: relative;
    transform: translateZ(0);
  }
}

[class*="el-icon"] {
  .theme-commercial & {
    color: #828282;
  }
}
.titleColor {
  color: rgb(103, 156, 187);
}
</style>
