import actions from "./actions";
import mutations from "./mutations";
import getters from './getters';

import { globalMutations } from "@/components/policy-wizard/store-module/mutations";

export const state = () => ({
    registration: {},
    registrations: []
});

/**
 * @type {import("vuex").Module}
 */
const mod = {
    state,
    namespaced: true,
    actions,
    mutations: {
        ...mutations,
        ...globalMutations
    },
    getters
};

export default mod;
