import { Constants } from "@/config";

/** @type {import("vuex").GetterTree<neptune.store.user.UserProfilePayload, neptune.IRootState>} */
const getters = {
  type(state, getters, rootState) {
    return rootState.auth.data ? "agent" : "insured";
  },
  party(state, getters, rootState) {
    return rootState.auth.data && Object.keys(rootState.auth.data).length !== 0 ? 1 : 2;
  },
  id(state) {
    return state.agencyInfo.agencyId;
  },
  parent(state) {
    return state.agencyDetailInfo.agentParentId;
  },
  agentNo(state) {
    return state.agencyInfo.agencyNo;
  },
  contactsUrl(state, getters) {
    const url = Constants.AGENCY_CONTACTS_URL.replace("{{AgentNo}}", getters.agentNo);

    return url;
  },
  licensesUrl(state, getters) {
    const url = Constants.AGENCY_LICENSES_URL.replace("{{AgentNo}}", getters.agentNo);
    return url;
  }
};

export default getters;
