import toCanvas from "html2canvas";

export const getBitmapDataURL = async (el, fileName = Date.now().toString()) => {
  const canvas = await toCanvas(el, {
    allowTaint: true
  });

  const dataURL = canvas.toDataURL();

  const link = document.createElement("a");
  // link.innerHTML = 'download image'
  link.href = dataURL;
  link.download = `${fileName}.png`;

  link.click();

  // const bmp = await createImageBitmap(canvas)
  const im = new Image();

  im.src = dataURL;

  return im;
};

/**
 * Creates a cropped version of a transparent bitmap
 * @param {HTMLCanvasElement} canvas
 * @returns {HTMLCanvasElement} cropped canvas
 */
export const trimBitmap = (canvas) => {
  const c2 = document.createElement("canvas");
  c2.width = canvas.width;
  c2.height = canvas.height;

  const ctx = c2.getContext("2d");

  ctx.drawImage(canvas, 0, 0);

  let w = canvas.width;
  let h = canvas.height;
  const pix = { x: [], y: [] };
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let x;
  let y;
  let index;

  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4;
      if (imageData.data[index + 3] > 0) {
        pix.x.push(x);
        pix.y.push(y);
      }
    }
  }
  pix.x.sort((a, b) => {
    return a - b;
  });
  pix.y.sort((a, b) => {
    return a - b;
  });
  const n = pix.x.length - 1;

  w = pix.x[n] - pix.x[0];
  h = pix.y[n] - pix.y[0];
  const cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

  c2.width = w;
  c2.height = h;
  ctx.putImageData(cut, 0, 0);

  return ctx.canvas;
};

/**
 * Scales down a canvas element
 * @param {HTMLCanvasElement} canvas
 * @param {number} scale
 * @returns {HTMLCanvasElement} scaled down canvas
 */
export const scaleBitmap = (canvas, scale) => {
  const c2 = document.createElement("canvas");

  c2.width = canvas.width * scale;
  c2.height = canvas.height * scale;

  const ctx = c2.getContext("2d");

  ctx.drawImage(canvas, 0, 0, c2.width, c2.height);

  return ctx.canvas;
};

/**
 * Same as scale, except we specify in pixels
 * @param {HTMLCanvasElement} canvas
 * @param {number | undefined} width
 * @param {number | undefined} height
 */
export const resizeBitmap = (canvas, width = 0, height = 0) => {
  const c2 = document.createElement("canvas");

  const wRatio = width / canvas.width;
  const hRatio = height / canvas.height;

  c2.width = canvas.width * wRatio || hRatio;
  c2.height = canvas.height * hRatio || wRatio;

  const ctx = c2.getContext("2d");

  ctx.drawImage(canvas, 0, 0, c2.width, c2.height);

  return ctx.canvas;
};
