import set from "lodash/set";

/**
 * @type {import('vuex').MutationTree<neptune.commercial.ICommercialState>}
 */
const mutations = {
  SET_STATE(state, newState) {
    Object.assign(state, newState);
  },
  SET_PROP(state, { path, value }) {
    set(state, path, value);
  }
};

export default mutations;
