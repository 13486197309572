import store from "@/store";
import { routeToV2 } from "@/utils/routeToV2";
export default [
  {
    name: "lockbox",
    path: "/lockbox-reprocessing",
    meta: {
      title: "Lockbox Adjustments",
      isHiddenFromSideNav: false
    },
    beforeEnter(_x1, _x2, next) {
      if (!store.getters["auth/roles"].includes("financeadmin")) {
        return next("/unauthorized");
      }
      routeToV2("/lockbox-adjustments");
    },
    component: () =>
      import(/* webpackChunkName: "PaymentDetailPage" */ "./components/LockboxAdjustments.vue")
  }
];
