<template>
  <div class="p-5">
    <img
      src="@/assets/pngs/Neptune_Horizon_DeepSea.png"
      class="h-[150px] w-auto mx-auto mb-[60px] mt-12"
    />

    <div>
      <transition
        mode="out-in"
        enter-class="transform opacity-0 translate-x-4"
        enter-active-class="transition ease-out duration-300"
        enter-to-class="transform opacity-100 translate-x-0"
        leave-class="transform opacity-100 translate-x-0"
        leave-active-class="transition ease-in duration-150"
        leave-to-class="transform opacity-0 -translate-x-4"
      >
        <h2
          v-if="step === 1"
          key="step1aText"
          class="max-w-[400px] sm:max-w-lg text-center mx-auto text-xl sm:text-2xl text-jetblack-full"
          data-test="enterInfoMessage"
        >
          Enter the information associated with your policy and we'll send you a link to view it.
        </h2>
        <div
          v-else-if="step === '2a'"
          key="step2Text"
          class="max-w-[400px] sm:max-w-lg text-center mx-auto"
          data-test="almostThereMessage"
        >
          <h2 class="text-xl sm:text-2xl text-jetblack-full">
            Almost there!
          </h2>
          <p class="text-lg max-w-[350px] mx-auto leading-snug text-jetblack-full">
            We have your email, now enter your
            <strong class="text-deepsea-full">phone number</strong> and we'll send you a new link.
          </p>
        </div>
        <div
          v-else-if="step === '2b'"
          key="step2bText"
          class="max-w-[400px] sm:max-w-lg text-center mx-auto"
        >
          <h2 class="text-xl sm:text-2xl text-jetblack-full">
            Almost there!
          </h2>
          <p class="text-lg max-w-[350px] mx-auto leading-snug text-jetblack-full">
            We have your email, now enter your policy's
            <strong class="text-deepsea-full">zip code</strong> and we'll send you a new link.
          </p>
        </div>
      </transition>

      <transition
        mode="out-in"
        enter-class="transform opacity-0 translate-x-4"
        enter-active-class="transition ease-out duration-300"
        enter-to-class="transform opacity-100 translate-x-0"
        leave-class="transform opacity-100 translate-x-0"
        leave-active-class="transition ease-in duration-150"
        leave-to-class="transform opacity-0 -translate-x-4"
      >
        <div v-if="step !== 3" key="formProcess" class="mx-auto max-w-sm mt-4 w-full">
          <PolicyLinkGenerationForm :loading="loading" @submit="submit" @setStep="step = $event" />
        </div>

        <div v-else key="successMessage " class="max-w-[450px] text-center mx-auto">
          <div class="relative">
            <ConfettiBlaster />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-24 w-24 text-deepsea-full mx-auto"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
          </div>

          <p class="text-lg text-jetblack-full font-bold" data-test="policyLinkMessage">
            If the information you entered matches the information on record, we'll email you a link
            to view your policy.
          </p>
          <p class="text-jetblack-medium text-base mt-4">You may close this window.</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import PolicyLinkGenerationForm from "@/components/PolicyLinkGenerationForm.vue";
import ConfettiBlaster from "@/components/ConfettiBlaster.vue";
import { authAxios } from "@/store/modules/auth";
import { Constants } from "@/config/index";
export default {
  components: {
    PolicyLinkGenerationForm,
    ConfettiBlaster
  },
  data() {
    return {
      loading: false,
      step: 1
    };
  },
  methods: {
    async submit({ email, phone, policyNumber, zipcode }) {
      try {
        this.loading = true;
        await authAxios.post(Constants.REQUEST_EMAIL_URL, {
          email,
          phone,
          policyNumber,
          zip: zipcode
        });
        this.step = 3;
      } catch (error) {
        this.$notify({
          title: "Error",
          type: "error",
          message: "Please ensure the information you entered is correct."
        });

        throw new Error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
