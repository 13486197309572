<template>
  <policy-holder-container>
    <router-view ref="routerView" v-bind="$props" />
  </policy-holder-container>
</template>

<script>
import PolicyHolderContainerVue from "@/components/sign_and_pay/policy_holder/PolicyHolderContainer.vue";
export default {
  components: {
    PolicyHolderContainer: PolicyHolderContainerVue
  },
  props: {
    encryptedPayload: {
      type: String
    }
  },
  methods: {
    async validator(model) {
      try {
        const data = await this.$store.dispatch("auth/validateWithDeepLink", model);
        console.log(data);
        return Promise.resolve(data);
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    }
  }
};
</script>

<style></style>
