<template>
  <div>
    <h3 class="font-bold !text-sm !text-gray-800 mb-3">Mortgagee Clause</h3>
    <div class="lienholder-info flex flex-col gap-12">
      <div v-for="(item, index) in lienholders" :key="`${item}-${index}`">
        <div class="mb-3">
          <label class="font-bold text-sm !text-gray-500 mt-4" data-test="mortgageeNameLabel">
            Name
          </label>
          <div class="flex flex-col justify-between">
            <p class="text-base select-text" :data-test="`mortgageeNameField-${index}`">
              {{ item.name1 }} {{ item.name2 }}
            </p>
            <p
              v-if="item.mortgageClause"
              :data-test="`mortgageeClauseField-${index}`"
              class="text-base select-text"
            >
              {{ item.mortgageClause }}
            </p>
          </div>
        </div>
        <div
          v-if="!item.mortgageClause && isInternal && canModify && !$readonly"
          class="float-right"
        >
          <!-- pencil-alt / edit icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
            :data-test="`mortgageeEditButton-${index}`"
            @click="edit(index)"
          >
            <path
              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
            />
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div v-if="!$readonly" class="mb-3">
          <label
            class="font-bold text-sm !text-gray-500"
            :data-test="`mortgageeAddressLabel-${index}`"
          >
            Address
          </label>
          <div class="flex justify-between select-text">
            <p class="text-base" :data-test="`mortgageeAddress1Field-${index}`">
              {{ item.addr1 }} {{ item.addr2 }}
            </p>
            <svg
              v-if="item.mortgageClause && isInternal && canModify"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
              viewBox="0 0 20 20"
              fill="currentColor"
              :data-test="`mortgageeEditButtonPrimary-${index}`"
              @click="edit(index)"
            >
              <path
                d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
              />
              <path
                fill-rule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <p class="text-base select-text" :data-test="`mortgageeAddress2Field-${index}`">
            {{ item.city }}, {{ item.state }} {{ item.zip }}
          </p>
        </div>
        <div>
          <label class="font-bold text-sm !text-gray-500" :data-test="`loanNumberLabel-${index}`"
            >Loan Number</label
          >
          <p class="text-base select-text" :data-test="`loanNumberField-${index}`">
            {{ item.reference }}
          </p>
        </div>
      </div>
    </div>

    <el-dialog
      v-loading="loading"
      custom-class="dialog-class"
      append-to-body
      :visible.sync="showCheckModal"
      width="30%"
    >
      <span slot="footer" class="dialog-footer" data-test="lienholderConfirmation">
        <button
          class="inline-flex items-center mr-3 rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          data-test="lienholderCancelButton"
          @click="cancelEdit"
        >
          Cancel
        </button>
        <el-button
          v-loading="loading"
          data-test="lienholderConfirmButton"
          class="!bg-deepsea-full !text-white hover:!bg-deepsea-full/75"
          @click="continueEdit"
          >Confirm</el-button
        >
      </span>
      You are about to edit a lienholder, which will result in a binding endorsement. Do you wish to
      proceed?
    </el-dialog>
    <el-dialog
      v-loading="loading"
      title="Edit Lienholder"
      custom-class="dialog-class"
      append-to-body
      :visible.sync="editing"
      width="55%"
    >
      <span slot="footer" class="dialog-footer">
        <button
          class="inline-flex items-center rounded-sm mr-3 border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          data-test="lienholderModalCancel"
          @click="editing = false"
        >
          Cancel
        </button>
        <el-button
          v-loading="loading"
          class="!bg-deepsea-full !text-white hover:!bg-deepsea-full/75"
          data-test="lienholderModalConfirm"
          @click="updateQuote"
          >Confirm</el-button
        >
      </span>
      <div>
        <el-carousel :interval="9999999" :loop="false" arrow="always" :initial-index="initialIndex">
          <el-carousel-item
            v-for="(item1, index1) in lienholderList"
            :key="index1"
            style="padding-top: 50px"
            class="pl-20 pr-20"
          >
            <div class="flex">
              <ValidationProvider
                rules="required"
                :name="`Name-${index1}`"
                immediate
                class="w-full mr-2"
              >
                <div slot-scope="{ errors }" class="errorLabel">
                  <el-input
                    v-model="item1.name"
                    :value="item1.name"
                    placeholder="Lienholder Name..."
                    data-test="lienholderModalName"
                  ></el-input>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                :name="`Address-${index1}`"
                immediate
                class="w-full"
              >
                <div slot-scope="{ errors }" class="errorLabel">
                  <el-input
                    v-model="item1.addr1"
                    :value="item1.addr1"
                    placeholder="Lienholder Address..."
                    data-test="lienholderModalAddress1"
                  ></el-input>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <el-input
                v-model="item1.addr2"
                class="ml-2"
                :value="item1.addr2"
                placeholder="Address 2..."
                data-test="lienholderModalAddress2"
              ></el-input>
            </div>

            <div class="flex mt-5">
              <ValidationProvider
                rules="required"
                :name="`City-${index1}`"
                class="w-full mr-2"
                immediate
              >
                <div slot-scope="{ errors }" class="errorLabel">
                  <el-input
                    v-model="item1.city"
                    :value="item1.city"
                    placeholder="Lienholder City..."
                    data-test="lienholderModalCity"
                  ></el-input>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                :name="`State-${index1}`"
                class="w-full mr-2"
                immediate
              >
                <div slot-scope="{ errors }" class="errorLabel">
                  <el-select
                    v-model="item1.state"
                    class="w-full"
                    filterable
                    placeholder="Lienholder State..."
                    data-test="lienholderModalState"
                  >
                    <el-option
                      v-for="state in stateList"
                      :key="state.value"
                      :label="state.text"
                      :value="state.value"
                    ></el-option>
                  </el-select>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                rules="required"
                :name="`Zipcode-${index1}`"
                immediate
                class="w-full"
              >
                <div slot-scope="{ errors }" class="errorLabel">
                  <el-input
                    v-model="item1.zip"
                    :value="item1.zip"
                    placeholder="Lienholder Zip..."
                    data-test="lienholderModalZip"
                  ></el-input>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="flex mt-5">
              <ValidationProvider
                rules="required"
                :name="`Loan Number-${index1}`"
                immediate
                class="w-full mr-2"
              >
                <div slot-scope="{ errors }" class="errorLabel">
                  <el-input
                    v-model="item1.loan_number"
                    :value="item1.loan_number"
                    placeholder="Loan Number..."
                    data-test="lienholderModalLoanNo"
                  ></el-input>
                  <p>{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <el-input
                v-model="item1.mortgage_clause"
                :value="item1.mortgage_clause"
                placeholder="Mortgage Clause..."
                data-test="lienholderModalMortgageClause"
              ></el-input>
            </div>
          </el-carousel-item>
        </el-carousel>
        <p
          v-if="errorMessage"
          class="text-center mt-4 -mb-8 errorLabel"
          data-test="lienholderModalError"
        >
          {{ errorMessage }}
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authAxios } from "../../../store/modules/auth";
import { Constants, applicationOptions } from "../../../config";
import { ValidationProvider } from "vee-validate";
import { unwrap_error } from "../../../components/policy-wizard/machine/services.js";
export default {
  components: {
    ValidationProvider
  },
  props: {
    canModify: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    initialIndex: 0,
    index: 0,
    editing: false,
    showCheckModal: false,
    endorseQuoteId: "",
    list: [],
    lienholderList: [],
    selectedToEdit: {},
    loading: false,
    errorMessage: ""
  }),
  computed: {
    stateList() {
      return applicationOptions?.stateList;
    },
    isInternal() {
      return this.$store.getters["auth/internal"] === true;
    },
    policyNo() {
      return this.$store.getters.policyNo;
    },
    /**
     * @type {() => neptune.ILienholderItem[]}
     */
    lienholders() {
      return this.$store.state.policy.lienholderInfo;
    }
  },
  watch: {
    /**
     * @type {() => neptune.ILienholderItem[]}
     */
    lienholders() {
      return this.$store.state.policy.lienholderInfo;
    }
  },
  methods: {
    edit(index) {
      this.showCheckModal = true;
      this.index = index;
    },
    async continueEdit() {
      try {
        this.loading = true;

        await this.getDataWithIndex(this.index);

        this.showCheckModal = false;
        this.editing = true;
        this.loading = false;
        this.selectedToEdit = this.list.application.lienholderList[this.index];
      } catch (error) {
        this.loading = false;
        this.$emit("update", "failed");
        return Promise.reject(error);
      }
    },
    async getDataWithIndex(index) {
      try {
        this.initialIndex = index;
        const {
          data: {
            result: { endorsementQuoteNumber: endorseQuoteId }
          }
        } = await authAxios.post(Constants.CREATE_ENDORSE_URL, `"${this.policyNo}"`, {
          headers: {
            "Content-Type": "application/json"
          }
        });
        this.endorseQuoteId = endorseQuoteId;
        const tritonUrl = `${Constants.BASE_TRITON_URL}/api/v4/agent/quotes/${this.endorseQuoteId}`;
        await authAxios.get(tritonUrl).then((res) => {
          this.list = res.data;
          this.lienholderList = res.data.application.lienholderList;
        });

        this.selectedToEdit = this.list.application.lienholderList[index];
        return endorseQuoteId;
      } catch (error) {
        this.loading = false;
        this.$emit("update", "endorsementError");
        return Promise.reject(error);
      }
    },

    async updateQuote() {
      try {
        await this.validateFields();
        this.loading = true;
        this.list.application.lienholderList = this.lienholderList;
        const url = `${Constants.BASE_TRITON_URL}/api/v4/agent/quotes`;
        await authAxios.put(url, this.list);

        const bindUrl = `${Constants.BASE_TRITON_URL}/api/v2/endorsement/bind`;
        await authAxios.put(bindUrl, {
          QuoteNumber: this.endorseQuoteId,
          TransactionDescription: "",
          Comments: ""
        });

        this.$emit("update");
        this.showCheckModal = false;
        this.selectedToEdit = {};
        this.endorseQuoteId = "";
        this.editing = false;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$emit("update", "failed");
        const errorMessage = unwrap_error(error);
        this.errorMessage = errorMessage;
        throw new Error(errorMessage);
      }
    },
    validateFields() {
      return Object.values(this.$_veeObserver.refs).reduce((promise, field) => {
        return promise
          .then(() => field?.validate())
          .then((result) => {
            if (!result.valid) {
              return Promise.reject(new Error("Please fill out the missing fields."));
            }
          });
      }, Promise.resolve());
    },
    cancelEdit() {
      this.showCheckModal = false;
      this.selectedToEdit = {};
      this.endorseQuoteId = "";
      this.initialIndex = 0;
      this.lienholderList = this.lienholders;
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  margin-bottom: 3rem;

  > * {
    display: flex;
    flex-flow: column;
    margin-bottom: 0.9rem;

    label {
      font-size: 1.2rem;
    }
  }
}
::v-deep .dialog-class {
  margin-bottom: auto;
}
.el-carousel__item h3 {
  // background-color: rgba(22, 171, 226, 0.7);
  font-size: 18px;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  // background-color: rgba(22, 171, 226, 0.9);
}

.el-carousel__item:nth-child(2n + 1) {
  // background-color: rgba(67, 174, 138, 0.9);
}

.bold-title {
  font-weight: bold;
}
.remove-bold {
  font-weight: normal;
}
.errorLabel {
  color: red;
}
.sub-header {
  font-weight: bold;
  margin-bottom: 15px;
}
</style>
