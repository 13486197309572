// import Axios from 'axios'
import Vue from "vue";
import { authAxios } from "../auth";
import { Constants } from "../../../config";

const errorHandler = async (error) => {
  console.log(error);
  // debugger
  await Vue.prototype.$msgbox({
    title: "ERROR",
    message: error.response?.data?.payload?.message ?? error.response?.data ?? error
  });

  return Promise.reject(error.response?.data?.message ?? error.response?.data ?? error);
};

/**
 * auth/validateWithDeepLink({ deepLink, phone, zip })
 * @type {import("vuex").ActionTree<any, neptune.IRootState>}
 */
export const actions = {
  async load({ dispatch, rootGetters }, policyNo) {
    const po = policyNo || rootGetters["policy/allData"].policyNo;
    await dispatch("getDocuments", po);
    // await dispatch("getPdfs", po);
  },

  async postSignatures({ rootState, dispatch }) {
    const {
      auth: {
        validateReturn: {
          policy: { policyDocStatus }
        }
      }
    } = rootState;

    try {
      const { data } = await authAxios.post(
        Constants.GET_SIGNATURES_URL,
        // '/api/v1/documents/signatures',
        policyDocStatus
      );
      await dispatch("auth/getSummary", null, {
        root: true
      });
      return data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  async getDocuments({ commit, rootGetters }, policyNo) {
    const po = policyNo || rootGetters["policy/allData"].policyNo;

    /** @type {ApiResponseWithPayload<neptune.auth.INewAuthorizationData>} */
    const {
      data: {
        payload: {
          // policy: {
          policyDocStatus: { documentsWithSignatures: docs },
          policyDocOCR
          // }
        }
      }
    } = await authAxios.get(Constants.GET_DOCUMENTS_URL.replace("{{policyNo}}", po));

    commit("SET_PROP", {
      path: "ocrDocs",
      value: policyDocOCR
    });

    commit("SET_PROP", {
      path: "documents",
      value: docs
    });

    return docs;
  },

  async getAllPolicyDocuments({ commit }, policyNo) {
    const result = await authAxios.get(
      Constants.GET_POLICY_DOCUMENTS.replace("{{policyNo}}", policyNo)
    );
    commit("SET_PROP", { path: "pdfs", value: result.data.payload });
    return result.data.payload;
  },
  async getAllPolicyDocuments2({ commit }, policyNo) {
    const result = await authAxios.get(
      Constants.GET_POLICY_DOCUMENTS2.replace("{{policyNo}}", policyNo)
    );
    commit("SET_PROP", { path: "pdfs", value: result.data.payload });
    return result.data.payload;
  },

  // eslint-disable-next-line no-unused-vars
  async deleteDocumentAxios({ getters }, { policyNo, policyDocId, policyDocVerId }) {
    const url = Constants.DELETE_POLICY_DOCUMENTS.replace("{{policyNo}}", policyNo);
    const response = await authAxios.delete(`${url}/${policyDocId}/${policyDocVerId}`);
    console.log(response);
    return response;
  },
  /**
   * Loads the current policy pdfs
   * @returns {Promise<neptune.IPolicyPdfDocument[]>} Pdfs
   */
  async getPdfs({ commit, rootGetters }, policyNo) {
    const po = policyNo || rootGetters["policy/allData"].policyNo;

    const {
      data: { payload }
    } = await authAxios.get(Constants.GET_POLICY_DOCUMENTS.replace("{{policyNo}}", po));

    commit("SET_PROP", {
      path: "pdfs",
      value: payload
    });

    return payload;
  }
};

export default actions;
