<template>
  <div class="deep-link flex flex-1 flex-col p-5">
    <div class="header-title">
      <img src="../../components/policy-wizard/images/logo2.svg" class="logo" />

      <h2>
        Please login below.
      </h2>
    </div>
    <el-card class="centered-card">
      <DeeplinkAuthentication :deep-link="encryptedPayload" :validator="validator" />

      <p class="my-5 text-sm text-center">
        Having trouble? Create another
        <router-link :to="{ name: 'generatePolicyEmail' }" class="text-blue-500">
          link
        </router-link>
      </p>
    </el-card>
  </div>
</template>

<script>
import DeeplinkAuthentication from "../../components/sign_and_pay/policy_holder/DeeplinkAuthentication.vue";
export default {
  components: {
    DeeplinkAuthentication
  },
  props: {
    encryptedPayload: {
      type: String
    }
  },
  data: () => ({
    success: false
  }),
  watch: {
    async success(val) {
      if (val) {
        this.$router.push({
          name: "policyWelcome"
        });
      }
    }
  },
  methods: {
    async validator(model) {
      try {
        const data = await this.$store.dispatch("auth/validateWithDeepLink", model);
        this.success = true;
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(new Error(error));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-title {
  text-align: center;
}

.logo {
  height: 150px;
  width: auto;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
