const mappings = {
  C01: "Cancel / Building Sold",
  C02: "Cancel / Contents Sold",
  C03: "Cancel / Expiration With Other Coverages",
  C04: "Cancel / Duplicate Policy",
  C05: "Cancel / Nonpayment",
  C06: "Cancel / Not Eligible For Coverage",
  C07: "Cancel / Property Closing Did Not Occur",
  C08: "Cancel / No Longer in Flood Map",
  C09: "Cancel / No Longer in SFHA",
  C10: "Cancel / Condo or Association Converting to RCBAP",
  C12: "Cancel / Mortgage Paid Off",
  C13: "Cancel / Cancel Prior to Effective Date",
  C15: "Cancel / No Longer in FEMA SFHA Review",
  C19: "Cancel / No Longer in SFHA by LOMA",
  C21: "Cancel / Continuous Lake Flooding",
  C22: "Cancel / Misrating",
  C23: "Cancel / Fraud or Material Misrepresentation",
  C24: "Cancel / Map Revision/LOMA/LODR",
  C30: "Cancel / FHA/VA",
  C33: "Cancel / Insured Request",
  C34: "Cancel / Insured Requested",
  C40: "Cancel / Open Claims",
  C41: "Cancel / Manual NonRenewal",
  CN: "Cancel",
  EB: "Endorsement",
  MA: "Manual Adjustment",
  NB: "New Business",
  EBM: "Mandatory Endorsement",
  EBR: "Endorsement Reversal",
  EBO: "Out of Sequence Endorsement",
  P01: "Pending Cancel / Building Sold",
  P02: "Pending Cancel / Contents Sold",
  P03: "Pending Cancel / Expiration With Other Coverages",
  P04: "Pending Cancel / Duplicate Policy",
  P05: "Pending Cancel / Nonpayment",
  P06: "Pending Cancel / Not Eligible For Coverage",
  P07: "Pending Cancel / Property Closing Did Not Occur",
  P08: "Pending Cancel / No Longer in Flood Map",
  P09: "Pending Cancel / No Longer in SFHA",
  P10: "Pending Cancel / Condo or Association Converting to RCBAP",
  P12: "Pending Cancel / Mortgage Paid Off",
  P13: "Pending Cancel / Cancel Prior to Effective Date",
  P15: "Pending Cancel / No Longer in FEMA SFHA Review",
  P19: "Pending Cancel / No Longer in SFHA by LOMA",
  P21: "Pending Cancel / Continuous Lake Flooding",
  P22: "Pending Cancel / Misrating",
  P23: "Pending Cancel / Fraud or Material Misrepresentation",
  P24: "Pending Cancel / Map Revision/LOMA/LODR",
  P30: "Pending Cancel / FHA/VA",
  P33: "Pending Cancel / Insured Request",
  P34: "Pending Cancel / Insured Requested",
  P40: "Pending Cancel / Open Claims",
  P41: "Pending Cancel / Manual NonRenewal",
  RI: "Reinstatement",
  RN: "Renewal",
  Grouped: "Grouped Endorsement"
};

/**
 * Retrieve the description of a transaction based on it's code number
 * If a code is not found, just returns code back
 * @param {string} code code number
 */
export function getTransactionDescription(code) {
  return mappings[code] || code;
}
