<template>
  <!-- Cancel Policy Dialog -->
  <el-dialog
    :visible.sync="value"
    :top="null"
    class="centered-dialog__wrapper"
    custom-class="centered-dialog cancel-dialog"
    :close-on-click-modal="false"
    append-to-body
    @closed="reset()"
  >
    <template #title>
      <div class="p-3 font-bold">
        <span class="text-red-500 el-icon-delete-solid" />
        Policy Cancellation
      </div>
    </template>
    <template #default>
      <component
        :is="activeStepComponent"
        ref="step"
        :reason="reason"
        @reason="reason = $event"
        @success="enable"
        @updatedDocuments="setDisable()"
      />
    </template>
    <template #footer>
      <div class="flex">
        <div class="flex flex-1 label mr-2 self-end">
          <label class="text-xs" data-test="cancelStepLabel">
            Step {{ step + 1 }} of {{ stepNames.length }}
          </label>
        </div>

        <!-- Buttons -->
        <div class="flex justify-end">
          <el-button
            v-if="!isLast"
            :disabled="!enableNext || cancelReasonDocDisable"
            size="mini"
            class="!bg-deepsea-full !text-white hover:!bg-deepsea-full/75"
            data-test="cancelNextButton"
            @click.native="next($event)"
          >
            Next
          </el-button>
          <el-button
            v-else
            :disabled="!enableNext"
            size="mini"
            class="!bg-deepsea-full !text-white hover:!bg-deepsea-full/75"
            data-test="cancelCancelButton"
            @click="cancel"
          >
            Cancel Policy
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import CancelStep1Vue from "./cancel-steps/CancelStep1.vue";
import CancelStep2Vue from "./cancel-steps/CancelStep2.vue";
import CancelStep3Vue from "./cancel-steps/CancelStep3.vue";
export default {
  components: {
    Reason: CancelStep1Vue,
    Upload: CancelStep2Vue,
    Sign: CancelStep3Vue
  },
  props: {
    visible: [String, Boolean],
    autoCancel: {
      type: [Boolean, String],
      default: false
    }
  },
  data: () => ({
    step: 0,
    reason: null,
    enableNext: false,
    suppressValue: false,
    documentsPresent: false
  }),
  computed: {
    value: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      }
    },

    cancelReasonDocDisable() {
      const code = this.reason?.reasonCode;

      return (
        (code === "02" || code === "01" || code === "12") && !this.isDocPresent && this.step === 1
      );
    },

    isDocPresent() {
      return this.documentsPresent;
    },

    stepNames() {
      const code = this.reason?.reasonCode;

      if (this.autoCancel) {
        return ["Sign"];
      }

      if (code === "13") {
        return ["Sign"];
      }

      const steps = ["Reason", "Upload", "Sign"];

      if (!this.reason?.policyCancelDocs?.length) {
        steps.splice(1, 1);
      }
      if (code === "02" || code === "01" || code === "12") {
        return ["Reason", "Upload", "Sign"];
      }
      return steps;
    },

    autoCancelPayload() {
      return {
        PolicyNumber: this.$store.getters["policy/allData"].policyNo,
        ReasonCode: 13,
        NoteInfo: {
          Note: "",
          Private: false
        },
        EffectiveDate: new Date().toISOString(),
        SuppressNotification: this.suppressValue
      };
    },

    activeStepComponent() {
      return this.stepNames[this.step];
    },

    isLast() {
      return this.step === this.stepNames.length - 1;
    }
  },
  watch: {
    step(val, oldVal) {
      if (val > oldVal) {
        this.enableNext = false;
      }
    }
  },
  methods: {
    setDisable() {
      this.documentsPresent = true;
    },
    reset() {
      this.step = 0;
    },
    refreshPolicy() {
      this.$store.dispatch(
        "policy/getPolicyDetails",
        this.$store.getters["policy/allData"].policyNo
      );
    },
    enable(val) {
      this.enableNext = val;
    },

    /** @param {MouseEvent} ev */
    async submit(ev) {
      this.enableNext = false;
      /** @type {Vue} */
      const currentComponent = this.$refs.step;

      const hasSubmit = !!currentComponent.$options.methods.submit;

      if (hasSubmit && !ev.ctrlKey) {
        try {
          const val = await currentComponent.submit();
          this.enableNext = true;
          return val;
        } catch (error) {
          this.enableNext = true;
          return Promise.reject(new Error(error));
        }
      } else {
        this.enableNext = true;
        return true;
      }
    },
    prev() {
      this.step -= 1;
    },
    async next(ev) {
      await this.submit(ev);
      this.refreshPolicy();
      this.step += 1;
    },
    async cancel(ev) {
      await this.submit(ev);
      this.$emit("cancel");
      this.$emit("fetchPendingPremium");
    },
    async cancelPolicy() {
      try {
        return this.$store.dispatch("policy/cancelPolicy", this.autoCancelPayload);
      } catch (error) {
        this.$msgbox({
          title: "Error",
          type: "error",
          message: error
        }).catch(() => {});

        return error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .cancel-dialog {
  .el-dialog__body {
    background-color: #f5f5f5;
  }
}
</style>
