<template>
  <div v-loading="loading">
    <div data-test="removePaymentMethod.text">
      Are you sure you want to remove your payment method?
    </div>
    <div slot="footer" class="mt-8 space-x-4">
      <button
        v-if="!loading"
        class="bg-gray-100 text-black p-2 rounded mt-3 w-full md:w-auto"
        data-test="removePaymentMethod.cancel"
        @click.prevent="close"
      >
        Cancel
      </button>
      <button
        v-if="!loading"
        class="bg-deepsea-full hover:!bg-deepsea-900 text-white p-2 rounded mt-3 w-full md:w-auto"
        data-test="removePaymentMethod.confirm"
        @click.prevent="submit"
      >
        Yes, I'm sure
      </button>
    </div>
  </div>
</template>

<script>
import { Constants } from "@/config";
import { authAxios } from "@/store/modules/auth";

export default {
  props: {
    policy: Object,
    paymentMethod: {
      type: Object
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async submit() {
      try {
        this.loading = true;

        const url = Constants.REMOVE_PAYMENT_METHOD.replace(
          "{{policyNo}}",
          this.policy.policyNo
        ).replace("{{paymentMethod}}", this.paymentMethod.stripePaymentMethodId);

        await authAxios.delete(url);

        this.$notify({
          title: "Success",
          message: "Payment method was successfully removed!",
          type: "success"
        });
      } catch (e) {
        this.$notify({
          title: "Failure",
          type: "error",
          message: "There was an error removing the payment method."
        });
        throw new Error(e);
      } finally {
        this.$emit("done");
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#payment-element {
  border: none;
}
</style>
