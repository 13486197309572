<template>
  <div class="map-display">
    <div class="map-display__inner">
      <iframe
        width="100%"
        height="100%"
        frameborder="0"
        style="border: 0;"
        :src="
          `https://www.google.com/maps/embed/v1/place?key=${apiKey}&${srcParams}&zoom=${zoom}&maptype=satellite`
        "
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: String,
      required: false
    },
    lat: {
      type: Number,
      require: false
    },
    lng: {
      type: Number,
      required: false
    },
    zoom: {
      type: Number,
      default: 20,
      validator: (val) => val <= 20 && val >= 2
    }
  },
  computed: {
    srcParams() {
      if (this.lat && this.lng) {
        return `q=${this.lat},${this.lng}`;
      }

      if (!this.address) {
        throw new Error("An address or lat/lng is needed to display a map");
      }

      const addr = encodeURIComponent(this.address);
      return `q=${addr}`;
    },
    apiKey() {
      return process.env.VUE_APP_GMAPS_KEY;
    }
  }
};
</script>

<style lang="scss" scoped>
.map-display {
  position: relative;
  background-color: #ccc;
  min-width: 100px;
  height: 0;
  padding-bottom: 56.25%;
  border-width: 0;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
