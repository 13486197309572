import actions from "./actions";
import globalMutators from "../settings/modules/ui/mutations";

/**
 * @returns {any}
 */
export const state = () => ({
  transactions: null
});

/**
 * @type {import("vuex").Module}
 */
const mod = {
  state,
  namespaced: true,
  actions: actions,
  mutations: globalMutators
};

export default mod;
