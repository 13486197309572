export const mutations = {
  SET_STATE(state, data) {
    Object.assign(state, data);
  },
  SET_ADDRESS_DATA(state, data) {
    state.addressData = data;
  }
};

export default mutations;
