<template>
  <section class="agent-details">
    <div>
      <label class="font-bold text-sm !text-gray-500" data-test="agencyLabel">
        Agency
      </label>
      <span class="spacer" />
      <p data-test="agencyName" class="text-base select-text">{{ agentInfo.company }}</p>

      <div class="flex text-base justify-between select-text">
        <p data-test="agencyNo">{{ agentInfo.agencyNo }}</p>

        <!-- pencil-alt / edit icon -->
        <svg
          v-if="canChangeAgency && !$readonly && hasPolicyIncepted"
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5 text-gray-400 hover:text-gray-900 cursor-pointer"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-test="editAgencyButton"
          @click="$emit('modify-agency')"
        >
          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="flex flex-col text-base">
        <a
          :href="mailto"
          :title="agentInfo.email"
          data-test="agencyEmail"
          class="!font-normal !text-black select-text"
        >
          {{ agentInfo.email }}
        </a>

        <a
          :href="phoneto"
          :title="agentInfo.phone"
          data-test="agencyPhone"
          class="!font-normal !text-black select-text"
        >
          {{ agentInfo.phone | phone }}
        </a>
      </div>
    </div>
    <div v-if="allData.producerName" class="address text-base mt-6">
      <label class="font-bold text-sm !text-gray-500" data-test="producerNameLabel">
        Producer Name
      </label>
      <p class="select-text" data-test="producerName">
        {{ allData.producerName }}
      </p>
    </div>
    <div class="address text-base mt-6">
      <label class="font-bold text-sm !text-gray-500">
        Address
      </label>

      <p class="uppercase select-text" data-test="agencyAddress1">
        {{ agentInfo.addr1 }} {{ agentInfo.addr2 }}
      </p>
      <p class="uppercase select-text" data-test="agencyAddress2">
        {{ agentInfo.city }}, {{ agentInfo.state }} {{ agentInfo.zip }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  filters: {
    phone: (value) => {
      if (value?.length !== 10) return value;
      return `(${value.substr(0, 3)}) ${value.substr(3, 3)}-${value.substr(6)}`;
    }
  },
  props: {
    agentId: {
      type: [Number, String],
      required: true
    },
    canChangeAgency: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      /** @type {neptune.store.user.UserProfilePayload} */
      details: {}
    };
  },

  computed: {
    hasPolicyIncepted() {
      const inceptionDate = new Date(this.$store.getters["policy/allData"].inceptionDate);
      const today = new Date();

      // Set both dates to the start of their respective days
      inceptionDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      return inceptionDate <= today;
    },
    allData() {
      /** @type {neptune.getters.IRootGetters} */
      const { "policy/allData": _allData } = this.$store.getters;

      return _allData;
    },

    agentInfo() {
      /** @type {neptune.store.user.AgencyDetailInfo} */
      const { zip, state, phone, fax, email, company, addr1, addr2, city, agencyNo } =
        this.details?.agencyInfo || {};
      return {
        zip,
        state,
        phone,
        fax,
        email,
        company,
        addr1,
        addr2,
        city,
        agencyNo
      };
    },

    mailto() {
      return `mailto:${this.agentInfo.email}`;
    },

    phoneto() {
      return `tel:${this.agentInfo.email}`;
    }
  },
  async mounted() {
    this.details = await this.$store.dispatch(
      "userdata/loadById",
      this.$store.state.policy?.confirmationData?.agencyNo
    );

    this.$store.commit("SET_AGENCY_INFO", this.details.agencyInfo);
  }
};
</script>

<style lang="scss" scoped>
.agent-details {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  > * {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
}
.title-size {
  font-size: 20px;
}
.bold-title {
  font-weight: bold;
}
.marginBot {
  margin-bottom: 10px;
}
</style>
