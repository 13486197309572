import { Constants } from "@/config";
import http from "../../../../axiosInstance";
import { stripeInstance } from "../..";

/**
 * @type {import('vuex').ActionTree<neptune.payment.ach.IAchState, neptune.IRootState>}
 */
const actions = {
  async createToken({ state, commit }) {
    const { token, error } = await stripeInstance.default.createToken(
      "bank_account",
      state.stripeOptions
    );

    if (error) {
      return Promise.reject(error);
    }

    if (token) {
      commit("SET_PROP", {
        path: "achToken",
        value: token
      });
    }

    return token;
  },

  /**
   * TODO: REPLACE policyNo references
   * @param {*} param0
   * @param {*} existingPaymentMethodId
   */
  async chargeAch({ rootState, state }, existingPaymentMethodId) {
    const policyNo = rootState.commercial.bindData.payload.policy.policyNo;
    const bankToken = state.achToken.id;

    const data = await http.post(Constants.PAY_ACH_URL, {
      policyNo: policyNo,
      bankToken: bankToken,
      // email: email,
      existingPaymentMethod: existingPaymentMethodId
    });

    if (data.statusCode !== 200) {
      return Promise.reject(new Error(data.message));
    }

    return data;
  }
};

export default actions;
