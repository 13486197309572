import store from "@/store";
// import { routeToV2 } from "@/utils/routeToV2";

/** @type {RouterConfigWithTypedMeta} */
const route = {
  //   name:      'user',
  path: "/profile/:token?",
  meta: {
    title: "User",
    theme: "neptune"
  },
  // beforeEnter() {
  //   routeToV2("/profile");
  // },
  component: () => import(/* webpackChunkName: "ProfileLayout" */ "@/views/user/ProfileLayout.vue"),
  children: [
    {
      path: "",
      name: "Profile",
      meta: {
        title: "Profile"
      },
      component: () =>
        import(/* webpackChunkName: "UserDashboard" */ "@/views/user/UserDashboard.vue")
    },
    {
      path: "agents",
      redirect: {
        name: "profile"
      },
      component: {
        name: "SubAgentDashboard",
        template: "<router-view></router-view>"
      },
      children: [
        {
          path: ":agentNo",
          props: true,
          name: "agents",
          component: () =>
            import(/* webpackChunkName: "AgentDashboard" */ "@/views/user/UserDashboard.vue"),
          meta: {
            title: "Agents"
          }
        }
      ]
    },
    {
      path: "/agency/registration/completion/",
      async beforeEnter(to, from, next) {
        try {
          const newToken = to.query?.auth;
          await store.dispatch("auth/loginWithToken", newToken);
          store.commit("auth/SET_TOKEN", newToken);

          next("/agency/registration/documents");
        } catch (e) {
          throw new Error(e);
        }
      }
    },
    {
      path: "/agency/registration/documents",
      component: () =>
        import(/* webpackChunkName: "ProfileLayout" */ "@/views/user/AgencyDocumentsSign.vue"),
      name: "AgencySigning"
    }
  ]
  // async beforeEnter(to, from, next) {
  //   // grab agentID from persisted auth data
  //   const { AgentId } = store.state.auth.data || {};

  //   await store.dispatch("userdata/load", AgentId);

  //   next();
  // }
};

export default route;
