import store from "@/store";
import { routeToV2 } from "@/utils/routeToV2";

export default [
  {
    path: "/billing/payments",
    meta: {
      title: "Payments"
    },
    beforeEnter(_x1, _x2, next) {
      if (!store.getters["auth/roles"].includes("financeadmin")) {
        return next("/unauthorized");
      }

      routeToV2("/billing/payments");
    },
    component: () => import(/* webpackChunkName: "PaymentsPage" */ "./pages/PaymentsPage.vue")
  },
  {
    name: "payment-detail",
    path: "/billing/payments/:id",
    meta: {
      title: "Payment Detail",
      isHiddenFromSideNav: true
    },
    beforeEnter(_x1, _x2, next) {
      if (!store.getters["auth/roles"].includes("financeadmin")) {
        return next("/unauthorized");
      }
      next();
    },
    component: () =>
      import(/* webpackChunkName: "PaymentDetailPage" */ "./pages/PaymentDetailPage.vue")
  }
];
