import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import { length } from "vee-validate/dist/rules";

Object.keys(rules)
  .filter((x) => x !== "length")
  .forEach((rule) => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule] // assign message
    });
  });

extend("length", length);

localize({
  en: {
    messages: {
      length: (_, { length }) => `${_} field must be ${length} characters long`
    }
  }
});
