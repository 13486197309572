<template>
  <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        data-test="aorChange.closeModal"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class=" text-gray-900 text-lg  m-0 leading-none">
        <span class="font-medium" data-test="aorChange.mainTitle">Change Agency</span>
      </h1>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form class="mt-4" @submit.prevent="handleSubmit(submit)">
          <ValidationProvider v-slot="{ errors }" name="Agency Number" rules="required">
            <div class="mb-2">
              <label
                for="agencyNumber"
                class="text-sm text-gray-700 leading-none"
                data-test="aorChange.agencyNoTitle"
                >Agency Number<span class="text-red-500 font-bold">*</span></label
              >
              <div class="relative">
                <input
                  v-model="agencyNumber"
                  name="agencyNumber"
                  type="text"
                  placeholder="e.x. FL0008"
                  class="focus:ring-deepsea-medium focus:border-deepsea-medium block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                  data-test="aorChange.agencyNoInput"
                  @blur="getAgencyDetails(agencyNumber)"
                />
              </div>
              <FadeTranslate>
                <p
                  v-if="errors[0]"
                  class="text-red-500 text-sm m-0"
                  data-test="aorChange.agencyNoError"
                >
                  {{ errors[0] }}
                </p>
              </FadeTranslate>
              <div v-if="!loading" data-test="aorChange.aorSelectedSection">
                <span class="text-xs text-gray-500" data-test="aorChange.aorSelectedTitle"
                  >Selected Agent of Record:
                </span>
                <span class="text-xs text-gray-500 font-bold" data-test="aorChange.aorSelected"
                  >{{ agencyInfo.company }} — {{ agencyInfo.agencyNo }}</span
                >
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            v-if="$internal"
            v-slot="{ errors }"
            name="Producer Name"
            rules=""
            class="mt-4"
            tag="div"
          >
            <div class="mb-4">
              <label
                for="producerName"
                class="text-sm text-gray-700 leading-none"
                data-test="aorChange.newProducerTitle"
                >New Producer Name <span class="text-gray-500 text-xs">(optional)</span></label
              >
              <div class="relative">
                <input
                  v-model="producerName"
                  name="producerName"
                  type="text"
                  placeholder=""
                  class="focus:ring-deepsea-medium focus:border-deepsea-medium block w-full !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                  data-test="aorChange.newProducerInput"
                />
              </div>

              <FadeTranslate>
                <p
                  v-if="errors[0]"
                  class="text-red-500 text-sm m-0"
                  data-test="aorChange.newProducerError"
                >
                  {{ errors[0] }}
                </p>
              </FadeTranslate>
            </div>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" name="Notes" rules="required">
            <div class="mb-4">
              <label
                for="notes"
                class="text-sm text-gray-700 leading-none"
                data-test="aorChange.notesTitle"
                >Notes<span class="text-red-500 font-bold">*</span></label
              >
              <div class="relative">
                <textarea
                  v-model="notes"
                  name="notes"
                  type="text"
                  placeholder="Describe the reason for the change..."
                  class="focus:ring-deepsea-medium focus:border-deepsea-medium block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                  data-test="aorChange.notesInput"
                />
              </div>
              <FadeTranslate>
                <p
                  v-if="errors[0]"
                  class="text-red-500 text-sm m-0"
                  data-test="aorChange.notesError"
                >
                  {{ errors[0] }}
                </p>
              </FadeTranslate>
            </div>
          </ValidationProvider>

          <div slot="footer" class="flex justify-end space-x-2">
            <button
              class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              data-test="aorChange.cancelButton"
              @click.prevent="$emit('close')"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="inline-flex items-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-deepsea-medium focus:ring-offset-2 min-w-[69px] disabled:opacity-75 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-not-allowed"
              :class="{ 'cursor-not-allowed': loading }"
              :disabled="loading || !notes.length || !agencyNumber.length"
              data-test="aorChange.submitButton"
              @click.prevent="handleSubmit(submit)"
            >
              <span v-if="!loading">
                Update
              </span>
              <svg
                v-else
                class="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

import { ValidationProvider, ValidationObserver, setInteractionMode } from "vee-validate";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";
import { authAxios } from "@/store/modules/auth";
import { Constants } from "@/config";
setInteractionMode("eager");

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FadeTranslate
  },
  data() {
    return {
      agencyNumber: "",
      notes: "",
      producerName: "",
      serverError: "",
      loading: false,
      agencyInfo: "",
      originalAgencyNumber: "",
      originalProducerName: ""
    };
  },
  computed: {
    policyData() {
      return this.$store.getters["policy/allData"];
    }
  },

  async mounted() {
    this.producerName = this.policyData.producerName;
    this.agencyNumber = this.policyData.agencyNo;
    this.originalProducerName = this.policyData.producerName;
    this.originalAgencyNumber = this.policyData.agencyNo;

    await this.getAgencyDetails(this.agencyNumber);
  },

  methods: {
    async getAgencyDetails(agentNo) {
      try {
        this.loading = true;
        const {
          data: {
            payload: { agencyInfo }
          }
        } = await authAxios.get(
          Constants.AGENCY_DETAILS_FOR_POLICY.replace("{{agentNo}}", agentNo)
        );

        this.agencyInfo = agencyInfo;
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      this.loading = true;

      try {
        const url = Constants.ENDORSE_POLICY_URL.replace(
          "{{policyNo}}",
          this.$route.params.policyNo
        );

        await authAxios.post(url, {
          endorsementType: "aor",
          agentNo: this.agencyNumber.trim(),
          notes: this.notes.trim(),
          producerName: this.producerName.trim()
        });

        let message = "";
        if (this.agencyNumber.trim() !== this.originalAgencyNumber.trim()) {
          message += "Agency";
          if (this.producerName.trim() !== this.originalProducerName.trim()) {
            message += " and Producer Name";
          }
        } else if (this.producerName.trim() !== this.originalProducerName.trim()) {
          message += "Producer Name";
        }

        Swal.fire({
          title: "Success!",
          text: message + " changed successfully",
          icon: "success",
          confirmButtonClass: "!bg-deepsea-full !border !border-deepsea-full hover:!bg-deepsea-full/75 focus:!ring-deepsea-medium focus:!ring-2 focus:!ring-offset-1",
          showConfirmButton: true
        });

        this.$root.$emit("refreshPolicyData");
        this.$emit("close");
        // await this.init();
      } catch (e) {
        const message =
          e.response?.data?.message ?? "Unable to update agency. Please try again later";

        Swal.fire({
          title: "Error!",
          text: message,
          confirmButtonClass: "!bg-deepsea-full !border !border-deepsea-full hover:!bg-deepsea-full/75 focus:!ring-deepsea-medium focus:!ring-2 focus:!ring-offset-1",
          icon: "error",
          showConfirmButton: true
        });
        this.$emit("close");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
