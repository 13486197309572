/**
 * An array of FontAwesome icons names
 * ! Excludes prefixes!!
 */
export const fontAwesomeIconNames = [
  "500px",
  "address-book",
  "address-book-o",
  "address-card",
  "address-card-o",
  "adjust",
  "adn",
  "align-center",
  "align-justify",
  "align-left",
  "align-right",
  "amazon",
  "ambulance",
  "american-sign-language-interpreting",
  "anchor",
  "android",
  "angellist",
  "angle-double-down",
  "angle-double-left",
  "angle-double-right",
  "angle-double-up",
  "angle-down",
  "angle-left",
  "angle-right",
  "angle-up",
  "apple",
  "archive",
  "area-chart",
  "arrow-circle-down",
  "arrow-circle-left",
  "arrow-circle-o-down",
  "arrow-circle-o-left",
  "arrow-circle-o-right",
  "arrow-circle-o-up",
  "arrow-circle-right",
  "arrow-circle-up",
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "arrows",
  "arrows-alt",
  "arrows-h",
  "arrows-v",
  "asl-interpreting",
  "assistive-listening-systems",
  "asterisk",
  "at",
  "audio-description",
  "automobile",
  "backward",
  "balance-scale",
  "ban",
  "bandcamp",
  "bank",
  "bar-chart",
  "bar-chart-o",
  "barcode",
  "bars",
  "bath",
  "bathtub",
  "battery",
  "battery-0",
  "battery-1",
  "battery-2",
  "battery-3",
  "battery-4",
  "battery-empty",
  "battery-full",
  "battery-half",
  "battery-quarter",
  "battery-three-quarters",
  "bed",
  "beer",
  "behance",
  "behance-square",
  "bell",
  "bell-o",
  "bell-slash",
  "bell-slash-o",
  "bicycle",
  "binoculars",
  "birthday-cake",
  "bitbucket",
  "bitbucket-square",
  "bitcoin",
  "black-tie",
  "blind",
  "bluetooth",
  "bluetooth-b",
  "bold",
  "bolt",
  "bomb",
  "book",
  "bookmark",
  "bookmark-o",
  "braille",
  "briefcase",
  "btc",
  "bug",
  "building",
  "building-o",
  "bullhorn",
  "bullseye",
  "bus",
  "buysellads",
  "cab",
  "calculator",
  "calendar",
  "calendar-check-o",
  "calendar-minus-o",
  "calendar-o",
  "calendar-plus-o",
  "calendar-times-o",
  "camera",
  "camera-retro",
  "car",
  "caret-down",
  "caret-left",
  "caret-right",
  "caret-square-o-down",
  "caret-square-o-left",
  "caret-square-o-right",
  "caret-square-o-up",
  "caret-up",
  "cart-arrow-down",
  "cart-plus",
  "cc",
  "cc-amex",
  "cc-diners-club",
  "cc-discover",
  "cc-jcb",
  "cc-mastercard",
  "cc-paypal",
  "cc-stripe",
  "cc-visa",
  "certificate",
  "chain",
  "chain-broken",
  "check",
  "check-circle",
  "check-circle-o",
  "check-square",
  "check-square-o",
  "chevron-circle-down",
  "chevron-circle-left",
  "chevron-circle-right",
  "chevron-circle-up",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "child",
  "chrome",
  "circle",
  "circle-o",
  "circle-o-notch",
  "circle-thin",
  "clipboard",
  "clock-o",
  "clone",
  "close",
  "cloud",
  "cloud-download",
  "cloud-upload",
  "cny",
  "code",
  "code-fork",
  "codepen",
  "codiepie",
  "coffee",
  "cog",
  "cogs",
  "columns",
  "comment",
  "comment-o",
  "commenting",
  "commenting-o",
  "comments",
  "comments-o",
  "compass",
  "compress",
  "connectdevelop",
  "contao",
  "copy",
  "copyright",
  "creative-commons",
  "credit-card",
  "credit-card-alt",
  "crop",
  "crosshairs",
  "css3",
  "cube",
  "cubes",
  "cut",
  "cutlery",
  "dashboard",
  "dashcube",
  "database",
  "deaf",
  "deafness",
  "dedent",
  "delicious",
  "desktop",
  "deviantart",
  "diamond",
  "digg",
  "dollar",
  "dot-circle-o",
  "download",
  "dribbble",
  "drivers-license",
  "drivers-license-o",
  "dropbox",
  "drupal",
  "edge",
  "edit",
  "eercast",
  "eject",
  "ellipsis-h",
  "ellipsis-v",
  "empire",
  "envelope",
  "envelope-o",
  "envelope-open",
  "envelope-open-o",
  "envelope-square",
  "envira",
  "eraser",
  "etsy",
  "eur",
  "euro",
  "exchange",
  "exclamation",
  "exclamation-circle",
  "exclamation-triangle",
  "expand",
  "expeditedssl",
  "external-link",
  "external-link-square",
  "eye",
  "eye-slash",
  "eyedropper",
  "fa",
  "facebook",
  "facebook-f",
  "facebook-official",
  "facebook-square",
  "fast-backward",
  "fast-forward",
  "fax",
  "feed",
  "female",
  "fighter-jet",
  "file",
  "file-archive-o",
  "file-audio-o",
  "file-code-o",
  "file-excel-o",
  "file-image-o",
  "file-movie-o",
  "file-o",
  "file-pdf-o",
  "file-photo-o",
  "file-picture-o",
  "file-powerpoint-o",
  "file-sound-o",
  "file-text",
  "file-text-o",
  "file-video-o",
  "file-word-o",
  "file-zip-o",
  "files-o",
  "film",
  "filter",
  "fire",
  "fire-extinguisher",
  "firefox",
  "first-order",
  "flag",
  "flag-checkered",
  "flag-o",
  "flash",
  "flask",
  "flickr",
  "floppy-o",
  "folder",
  "folder-o",
  "folder-open",
  "folder-open-o",
  "font",
  "font-awesome",
  "fonticons",
  "fort-awesome",
  "forumbee",
  "forward",
  "foursquare",
  "free-code-camp",
  "frown-o",
  "futbol-o",
  "gamepad",
  "gavel",
  "gbp",
  "ge",
  "gear",
  "gears",
  "genderless",
  "get-pocket",
  "gg",
  "gg-circle",
  "gift",
  "git",
  "git-square",
  "github",
  "github-alt",
  "github-square",
  "gitlab",
  "gittip",
  "glass",
  "glide",
  "glide-g",
  "globe",
  "google",
  "google-plus",
  "google-plus-circle",
  "google-plus-official",
  "google-plus-square",
  "google-wallet",
  "graduation-cap",
  "gratipay",
  "grav",
  "group",
  "h-square",
  "hacker-news",
  "hand-grab-o",
  "hand-lizard-o",
  "hand-o-down",
  "hand-o-left",
  "hand-o-right",
  "hand-o-up",
  "hand-paper-o",
  "hand-peace-o",
  "hand-pointer-o",
  "hand-rock-o",
  "hand-scissors-o",
  "hand-spock-o",
  "hand-stop-o",
  "handshake-o",
  "hard-of-hearing",
  "hashtag",
  "hdd-o",
  "header",
  "headphones",
  "heart",
  "heart-o",
  "heartbeat",
  "history",
  "home",
  "hospital-o",
  "hotel",
  "hourglass",
  "hourglass-1",
  "hourglass-2",
  "hourglass-3",
  "hourglass-end",
  "hourglass-half",
  "hourglass-o",
  "hourglass-start",
  "houzz",
  "html5",
  "i-cursor",
  "id-badge",
  "id-card",
  "id-card-o",
  "ils",
  "image",
  "imdb",
  "inbox",
  "indent",
  "industry",
  "info",
  "info-circle",
  "inr",
  "instagram",
  "institution",
  "internet-explorer",
  "intersex",
  "ioxhost",
  "italic",
  "joomla",
  "jpy",
  "jsfiddle",
  "key",
  "keyboard-o",
  "krw",
  "language",
  "laptop",
  "lastfm",
  "lastfm-square",
  "leaf",
  "leanpub",
  "legal",
  "lemon-o",
  "level-down",
  "level-up",
  "life-bouy",
  "life-buoy",
  "life-ring",
  "life-saver",
  "lightbulb-o",
  "line-chart",
  "link",
  "linkedin",
  "linkedin-square",
  "linode",
  "linux",
  "list",
  "list-alt",
  "list-ol",
  "list-ul",
  "location-arrow",
  "lock",
  "long-arrow-down",
  "long-arrow-left",
  "long-arrow-right",
  "long-arrow-up",
  "low-vision",
  "magic",
  "magnet",
  "mail-forward",
  "mail-reply",
  "mail-reply-all",
  "male",
  "map",
  "map-marker",
  "map-o",
  "map-pin",
  "map-signs",
  "mars",
  "mars-double",
  "mars-stroke",
  "mars-stroke-h",
  "mars-stroke-v",
  "maxcdn",
  "meanpath",
  "medium",
  "medkit",
  "meetup",
  "meh-o",
  "mercury",
  "microchip",
  "microphone",
  "microphone-slash",
  "minus",
  "minus-circle",
  "minus-square",
  "minus-square-o",
  "mixcloud",
  "mobile",
  "mobile-phone",
  "modx",
  "money",
  "moon-o",
  "mortar-board",
  "motorcycle",
  "mouse-pointer",
  "music",
  "navicon",
  "neuter",
  "newspaper-o",
  "object-group",
  "object-ungroup",
  "odnoklassniki",
  "odnoklassniki-square",
  "opencart",
  "openid",
  "opera",
  "optin-monster",
  "outdent",
  "pagelines",
  "paint-brush",
  "paper-plane",
  "paper-plane-o",
  "paperclip",
  "paragraph",
  "paste",
  "pause",
  "pause-circle",
  "pause-circle-o",
  "paw",
  "paypal",
  "pencil",
  "pencil-square",
  "pencil-square-o",
  "percent",
  "phone",
  "phone-square",
  "photo",
  "picture-o",
  "pie-chart",
  "pied-piper",
  "pied-piper-alt",
  "pied-piper-pp",
  "pinterest",
  "pinterest-p",
  "pinterest-square",
  "plane",
  "play",
  "play-circle",
  "play-circle-o",
  "plug",
  "plus",
  "plus-circle",
  "plus-square",
  "plus-square-o",
  "podcast",
  "power-off",
  "print",
  "product-hunt",
  "puzzle-piece",
  "qq",
  "qrcode",
  "question",
  "question-circle",
  "question-circle-o",
  "quora",
  "quote-left",
  "quote-right",
  "ra",
  "random",
  "ravelry",
  "rebel",
  "recycle",
  "reddit",
  "reddit-alien",
  "reddit-square",
  "refresh",
  "registered",
  "remove",
  "renren",
  "reorder",
  "repeat",
  "reply",
  "reply-all",
  "resistance",
  "retweet",
  "rmb",
  "road",
  "rocket",
  "rotate-left",
  "rotate-right",
  "rouble",
  "rss",
  "rss-square",
  "rub",
  "ruble",
  "rupee",
  "s15",
  "safari",
  "save",
  "scissors",
  "scribd",
  "search",
  "search-minus",
  "search-plus",
  "sellsy",
  "send",
  "send-o",
  "server",
  "share",
  "share-alt",
  "share-alt-square",
  "share-square",
  "share-square-o",
  "shekel",
  "sheqel",
  "shield",
  "ship",
  "shirtsinbulk",
  "shopping-bag",
  "shopping-basket",
  "shopping-cart",
  "shower",
  "sign-in",
  "sign-language",
  "sign-out",
  "signal",
  "signing",
  "simplybuilt",
  "sitemap",
  "skyatlas",
  "skype",
  "slack",
  "sliders",
  "slideshare",
  "smile-o",
  "snapchat",
  "snapchat-ghost",
  "snapchat-square",
  "snowflake-o",
  "soccer-ball-o",
  "sort",
  "sort-alpha-asc",
  "sort-alpha-desc",
  "sort-amount-asc",
  "sort-amount-desc",
  "sort-asc",
  "sort-desc",
  "sort-down",
  "sort-numeric-asc",
  "sort-numeric-desc",
  "sort-up",
  "soundcloud",
  "space-shuttle",
  "spinner",
  "spoon",
  "spotify",
  "square",
  "square-o",
  "stack-exchange",
  "stack-overflow",
  "star",
  "star-half",
  "star-half-empty",
  "star-half-full",
  "star-half-o",
  "star-o",
  "steam",
  "steam-square",
  "step-backward",
  "step-forward",
  "stethoscope",
  "sticky-note",
  "sticky-note-o",
  "stop",
  "stop-circle",
  "stop-circle-o",
  "street-view",
  "strikethrough",
  "stumbleupon",
  "stumbleupon-circle",
  "subscript",
  "subway",
  "suitcase",
  "sun-o",
  "superpowers",
  "superscript",
  "support",
  "table",
  "tablet",
  "tachometer",
  "tag",
  "tags",
  "tasks",
  "taxi",
  "telegram",
  "television",
  "tencent-weibo",
  "terminal",
  "text-height",
  "text-width",
  "th",
  "th-large",
  "th-list",
  "themeisle",
  "thermometer",
  "thermometer-0",
  "thermometer-1",
  "thermometer-2",
  "thermometer-3",
  "thermometer-4",
  "thermometer-empty",
  "thermometer-full",
  "thermometer-half",
  "thermometer-quarter",
  "thermometer-three-quarters",
  "thumb-tack",
  "thumbs-down",
  "thumbs-o-down",
  "thumbs-o-up",
  "thumbs-up",
  "ticket",
  "times",
  "times-circle",
  "times-circle-o",
  "times-rectangle",
  "times-rectangle-o",
  "tint",
  "toggle-down",
  "toggle-left",
  "toggle-off",
  "toggle-on",
  "toggle-right",
  "toggle-up",
  "trademark",
  "train",
  "transgender",
  "transgender-alt",
  "trash",
  "trash-o",
  "tree",
  "trello",
  "tripadvisor",
  "trophy",
  "truck",
  "try",
  "tty",
  "tumblr",
  "tumblr-square",
  "turkish-lira",
  "tv",
  "twitch",
  "twitter",
  "twitter-square",
  "umbrella",
  "underline",
  "undo",
  "universal-access",
  "university",
  "unlink",
  "unlock",
  "unlock-alt",
  "unsorted",
  "upload",
  "usb",
  "usd",
  "user",
  "user-circle",
  "user-circle-o",
  "user-md",
  "user-o",
  "user-plus",
  "user-secret",
  "user-times",
  "users",
  "vcard",
  "vcard-o",
  "venus",
  "venus-double",
  "venus-mars",
  "viacoin",
  "viadeo",
  "viadeo-square",
  "video-camera",
  "vimeo",
  "vimeo-square",
  "vine",
  "vk",
  "volume-control-phone",
  "volume-down",
  "volume-off",
  "volume-up",
  "warning",
  "wechat",
  "weibo",
  "weixin",
  "whatsapp",
  "wheelchair",
  "wheelchair-alt",
  "wifi",
  "wikipedia-w",
  "window-close",
  "window-close-o",
  "window-maximize",
  "window-minimize",
  "window-restore",
  "windows",
  "won",
  "wordpress",
  "wpbeginner",
  "wpexplorer",
  "wpforms",
  "wrench",
  "xing",
  "xing-square",
  "y-combinator",
  "y-combinator-square",
  "yahoo",
  "yc",
  "yc-square",
  "yelp",
  "yen",
  "yoast",
  "youtube",
  "youtube-play",
  "youtube-square"
];

/**
 * An array of ElementUI icon names
 * ? Includes prefixes
 */
export const elementUIIconNames = [
  "el-icon-platform-eleme",
  "el-icon-eleme",
  "el-icon-delete-solid",
  "el-icon-delete",
  "el-icon-s-tools",
  "el-icon-setting",
  "el-icon-user-solid",
  "el-icon-user",
  "el-icon-phone",
  "el-icon-phone-outline",
  "el-icon-more",
  "el-icon-more-outline",
  "el-icon-star-on",
  "el-icon-star-off",
  "el-icon-s-goods",
  "el-icon-goods",
  "el-icon-warning",
  "el-icon-warning-outline",
  "el-icon-question",
  "el-icon-info",
  "el-icon-remove",
  "el-icon-circle-plus",
  "el-icon-success",
  "el-icon-error",
  "el-icon-zoom-in",
  "el-icon-zoom-out",
  "el-icon-remove-outline",
  "el-icon-circle-plus-outline",
  "el-icon-circle-check",
  "el-icon-circle-close",
  "el-icon-s-help",
  "el-icon-help",
  "el-icon-minus",
  "el-icon-plus",
  "el-icon-check",
  "el-icon-close",
  "el-icon-picture",
  "el-icon-picture-outline",
  "el-icon-picture-outline-round",
  "el-icon-upload",
  "el-icon-upload2",
  "el-icon-download",
  "el-icon-camera-solid",
  "el-icon-camera",
  "el-icon-video-camera-solid",
  "el-icon-video-camera",
  "el-icon-message-solid",
  "el-icon-bell",
  "el-icon-s-cooperation",
  "el-icon-s-order",
  "el-icon-s-platform",
  "el-icon-s-fold",
  "el-icon-s-unfold",
  "el-icon-s-operation",
  "el-icon-s-promotion",
  "el-icon-s-home",
  "el-icon-s-release",
  "el-icon-s-ticket",
  "el-icon-s-management",
  "el-icon-s-open",
  "el-icon-s-shop",
  "el-icon-s-marketing",
  "el-icon-s-flag",
  "el-icon-s-comment",
  "el-icon-s-finance",
  "el-icon-s-claim",
  "el-icon-s-custom",
  "el-icon-s-opportunity",
  "el-icon-s-data",
  "el-icon-s-check",
  "el-icon-s-grid",
  "el-icon-menu",
  "el-icon-share",
  "el-icon-d-caret",
  "el-icon-caret-left",
  "el-icon-caret-right",
  "el-icon-caret-bottom",
  "el-icon-caret-top",
  "el-icon-bottom-left",
  "el-icon-bottom-right",
  "el-icon-back",
  "el-icon-right",
  "el-icon-bottom",
  "el-icon-top",
  "el-icon-top-left",
  "el-icon-top-right",
  "el-icon-arrow-left",
  "el-icon-arrow-right",
  "el-icon-arrow-down",
  "el-icon-arrow-up",
  "el-icon-d-arrow-left",
  "el-icon-d-arrow-right",
  "el-icon-video-pause",
  "el-icon-video-play",
  "el-icon-refresh",
  "el-icon-refresh-right",
  "el-icon-refresh-left",
  "el-icon-finished",
  "el-icon-sort",
  "el-icon-sort-up",
  "el-icon-sort-down",
  "el-icon-rank",
  "el-icon-loading",
  "el-icon-view",
  "el-icon-c-scale-to-original",
  "el-icon-date",
  "el-icon-edit",
  "el-icon-edit-outline",
  "el-icon-folder",
  "el-icon-folder-opened",
  "el-icon-folder-add",
  "el-icon-folder-remove",
  "el-icon-folder-delete",
  "el-icon-folder-checked",
  "el-icon-tickets",
  "el-icon-document-remove",
  "el-icon-document-delete",
  "el-icon-document-copy",
  "el-icon-document-checked",
  "el-icon-document",
  "el-icon-document-add",
  "el-icon-printer",
  "el-icon-paperclip",
  "el-icon-takeaway-box",
  "el-icon-search",
  "el-icon-monitor",
  "el-icon-attract",
  "el-icon-mobile",
  "el-icon-scissors",
  "el-icon-umbrella",
  "el-icon-headset",
  "el-icon-brush",
  "el-icon-mouse",
  "el-icon-coordinate",
  "el-icon-magic-stick",
  "el-icon-reading",
  "el-icon-data-line",
  "el-icon-data-board",
  "el-icon-pie-chart",
  "el-icon-data-analysis",
  "el-icon-collection-tag",
  "el-icon-film",
  "el-icon-suitcase",
  "el-icon-suitcase-1",
  "el-icon-receiving",
  "el-icon-collection",
  "el-icon-files",
  "el-icon-notebook-1",
  "el-icon-notebook-2",
  "el-icon-toilet-paper",
  "el-icon-office-building",
  "el-icon-school",
  "el-icon-table-lamp",
  "el-icon-house",
  "el-icon-no-smoking",
  "el-icon-smoking",
  "el-icon-shopping-cart-full",
  "el-icon-shopping-cart-1",
  "el-icon-shopping-cart-2",
  "el-icon-shopping-bag-1",
  "el-icon-shopping-bag-2",
  "el-icon-sold-out",
  "el-icon-sell",
  "el-icon-present",
  "el-icon-box",
  "el-icon-bank-card",
  "el-icon-money",
  "el-icon-coin",
  "el-icon-wallet",
  "el-icon-discount",
  "el-icon-price-tag",
  "el-icon-news",
  "el-icon-guide",
  "el-icon-male",
  "el-icon-female",
  "el-icon-thumb",
  "el-icon-cpu",
  "el-icon-link",
  "el-icon-connection",
  "el-icon-open",
  "el-icon-turn-off",
  "el-icon-set-up",
  "el-icon-chat-round",
  "el-icon-chat-line-round",
  "el-icon-chat-square",
  "el-icon-chat-dot-round",
  "el-icon-chat-dot-square",
  "el-icon-chat-line-square",
  "el-icon-message",
  "el-icon-postcard",
  "el-icon-position",
  "el-icon-turn-off-microphone",
  "el-icon-microphone",
  "el-icon-close-notification",
  "el-icon-bangzhu",
  "el-icon-time",
  "el-icon-odometer",
  "el-icon-crop",
  "el-icon-aim",
  "el-icon-switch-button",
  "el-icon-full-screen",
  "el-icon-copy-document",
  "el-icon-mic",
  "el-icon-stopwatch",
  "el-icon-medal-1",
  "el-icon-medal",
  "el-icon-trophy",
  "el-icon-trophy-1",
  "el-icon-first-aid-kit",
  "el-icon-discover",
  "el-icon-place",
  "el-icon-location",
  "el-icon-location-outline",
  "el-icon-location-information",
  "el-icon-add-location",
  "el-icon-delete-location",
  "el-icon-map-location",
  "el-icon-alarm-clock",
  "el-icon-timer",
  "el-icon-watch-1",
  "el-icon-watch",
  "el-icon-lock",
  "el-icon-unlock",
  "el-icon-key",
  "el-icon-service",
  "el-icon-mobile-phone",
  "el-icon-bicycle",
  "el-icon-truck",
  "el-icon-ship",
  "el-icon-basketball",
  "el-icon-football",
  "el-icon-soccer",
  "el-icon-baseball",
  "el-icon-wind-power",
  "el-icon-light-rain",
  "el-icon-lightning",
  "el-icon-heavy-rain",
  "el-icon-sunrise",
  "el-icon-sunrise-1",
  "el-icon-sunset",
  "el-icon-sunny",
  "el-icon-cloudy",
  "el-icon-partly-cloudy",
  "el-icon-cloudy-and-sunny",
  "el-icon-moon",
  "el-icon-moon-night",
  "el-icon-dish",
  "el-icon-dish-1",
  "el-icon-food",
  "el-icon-chicken",
  "el-icon-fork-spoon",
  "el-icon-knife-fork",
  "el-icon-burger",
  "el-icon-tableware",
  "el-icon-sugar",
  "el-icon-dessert",
  "el-icon-ice-cream",
  "el-icon-hot-water",
  "el-icon-water-cup",
  "el-icon-coffee-cup",
  "el-icon-cold-drink",
  "el-icon-goblet",
  "el-icon-goblet-full",
  "el-icon-goblet-square",
  "el-icon-goblet-square-full",
  "el-icon-refrigerator",
  "el-icon-grape",
  "el-icon-watermelon",
  "el-icon-cherry",
  "el-icon-apple",
  "el-icon-pear",
  "el-icon-orange",
  "el-icon-coffee",
  "el-icon-ice-tea",
  "el-icon-ice-drink",
  "el-icon-milk-tea",
  "el-icon-potato-strips",
  "el-icon-lollipop",
  "el-icon-ice-cream-square",
  "el-icon-ice-cream-round"
];

/**
 * Copies the specified text to the users clipboard
 * @param {string | number} str
 */
export const copyToClipboard = (str) => {
  // Create new element
  const el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

/**
 * Toggles fullscreen for entire document
 * the EL will default to the html tag
 * @param {HTMLElement} el
 */
export const goFullscreen = async (el) => {
  await (el || document.querySelector("html")).requestFullscreen({
    // navigationUI: 'hide'
  });

  console.log("fs closed");
};

export const DemoPolicyNumbers = [
  "NTU2000004",
  "NTU1002829",
  "NFP1001933",
  "NFP1003966",
  "NTU1001711",
  "NFP1001527",
  "NFP1000010",
  "NFP1004341",
  "NFP1004062",
  "NFP1005373",
  "NTU1004584",
  "NAA1009027",
  "NAA1007848",
  "NTU1000290",
  "NFP1001693",
  "NAA1005587",
  "NTU1006165"
];

export default {
  copyToClipboard
};
