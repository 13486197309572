// @ts-check

import actions from "./actions";
import globalMutators from "../settings/modules/ui/mutations";
import getters from "./getters";

/**
 * @type {() => neptune.policyholder.IDocumentState}
 */
export const state = () => ({
  documents: [],
  pdfs: [],
  missing: []
});

/**
 * @type {import("vuex").Module<neptune.policyholder.IDocumentState, neptune.IRootState>}
 */
const mod = {
  state,
  namespaced: true,
  actions: actions,
  mutations: {
    ...globalMutators
  },
  getters
};

export default mod;
