<template>
  <div>
    <div class="flex-1 text-center flex flex-col justify-center items-center rounded bg-gray-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mx-auto h-12 w-12 text-gray-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          vector-effect="non-scaling-stroke"
          fill-rule="evenodd"
          d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
          clip-rule="evenodd"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900" data-test="unauthorized.title">Unauthorized</h3>
      <p class="mt-1 text-sm text-gray-500" data-test="unauthorized.subtitle">You have not been authorized to view this page.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
