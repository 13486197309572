<template>
  <div>
    <div
      class="h-[350px] "
      :class="{
        'bg-deepsea-full': selectedOption === 'Flood',
        'bg-[#FFBBAF]': selectedOption === 'Earthquake'
      }"
    >
      <div class="flex flex-row py-4 w-full max-w-screen-xl mx-auto">
        <div class="flex-1 flex">
          <div class="">
            <img
              v-if="selectedOption === 'Flood'"
              src="@/assets/pngs/Neptune_Horizon_White.png"
              class="h-[100px] w-auto"
              alt="Flood Logo"
            />

            <img
              v-else-if="selectedOption === 'Earthquake'"
              src="../../assets/jumpstart.png"
              class="w-60 mt-8"
            />
          </div>
        </div>
      </div>
    </div>
    <section class="-mt-48">
      <div class="flex justify-start max-w-screen-xl mx-auto mb-4">
        <nav aria-label="Tabs" class="flex space-x-4">
          <button
            data-test="dashboard"
            aria-current="page"
            class="px-3 py-2 font-medium text-sm rounded-md border border-sky-700"
            :class="{
              'bg-sky-100 text-sky-700': selectedOption === 'Flood',
              'text-sky-700  hover:bg-sky-100 hover:text-sky-700': selectedOption === 'Earthquake'
            }"
            @click="selectedOption = 'Flood'"
          >
            Flood
</button
          ><button
            data-test="recentHistory"
            class="px-3 py-2 font-medium text-sm rounded-md  hover:bg-orange-100 hover:text-orange-700"
            :class="{
              'text-orange-700 bg-orange-100 border-orange-300 border': selectedOption === 'Flood',

              'bg-orange-100 text-orange-700': selectedOption === 'Earthquake'
            }"
            @click="selectedOption = 'Earthquake'"
          >
            Earthquake
          </button>
        </nav>
      </div>
      <div class="mx-auto z-10 w-full max-w-screen-xl">
        <transition
          mode="out-in"
          enter-class="transform opacity-0 translate-y-4"
          enter-active-class="transition ease-out duration-300"
          enter-to-class="transform opacity-100 translate-y-0"
          leave-class="transform opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-to-class="transform opacity-0 -translate-y-4"
        >
          <div v-if="filteredPolicies.length > 0" :key="selectedOption" class="space-y-2">
            <div v-for="policy in filteredPolicies" :key="policy.activePolicyNo" class="">
              <div class="p-6 md:flex bg-white rounded-xl border border-gray-200">
                <div class="map__container flex flex-col w-full">
                  <map-display
                    :key="policy.address"
                    :address="policy.address"
                    :zoom="19"
                    class="border-4 border-white shadow-md flex-1"
                  />
                </div>
                <div class="location-info__container py-4 md:px-4 md:py-0">
                  <span class="text-lg md:text-2xl text-gray-700">{{ policy.address }}</span>
                  <div class="flex flex-row pt-2">
                    <div class="w-1/4 flex flex-col text-md text-gray-600 space-y-2">
                      <span>Name</span>
                      <span>Premium</span>
                      <span>Policy Number</span>
                      <span>Status</span>
                    </div>
                    <div class="flex flex-col text-md text-gray-700 space-y-2">
                      <span>{{ policy.name }}</span>
                      <span>{{ policy.totalPremium | currency }}</span>
                      <span>{{ policy.activePolicyNo }}</span>
                      <span>{{ computedStatus(policy) }}</span>
                    </div>
                  </div>
                </div>
                <div class="location-actions__container space-y-2 max-w-md">
                  <button
                    type="button"
                    class="max-w-[214px] inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-lg font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ml-auto"
                    :class="{
                      'bg-deepsea-full hover:bg-deepsea-full/75': selectedOption === 'Flood',
                      'bg-brand-orange hover:bg-brand-orange/75': selectedOption === 'Earthquake'
                    }"
                    @click="viewPolicy(policy.activePolicyNo)"
                  >
                    View policy detail
                  </button>
                  <p
                    v-if="showHistoricalButton(policy)"
                    class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-lg font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  >
                    To view other policy terms for this address, access the policy using the button
                    above and view the 'Policy History’ list available on the policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else key="noPoliciesFound">
            <div
              class="px-6 py-20 bg-white rounded-xl border border-gray-200 text-center text-gray-500"
            >
              No policies found
            </div>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>
<script>
import {
  DateFiltersMixin,
  NumberFiltersMixin
} from "@/components/policy-wizard/utils/mixins/formatting";
import MapDisplay from "@/components/atoms/Map.vue";
import { reducePolicies } from "./utils";
import { compareDesc } from "date-fns";

export default {
  components: {
    MapDisplay
  },
  mixins: [DateFiltersMixin, NumberFiltersMixin],
  data() {
    return {
      policies: [],
      selectedOption: "Flood"
    };
  },
  computed: {
    filteredPolicies() {
      const data = reducePolicies(this.policies);
      let productIds = [1, 2, 3, 6, 7, 8];
      if (this.selectedOption === "Earthquake") productIds = [4, 5];
      const filtered = data.filter((location) => productIds.includes(location.productId));
      return filtered.sort((a, b) => b.policyId - a.policyId);
    }
  },
  mounted() {
    // reversing in order to show most recent first
    this.policies = [...this.$store?.state?.policy?.policyList].reverse();

    const { accountType } = this.$store.state.auth;
    let meta = {};

    if (accountType !== "policyholder") {
      const { AgentId, FirstName, LastName, Company, Email, Phone } = this.$store.state.auth.data;
      meta = {
        ...meta,
        agent_name: FirstName && LastName ? `${FirstName} ${LastName}` : Company,
        agent_number: AgentId,
        agent_email: Email,
        agent_phone: Phone,
        is_policy_holder: false
      };
    } else {
      const { email } = this.$store.state.auth.validateReturn;
      const [{ insuredName }] = [...this.$store.state.auth.validateReturn.policies].sort((a, b) =>
        compareDesc(a.inceptionDate, b.inceptionDate)
      );
      meta = {
        ...meta,
        policyholder_email: email,
        policyholder_name: insuredName,
        is_policy_holder: true
      };
    }

    // update ada meta
    this.$ada.setSensitiveMetaFields(meta);
    this.$ada.setMetaFields({
      quote_number: null,
      policy_number: null
    });
  },
  methods: {
    viewPolicy(policyNo) {
      this.$router.push({
        name: "policyholderDetail",
        path: `/policyholder/policies/${policyNo}`,
        params: {
          policyNo
        }
      });
    },
    showHistoricalButton(cardObj) {
      return cardObj.policies.length > 1;
    },
    computedStatus(loc) {
      if (!loc.policies || (Array.isArray(loc.policies) && !loc.policies.length)) {
        return "";
      }

      const [{ isCancelled, isExpired }] = loc.policies;

      if (isCancelled && isExpired) {
        return "Cancelled/Expired";
      } else if (isExpired) {
        return "Expired";
      } else if (isCancelled) {
        return "Cancelled";
      } else {
        return "Active";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.address {
  color: #4b5563;
}
.card-styles {
  border-bottom: 1px solid #ccc;
  padding: 2rem 1rem;
}
div {
  font-family: "Lato", sans-serif;
}

.list-button {
  float: right;
  font-size: 1.5rem;
  min-width: 240px;
  margin-top: -35px;
}

// location
.location__container {
  min-height: 6rem;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.location__container:not(:last-child) {
  border-bottom: 1px solid #d1d5db;
}

.location-info__container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.location-actions__container {
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 768px) {
    flex-direction: column;
  }
}

.location-action {
  background-color: #26a97b;
  border-color: #26a97b;
  border-radius: 0.25rem;
  // min-width: 240px;
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem 1rem;
  // on the base
  margin-left: 1rem;
  min-width: 240px;
  @media screen and (min-width: 768px) {
    margin-left: 0;
  }
}

.location-action.secondary {
  background-color: transparent;
  color: #555;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
}

.location-action:not(:last-child) {
  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
}

.location-action:first-child {
  margin-left: 0;
}

.map__container {
  @media screen and (min-width: 768px) {
    max-width: 16rem;
  }
}
</style>
