<template>
  <!-- cancel modal -->
  <div class="relative z-[2000]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <transition
        appear
        mode="out-in"
        enter-class="opacity-0"
        enter-active-class="ease-out duration-300"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-active-class="ease-in duration-200"
        leave-to-class="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          @click="$emit('close')"
        ></div>
      </transition>
      <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
        <transition
          appear
          mode="out-in"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-active-class="ease-out duration-300"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 id="modal-title" class="text-lg leading-6 font-medium text-gray-900" data-test="cancelModalTitle">
                  Policy Cancellation Request
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500" data-test="cancelModalMessage">
                    Midterm cancellations are now facilitated through our chat bot, Poseidon, for
                    review by our customer success team. To start a cancellation request for this
                    policy, simply click <strong>"Start Chat"</strong> and type
                    <strong>"Cancel"</strong> in the chat window that opens. We will request more
                    information and possibly supporting documentation to proceed.
                  </p>
                  <p class="text-sm text-gray-500 mt-4">
                    This is the fastest and easiest way to submit a cancellation request!
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                data-test="cancelModalStartChat"
                @click="
                  $ada.toggle();
                  $emit('close');
                "
              >
                Start Chat
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                data-test="cancelModalClose"
                @click="$emit('close')"
              >
                Cancel
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
};
</script>
