export function reducePolicies(policies) {
  if (!policies) return [];

  const locations = policies.reduce((starter, item) => {
    const key = item.addressId;

    if (starter[key]) {
      const current = starter[key];
      starter[key] = [...current, item];
    } else {
      starter[key] = [item];
    }

    return starter;
  }, {});

  // eslint-disable-next-line no-unused-vars
  return Object.entries(locations).map(([_, value]) => {
    const [activePolicy] = value;
    return {
      name: activePolicy.insuredName,
      address: activePolicy.address,
      productId: activePolicy.productId,
      activePolicyNo: activePolicy.policyNo,
      totalPremium: activePolicy.totalPremium,
      policies: value,
      inceptionDate: activePolicy.inceptionDate,
      policyId: activePolicy.policyId
    };
  });
}
