<template>
  <div class="fixed inset-0 z-[2000] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div
      class="bg-white max-w-md w-full relative z-10 p-6 rounded-md"
      data-test="reinstatementConfirmation"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class="text-gray-900 text-lg m-0 leading-none">
        Confirm Reinstatement
      </h1>
      <p class="text-gray-500">Are you sure you wish to reinstate?</p>

      <div class="flex justify-between items-center mt-8">
        <div v-if="$internal" class="flex flex-row sm:flex-1 items-center">
          <el-switch v-model="suppressValueReinstate" class="mr-3 mt-1" active-color="#004C9D" />
          <p
            v-if="suppressValueReinstate"
            class="text-gray-500 text-sm text-left"
            data-test="reinstateNotifsSuppressed"
          >
            Notifications suppressed
          </p>
          <p v-else class="text-gray-500 text-sm text-left" data-test="reinstateNotifsActive">
            Notifications active
          </p>
        </div>
        <div slot="footer" class="flex justify-end space-x-2">
          <button
            data-test="resetPasswordCancel"
            class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>

          <button
            type="submit"
            class="inline-flex items-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none min-w-[69px]"
            data-test="resetPassword"
            :class="{ 'cursor-not-allowed': loading }"
            :disabled="loading"
            @click="reinstatePolicy"
          >
            <span v-if="!loading">
              Confirm
            </span>
            <svg
              v-else
              class="animate-spin h-5 w-5 text-white mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    policyData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      suppressValueReinstate: true
    };
  },
  methods: {
    ...mapActions("policy", ["reinstatePolicyAction"]),
    async reinstatePolicy() {
      try {
        this.loading = true;
        await this.reinstatePolicyAction({
          policyNo: this.policyData.policyNo,
          suppress: this.suppressValueReinstate
        });
        this.$notify({
          title: "Success",
          message: "Policy has been successfully reinstated!",
          type: "success"
        });
        this.$root.$emit("refreshOnReinstate");
        this.$emit("close");
      } catch (err) {
        console.log(err);
        let message = "There was an error reinstating the policy.";
        if (err?.response?.data?.payload?.errors[0]?.message)
          message = err?.response?.data?.payload?.errors[0].message;
        this.$notify.error({
          title: "Error",
          message
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
