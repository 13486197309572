<template>
  <div class="abs-fill cancel-signatures flex flex-col">
    <h2 class="my-8 text-center" data-test="phCancelTitle">
      Policy Cancellation
    </h2>

    <div v-if="isPending && !submitting && !submitted">
      <signature-form-container
        :signatures="signatures"
        @changed="onSignComplete"
        @complete="signed = true"
      />

      <neptune-button
        v-if="signed"
        style="margin-left: 40%;"
        data-test="phCancelPolicyButton"
        @click="onCancelClick"
      >
        Cancel Policy
      </neptune-button>
    </div>

    <div
      v-else-if="isPending && submitted"
      class="text-center text-2xl"
      data-test="phCancelPendingMsg"
    >
      This policy is currently pending cancellation.
    </div>

    <div v-else-if="!isPending" class="flex flex-1 flex-col justify-center text-center">
      <h3 data-test="phCancelSigningComplete">
        Signing Complete
      </h3>

      <p data-test="phCancelSuccessful">
        You have successfully cancelled this policy.
      </p>
    </div>
  </div>
</template>

<script>
import SignatureFormContainerVue from "../../components/policy-wizard/steps/sign/SignatureFormContainer.vue";
import ActionButtonVue from "../../components/policy-wizard/components/ActionButton.vue";
export default {
  components: {
    SignatureFormContainer: SignatureFormContainerVue,
    NeptuneButton: ActionButtonVue
  },
  data() {
    return {
      signed: false,
      submitting: false,
      submitted: false,
      localSignatures: null,
      $w: () => null
    };
  },
  computed: {
    cancelDocument() {
      /** @type {neptune.IRootState} */
      const s = this.$store.state;

      const docs = s.auth.validateReturn?.policy?.policyDocStatus?.documentsWithSignatures || [];

      return docs.find((d) => d.document.docName.toLowerCase() === "cancel");
    },

    /** @type {() => neptune.documents.Signature[]} */
    signatures() {
      /** @type {neptune.documents.Signature[]} */
      const signatures = this.localSignatures;
      if (signatures) {
        return signatures.filter((v) => v.party === 2);
      }
    },
    /** @type {() => neptune.auth.PolicyDocStatus} */
    signaturePayload() {
      const policyNo = this.$store.getters.policyNo;
      const documentsWithSignatures = JSON.parse(
        JSON.stringify(this.$store.getters["document/documentsWithSignatures"])
      );

      if (this.signatures) {
        this.signatures.forEach((sig) => {
          const { policyDocId, policyDocSignatureId } = sig;

          const matchDoc = documentsWithSignatures.find(
            (v) => v.document.policyDocId === policyDocId
          );
          const matchSig = matchDoc.signatures.find(
            (sig) => sig.policyDocSignatureId === policyDocSignatureId
          );

          if (matchSig) {
            Object.assign(matchSig, sig);
          }
        });
      }

      return {
        policyNo,
        documentsWithSignatures,
        isPolicySigned: true,
        isPolicyPaid: false
      };
    },
    isPending() {
      return !!this.policyData.isPendingCancel;
    },
    canCancel() {
      return !!this.policyData.canCancelPolicy;
    },
    signComplete() {
      return this.signatures.every((v) => !!v.signedImage || !!v.policyDocSignatureId);
    },

    /** @type {() => neptune.IPolicyData} */
    policyData() {
      return this.$store.getters["policy/allData"];
    }
  },
  beforeMount() {
    this.$data.$w = this.$watch("cancelDocument", this.copySignatures, {
      immediate: true
    });
  },
  methods: {
    onSignComplete({ signatures }) {
      console.log(signatures);

      /** @type {neptune.documents.Signature[]} */
      const sigs = signatures;

      sigs.forEach((s) => {
        const { policyDocSignatureId } = s;

        const match = this.localSignatures.find(
          (s) => s.policyDocSignatureId === policyDocSignatureId
        );

        if (match) {
          Object.assign(match, s);
        }
      });

      // replace signatures in store
      this.$store.commit("auth/SET_PROP", {
        path: "validateReturn.policy.policyDocStatus.documentsWithSignatures",
        value: this.signaturePayload.documentsWithSignatures
      });

      this.signed = true;
    },
    async onCancelClick() {
      this.submitting = true;

      await this.$store.dispatch("document/postSignatures");

      this.submitting = false;

      this.submitted = true;

      this.signed = true;
    },
    copySignatures(doc) {
      if (doc.signatures) {
        this.$data.$w();

        this.localSignatures = JSON.parse(JSON.stringify(doc.signatures));
      }
    }
  }
};
</script>

<style></style>
