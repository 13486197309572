<template>
  <cms-layout v-bind="settings">
    <template #nav-footer>
      <div class="nav-footer flex items-center justify-center w-10">
        <a class="w-5 h-5 flex items-center content-center text-white" @click="fs()">
          <span v-if="!fullscreen" class="el-icon-full-screen" />
          <span v-else class="fa fa-compress" />
        </a>
      </div>
    </template>

    <template #cms-logo>
      <div class="py-4">
        <router-link to="/policies" class="flex items-center">
          <img src="/static/images/icons/shield.svg" class="w-10 pr-4" />

          <div class="cms-logo-text bold">
            <!-- <span class="text-pink-600">
              Neptune
            </span> -->
            <span class="text-blue-600 font-black text-lg uppercase">
              Poseidon
            </span>
          </div>
        </router-link>
      </div>
    </template>

    <template #header>
      <main-header class="w-full h-full" />
    </template>

    <keep-alive>
      <router-view />
    </keep-alive>
  </cms-layout>
</template>

<script>
import CmsLayoutVue from "../layouts/CmsLayout.vue";
import MainHeaderVue from "../components/header/MainHeader.vue";
import { goFullscreen } from "../utils";
export default {
  name: "AdminSection",
  components: {
    CmsLayout: CmsLayoutVue,
    MainHeader: MainHeaderVue
  },
  data() {
    return {
      fullscreen: document.fullscreen
    };
  },
  computed: {
    settings() {
      const {
        settings: {
          ui: {
            nav: { hidden: navHidden, fixed: navFixed, minified: navMinified },
            header: { fixed: headerFixed }
          }
        }
      } = this.$store.state;

      return {
        navHidden,
        navFixed,
        navMinified,
        headerFixed,
        theme: this.$attrs.theme || "light"
      };
    },

    /** @returns {{title: string, icon: string, description: string}} */
    meta() {
      return this.$route.meta;
    }
  },
  methods: {
    async fs() {
      if (document.fullscreen) {
        await document.exitFullscreen();
      } else {
        await goFullscreen();
      }

      this.fullscreen = document.fullscreen;
    }
  }
};
</script>

<style lang="scss" scoped>
.nav {
  &-footer {
    a {
      cursor: pointer;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
