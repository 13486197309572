import actions from "./actions";
import mutations from "./mutations";

export const state = () => ({
  addresses: []
});

/**
 * @type {import("vuex").Module}
 */
const mod = {
  state,
  namespaced: true,
  actions: actions,
  mutations: mutations
};

export default mod;
