export const someoneLivesHereProductTypes = [
  {
    name: "single_family",
    title: "One Home",
    description:
      "Single family home, single unit in building of any size, or an entire detached 2-4 family building.",
    icon: require("./assets/single_family.png"),
    scope: 1
  },
  {
    name: "rcbap",
    title: "Entire Residential Condo Building",
    description: "RCBAP - Requires condo form of ownership",
    icon: require("./assets/rcbap.png"),
    scope: 2
  },
  {
    name: "mixed_use",
    title: "Mixed Use Building",
    description: "Some residential and some commercial use in the same building. Entire Building.",
    icon: require("./assets/mixed_use.png"),
    scope: 4
  },
  {
    name: "apartment",
    title: "Apartment or Coop",
    description: "An entire apartment building or coop (not condo form of ownership)",
    icon: require("./assets/apartment.png"),
    scope: 8
  }
];
export const earthquakeProductTypes = [
  {
    name: "earthquake_residential",
    title: "Residential",
    description: "Somebody lives here",
    icon: require("./assets/jumpstart-logo-icon.png"),
    scope: 64
  },
  {
    name: "earthquake_commercial",
    title: "Commercial",
    description: "Nobody lives here",
    icon: require("./assets/jumpstart-logo-icon.png"),
    scope: 64
  }
];

export const nooneLivesHereProductTypes = [
  {
    name: "commercial",
    title: "Main Street Mercantile Commercial Buildings",
    description: "An entire office, retail, restaurant, industrial, other non-residential building",
    icon: require("./assets/commercial.png"),
    scope: 16
  },
  {
    name: "hotel",
    title: "Hotels, Motels and Inns",
    description: "Entire building",
    icon: require("./assets/hotel_motel_inn.png"),
    scope: 32
  }
];

export function getProductFromProductTypeName(type, params) {
  switch (type) {
    case "single_family":
      return 1;
    case "rcbap":
      return 3;
    case "apartment": {
      const unitsForBuilding = params.unitsForBuilding ?? 0;
      return unitsForBuilding < 5 ? 1 : 2;
    }
    case "mixed_use": {
      const unitsForBuilding = params.unitsForBuilding ?? 0;
      if (params.isResidential && unitsForBuilding < 5) return 1;
      // Otherwise fall-through and create a commercial quote.
      // this would indicate it is an not a condominium
      // form and the units are between 5 and 20.
    }
    // eslint-disable-next-line no-fallthrough
    case "commercial":
    case "hotel":
      return 2;
    case "earthquake_residential":
      return 4;
    case "earthquake_commercial":
      return 5;
    default:
      throw new Error(`getProductFromProductTypeName: ${type} is not supported`);
  }
}

export function getPropertyTypeFromProductType(type, params) {
  switch (type) {
    case "apartment": {
      const unitsForBuilding = params.unitsForBuilding ?? 0;
      if (unitsForBuilding < 5) {
        return "primaryHome";
      }
    }
    // eslint-disable-next-line no-fallthrough
    case "commercial":
    case "hotel":
      return type;
    default:
      return null;
  }
}

export function getProductType({ propertyType, numberOfUnits, productNo, mixedUseResidentialPct }) {
  switch (productNo) {
    case 1:
      if (numberOfUnits >= 1) {
        return "apartment";
      } else {
        return "single_family";
      }
    case 2:
      if (mixedUseResidentialPct > 0) {
        return "mixed_use";
      }
      return propertyType;
    case 3:
      return "rcbap";
    case 4:
      return "earthquake_residential";
    case 5:
      return "earthquake_commercial";
    case 6:
      if (numberOfUnits >= 1) {
        return "apartment";
      } else {
        return "single_family";
      }
    case 7:
      if (mixedUseResidentialPct > 0) {
        return "mixed_use";
      }
      return propertyType;
    case 8:
      return "rcbap";
    default:
      return "";
  }
}

export function getProductTypeInfo(name) {
  return [
    ...someoneLivesHereProductTypes,
    ...nooneLivesHereProductTypes,
    ...earthquakeProductTypes
  ].find((item) => item.name === name);
}
