import cloneDeep from "lodash/cloneDeep";
import store from "@/store";

/**
 * Construct an aggregated object from an array of objects
 * @param {{[key: string]: any}[]} items the array of objects
 * @param {string[]} fields the fields you want to aggregate
 */
export const aggregateObjectArray = (items, fields) => {
  if (!items?.length) {
    return null;
  }
  const baseItem = cloneDeep(items[0]);

  if (items.length > 1) {
    for (let i = 1; i < items.length; i++) {
      for (const prop in items[i]) {
        console.log(prop);

        if (fields.includes(prop)) {
          baseItem[prop] += +items[i][prop];
        } else {
          baseItem[prop] = items[i][prop];
        }
      }
    }
  }

  return baseItem;
};

/**
 * Remove vuex from localStorage
 */
export const clearLocalStorage = () => {
  window.localStorage.removeItem("vuex");
};

export const toggleInternal = () => {
  let roles = store.state.auth?.data?.Roles.split(",");

  if (roles.includes("internal")) {
    roles = roles.filter((r) => r !== "internal");
  } else {
    roles.push("internal");
  }

  store.commit("auth/SET_PROP", {
    path: "data.Roles",
    value: roles.join(",")
  });
};
