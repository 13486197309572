<template>
  <div>
    <section v-if="companyName">
      <label class="font-bold !text-sm !text-gray-500" data-test="insuredNameLabel"
        >Insured Name</label
      >
      <div class="weight-normal text-base select-text" data-test="insuredNameField">
        {{ companyName }}
      </div>
    </section>

    <!-- Applicant -->
    <section>
      <label class="font-bold !text-sm !text-gray-500" data-test="applicantLabel">Applicant</label>
      <div class="text-base select-text" data-test="applicantName">
        {{ applicant.name }}
      </div>
      <p class="text-base">
        <a
          data-test="applicantEmail"
          :href="`mailto:${applicant.email}`"
          class="!text-base !text-black !font-normal select-text"
          >{{ applicant.email }}</a
        >
      </p>
      <div class="text-base">
        <a
          data-test="applicantPhone"
          :href="`tel:${applicant.phone}`"
          class="!text-base !text-black !font-normal select-text"
          >{{ applicant.phone | formatPhone }}</a
        >
      </div>
    </section>

    <!-- CoApplicant -->
    <section v-if="coApplicant">
      <label class="font-bold !text-sm !text-gray-500" data-test="coApplicantLabel"
        >Co-Applicant</label
      >
      <div
        v-if="!coApplicant.removed"
        class="weight-normal select-text"
        data-test="coApplicantName"
      >
        {{ coApplicant.name }}

        <div v-if="coApplicant.email" data-test="coApplicantEmail" class="text-base">
          <a
            class="!text-base !text-black !font-normal select-text"
            :href="`mailto:${coApplicant.email}`"
            >{{ coApplicant.email }}</a
          >
        </div>
        <div v-if="coApplicant.phone" data-test="coApplicantPhone" class="text-base">
          <a
            class="!text-base !text-black !font-normal select-text"
            :href="`tel:${coApplicant.phone}`"
            >{{ coApplicant.phone | formatPhone }}</a
          >
        </div>
      </div>
      <div v-else class="text-red-500 italic font-light">Removed</div>
    </section>

    <!-- Building Owner -->
    <section v-if="policyData.buildingOwner">
      <label class="font-bold !text-sm !text-gray-500" data-test="buildingOwnerLabel"
        >Building Owner</label
      >
      <div class="!text-base !text-black select-text" data-test="buildingOwnerField">
        {{ policyData.buildingOwner }}
      </div>
    </section>

    <!-- Mailing Address -->
    <div>
      <label class="font-bold !text-sm !text-gray-500" data-test="mailingAddressLabel"
        >Mailing Address</label
      >
      <div class="weight-normal text-base select-text" data-test="mailingAddress1">
        {{ mailingAddress.line1 }}
      </div>
      <div
        v-if="mailingAddress.address2"
        class="weight-normal text-base select-text"
        data-test="mailingAddress1"
      >
        {{ mailingAddress.address2 }}
      </div>
      <div data-test="mailingAddress2" class="text-base select-text">
        {{ mailingAddress.line2 }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatPhone } from "../../../utils/TextFormatting";
export default {
  filters: {
    formatPhone: formatPhone
  },
  props: {
    /**
     * @type {Vue.PropType<neptune.IPolicyContactDiffInfo>}
     */
    keyedObject: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * @type {Vue.ComputedOptions<any>}
     */
    applicant() {
      const { firstName, lastName, email, phone } = this.keyedObject;

      return {
        name: `${firstName.values.new} ${lastName.values.new}`,
        email: email.values.new,
        phone: phone.values.new
      };
    },

    /**
     * @type {Vue.ComputedOptions<any>}
     */
    coApplicant() {
      const {
        coApplicantFirstName,
        coApplicantLastName,
        coApplicantEmail,
        coApplicantPhone
      } = this.keyedObject;

      const {
        values: { old: oldF, new: newF }
      } = coApplicantFirstName;

      const {
        values: { old: oldL, new: newL }
      } = coApplicantLastName;

      const {
        values: { new: newE }
      } = coApplicantEmail;

      const {
        values: { new: newP }
      } = coApplicantPhone;

      if (newF === "" && newL === "") {
        return "";
      }

      return {
        name: `${newF === null ? "" : newF} ${newL === null ? "" : newL}`,
        email: `${newE === null ? "" : newE}`,
        phone: `${newP === null ? "" : newP}`,
        removed: (oldF || oldL) && !(newF || newL)
      };
    },

    companyName() {
      return this.keyedObject.companyName.values.new;
    },

    /**
     * @type {Vue.ComputedOptions<any>}
     */
    mailingAddress() {
      const {
        mailingState,
        mailingZip,
        mailingAddr1,
        mailingAddr2,
        mailingCity,
        mailingCountry
      } = this.keyedObject;

      return {
        line1: `${mailingAddr1.values.new}`,
        line2: `${mailingCity.values.new}, ${mailingState.values.new} ${mailingZip.values.new} ${mailingCountry.values.new}`,
        address2: `${mailingAddr2.values.new}`,
        changed:
          mailingState.diff ||
          mailingZip.diff ||
          mailingAddr1.diff ||
          mailingAddr2.diff ||
          mailingCity.diff ||
          mailingCountry.diff
      };
    },

    policyData() {
      return this.$store.getters["policy/allData"];
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.email-max-width {
  max-width: 300px;
  width: 300px;
  overflow: auto;
}

.weight-normal {
  font-weight: normal;
}
</style>
