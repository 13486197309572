import { Constants } from "@/config";
import { authAxios } from "@/store/modules/auth";

// import Axios from "axios";
import http from "../../../components/policy-wizard/store-module/axiosInstance";

export const asyncTimeout = (delay) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, delay);
  });
};

/**
 * @type {import('vuex').ActionTree<neptune.commercial.ICommercialState, neptune.IRootState>}
 */
const actions = {
  // eslint-disable-next-line no-unused-vars
  async getLockBoxUnapplied({ getters }, body) {
    const response = await authAxios.post(Constants.GET_LOCKBOX_UNAPPLIED, body);
    return response.data;
  },
  // eslint-disable-next-line no-unused-vars
  async postSingleLockbox({ commit }, body) {
    const response = await authAxios.post(Constants.POST_SINGLE_LOCKBOX_UPDATE, body);
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async getLockBoxAddress({ getters }, policynumber) {
    const response = await authAxios.get(
      Constants.GET_LOCKBOX_INFO + policynumber,
      this._vm.axiosConfig
    );
    return response.data.payload;
  },

  // eslint-disable-next-line no-unused-vars
  async postCheckPayment({ commit }, { policyNo, checkBody }) {
    const response = await authAxios.post(
      Constants.POST_CHECK_PAYMENT.replace("{{policyNo}}", policyNo),
      checkBody
    );
    return response.data.payload.data;
  },
  // eslint-disable-next-line no-unused-vars
  async getPaymentMethodData({ commit, rootState }, { policyNo, axiosConfig }) {
    // const { UserId } = getters;
    try {
      const { data } = await http.get(
        // `${Constants.SAVED_PAYMENTMETHODS_URL}`
        Constants.SAVED_PAYMENTMETHODS_URL.replace("{{policyNo}}", policyNo),
        axiosConfig
      );

      commit("SET_PROP", {
        path: "paymentMethodData",
        value: data.payload || ""
      });

      return data;
    } catch (error) {
      return Promise.reject(new Error(error));
    }
  },

  // eslint-disable-next-line no-unused-vars
  async createPaymentIntent({ commit }, { policyNo, cardType, country, axiosConfig, payerName }) {
    try {
      const data = await http.post(
        // '/api/v1/payments/intent',
        Constants.INTENT_URL,
        {
          policyNo: policyNo,
          cardType,
          country,
          payerName
        },
        axiosConfig
      );

      if (!data) {
        return Promise.reject(
          Error(
            `${Constants.INTENT_URL} did not return any valid response. Please try a different credit card `
          )
        );
      }

      commit("SET_PROP", {
        path: "paymentIntentToken",
        value: data?.data?.payload?.clientSecret
      });

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePaymentIntent({ commit }, { existingPaymentMethodId, policyNo, cardType, country }) {
    try {
      const data = await http.put(
        // '/api/v1/payments/intent',
        Constants.INTENT_URL,
        {
          existingPaymentMethod: existingPaymentMethodId,
          policyNo: policyNo,
          cardType,
          country
        },
        this._vm.axiosConfig
      );

      if (!data) {
        return Promise.reject(
          Error(
            `${Constants.INTENT_URL} did not return any valid response. Please try a different credit card `
          )
        );
      }

      commit("SET_PROP", {
        path: "paymentIntentToken",
        value: data?.data?.payload?.clientSecret
      });

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * @deprecated Use confirmCardPayment Instead
   * https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment
   * @param {import('vuex').ActionContext<neptune.payment.ICreditCardState, neptune.IRootState>} param0
   * @param {neptune.payment.IStripePaymentParams} param1
   */
  async handleStripeCardPayment({ commit, state }, { stripe, cardNumber }) {
    const { paymentIntentToken } = state;

    const res = await stripe.handleCardPayment(paymentIntentToken, cardNumber, {});
    if (res.error) {
      return Promise.reject(res.error?.message ?? res.error);
    }

    commit("SET_PROP", {
      path: "stripePaymentData",
      value: res.paymentIntent || res
    });

    return res;
  },

  // todo: test to see if the payment was successful before calling this
  async savePaymentData({ state }, { policyNo, email, payerName, remember, axiosConfig }) {
    // const email = rootState.currentQuote?.quote?.application?.email
    const intentId = state.stripePaymentData.id;
    const amount_received = state.stripePaymentData.amount;
    const paymentMethodId = state.stripePaymentData.payment_method;
    const intentObject = state.stripePaymentData;

    /** @type {neptune.payment.ISavePaymentParams} */
    const payload = {
      policyNo: policyNo,
      amount: amount_received,
      intentId: intentId,
      paymentMethodId: paymentMethodId,
      email: email,
      payerName: payerName,
      saveForReuse: remember,
      responseData: intentObject
    };

    try {
      const res = await http.post(
        Constants.PAY_CARD_URL, // '/api/v1/payments/card',
        payload,
        axiosConfig
      );

      return res.data;
    } catch (error) {
      return Promise.reject(new Error(error));
    }
  },
  // eslint-disable-next-line no-unused-vars
  async transferPayment({ commit }, { body, amountParam, curPolicyNo, nextPolicyNo }) {
    try {
      const response = await authAxios.put(
        Constants.TRANSFER_PAYMENT_URL.replace("{{curPolicyNo}}", curPolicyNo)
          .replace("{{nextPolicyNo}}", nextPolicyNo)
          .replace("{{amount}}", amountParam),
        body,
        { headers: { "Content-Type": "application/json" } }
      );

      return response;
    } catch (error) {
      return {
        message: error.response?.data.payload.errors[0].message
      };
    }
  }
};

export default actions;
