/**
 * Check if a WKT POLYGON string is valid.
 * @param {string} wkt - The WKT POLYGON string.
 * @returns {boolean} - Whether wkt is a valid polygon wkt.
 */
export function isValidWkt(wkt) {
  return (
    typeof wkt === "string" && wkt.length > 0 && wkt.startsWith("POLYGON") && wkt.endsWith("))")
  );
}

/**
 * Build a single polygon from a partial WKY string
 * @param {string} coordinateString.
 * @returns {Array.<google.maps.LatLngLiteral>} - Array of coordinates.
 */
export function buildPolygon(coordinateString) {
  // Split the coordinate string into individual coordinates
  const coordinates = coordinateString.split(",").map((coord) => coord.trim());

  const path = [];
  coordinates.forEach((coord) => {
    const [lng, lat] = coord.split(" ").map(Number);
    if (!isNaN(lat) && !isNaN(lng)) {
      path.push({ lat: Number(lat), lng: Number(lng) });
    }
  });

  return path;
}

/**
 * Convert a WKT POLYGON string into an array of coordinates for Google Maps Polygon.
 * @param {string} wkt - The WKT POLYGON string.
 * @returns {Array.<google.maps.LatLngLiteral>} - Array of coordinates.
 */
export function wktToPolygon(wkt) {
  // Check if the input is a valid WKT POLYGON string
  if (!isValidWkt(wkt)) return [];

  // Extract the coordinate part of the WKT string
  const coordinateString = wkt
    .replace("POLYGON ((", "")
    .replace("))", "")
    .trim(); // Remove "POLYGON ((" and "))"

  if (coordinateString.includes("),(")) {
    const polygons = coordinateString.split("),(");
    const path = [];
    polygons.forEach((polygon) => {
      path.push(buildPolygon(polygon));
    });

    return path;
  }

  return buildPolygon(coordinateString);
}
