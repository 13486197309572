import store from "@/store";
// import router from '@/router/index'

/** @type {import('vue-router').RouteConfig[]} */
const routes = [
  {
    path: "/agents/new",
    name: "newAgent",
    props: (r) => r.params,
    component: () => import(/* webpackChunkName: "NewAgent" */ "@/views/user/admin/NewAgent.vue"),
    meta: {
      title: "New Agent",
      isHiddenFromSideNav: true,
      theme: "neptune"
    },
    beforeEnter(to, from, next) {
      if (store.getters["auth/internal"]) {
        next();
      } else {
        next("/profile");
      }
    }
  },
  {
    path: "/agents/:parentId/new",
    name: "newSubAgent",
    props: true,
    component: () =>
      import(/* webpackChunkName: "NewSubAgent" */ "@/views/user/admin/NewAgent.vue"),
    meta: {
      title: "New Sub Agent",
      isHiddenFromSideNav: true,
      theme: "neptune"
    },
    beforeEnter(to, from, next) {
      if (store.getters["auth/internal"]) {
        next();
      } else {
        next("/profile");
      }
    }
  }
];

export default routes;
