import {
    authAxios
} from "@/store/modules/auth";
import {
    Constants
} from "@/config";

function unwrapError(data) {
    if (Array.isArray(data.Errors) && data.Errors.length) {
        const [firstError] = data.Errors;
        return firstError.Error;
    }
    return "An unknown error occurred";
}

/** @type {import("vuex").ActionTree} */
export const actions = {
    async fetchRegistrations({ commit }, agentNo) {
        try {
            const response = await authAxios.get(
                `${Constants.BASE_TRITON_URL}/api/v4/agent/widgets/${agentNo}` // <-- changed to `widgets`
            );
            commit("SET_PROP", {
                path: "registrations",
                value: response.data.Data
            });
        } catch (e) {
            console.error(e.message);
            const reason = unwrapError(e.response?.data);
            if (reason === "No Active Registration Found") {
                commit("SET_PROP", {
                    path: "registrations",
                    value: []
                });
            }
            throw new Error(`Unable to fetch the widget registration - ${reason}`);
        }
    },
    async updateRegistration({ commit }, {
        Disclosure,
        ReferralEmail,
        ReferralPhone,
        UseReferralEmailOnLeads,
        Logo,
        Id,
        Format,
        ReferralWebsite,
        Name,
        agentNo,
        index
    }) {
        try {
            const response = await authAxios.post(`${Constants.BASE_TRITON_URL}/api/v4/agent/widget`, {
                Id,
                AgentNo: agentNo,
                ReferralEmail,
                ReferralPhone,
                UseReferralEmailOnLeads,
                Disclosure,
                Logo,
                Format,
                ReferralWebsite,
                Name
            });
            commit("UPDATE_REGISTRATION", {
                index,
                newRegistration: response.data.Data
            });
            return response.data.Data;
        } catch (e) {
            let reason = e.message;
            console.error(e.message);
            if (e.response) reason = unwrapError(e.response?.data);
            throw new Error(`Unable to update the widget registration - ${reason}`);
        }
    },
    async deleteRegistration({ commit }, { Id, index, agentNo }) {
        try {
            await authAxios.delete(`${Constants.BASE_TRITON_URL}/api/v4/agent/widget/${agentNo}/${Id}`);
            commit("REMOVE_REGISTRATION", {
                index
            });
        } catch (e) {
            let reason = e.message;
            console.error(e.message);
            if (e.response) reason = unwrapError(e.response?.data);
            throw new Error(`Unable to remove the widget registration - ${reason}`);
        }
    },
    setRegistration({ commit }, data) {
        commit("SET_REGISTRATION", data);
    },
    setAgentNo({ commit }, agentNo) {
        commit("SET_AGENT_NO", agentNo);
    }
};

export default actions;
