<template>
  <div class="embed-view" style="height: 100%">
    <TopHeader />
    <div class="pt-12" style="height: 100%">
      <router-view></router-view>
    </div>
    <Ie11Alert />
  </div>
</template>

<script>
import TopHeader from "@/components/header/TopHeader.vue";
import Ie11Alert from "@/components/ie11/ie11Alert.vue";
export default {
  components: { TopHeader, Ie11Alert }
};
</script>
