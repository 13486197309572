<template>
  <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg h-100 w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <div class="flex flex-col items-center justify-around gap-2">
        <p>
          Are you sure you wish to end this moratorium?
        </p>
        <p>Enter the stop date below:</p>
        <input v-model="stopDate" type="datetime-local" :picker-options="pickerOptions" />
      </div>

      <div slot="footer" class="flex justify-end space-x-2 mt-4">
        <button
          class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
          @click.prevent="$emit('close')"
        >
          Cancel
        </button>

        <button
          type="primary"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 min-w-[69px]"
          :class="{ 'cursor-not-allowed': loading }"
          :disabled="loading"
          @click="stopMoratorium"
        >
          <span v-if="!loading">
            Confirm
          </span>
          <svg
            v-else
            class="h-5 w-5 text-white animate-spin fill-brand-blue/50 inline-block"
            viewBox="0 0 101 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    moratorium: Object,
    loadData: Function
  },
  data() {
    return {
      stopDate: "",
      loading: false,
      pickerOptions: {
        disabledDate(date) {
          const yesterday = new Date();
          const tomorrow = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          tomorrow.setDate(tomorrow.getDate());
          tomorrow.setHours(23, 59, 59);
          return date <= yesterday || date >= tomorrow;
        }
      }
    };
  },
  methods: {
    ...mapActions("moratoriums", ["postMoratorium"]),
    async stopMoratorium() {
      try {
        this.loading = true;
        const [newStopDate] = new Date(this.stopDate).toISOString().split(".");
        this.moratorium.stopDate = newStopDate;
        this.moratorium.locations?.forEach((location) => {
          location.stopDate = newStopDate;
        });

        await this.postMoratorium(this.moratorium);
        this.loadData();
        this.$emit("close");
      } catch (e) {
        throw new Error(e);
      } finally {
        this.showStopModal = false;
        this.stopDate = "";
        this.loading = false;
      }
    }
  }
};
</script>
