import Axios from "axios";
import { asyncTimeout } from "@/utils/debug";
import { Constants } from "../../../config";

/**
 * @type {import('vuex').ActionTree<neptune.commercial.ICommercialState, neptune.IRootState>}
 */
const actions = {
  async sign({ state, rootState, commit }) {
    // await asyncTimeout(2000);

    // return true

    /** @type {neptune.commercial.IPolicySaveParams} */
    // const params = state.bindData.payload.policyDocStatus

    const params = rootState.auth.validateReturn.policyDocStatus;

    rootState.auth.validateReturn.policyDocStatus.isPolicySigned = true;
    state.bindData.payload.policyDocStatus.isPolicySigned = true;
    state.isPolicySigned = true;

    commit("SET_STATE", state);
    const val = await Axios.post(
      Constants.GET_SIGNATURES_URL,
      // '/api/v1/documents/signatures',
      params
      // rootState.auth.axiosConfig
    );

    return val;
  },

  async load({ state, commit }) {
    const {
      bindData: {
        payload: {
          policy: { policyNo }
        }
      }
    } = state;
    const { data } = await Axios.get(
      Constants.GET_DOCUMENTS_URL.replace("{{policyNo}}", policyNo)
      // `/api/v1/documents/${policyNo}`
    );
    commit("SET_PROP", {
      path: "bindData.payload.policyDocStatus",
      value: data
    });

    return data;
  },

  async pay({ state }) {
    await asyncTimeout(3000);
    console.log("pay", state);
    return true;
  }
};

export default actions;
