import store from "@/store";

export const routeToV2 = (param) => {
  const jwtToken = store.state.auth.token;
  const jwtTokenExpire = new Date(new Date().getTime() + 30 * 60000).getTime();
  const jwtTokenPlusFive = btoa(jwtToken + ".TD" + jwtTokenExpire);
  const baseUrl = `${process.env.VUE_APP_INTRANET_V2_URL}`;
  const authPath = `#/auth`;
  const v2URL = new URL(baseUrl + authPath + "/" + jwtTokenPlusFive + "?redirect_uri=" + param);

  const a = document.createElement("a");
  a.href = v2URL.href;
  a.click();
};
