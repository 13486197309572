<template>
  <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class="text-gray-900 text-lg  m-0 leading-none">
        <span class="font-medium">Reset Password for </span>
        <span class="font-bold ">{{ company }}</span>
      </h1>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form class="mt-4" @submit.prevent="handleSubmit(submit)">
          <ValidationProvider
            v-slot="{ errors }"
            name="Password"
            rules="required|min:8|max:100|containsSpecialCharacter|containsUppercase|containsLowercase|containsDigit"
          >
            <div class="mb-4">
              <label for="Password" class="text-sm text-gray-700  leading-none"
                >Enter a new password</label
              >
              <div class="relative">
                <input
                  v-model="password"
                  name="Password"
                  :type="passwordFieldType"
                  placeholder="Enter your new password"
                  data-test="resetPasswordNew"
                  class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                />
                <svg
                  v-if="passwordFieldType === 'password'"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 absolute right-[5px] top-1/2 -translate-y-1/2 cursor-pointer text-gray-400 hover:text-gray-900"
                  @click="
                    () => {
                      passwordFieldType = passwordFieldType === 'password' ? 'text' : 'password';
                    }
                  "
                >
                  <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                  <path
                    fill-rule="evenodd"
                    d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 absolute right-[5px] top-1/2 -translate-y-1/2 cursor-pointer text-gray-400 hover:text-gray-900"
                  @click="
                    () => {
                      passwordFieldType = passwordFieldType === 'password' ? 'text' : 'password';
                    }
                  "
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.28 2.22a.75.75 0 00-1.06 1.06l14.5 14.5a.75.75 0 101.06-1.06l-1.745-1.745a10.029 10.029 0 003.3-4.38 1.651 1.651 0 000-1.185A10.004 10.004 0 009.999 3a9.956 9.956 0 00-4.744 1.194L3.28 2.22zM7.752 6.69l1.092 1.092a2.5 2.5 0 013.374 3.373l1.091 1.092a4 4 0 00-5.557-5.557z"
                    clip-rule="evenodd"
                  />
                  <path
                    d="M10.748 13.93l2.523 2.523a9.987 9.987 0 01-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 010-1.186A10.007 10.007 0 012.839 6.02L6.07 9.252a4 4 0 004.678 4.678z"
                  />
                </svg>
              </div>
              <FadeTranslate>
                <p v-if="errors[0]" class="text-red-500 text-sm m-0">{{ errors[0] }}</p>
              </FadeTranslate>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Confirm Password"
            rules="required|min:8|max:100|confirmed:Password|containsSpecialCharacter|containsUppercase|containsLowercase|containsDigit"
          >
            <div class="mb-4">
              <label for="confirmPassword" class="text-sm text-gray-700 leading-none"
                >Confirm password</label
              >
              <div class="relative">
                <input
                  v-model="confirmPassword"
                  name="confirmPassword"
                  :type="passwordConfirmationFieldType"
                  placeholder="Confirm your new password"
                  data-test="resetPasswordConfirm"
                  class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                />
                <svg
                  v-if="passwordConfirmationFieldType === 'password'"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 absolute right-[5px] top-1/2 -translate-y-1/2 cursor-pointer text-gray-400 hover:text-gray-900"
                  @click="
                    () => {
                      passwordConfirmationFieldType =
                        passwordConfirmationFieldType === 'password' ? 'text' : 'password';
                    }
                  "
                >
                  <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                  <path
                    fill-rule="evenodd"
                    d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-5 h-5 absolute right-[5px] top-1/2 -translate-y-1/2 cursor-pointer text-gray-400 hover:text-gray-900"
                  @click="
                    () => {
                      passwordConfirmationFieldType =
                        passwordConfirmationFieldType === 'password' ? 'text' : 'password';
                    }
                  "
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.28 2.22a.75.75 0 00-1.06 1.06l14.5 14.5a.75.75 0 101.06-1.06l-1.745-1.745a10.029 10.029 0 003.3-4.38 1.651 1.651 0 000-1.185A10.004 10.004 0 009.999 3a9.956 9.956 0 00-4.744 1.194L3.28 2.22zM7.752 6.69l1.092 1.092a2.5 2.5 0 013.374 3.373l1.091 1.092a4 4 0 00-5.557-5.557z"
                    clip-rule="evenodd"
                  />
                  <path
                    d="M10.748 13.93l2.523 2.523a9.987 9.987 0 01-3.27.547c-4.258 0-7.894-2.66-9.337-6.41a1.651 1.651 0 010-1.186A10.007 10.007 0 012.839 6.02L6.07 9.252a4 4 0 004.678 4.678z"
                  />
                </svg>
              </div>
              <FadeTranslate>
                <p v-if="errors[0]" class="text-red-500 text-sm m-0">{{ errors[0] }}</p>
              </FadeTranslate>
            </div>
          </ValidationProvider>
          <div slot="footer" class="flex justify-end space-x-2">
            <button
              data-test="resetPasswordCancel"
              class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              @click.prevent="$emit('close')"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="ml-auto inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-deepsea-full hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              data-test="resetPassword"
              :class="{ 'cursor-not-allowed': loading }"
              :disabled="loading"
              @click="handleSubmit(submit)"
            >
              <span v-if="!loading">
                Reset
              </span>
              <svg
                v-else
                class="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

import { ValidationProvider, ValidationObserver, setInteractionMode, extend } from "vee-validate";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";
import { authAxios } from "@/store/modules/auth";
import config from "@/config";
setInteractionMode("eager");

extend("containsSpecialCharacter", {
  validate(value) {
    if (value) return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);

    return false;
  },
  message: "Must contain at least one special character"
});

extend("containsUppercase", {
  validate(value) {
    if (value) return /[A-Z]/.test(value);

    return false;
  },
  message: "Must contain at least one uppercase character"
});

extend("containsLowercase", {
  validate(value) {
    if (value) return /[a-z]/.test(value);

    return false;
  },
  message: "Must contain at least one lowercase character"
});

extend("containsDigit", {
  validate(value) {
    if (value) return /[0-9]/.test(value);

    return false;
  },
  message: "Must contain at least one digit"
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FadeTranslate
  },
  props: {
    company: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      serverError: "",
      loading: false,
      passwordFieldType: "password",
      passwordConfirmationFieldType: "password"
    };
  },

  methods: {
    async submit() {
      try {
        this.serverError = "";
        this.loading = true;

        await authAxios.put(config.expObj.CHANGE_PASSWORD_URL, {
          user_name: this.$store.getters["userdata/agentNo"],
          new_pass_word: this.password,
          re_new_pass_word: this.confirmPassword
        });

        Swal.fire({
          text: "Password reset successfully!",
          icon: "success",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });

        this.$emit("close");
      } catch (e) {
        this.serverError = e.response.data;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
