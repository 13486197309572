<template>
  <div class="flex-1 flex flex-col">
    <div v-if="loading && !hasError" class="flex-1 flex flex-col justify-center items-center">
      <lottie-viewer path="/static/lottie/preloader3.json" :loop="true" />
    </div>
    <PolicyWizard
      v-if="!loading && !error"
      :policy-no="policyNo"
      :party="party"
      :auth-token="token"
      :is-modal="false"
      @payComplete="onPayComplete"
    >
    </PolicyWizard>

    <div v-if="hasError" class="flex flex-col justify-start items-center flex-1 pt-24">
      <div class="rounded-md bg-red-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-red-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-red-800">
              Hmm... we had an issue loading policy {{ policyNo }}
            </h3>
            <div class="mt-2 text-sm text-red-700">
              <ul role="list" class="list-disc pl-5 space-y-1">
                <li>{{ error.toString() }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LottieViewer from "@/components/policy-wizard/components/LottieViewer.vue";
import PolicyWizard from "../policy-wizard/index.vue";

export default {
  components: {
    LottieViewer,
    PolicyWizard
  },
  props: {
    policyNo: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      error: undefined,
      fakeError: true
    };
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    party() {
      return this.$store.getters["auth/accountType"] === "policyholder" ? 1 : 2;
    },
    hasError() {
      return !!this.error;
    }
  },
  async mounted() {
    try {
      this.error = undefined;

      await this.getPolicyDetails(this.policyNo);

      this.loading = false;
    } catch (e) {
      this.error = e;
    }
  },
  methods: {
    ...mapActions("policy", ["getPolicyDetails"]),
    ...mapGetters("policy", ["allData"]),
    async onPayComplete() {
      try {
        await this.getPolicyDetails(this.policyNo);
      } catch (e) {
        this.error = e;
      }
    }
  }
};
</script>
