import CmsLayout from "@/views/AdminSection.vue";
import PaymentRoutes from "./payments/routes";
import PolicyRoutes from "./policies/routes";
import LockboxRoutes from "./lockbox/routes";
export default {
  path: "/billing",
  meta: {
    title: "Billing"
  },
  component: CmsLayout,
  children: [...PaymentRoutes, ...PolicyRoutes, ...LockboxRoutes]
};
