import { Constants } from "@/config";
import { authAxios } from "@/store/modules/auth";
import { stripeInstance } from "@/components/policy-wizard/store-module/modules/payments";

/**
 * @type {import('vuex').ActionTree<neptune.payment.ach.IAchState, neptune.IRootState>}
 */
const actions = {
  async createToken({ state, commit }) {
    const { token, error } = await stripeInstance.default.createToken(
      "bank_account",
      state.stripeOptions
    );

    if (error) {
      return new Error(error.message);
    }

    if (token) {
      commit("SET_PROP", {
        path: "achToken",
        value: token
      });
    }

    return token;
  },

  async chargeAch({ rootState, state }, existingPaymentMethodId) {
    const policyNo = rootState.commericial.bindData.payload.policy.policyNo;
    const bankToken = state.achToken.id;

    const data = await authAxios.post(Constants.PAY_ACH_URL, {
      policyNo: policyNo,
      bankToken: bankToken,
      // email: email,
      existingPaymentMethod: existingPaymentMethodId
    });

    if (data.statusCode !== 200) {
      return Promise.reject(new Error(data.message));
    }

    return data;
  }
};

export default actions;
