import * as dataUtils from "./data";

/**
 * ! Should only be used if node env is dev
 */
Object.assign(window, {
  d3: require("d3"),
  _: require("lodash"),
  diff: require("deep-object-diff"),
  ...dataUtils
});

export const asyncTimeout = (delay) => {
  // eslint-disable-next-line promise/param-names
  return new Promise((res) => {
    setTimeout(() => {
      res(true);
    }, delay);
  });
};
