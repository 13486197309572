<template>
  <div class="step2 overflow-auto">
    <SignatureFormContainer
      v-if="serverSignatures"
      class="slim"
      :signatures="serverSignatures"
      :can-change-use-name-text="true"
      @changed="serverSignatures = $event.signatures"
      @complete="onComplete"
    />

    <div
      v-if="cancelFound === false"
      class="not-found text-2xl font-black text-red-600"
      data-test="cancelSigNone"
    >
      There are no cancel documents to sign. Please click on the "Cancel Policy" button to continue.
    </div>
  </div>
</template>

<script>
import SignatureFormContainerVue from "../../../policy-wizard/steps/sign/SignatureFormContainer.vue";
import { authAxios } from "../../../../store/modules/auth";
import { Constants } from "../../../../config";
// import { demoSignatures } from '../../../policy-wizard/steps/sign/models'
export default {
  components: {
    SignatureFormContainer: SignatureFormContainerVue
  },
  data: () => ({
    show: true,
    $success: false,

    /** @type {neptune.documents.Signature[]} */
    serverSignatures: null,
    cancelFound: null,
    loading: true,
    allDocuments: []
  }),
  computed: {
    userType() {
      return this.$route.meta?.userType || 1;
    },

    agent() {
      return this.userType === 1;
    },

    signComplete() {
      /** @type {neptune.documents.Signature[]} */
      const signatures = this.serverSignatures || [];

      return signatures.every((s) => !!s.signedByName);
    },
    postPayload() {
      /** @type {neptune.policyholder.PolicyDocStatus} */
      const pl = {
        policyNo: this.$store.getters["policy/allData"].policyNo,
        documentsWithSignatures: this.allDocuments,
        isPolicySigned: false,
        isPolicyPaid: false
      };

      return pl;
    }
  },
  watch: {
    signComplete(v) {
      if (v) {
        /** @type {neptune.documents.DocumentWithSignature} */
        const cancelDoc = this.allDocuments.find(
          (d) => +d.document.docTypeId === 8 || +d.document.docTypeId === 12
        );

        this.serverSignatures.forEach((s) => {
          const { policyDocSignatureId } = s;

          const match = cancelDoc.signatures.find(
            (v) => v.policyDocSignatureId === policyDocSignatureId
          );

          if (match) {
            Object.assign(match, s);
          }
        });
        this.$emit("success", true);
      }
    }
  },
  mounted() {
    this.loadSignatures();
  },
  methods: {
    onComplete(model) {
      this.$data.$success = true;
      this.newModel = model;
      this.$emit("success", this.newModel);
    },
    async loadSignatures() {
      /** @type {neptune.documents.DocumentWithSignature[]} */
      // this.allDocuments = await this.$store.dispatch("policy/getDocumentsWithSignatures");
      this.allDocuments = this.$store.getters["document/unsignedDocs"];
      const cancelDoc = this.allDocuments.find(
        (d) => +d.document.docTypeId === 8 || +d.document.docTypeId === 12
      );

      this.serverSignatures = cancelDoc?.signatures.filter((s) => s.party === this.userType);
      // debugger

      this.cancelFound = !!cancelDoc;
      this.loading = false;

      if (!cancelDoc) {
        this.$emit("success", true);
      }
    },

    async submit() {
      const val = await authAxios.post(
        Constants.GET_SIGNATURES_URL,
        // '/api/v1/documents/signatures',
        this.postPayload
        // rootState.auth.axiosConfig
      );

      return val;
    }
  }
};
</script>

<style lang="scss" scoped>
.step2 {
  width: 800px !important;
  height: 500px !important;
  max-width: 100%;
  max-height: 100%;
  position: relative;

  ::v-deep .popup > * {
    max-width: 800px;
  }

  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slim {
    padding: 0;

    ::v-deep .signature-container {
      padding: 0;

      .signature-inner {
        margin: 0;
      }
    }
  }
}
</style>
