<template>
  <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        data-test="resendEmail.close"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class=" text-gray-900 text-lg  m-0 leading-none">
        <span class="font-medium" data-test="resendEmail.title">Resend Policy Link </span>
      </h1>
      <p class="text-sm text-gray-500 mt-2" data-test="resendEmail.messageBody">
        This will send a new policy link to the insured at the email address we have on file ({{
          policyData.email
        }}). Using the link, the insured will automatically be logged into their policy and prompted
        for any missing signatures and/or payments before being directed to the policy detail page.
      </p>
      <p class="text-sm text-gray-500 mt-4" data-test="resendEmail.changeText">
        To send this link to a different email address, please enter it here:
      </p>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form class="mt-4" @submit.prevent="handleSubmit(submit)">
          <ValidationProvider v-slot="{ errors }" name="Email" rules="email">
            <div class="mb-4">
              <label for="Email" class="text-sm text-gray-700  leading-none">Email</label>
              <div class="relative">
                <input
                  v-model="email"
                  name="Email"
                  type="email"
                  placeholder="example@email.com"
                  class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2"
                  :class="{
                    'border-red-500': errors[0],
                    'border-gray-300': !errors[0]
                  }"
                  data-test="resendEmail.changeInput"
                />
              </div>
              <FadeTranslate>
                <p v-if="errors[0]" class="text-red-500 text-sm m-0" data-test="resendEmail.error">
                  {{ errors[0] }}
                </p>
              </FadeTranslate>
            </div>
          </ValidationProvider>

          <div slot="footer" class="flex justify-end space-x-2">
            <button
              type="button"
              data-test="resendEmail.cancel"
              class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              @click.prevent="$emit('close')"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="inline-flex items-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none focus:ring-2 focus:deepsea-medium focus:ring-offset-2 min-w-[69px]"
              data-test="resendEmail.sendLink"
              :class="{ 'cursor-not-allowed': loading }"
              :disabled="loading"
              @click="handleSubmit(submit)"
            >
              <span v-if="!loading">
                Send Link
              </span>
              <svg
                v-else
                class="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver, setInteractionMode } from "vee-validate";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";
setInteractionMode("eager");

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FadeTranslate
  },
  data() {
    return {
      email: "",
      serverError: "",
      loading: false
    };
  },
  computed: {
    policyData() {
      return this.$store.getters["policy/allData"];
    }
  },

  methods: {
    ...mapActions("policy", {
      sendNewLinkAxios: "sendNewLinkAxios"
    }),
    async submit() {
      try {
        this.loading = true;
        const emailToSend = this.email === "" ? this.policyData.email : this.email;
        await this.sendNewLinkAxios({ policyNo: this.$route.params.policyNo, email: emailToSend });
        Swal.fire({
          text: `Email successfully sent to ${emailToSend}!`,
          icon: "success",
          showConfirmButton: true,
          confirmButtonClass:
            "!bg-deepsea-full !border !border-deepsea-full hover:!bg-deepsea-full/75 focus:!ring-deepsea-medium focus:!ring-2 focus:!ring-offset-1"
        });
        this.$emit("close");
      } catch (e) {
        Swal.fire({
          title: "Error!",
          text: "There was an error sending the email:" + e?.response?.data?.message,
          icon: "error",
          showConfirmButton: true
        });
        this.$emit("close");
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
