<template>
  <div class="finalize-cancel flex flex-1 flex-col abs-fill">
    <!-- Policy Wizard -->
    <policy-wizard
      v-if="policyNo"
      :auth-token="token"
      class="h-full w-full"
      :party="party"
      :policy-no="policyNo"
      step="cancel"
    >
      <template #cancel>
        <cancel-signatures v-if="userNeededActions.sign" />

        <div v-else-if="pending" class="status-display abs-fill flex justify-center items-center">
          <!-- Status -->
          <div class="text-3xl">
            <p v-if="agentNeededActions.sign && agentNeededActions.upload">
              As soon as your agent signs and uploads a required document, your policy will be
              cancelled. You will receive an email and a mailed confinnation of cancellation.
            </p>
            <p v-else-if="agentNeededActions.sign">
              As soon as your agent signs the cancellation notice, your policy will be cancelled.
              You will receive an email and a mailed confirmation of cancellation.
            </p>
            <p v-else-if="agentNeededActions.upload">
              As soon as your agent uploads a required document, your policy will be cancelled. You
              will receive an email and a mailed confirmation of cancellation.
            </p>
            <p v-else>
              Both you and the agent have completed the remaining tasks prior to cancelling. <br />
              Please wait while we finish processing this cancellation. This could take a couple
              minutes.
            </p>
          </div>
        </div>

        <div v-else class="status-display abs-fill flex justify-center items-center">
          <!-- Status -->
          <div class="text-3xl">
            Your policy is cancelled. You will recieve an email and a mailed confirmation of
            cancellation.
          </div>
        </div>
      </template>
    </policy-wizard>
  </div>
</template>

<script>
import PolicyWizard from "@/components/policy-wizard/index.vue";
import { mapGetters } from "vuex";
import PolicyHolderCancelSignatureVue from "./PolicyHolderCancelSignature.vue";
import store from "../../store";
export default {
  components: {
    PolicyWizard,
    CancelSignatures: PolicyHolderCancelSignatureVue
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    /** @type {() => neptune.getters.IRootGetters} */
    rootGetters() {
      return this.$store.getters;
    },
    policyNo() {
      /** @type {neptune.getters.IRootGetters} */
      const s = this.$store.getters;

      return s.policyNo;
    },
    pending() {
      return !!this.rootGetters["policy/allData"]?.isPendingCancel;
    },
    policyState() {
      return this.$store.state[this.policyNo] || this.$store.state.policy;
    },
    party() {
      return 2;
    },
    agentNeededActions() {
      return {
        sign: !this.rootGetters["document/isSigned"]?.agent,
        upload: this.rootGetters["policy/cancelReason"]?.policyCancelDocs?.length
          ? this.rootGetters["document/unsignedDocs"]?.length > 0
          : false
      };
    },

    /**
     * TODO: UPLOADS CANNOT DIFFERENTIATE WHICH USER NEEDS TO UPLOAD
     * upload with be the exact same as agent
     */
    userNeededActions() {
      return {
        sign: !this.rootGetters["document/isSigned"]?.insured,
        upload: this.rootGetters["policy/cancelReason"]?.policyCancelDocs?.length
          ? this.rootGetters["document/unsignedDocs"]?.length > 0
          : false
      };
    },
    ...mapGetters("auth", {
      token: "policyHolderToken"
    })
  },
  mounted() {
    this.w = this.$watch("policyNo", this.policyWatch, {
      immediate: true
    });
  },
  methods: {
    async loadAllData() {
      this.loading = true;
      await store.dispatch("policy/getPolicyDetails", this.policyNo);
      this.loading = false;
    },
    policyWatch(val) {
      if (val) {
        if (this.w) this.w();
        this.loadAllData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.status-display {
  margin: auto;
  text-align: center;
  padding: 1rem;
  max-width: 800px;
}
</style>
