/**
 * d3.format(".0%")(0.123);  // rounded percentage, "12%"
 * d3.format("($.2f")(-3.5); // localized fixed-point currency, "(£3.50)"
 * d3.format("+20")(42);     // space-filled and signed, "                 +42"
 * d3.format(".^20")(42);    // dot-filled and centered, ".........42........."
 * d3.format(".2s")(42e6);   // SI-prefix with two significant digits, "42M"
 * d3.format("#x")(48879);   // prefixed lowercase hexadecimal, "0xbeef"
 * d3.format(",.2r")(4223);  // grouped thousands with two significant digits, "4,200"
 *
 * https://github.com/d3/d3-format
 */

import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

/**
 * These mixins contain filters
 * that turns strings and numbers into
 * common formatting for things such as currency
 * or large numbers such as 1K or 1.5M
 *
 * This also includes formatting for
 * dates and times
 */

const defaultFormatters = {
  currency: format("($,.2f"),
  currencySI: format("($,.2s"),
  dateLong: timeFormat("%A, %b %e, %Y"),
  dateLongNoWeekDay: timeFormat("%b %e, %Y"),
  dateShort: timeFormat("%a, %b %e, %Y"),
  dateShortSimple: timeFormat("%m/%d/%Y"),
  dateShortSimpleWithTime: timeFormat("%m/%d/%Y %I:%M %p"),
  currencyNoCents: format("($,")
};

/**
 * mixins for number formatting
 */
export const NumberFiltersMixin = {
  filters: {
    currency: (val) => {
      if (isNaN(+val)) {
        return val;
      }
      return val === 0 ? "-" : defaultFormatters.currency(val);
    },
    currencySI: (val) => (val === 0 ? "-" : defaultFormatters.currencySI(val)),
    currencyNoCents: (val) => (val === 0 ? "-" : defaultFormatters.currencyNoCents(val)),
    shortenCents: (val) => val.replace(".00", "")
  }
};

/**
 * Collection of filters for date formatting
 */
export const DateFiltersMixin = {
  filters: {
    longDate: (val) => defaultFormatters.dateLong(Date.parse(val)),
    longDateNoWeekDay: (val) => defaultFormatters.dateLongNoWeekDay(Date.parse(val)),
    shortDate: (val) => defaultFormatters.dateShort(Date.parse(val)),
    shortDateSimple: (val) => defaultFormatters.dateShortSimple(Date.parse(val)),
    shortDateSimpleWithTime: (val) =>
      defaultFormatters.dateShortSimpleWithTime(Date.parse(val.endsWith("Z") ? val : val + "Z")) // adding a Z because it's GMT
  }
};

export const StringFilters = {
  filters: {
    titleCase: (val) => {
      return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
    },
    underscoreString: (val) => {
      const splitArr = val.split("_");
      return splitArr.map((x) => x.charAt(0).toUpperCase() + x.substr(1).toLowerCase()).join(" ");
    },
    splitUnderscore: (val) => {
      const [result] = val.split("_").reverse();
      if (result.includes(".")) {
        return result.slice(result.indexOf(".") + 1);
      }
      return result === "pdf" ? result.toUpperCase() : result;
    }
  }
};
