var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"flex flex-col"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"File"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, validate }){return [_c('input',{ref:"file",staticClass:"file:mr-4 file:py-2 file:px-4 file:rounded-full file:!border-2 file:!border file:!border-sky-200 file:text-sm file:font-semibold file:bg-sky-50 file:text-sky-700 file:border-solid hover:file:bg-sky-100 mt-5 text-slate-500 cursor-pointer",class:{
              'border-red-500': errors[0],
              'border-gray-300': !errors[0]
            },attrs:{"type":"file","data-test":"fileSelector"},on:{"change":function($event){return _vm.selectFile($event, validate)}}}),_c('FadeTranslate',[(errors && errors.length && !_vm.selectedFile)?_c('p',{staticClass:"text-xs text-red-500 text-xs"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":'required',"name":"Document Type"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"text-sm text-gray-700 leading-none block mt-5 mb-1"},[_c('span',[_vm._v("Select document type")]),_c('span',{staticClass:"text-red-500"},[_vm._v("*")])]),(_vm.isEarthquake)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.docType),expression:"docType"}],staticClass:"focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2",class:{
              'border-red-500': errors[0],
              'border-gray-300': !errors[0]
            },attrs:{"id":"selectDocType","placeholder":"Document Type","data-test":"docTypeSelector-a"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.docType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.jsDocTypesArray),function(item,index){return _c('option',{key:index,attrs:{"data-test":`docTypeA-${item.type}`},domProps:{"value":item.type}},[_vm._v(" "+_vm._s(item.type)+" ")])}),0):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.docType),expression:"docType"}],staticClass:"focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2",class:{
              'border-red-500': errors[0],
              'border-gray-300': !errors[0]
            },attrs:{"id":"selectDocType","placeholder":"Document Type","data-test":"docTypeSelector-a"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.docType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.filteredDocumentTypes),function(item,index){return _c('option',{key:index,attrs:{"data-test":`docTypeA-${item.type}`},domProps:{"value":item.type}},[_vm._v(" "+_vm._s(item.type)+" ")])}),0),_c('FadeTranslate',[(errors && errors.length)?_c('p',{staticClass:"text-xs text-red-500 text-xs"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"mt-2 mb-5"},[_c('ValidationProvider',{staticClass:"mb-5",attrs:{"rules":"required","name":"Document Name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"text-sm text-gray-700 leading-none block mt-5 mb-1"},[_c('span',[_vm._v("Enter a document name")]),_c('span',{staticClass:"text-red-500"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.friendlyName),expression:"friendlyName"}],staticClass:"focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none !rounded-md sm:text-sm border border-gray-300 pl-2.5 py-2",class:{
              'border-red-500': errors[0],
              'border-gray-300': !errors[0]
            },attrs:{"id":"filename","type":"text","data-test":"documentNameInput"},domProps:{"value":(_vm.friendlyName)},on:{"input":function($event){if($event.target.composing)return;_vm.friendlyName=$event.target.value}}}),_c('FadeTranslate',[(errors && errors.length)?_c('p',{staticClass:"text-xs text-red-500 text-xs"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"flex space-x-2 justify-end mt-4"},[_c('button',{staticClass:"inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2",class:{
            'focus:ring-brand-darkorange': _vm.isEarthquake
          },attrs:{"data-test":"cancelDocButton"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 min-w-[69px] justify-center",class:{
            '!text-brand-orange !bg-brand-orange !border !border-brand-orange hover:!bg-brand-darkorange !text-white focus:ring-brand-darkorange': _vm.isEarthquake,
            'cursor-not-allowed': _vm.loading
          },attrs:{"disabled":_vm.loading,"type":"primary","data-test":"uploadDocButton"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[(!_vm.loading)?_c('span',[_vm._v("Upload")]):_c('svg',{staticClass:"animate-spin h-5 w-5 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})])])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }