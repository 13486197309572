<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="submit">
      <transition
        mode="out-in"
        enter-class="transform opacity-0 translate-x-4"
        enter-active-class="transition ease-out duration-300"
        enter-to-class="transform opacity-100 translate-x-0"
        leave-class="transform opacity-100 translate-x-0"
        leave-active-class="transition ease-in duration-150"
        leave-to-class="transform opacity-0 -translate-x-4"
      >
        <ValidationProvider
          v-if="step === 1"
          ref="emailOrPolicyNo"
          key="emailOrPolicyNo"
          v-slot="{ errors }"
          rules="required|emailOrPolicyNo"
          name="E-mail or Policy"
          tag="div"
        >
          <div class="mt-5 sm:flex">
            <div>
              <label for="emailOrPolicyNo" class="sr-only">Email or Policy Number</label>
              <input
                id="emailOrPolicyNo"
                v-model="emailOrPolicyNo"
                name="emailOrPolicyNo"
                class="block w-full py-3 rounded-md placeholder:text-gray-400 shadow-sm focus:!ring-blue-200 focus:!border-blue-200 sm:flex-1 border !border-blue-300 px-3 sm:min-w-[320px] text-jetblack-full"
                data-test="emailOrPolicyNo"
                placeholder="Email or policy number"
              />

              <FadeTranslate>
                <div v-if="errors" class="text-red-500 text-sm mt-1">{{ errors[0] }}</div>
              </FadeTranslate>
            </div>
            <div>
              <button
                type="submit"
                class="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-deepsea-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:!ring-blue-200 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto cursor-pointer transition duration-150"
                data-test="emailPolicySubmit"
                :class="{
                  'opacity-50': errors[0] || emailOrPolicyNo.length === 0,
                  'hover:bg-deepsea-full/75': !errors[0] && emailOrPolicyNo.length > 0
                }"
              >
                Next
              </button>
            </div>
          </div>
        </ValidationProvider>

        <ValidationProvider
          v-else-if="step === '2a'"
          ref="phone"
          key="phoneInput"
          v-slot="{ errors }"
          class="relative"
          rules="required|phone"
          name="Phone Number"
          tag="div"
        >
          <!-- <div
            class="text-sm text-gray-400 mb-1 cursor-pointer hover:text-white duration-150"
            @click="
              step = 1;
              $emit('setStep', 1);
            "
          >
            Back
          </div> -->
          <div>
            <label for="phone" class="block mb-0.5 text-jetblack-medium">Phone Number</label>
            <div class="sm:flex">
              <div class="relative">
                <input
                  id="phone"
                  v-model="phone"
                  type="phone"
                  name="phone"
                  class="block w-full py-3 rounded-md placeholder:text-gray-400 shadow-sm focus:!ring-blue-200 focus:!border-blue-200 sm:flex-1 border !border-blue-300 px-3 sm:min-w-[320px] text-jetblack-full"
                  data-test="phone"
                  placeholder="888-888-8888"
                  @keydown="enforceFormat"
                  @keyup="formatToPhone"
                />
                <FadeTranslate>
                  <div v-if="errors" class="text-red-500 text-sm mt-1">{{ errors[0] }}</div>
                </FadeTranslate>
              </div>

              <div>
                <button
                  type="submit"
                  class="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:!ring-blue-200 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto cursor-pointer"
                  data-test="phoneSubmit"
                  :class="{
                    'opacity-50': errors[0] || phone.length === 0,
                    'hover:bg-deepsea-full/75': !errors[0] && phone.length > 0
                  }"
                >
                  <span v-if="!loading">Submit</span>
                  <div v-else class="flex space-x-1 items-center">
                    <svg
                      class="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Processing...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider
          v-if="step === '2b'"
          ref="zipcode"
          key="zipcode"
          v-slot="{ errors }"
          rules="required|min:5|max:5"
          name="Zip Code"
          tag="div"
        >
          <div class="mt-5 sm:flex">
            <div>
              <label for="zipcode" class="sr-only">Zip Code</label>
              <input
                id="zipcode"
                v-model="zipcode"
                name="zipcode"
                class="block w-full py-3 rounded-md placeholder:text-gray-400 shadow-sm focus:!ring-blue-200 focus:!border-blue-200 sm:flex-1 border !border-blue-300 px-3 sm:min-w-[320px] text-jetblack-full"
                placeholder="Zip Code"
              />

              <FadeTranslate>
                <div v-if="errors" class="text-red-500 text-sm mt-1">{{ errors[0] }}</div>
              </FadeTranslate>
            </div>
            <div>
              <button
                type="submit"
                class="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-deepsea-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:!ring-blue-200 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto cursor-pointer transition duration-150"
                :class="{
                  'opacity-50': errors[0] || zipcode.length === 0,
                  'hover:bg-gray-900': !errors[0] && zipcode.length > 0
                }"
              >
                <span v-if="!loading">Submit</span>
                <div v-else class="flex space-x-1 items-center">
                  <svg
                    class="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Processing...</span>
                </div>
              </button>
            </div>
          </div>
        </ValidationProvider>
      </transition>
    </form>
  </ValidationObserver>
</template>

<script>
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from "vee-validate";

setInteractionMode("eager");
extend("emailOrPolicyNo", {
  validate(value) {
    if (value.includes("@")) {
      // eslint-disable-next-line no-useless-escape
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      );
    } else if (value.length >= 10) return true;
  },
  message: "Please use a valid email or policy number"
});

extend("phone", {
  validate(value) {
    return /\d{3}-\d{3}-\d{4}/.test(value);
  },
  message: "Please use a valid phone number in ###-###-#### format"
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FadeTranslate
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emailOrPolicyNo: "",
      phone: "",
      zipcode: "",
      step: 1
    };
  },

  methods: {
    isNumericInput(event) {
      const key = event.keyCode;
      return (
        (key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    },
    isModifierKey(event) {
      const key = event.keyCode;
      return (
        event.shiftKey === true ||
        key === 35 ||
        key === 36 || // Allow Shift, Home, End
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 46 || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        // Allow Ctrl/Command + A,C,V,X,Z
        ((event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
      );
    },
    formatToPhone(event) {
      if (this.isModifierKey(event)) return;

      const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) event.target.value = `${areaCode}-${middle}-${last}`;
      else if (input.length > 3) event.target.value = `${areaCode}-${middle}`;
      else if (input.length > 0) event.target.value = `${areaCode}`;
    },
    enforceFormat(event) {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if (!this.isNumericInput(event) && !this.isModifierKey(event)) event.preventDefault();
    },
    async submit() {
      switch (this.step) {
        case 1: {
          const { valid } = await this.$refs.emailOrPolicyNo.validate();

          if (!valid) return;

          const isEmail = this.emailOrPolicyNo.includes("@");

          if (isEmail) {
            this.step = "2a";
            this.$emit("setStep", "2a");
          } else {
            this.step = "2b";
            this.$emit("setStep", "2b");
          }
          break;
        }
        case "2a": {
          const { valid } = await this.$refs.phone.validate();
          if (valid) {
            this.$emit("submit", {
              email: this.emailOrPolicyNo,
              phone: this.phone,
              policyNumber: null,
              zipcode: null
            });
          }
          break;
        }
        case "2b": {
          const { valid } = await this.$refs.zipcode.validate();
          if (valid) {
            this.$emit("submit", {
              email: null,
              phone: null,
              policyNumber: this.emailOrPolicyNo,
              zipcode: this.zipcode
            });
          }
          break;
        }
      }
    }
  }
};
</script>
