<template>
  <div v-if="showAlert && isIE11 && !$insured" class="sticky flex">
    <p>
      Support for Internet Explorer 11 - the browser you are currently using - will be deprecated
      soon. Please use one of our officially supported browsers (<a
        href="https://www.google.com/chrome/"
        target="_blank"
        class="none"
        >Chrome</a
      >,
      <a class="none" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>,
      <a class="none" href="https://www.microsoft.com/en-us/edge" target="_blank">Edge</a>).
    </p>
    <span class="el-icon-close" @click="closeAlert" />
  </div>
</template>
<script>
export default {
  name: "Ie11",
  data() {
    return {
      showAlert: true
    };
  },
  computed: {
    isIE11() {
      return !!window.MSInputMethodContext && !!document.documentMode;
    }
  },
  mounted() {
    if (localStorage.getItem("ie11Alert")) {
      if (new Date(localStorage.getItem("ie11Alert")) < new Date()) {
        this.showAlert = true;
      } else {
        this.showAlert = false;
      }
    } else {
      this.showAlert = true;
    }
  },
  methods: {
    closeAlert() {
      const date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("ie11Alert", date);
      this.showAlert = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: orangered;
  color: white;
  padding: 15px;
  width: 80%;
  margin-left: 10%;
  text-align: center;
  font-size: 20px;
  z-index: 1 !important;
  border-radius: 7px;
  .none {
    color: white;
  }
  span {
    cursor: pointer;
  }
}
</style>
