import { http } from "./../../axiosInstance";
import { Constants } from "../../../../../config";

/** @type {import("vuex").ActionTree<any, neptune.IRootState>} */
const actions = {
  // Load Initial Info
  async load({ state, commit, rootGetters }) {
    /** @type {ApiResponseWithPayload<neptune.payment.IPaymentMethod[]>} */
    const {
      data: { payload }
    } = await http.get(
      // '/api/v1/payments/paymentmethods'
      Constants.SAVED_PAYMENTMETHODS_URL.replace(
        "{{policyNo}}",
        rootGetters["policy/allData"].policyNo
      )
    );

    commit("SET_PROP", {
      path: "paymentMethods",
      value: payload
    });

    return state.paymentMethods;
  }
};

export default actions;
