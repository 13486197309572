import Axios from "axios";
import router from "@/router";
import store from "@/store";
import Swal from "@/utils/swal.js";
import { Constants } from "@/config";

export const http = Axios.create({});

const insuredRedirect = () => {
  router.push({ name: "generatePolicyEmail" });
};

const agentRedirect = () => {
  window.location.assign(Constants.AGENT_LOGIN);
};

http.interceptors.response.use(null, (error) => {
  let title = "Error";
  let message = error.message || error.response?.data;
  const statusCode = error.response.status;

  if (statusCode === 500) {
    message = "There was a problem with the server. 500 Error";
  }

  if (statusCode === 422) {
    return;
  }

  if (statusCode === 401) {
    title = "Unauthorized";
    message = "You are not authorized to view this page. Please log in and try again";
    const accountType = store.state.auth.accountType;
    const redirectUser = accountType === "policyholder" ? insuredRedirect : agentRedirect;
    store.commit("auth/CLEAR_AUTH");
    redirectUser();
    if (accountType === "policyholder") {
      Swal.fire({
        title: title,
        text: message,
        icon: "error",
        showConfirmButton: true
      });
    }

    return error;
  }

  Swal.fire({
    title: title,
    text: message,
    icon: "error",
    showConfirmButton: true
  });

  return error;
});

export default http;
