import set from "lodash/set";

/** @type {import("vuex").MutationTree} */
export const globalMutators = {
  SET_STATE(state, data) {
    Object.assign(state, data);
  },
  SET_PROP(state, { path, value }) {
    set(state, path, value);
  }
};

export default globalMutators;
