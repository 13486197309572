<template>
  <div>
    <div class="document-uploader flex items-end">
      <div class="flex-1">
        <file-browser-button multiple :disabled="disableAdding" @change="onChange">
          <span class="el-icon-folder-opened" /> Browse
        </file-browser-button>
        <div>
          Click to select
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileBrowserButtonVue from "./FileBrowserButton.vue";
import { authAxios } from "../../../store/modules/auth";
import { Constants } from "../../../config";
import { asyncTimeout } from "../../../utils/debug";
export default {
  components: {
    FileBrowserButton: FileBrowserButtonVue
  },
  filters: {
    asJSON: (str) => JSON.stringify(str, null, " ")
  },
  props: {
    maxDocuments: {
      type: Number,
      default: 10
    },
    maxDocumentSize: {
      type: Number,
      default: Infinity
    },

    url: {
      type: String
    },
    policyNo: {
      type: String,
      required: true
    },
    docType: {
      type: [String, Number],
      default: "6"
    }
  },
  data: () => ({
    /**
     * @type {File[]}
     */
    files: null,
    uploadedFiles: [],
    uploading: false
  }),
  computed: {
    fileMeta() {
      if (this.files) {
        return this.files.map((f) => {
          const { name, size, type } = f;

          const uploaded = this.uploadedFiles.includes(f);

          return {
            name,
            size,
            type,
            uploaded,
            file: f
          };
        });
      }
    },
    filesLeft() {
      if (this.fileMeta) {
        return this.fileMeta.filter((fm) => !fm.uploaded);
      }
    },
    disableAdding() {
      return this.files && this.files.length === this.maxDocuments;
    },
    defaultUrl() {
      const policyNo = this.policyNo;
      const url = Constants.UPLOAD_URL.replace("{{policyNo}}", policyNo).replace(
        "{{docType}}",
        this.docType
      );
      return url;
    }
  },
  watch: {
    fileMeta: {
      handler(val) {
        if (!val || val.length === 0) {
          this.uploadedFiles = [];
        }
        this.$emit("change", val);
      }
    }
  },
  methods: {
    /**
     *
     * @param {File[]} files
     */
    onChange(files) {
      if (!files || !files.length) return;
      /** @type {File[]} */
      const curFiles = [];

      if (!this.files) {
        this.files = [];
      } else if (this.files.length >= this.maxDocuments) {
        return;
      }

      const trimmed = files.length > this.maxDocuments ? files.slice(0, this.maxDocuments) : files;

      // ? only add if it doesn't already exist
      trimmed.forEach((f) => {
        if (this.files.every((cf) => cf.name !== f.name)) {
          curFiles.push(f);
        }
      });

      this.files = [...(this.files || []), ...curFiles];
    },

    async uploadAll() {
      this.uploading = true;

      try {
        await Promise.all(this.files.map((p) => this.uploadFile(p)));
        await asyncTimeout(900);
        this.$emit("complete");
      } catch (error) {
        this.$emit("upload:error", error);
        return Promise.reject(new Error(error));
      } finally {
        this.uploading = false;
      }
    },

    /**
     *
     * @param {File} file
     */
    async uploadFile(file, description) {
      const fd = new window.FormData();
      fd.append(description || file.name, file, file.name);

      try {
        await authAxios.post(this.url || this.defaultUrl, fd);
        this.uploadedFiles.push(file);
        this.$emit("upload:file", file);
        return file;
      } catch (error) {
        this.$emit("upload:error", error);
        return Promise.reject(new Error(error));
      }
    },

    removeFile(file) {
      const i = this.files.indexOf(file);
      const i2 = this.uploadedFiles.indexOf(file);
      this.files.splice(i, 1);
      this.uploadedFiles.splice(i2, 1);
    }
  }
};
</script>
