import http from "@/components/policy-wizard/store-module/axiosInstance";
import { authAxios, initialState } from ".";

/** @type {import("vuex").MutationTree} */
const mutations = {
  SET_STATE(state, data) {
    Object.assign(state, data);
  },
  SET_TOKEN(state, token) {
    this._vm.$ada.setSensitiveMetaFields({
      auth_token: token
    });
    state.token = token;
    http.defaults.headers.Authorization = token;
    authAxios.defaults.headers.Authorization = token;
  },
  CLEAR_AUTH(state) {
    Object.assign(state, initialState);
  }
};

export default mutations;
