<template>
  <div
    class="main-app"
    :class="{ 'is-mobile': $mobile, 'is-desktop': !$mobile, [browser.name]: true }"
  >
    <!-- Router Entry -->

    <keep-alive>
      <transition
        name="fade"
        css
        mode="out-in"
        appear
        @appear="updateClasses()"
        @before-enter="updateClasses()"
        @enter="updateClasses()"
      >
        <router-view :theme="theme" />
      </transition>
    </keep-alive>
    <FinalizeCancellationModal
      v-if="finalizeCancellationModal.open"
      :open="finalizeCancellationModal.open"
      @close="finalizeCancellationModal.open = false"
    />
    <EndorsementTransactionModal
      v-if="endorsementTransactionModal.open"
      :transaction="endorsementTransactionModal.transaction"
      @close="endorsementTransactionModal.open = false"
    />
    <GroupedEndorsementModal
      v-if="groupedEndorsementModal.open"
      :row-data="groupedEndorsementModal.arr"
      @close="groupedEndorsementModal.open = false"
    />
    <TransitionModal>
      <TransferModal
        v-if="transferModal.open"
        :selected-transfer-payment="selectedTransferPayment"
        @close="transferModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <EndMoratoriumModal
        v-if="endMoratoriumModal.open"
        :moratorium="endMoratoriumModal.moratorium"
        :load-data="endMoratoriumModal.loadData"
        @close="endMoratoriumModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <ResetPasswordModal v-if="resetPasswordModal.open" @close="resetPasswordModal.open = false" />
    </TransitionModal>

    <TransitionModal>
      <ResetSeparateAccountPasswordModal
        v-if="resetSeparateAccountPasswordModal.open"
        :company="resetSeparateAccountPasswordModal.company"
        @close="resetSeparateAccountPasswordModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <ModifyRegistrationModal
        v-if="modifyRegistrationModal.open"
        :registration-index="modifyRegistrationModal.registrationIndex"
        @close="modifyRegistrationModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <RemoveRegistrationModal
        v-if="removeRegistrationModal.open"
        :registration-index="removeRegistrationModal.registrationIndex"
        @close="removeRegistrationModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <WidgetEmbedModal
        v-if="widgetEmbedModal.open"
        :registration-index="widgetEmbedModal.registrationIndex"
        @close="widgetEmbedModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <ReinstatementModal
        v-if="reinstatementModal.open"
        :policy-data="reinstatementModal.policyData"
        @close="reinstatementModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <UploadDocumentModal
        v-if="uploadDocumentModal.open"
        :selected-document-type="uploadDocumentModal.selectedDocumentType"
        @close="uploadDocumentModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <ResendEmailLinkModal
        v-if="resendEmailLinkModal.open"
        @close="resendEmailLinkModal.open = false"
      />
    </TransitionModal>

    <TransitionModal>
      <ChangeInceptionDateModal
        v-if="changeInceptionDateModal.open"
        :new-policy-number="changeInceptionDateModal.newPolicyNumber"
        @close="changeInceptionDateModal.open = false"
        @newPolicyNumber="changeInceptionDateModal.newPolicyNumber = $event"
      />
    </TransitionModal>

    <TransitionModal>
      <ChangeAgencyModal v-if="changeAgencyModal.open" @close="changeAgencyModal.open = false" />
    </TransitionModal>

    <TransitionModal>
      <EcReviewModal
        v-if="ecReview.open"
        :data="ecData"
        :file-obj="ecFile"
        @close="ecReview.open = false"
      />
    </TransitionModal>
  </div>
</template>

<script>
import FinalizeCancellationModal from "@/components/FinalizeCancellationModal.vue";
import TransferModal from "@/components/TransferModal.vue";
import ResetPasswordModal from "@/components/ResetPasswordModal.vue";
import ResetSeparateAccountPasswordModal from "@/components/ResetSeparateAccountPasswordModal.vue";
import EndMoratoriumModal from "@/components/EndMoratoriumModal";
import ModifyRegistrationModal from "@/components/ModifyRegistrationModal.vue";
import RemoveRegistrationModal from "@/components/RemoveRegistrationModal.vue";
import WidgetEmbedModal from "@/components/WidgetEmbedModal.vue";
import TransitionModal from "@/components/transitions/TransitionModal.vue";
import ReinstatementModal from "@/components/ReinstatementModal.vue";
import EndorsementTransactionModal from "@/components/EndorsementTransactionModal.vue";
import UploadDocumentModal from "@/components/UploadDocumentModal.vue";
import GroupedEndorsementModal from "./components/GroupedEndorsementModal.vue";
import ResendEmailLinkModal from "@/components/ResendEmailLinkModal.vue";
import ChangeInceptionDateModal from "@/components/ChangeInceptionDateModal.vue";
import ChangeAgencyModal from "@/components/ChangeAgencyModal.vue";
import EcReviewModal from "@/components/EcReviewModal.vue";

import { detect } from "detect-browser";

export default {
  components: {
    FinalizeCancellationModal,
    TransferModal,
    TransitionModal,
    ResetPasswordModal,
    ResetSeparateAccountPasswordModal,
    EndMoratoriumModal,
    ModifyRegistrationModal,
    RemoveRegistrationModal,
    WidgetEmbedModal,
    ReinstatementModal,
    EndorsementTransactionModal,
    UploadDocumentModal,
    GroupedEndorsementModal,
    ResendEmailLinkModal,
    ChangeInceptionDateModal,
    ChangeAgencyModal,
    EcReviewModal
  },
  data() {
    return {
      loaded: false,
      browser: detect(),
      ecReview: {
        open: false
      },
      changeAgencyModal: {
        open: false
      },
      changeInceptionDateModal: {
        open: false,
        newPolicyNumber: false
      },
      endorsementTransactionModal: {
        open: false
      },
      finalizeCancellationModal: {
        open: false
      },
      transferModal: {
        open: false
      },
      resetPasswordModal: {
        open: false
      },
      endMoratoriumModal: {
        open: false,
        moratorium: null,
        loadData: null
      },
      resetSeparateAccountPasswordModal: {
        open: false,
        company: ""
      },
      modifyRegistrationModal: {
        open: false,
        registrationIndex: 0
      },
      removeRegistrationModal: {
        open: false,
        registrationIndex: 0
      },
      widgetEmbedModal: {
        open: false,
        registrationIndex: 0
      },
      selectedTransferPayment: null,
      reinstatementModal: {
        open: false,
        policyData: ""
      },
      uploadDocumentModal: {
        open: false,
        selectedDocumentType: ""
      },
      groupedEndorsementModal: {
        open: false,
        arr: []
      },
      resendEmailLinkModal: {
        open: false
      },
      ecData: null,
      ecFile: null
    };
  },

  computed: {
    theme() {
      return this.$root.theme;
    }
  },
  watch: {
    theme: {
      handler() {
        this.updateClasses();
      },
      immediate: true
    }
  },
  mounted() {
    this.$root.$on("openChangeInceptionDateModal", () => {
      this.changeInceptionDateModal.open = true;
    });

    this.$root.$on("openChangeAgencyModal", () => {
      this.changeAgencyModal.open = true;
    });
    this.$root.$on("openEcReviewModal", ({ ocr, file }) => {
      this.ecData = ocr;
      this.ecFile = file;
      this.ecReview.open = true;
    });

    this.$root.$on("openReinstatementModal", ({ policyData }) => {
      this.reinstatementModal.open = true;
      this.reinstatementModal.policyData = policyData;
    });
    this.$root.$on("toggleEndorsementTransactionModal", (transaction) => {
      this.endorsementTransactionModal.open = true;
      this.endorsementTransactionModal.transaction = transaction;
    });
    this.$root.$on("toggleGroupedTransactionModal", (arr) => {
      this.groupedEndorsementModal.open = true;
      this.groupedEndorsementModal.arr = arr;
    });
    this.$root.$on("toggleFinalizeCancellationModal", () => {
      this.finalizeCancellationModal.open = !this.finalizeCancellationModal.open;
    });

    this.$root.$on("openResendEmailLinkModal", () => {
      this.resendEmailLinkModal.open = true;
    });

    this.$root.$on("openUploadDocumentModal", (selectedDocumentType) => {
      this.uploadDocumentModal.open = !this.uploadDocumentModal.open;
      this.uploadDocumentModal.selectedDocumentType = selectedDocumentType;
    });

    this.$root.$on("openTransferPaymentModal", (selectedTransferPayment) => {
      this.selectedTransferPayment = selectedTransferPayment;
      this.transferModal.open = true;
    });

    this.$root.$on("openPasswordResetModal", () => {
      this.resetPasswordModal.open = true;
    });

    this.$root.$on("passLoadData", (loadData) => {
      this.endMoratoriumModal.loadData = loadData;
    });

    this.$root.$on("openEndMoratoriumModal", (moratorium) => {
      this.endMoratoriumModal.open = true;
      this.endMoratoriumModal.moratorium = moratorium;
    });

    this.$root.$on("openResetSeparateAccountPasswordModal", (company) => {
      this.resetSeparateAccountPasswordModal.open = true;
      this.resetSeparateAccountPasswordModal.company = company;
    });

    this.$root.$on("openModifyRegistrationModal", (index) => {
      this.modifyRegistrationModal.open = true;
      this.modifyRegistrationModal.registrationIndex = index;
    });

    this.$root.$on("openRemoveRegistrationModal", (index) => {
      this.removeRegistrationModal.open = true;
      this.removeRegistrationModal.registrationIndex = index;
    });

    this.$root.$on("openWidgetEmbedModal", (index) => {
      this.widgetEmbedModal.open = true;
      this.widgetEmbedModal.registrationIndex = index;
    });

    if (this.$mobile || window.innerWidth < 768) {
      this.$store.commit("settings/ui/SET_PROP", {
        path: "nav.hidden",
        value: true
      });
    }
  },

  methods: {
    updateClasses() {
      const classes = document.body.classList;

      classes.forEach((className) => {
        if (className.startsWith("theme-")) {
          classes.remove(className);
        }
      });

      document.body.classList.add(`theme-${this.theme}`);
    }
  }
};
</script>

<style lang="scss">
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* hide number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main-app {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-flow: column;
  // height: 100%

  .ie & {
    height: 100%;
  }
}
</style>
