// import DeepLinkView from "@/views/policyholder/PolicyHolderDeeplinkView.vue";
import PolicyDetail from "@/components/routes/PolicyDetail.vue";
import PolicyWizardScreen from "@/components/routes/PolicyWizardScreen.vue";
import store from "@/store";
import Vue from "vue";
import { extractToken } from "../index";
export const errorHandler = (error) => {
  console.log(error);
  // debugger
  Vue.prototype.$msgbox({
    title: "ERROR",
    message: error.response?.data?.payload?.message
  });
};

/** @type {import("vue-router").RouteConfig[]} */

const routes = [
  {
    path: "/agent/policylogin/:encryptedPayload?",
    component: PolicyDetail,
    name: "agentPolicy",
    async beforeEnter(to, from, next) {
      const deepLink = to.params?.encryptedPayload;

      /** @type {import('axios').AxiosResponse} */
      const {
        data: { statusCode, payload }
      } = await store.dispatch("auth/validateAgentDeepLink", deepLink);

      if (statusCode === 200) {
        const { policy } = payload;

        return next(`policy/embed/${policy}`);
      } else {
        next(`/login`);
      }
    }
  },
  {
    path: "/agent/authenticate/:encryptedPayload?",
    name: "agentAuth",
    component: PolicyWizardScreen,
    async beforeEnter(to, from, next) {
      const deepLink = to.params?.encryptedPayload;
      const token = extractToken(deepLink);

      await store.dispatch("auth/loginWithToken", token);
      return next("/commissions");
    }
  },
  {
    path: "/agent/policy/:policyNo/policy-wizard/:resourceId/",
    name: "agentPolicyWizardGate",
    async beforeEnter(to, from, next) {
      try {
        if (!to.params?.resourceId) {
          throw new Error("invalid resource");
        }

        await store.dispatch("auth/redeemResource", to.params.resourceId);
        await store.dispatch("auth/loginWithToken");

        return next({
          name: "agentPolicyWizard",
          params: { policyNo: to.params.policyNo }
        });
      } catch (e) {
        next("/unauthorized");
      }
    }
  },
  {
    path: "/agent/policy/:policyNo/policy-wizard/",
    name: "agentPolicyWizard",
    component: PolicyWizardScreen,
    async beforeEnter(to, from, next) {
      try {
        if (!store.state.auth.token && !store.state.auth.data?.Token) {
          throw new Error("unauthorized");
        }

        return next();
      } catch (e) {
        next("/unauthorized");
      }
    },
    props: true
  }
];
export default routes;
