// @ts-check

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

/** @type {stripe.BankAccountTokenOptions} */
const defaultOptions = {
  country: "US",
  currency: "usd",
  routing_number: "",
  account_number: "",
  account_holder_name: "",
  account_holder_type: ""
};

/**
 * @type {any}
 */
export const state = {
  /** @type {stripe.BankAccountTokenOptions} */
  stripeOptions: { ...defaultOptions },

  /** @type {stripe.Token} */
  achToken: null
};

/** @type {import('vuex').Module<any,any>} */
const mod = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
};

export default mod;
