import { actions } from "xstate";
const { choose } = actions;

export default {
  initial: "begin",
  on: {
    UNAUTHORIZED: "unauthorized",
    REDIRECT: "redirect_user"
  },
  states: {
    unauthorized: {
      after: {
        5000: "redirect_user"
      }
    },
    redirect_user: {
      type: "final",
      entry: [
        choose([
          { actions: ["redirectInsured"], cond: "isPolicyholder" },
          { actions: ["redirectAgent"] }
        ]),
        "logout"
      ]
    },
    begin: {
      always: [{ target: "fetch_policy" }]
    },
    fetch_policy: {
      type: "parallel",
      states: {
        fetch_policy_confirmation: {
          initial: "begin",
          states: {
            begin: {
              entry: ["clearError"],
              invoke: {
                src: "fetch_policy_confirmation",
                onDone: {
                  actions: [
                    "setConfirmation",
                    choose([
                      {
                        cond: "isEndorsement",
                        actions: [{ type: "setOptInAutoPayRenewal", data: false }]
                      }
                    ])
                  ],
                  target: "complete"
                },
                onError: {
                  actions: ["setError"],
                  target: "retry"
                }
              }
            },
            retry: {
              on: {
                RETRY: {
                  actions: ["clearError"],
                  target: "begin"
                }
              }
            },
            complete: {
              type: "final"
            }
          }
        },

        fetch_policy_transactions: {
          initial: "begin",
          states: {
            begin: {
              entry: ["clearError"],

              invoke: {
                src: "fetch_policy_transactions",
                onDone: {
                  actions: ["setTransactions"],
                  target: "complete"
                },
                onError: {
                  actions: ["setError"],
                  target: "retry"
                }
              }
            },
            retry: {
              on: {
                RETRY: {
                  actions: ["clearError"],
                  target: "begin"
                }
              }
            },
            complete: {
              type: "final"
            }
          }
        }
      },
      onDone: {
        target: "signatures"
      }
    },
    signatures: {
      initial: "begin",
      states: {
        begin: {
          invoke: {
            src: "fetch_policy_documents",
            onDone: {
              actions: ["setDocuments", "clearError"],
              target: "loaded"
            },
            onError: {
              actions: ["setError"],
              target: "retry"
            }
          }
        },
        retry: {
          on: {
            RETRY: {
              actions: ["clearError"],
              target: "begin"
            }
          }
        },
        loaded: {
          always: [{ target: "complete", cond: "isSignedByParty" }],
          on: {
            SUBMIT_SIGNATURES: {
              target: "submit_signatures"
            }
          }
        },
        submit_signatures: {
          initial: "begin",
          states: {
            begin: {
              invoke: {
                src: "save_signatures",
                onDone: {
                  actions: ["setSignatures", "clearError"],
                  target: "fetchSigs"
                },
                onError: {
                  actions: ["setError"],
                  target: "retry"
                }
              }
            },

            fetchSigs: {
              invoke: {
                src: "fetch_policy_confirmation",
                onDone: {
                  actions: ["setConfirmation", "clearError"],
                  target: "complete"
                },
                onError: {
                  actions: ["setError"],
                  target: "retry"
                }
              }
            },
            retry: {
              on: {
                RETRY: {
                  actions: ["clearError"],
                  target: "begin"
                }
              }
            },
            complete: {
              type: "final"
            }
          },
          onDone: {
            target: "complete"
          }
        },
        complete: {
          type: "final"
        }
      },
      onDone: {
        target: "payment"
      }
    },
    payment: {
      initial: "begin",
      states: {
        begin: {
          always: [
            { target: "fetch_payment_methods", cond: "isPaymentRequired" },
            { target: "payment_completed" }
          ]
        },
        fetch_payment_methods: {
          id: "fetch_payment_methods",
          initial: "load_stripe",
          states: {
            load_stripe: {
              invoke: {
                src: "load_stripe",
                onDone: {
                  actions: ["setStripe"],
                  target: "get_client_secret_gate"
                },
                onError: {
                  actions: ["setError"],
                  target: "load_stripe_failed"
                }
              }
            },
            load_stripe_failed: {
              on: {
                RETRY: {
                  actions: ["clearError"],
                  target: "load_stripe"
                }
              }
            },
            get_client_secret_gate: {
              always: [
                { target: "get_client_secret_triton" }
              ]
            },
            get_client_secret: {
              invoke: {
                src: "get_client_secret",
                onDone: {
                  actions: ["setClientSecret"],
                  target: "load_payment_methods"
                },
                onError: {
                  actions: ["setError"],
                  target: "load_stripe_failed"
                }
              }
            },
            get_client_secret_triton: {
              invoke: {
                src: "get_client_secret_triton",
                onDone: {
                  actions: ["setClientSecret"],
                  target: "load_payment_methods"
                },
                onError: {
                  actions: ["setError"],
                  target: "load_stripe_failed"
                }
              }
            },
            get_client_secret_failed: {
              on: {
                RETRY: {
                  target: "get_client_secret"
                }
              }
            },
            load_payment_methods: {
              meta: {
                loading: true
              },
              invoke: {
                src: "fetch_payment_methods",
                onDone: {
                  actions: ["setPaymentMethods", "clearError"],
                  target: "complete"
                },
                onError: {
                  actions: ["setError"],
                  target: "retry"
                }
              }
            },
            retry: {
              on: {
                RETRY: {
                  actions: ["clearError"],
                  target: "load_payment_methods"
                }
              }
            },
            complete: {
              type: "final"
            }
          },
          onDone: {
            target: "loaded"
          }
        },
        loaded: {
          id: "loaded",
          initial: "begin",
          on: {
            MAKE_ACH_PAYMENT: {
              target: ".automated_check_payment"
            },
            MAKE_MORTGAGEE_PAYMENT: {
              target: ".mortgagee_payment"
            },
            MAKE_CC_PAYMENT: {
              target: ".credit_card_payment"
            },
            APPLY_CHECK: {
              target: ".apply_check_payment"
            },
            LOCKBOX_INFO: {
              target: ".lockbox_check_payment"
            },
            CC_MESSAGE: {
              target: ".cc_message"
            }
          },
          states: {
            begin: {
              always: [
                { cond: "isEarthquakeMonthly", target: "credit_card_payment" },
                { target: "mortgagee_payment", cond: "isBillInitiallyMortgagee" },
                { target: "automated_check_payment" }
              ]
            },
            mortgagee_payment: {},
            automated_check_payment: {
              id: "automated_check_payment",
              initial: "wait",
              states: {
                wait: {
                  on: {
                    ON_MAKE_ACH_PAYMENT: {
                      target: "make_payment"
                    },
                    OPT_IN_AUTOPAY_RENEWAL: {
                      actions: ["setOptInAutoPayRenewal"]
                    }
                  }
                },
                make_payment: {
                  initial: "confirm",
                  entry: ["clearPaymentError"],
                  meta: {
                    loading: true
                  },
                  states: {
                    confirm: {
                      on: {
                        RETRY: {
                          target: "#fetch_payment_methods.load_payment_methods"
                        }
                      },
                      invoke: {
                        src: "confirm_ach",
                        onDone: {
                          actions: ["setPaymentMethodFromSetupIntent"],
                          target: "process"
                        },
                        onError: {
                          target: "#automated_check_payment.wait",
                          actions: ["setPaymentError"]
                        }
                      }
                    },
                    process: {
                      invoke: {
                        src: "process_payment",
                        onDone: {
                          target: "complete"
                        },
                        onError: {
                          actions: ["setProcessPaymentError"],
                          target: "failed"
                        }
                      }
                    },
                    failed: {
                      on: {
                        RETRY: {
                          actions: ["clearError"],
                          target: "#fetch_payment_methods.load_stripe"
                        }
                      }
                    },
                    complete: {
                      type: "final"
                    }
                  },
                  onDone: {
                    target: "complete"
                  }
                },
                complete: {
                  type: "final"
                }
              },
              onDone: {
                target: "complete"
              }
            },
            credit_card_payment: {
              id: "credit_card_payment",
              initial: "wait",
              on: {
                CLEAR_TRANSACTION_FEES: {
                  target: "#credit_card_payment.wait",
                  actions: ["clearPaymentError", "clearTransactionFees"]
                }
              },
              states: {
                wait: {
                  on: {
                    ON_MAKE_CC_PAYMENT: {
                      target: "complete"
                    },
                    PAYMENT_METHOD_CREATED: {
                      actions: ["setPaymentMethod"],
                      target: "get_transaction_fee_gate"
                    },
                    PAYMENT_ERROR: {
                      actions: ["setPaymentError"]
                    }
                  }
                },
                get_transaction_fee_gate: {
                  always: [
                    { target: "get_transaction_fee_triton" }
                  ]
                },
                get_transaction_fee_triton: {
                  invoke: {
                    src: "get_transaction_fee_triton",
                    onDone: {
                      actions: ["setTransactionFeesFromPayload"],
                      target: "ack_fee"
                    },
                    onError: {
                      actions: ["setError"]
                    }
                  }
                },
                get_transaction_fee: {
                  invoke: {
                    src: "get_transaction_fee",
                    onDone: {
                      actions: ["setTransactionFees"],
                      target: "ack_fee"
                    },
                    onError: {
                      actions: ["setError"]
                    }
                  }
                },
                ack_fee: {
                  on: {
                    ACK_FEE: {
                      actions: ["setAckFee"]
                    },
                    OPT_IN_AUTOPAY_RENEWAL: {
                      actions: ["setOptInAutoPayRenewal"]
                    },
                    CONFIRM_SETUP_INTENT: {
                      cond: "isFeeAcknowledged",
                      target: "confirm_setup_intent"
                    },
                    PAYMENT_METHOD_CREATED: {
                      actions: ["setPaymentMethod"],
                      target: "get_transaction_fee_gate"
                    }
                  }
                },
                confirm_setup_intent: {
                  meta: {
                    loading: true
                  },
                  on: {
                    RETRY: {
                      actions: ["clearError", "clearTransactionFees"],
                      target: "#fetch_payment_methods.load_stripe"
                    }
                  },
                  invoke: {
                    src: "confirm_setup_intent",
                    onDone: [{ target: "make_payment" }],
                    onError: {
                      target: "confirm_failed",
                      actions: ["setPaymentError"]
                    }
                  }
                },
                confirm_failed: {
                  on: {
                    RETRY: {
                      actions: ["clearError", "clearTransactionFees"],
                      target: "#fetch_payment_methods.load_stripe"
                    }
                  }
                },
                make_payment: {
                  initial: "begin",
                  meta: {
                    loading: true
                  },
                  states: {
                    begin: {
                      invoke: {
                        src: "process_payment",
                        onDone: {
                          target: "complete"
                        },
                        onError: {
                          actions: ["setProcessPaymentError"],
                          target: "process_failed"
                        }
                      }
                    },
                    process_failed: {
                      on: {
                        RETRY: {
                          actions: ["clearError", "clearTransactionFees"],
                          target: "#fetch_payment_methods.load_stripe"
                        }
                      }
                    },
                    complete: {
                      type: "final"
                    }
                  },
                  onDone: {
                    target: "complete"
                  }
                },
                complete: {
                  type: "final"
                }
              },
              onDone: {
                target: "complete"
              }
            },
            lockbox_check_payment: {},
            cc_message: {},
            apply_check_payment: {
              initial: "wait",
              states: {
                wait: {
                  on: {
                    ON_MAKE_CHECK_PAYMENT: {
                      target: "complete"
                    }
                  }
                },
                make_payment: {
                  initial: "begin",
                  states: {
                    begin: {
                      invoke: {
                        src: "apply_check_payment",
                        onDone: {
                          target: "complete"
                        },
                        onError: {}
                      }
                    },
                    complete: {
                      type: "final"
                    }
                  },
                  onDone: {
                    target: "complete"
                  }
                },
                complete: {
                  type: "final"
                }
              },
              onDone: {
                target: "complete"
              }
            },

            complete: {
              type: "final"
            }
          },
          onDone: {
            target: "payment_completed"
          }
        },
        payment_completed: {
          type: "final"
        }
      },
      onDone: {
        target: "finish"
      }
    },
    finish: {
      type: "final"
    }
  }
};
