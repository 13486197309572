export const stateNames = [
  "Arizona",
  "Louisiana",
  "Idaho",
  "Minnesota",
  "North Dakota",
  "South Dakota",
  "New York",
  "Alaska",
  "Georgia",
  "Indiana",
  "Michigan",
  "Mississippi",
  "Ohio",
  "Texas",
  "Nebraska",
  "Colorado",
  "Maryland",
  "Kansas",
  "Illinois",
  "Wisconsin",
  "California",
  "Iowa",
  "Pennsylvania",
  "Montana",
  "Missouri",
  "Florida",
  "Kentucky",
  "Maine",
  "Utah",
  "Oklahoma",
  "Tennessee",
  "Oregon",
  "West Virginia",
  "Arkansas",
  "Washington",
  "North Carolina",
  "Virginia",
  "Wyoming",
  "Alabama",
  "South Carolina",
  "New Mexico",
  "New Hampshire",
  "Vermont",
  "Nevada",
  "Hawaii",
  "Massachusetts",
  "Rhode Island",
  "New Jersey",
  "Delaware",
  "Connecticut",
  "District of Columbia"
];

export const stateHashes = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};

/**
 * License Data for all states
 * @type {import('./types').ILicenseData)}
 */
export const LicenseData = {
  AgencyComissions: [
    {
      CompanyId: "006000",
      EffectiveDate: "2017-11-15T00:00:00",
      MasterNewBusiness: 2,
      MasterRenewBusiness: 2,
      NewStandard: 1,
      RenewStandard: 1
    },
    {
      CompanyId: "006100",
      EffectiveDate: "2017-11-15T00:00:00",
      MasterNewBusiness: 2,
      MasterRenewBusiness: 2,
      NewStandard: 1,
      RenewStandard: 1
    }
  ],
  AgentAuthority: {
    Plans:
      "AKF,ALF,ARF,AZF,CAF,COF,CTF,DEF,FLF,GAF,HIF,IAF,IDF,ILF,INF,KSF,KYF,LAF,MAF,MDF,MEF,MIF,MNF,MOF,MSF,MTF,NCF,NDF,NEF,NHF,NJF,NMF,NVF,NYF,OHF,OKF,ORF,PAF,RIF,SCF,SDF,TNF,TXF,UTF,VAF,VTF,WAF,WIF,WVF,WYF,AKF,ALF,ARF,AZF,CAF,COF,CTF,DEF,FLF,GAF,HIF,IAF,IDF,ILF,INF,KSF,KYF,LAF,MAF,MDF,MEF,MIF,MNF,MOF,MSF,MTF,NCF,NDF,NEF,NHF,NJF,NMF,NVF,NYF,OHF,OKF,ORF,PAF,RIF,SCF,SDF,TNF,TXF,UTF,VAF,VTF,WAF,WIF,WVF,WYF,AKF,ALF,ARF,AZF,CAF,COF,CTF,DEF,FLF,GAF,HIF,IAF,IDF,ILF,INF,KSF,KYF,LAF,MAF,MDF,MEF,MIF,MNF,MOF,MSF,MTF,NCF,NDF,NEF,NHF,NJF,NMF,NVF,NYF,OHF,OKF,ORF,PAF,RIF,SCF,SDF,TNF,TXF,UTF,VAF,VTF,WAF,WIF,WVF,WYF"
  },
  AgentInfo: {
    Addresses: {
      Location: {
        Address1: "7785 66th street North",
        Address2: "",
        City: "Pinellas Park",
        County: "Pinellas",
        State: "FL",
        Zip: "33781"
      },
      Mailing: {
        Address1: "7785 66th street North",
        Address2: "",
        City: "Pinellas Park",
        County: null,
        State: "FL",
        Zip: "33781"
      }
    },
    AgencyEmail: "QA_Testing@Westpointuw.com",
    AgencyStatus: "A",
    AgentID: "FL0008",
    AgentName: "TESTING AGENT",
    AgentRating: 0,
    ApprovedForDollarEb: true,
    AutomatedEmail: "QA_Testing@Westpointuw.com",
    BranchOffice: false,
    CommonName: "TESTING AGENT",
    ContactInfo: [
      {
        Email: "stephanie@neptuneflood.com",
        Fax: "",
        IsPrimaryContact: true,
        LicenseeStatus: "",
        LicenseeStatusDate: "2018-11-15T00:00:00",
        LicenseNumber: "xxxxxx",
        Name: "Stephaine Lee",
        Phone: "(813)727-1535",
        Title: "COO"
      }
    ],
    Downloads: false,
    LimitedLiabilityCompany: "Corporation",
    MarketingRep: "Stephanie Lee",
    MasterAgencyId: null,
    Phone1: "(888) 211-2776",
    QuoteEmail: "QA_Testing@Westpointuw.com",
    RemoteReference: ""
  },
  MultiStateInfo: [
    { License: "123", LicenseExpireDate: "2017-12-30T00:00:00", State: "AK" },
    {
      License: "796854",
      LicenseExpireDate: "2019-12-31T00:00:00",
      State: "AL"
    },
    {
      License: "16847273",
      LicenseExpireDate: "2021-10-31T00:00:00",
      State: "AZ"
    },
    {
      License: "OM11327",
      LicenseExpireDate: "2028-01-01T00:00:00",
      State: "CA"
    },
    {
      License: "582458",
      LicenseExpireDate: "2019-12-31T00:00:00",
      State: "CO"
    },
    {
      License: "2584193",
      LicenseExpireDate: "2020-09-30T00:00:00",
      State: "CT"
    },
    {
      License: "3000260665",
      LicenseExpireDate: "2021-02-28T00:00:00",
      State: "DE"
    },
    {
      License: "8453629",
      LicenseExpireDate: "2020-09-21T00:00:00",
      State: "FL"
    },
    {
      License: "812213",
      LicenseExpireDate: "2021-04-30T00:00:00",
      State: "GA"
    },
    {
      License: "466977",
      LicenseExpireDate: "2020-10-16T00:00:00",
      State: "HI"
    },
    { License: "123", LicenseExpireDate: "2026-05-04T00:00:00", State: "IA" },
    {
      License: "1234IL",
      LicenseExpireDate: "2028-01-01T00:00:00",
      State: "IL"
    },
    {
      License: "3377235",
      LicenseExpireDate: "2020-10-31T00:00:00",
      State: "IN"
    },
    { License: "1234", LicenseExpireDate: "2021-10-20T00:00:00", State: "KS" },
    {
      License: "123456987",
      LicenseExpireDate: "2019-01-31T00:00:00",
      State: "KY"
    },
    {
      License: "ss4566df",
      LicenseExpireDate: "2020-01-01T00:00:00",
      State: "MA"
    },
    {
      License: "3000200145",
      LicenseExpireDate: "2020-10-31T00:00:00",
      State: "MD"
    },
    { License: "1234", LicenseExpireDate: "2020-12-09T00:00:00", State: "ME" },
    {
      License: "10529617",
      LicenseExpireDate: "2019-10-31T00:00:00",
      State: "MI"
    },
    {
      License: "8453629",
      LicenseExpireDate: "2020-09-21T00:00:00",
      State: "MO"
    },
    {
      License: "10529617",
      LicenseExpireDate: "2019-10-31T00:00:00",
      State: "MS"
    },
    {
      License: "000000",
      LicenseExpireDate: "2020-02-08T00:00:00",
      State: "NC"
    },
    { License: "12354", LicenseExpireDate: "2021-11-18T00:00:00", State: "NE" },
    {
      License: "2401558",
      LicenseExpireDate: "2020-12-31T00:00:00",
      State: "NH"
    },
    {
      License: "564564654",
      LicenseExpireDate: "2020-06-01T00:00:00",
      State: "NJ"
    },
    { License: "1234", LicenseExpireDate: "2024-06-10T00:00:00", State: "NM" },
    { License: "1234", LicenseExpireDate: "2022-06-16T00:00:00", State: "NV" },
    {
      License: "12456456",
      LicenseExpireDate: "2021-01-01T00:00:00",
      State: "NY"
    },
    {
      License: "1180373",
      LicenseExpireDate: "2020-01-31T00:00:00",
      State: "OH"
    },
    { License: "1234", LicenseExpireDate: "2021-11-18T00:00:00", State: "OK" },
    {
      License: "332234",
      LicenseExpireDate: "2020-01-01T00:00:00",
      State: "OR"
    },
    {
      License: "848515",
      LicenseExpireDate: "2023-10-18T00:00:00",
      State: "PA"
    },
    {
      License: "3000395869",
      LicenseExpireDate: "2020-10-31T00:00:00",
      State: "RI"
    },
    {
      License: "891971",
      LicenseExpireDate: "2020-05-31T00:00:00",
      State: "SC"
    },
    {
      License: "2394626",
      LicenseExpireDate: "2020-10-31T00:00:00",
      State: "TN"
    },
    {
      License: "1234TX",
      LicenseExpireDate: "2028-01-01T00:00:00",
      State: "TX"
    },
    {
      License: "1234567VA",
      LicenseExpireDate: "2028-01-01T00:00:00",
      State: "VA"
    },
    {
      License: "976494",
      LicenseExpireDate: "2019-10-17T00:00:00",
      State: "WA"
    },
    {
      License: "16847273",
      LicenseExpireDate: "2019-10-31T00:00:00",
      State: "WI"
    },
    {
      License: "16847273",
      LicenseExpireDate: "2019-05-31T00:00:00",
      State: "WV"
    }
  ],
  TaxInfo: {
    EOAmount: 0.0,
    EOCarrier: "",
    EOExpireDate: "2018-10-02T00:00:00",
    EOPolicyNumber: "",
    LastReviewDate: "2017-10-02T00:00:00",
    TaxId: "W17000002959"
  }
};

export const AllStates = [
  "AL",
  "VA",
  "GA",
  "MS",
  "NC",
  "SC",
  "TN",
  "TX",
  "NJ",
  "PA",
  "MD",
  "CT",
  "HI",
  "OH",
  "AZ",
  "WA",
  "CA",
  "MI",
  "DE",
  "NH",
  "CO",
  "MO",
  "IN",
  "RI",
  "WV",
  "NY",
  "MA",
  "FL",
  "WI",
  "OK",
  "NM",
  "IA",
  "NV",
  "NE",
  "ME",
  "KS",
  "MN",
  "OR"
];

/**
 * This will simply the state name to any other state name, or abbreviation
 * @param {string} nameOrAbbr
 */
export const findState = (nameOrAbbr) => {
  return Object.entries(stateHashes).find(([k, v]) => {
    const m = nameOrAbbr.toLowerCase();
    return m === k.toLowerCase() || m === v.toLowerCase();
  });
};
