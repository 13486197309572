<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white max-w-lg w-full relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <h1 class=" text-gray-900 text-lg  m-0 leading-none">
        <span class="font-bold">Add document or file </span>
      </h1>
      <Upload :selected-document-type="selectedDocumentType" @close="closeModal()" />
    </div>
  </div>
</template>
<script>
import Upload from "@/components/upload/Upload.vue";

export default {
  components: {
    Upload
  },
  props: {
    selectedDocumentType: {
      default: "",
      type: String
    }
  },

  methods: {
    closeModal() {
      this.$emit("close");

      // Get the current URL
      const currentURL = window.location.href;

      // Remove the query parameters from the URL
      const urlWithoutQueryParams = currentURL.split("?")[0];
      // Use the new URL without query parameters
      window.history.replaceState({}, document.title, urlWithoutQueryParams);
    }
  }
};
</script>
