<template>
  <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

    <div class="bg-white w-5xl h-100 relative z-10 p-6 rounded-md">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
        viewBox="0 0 20 20"
        fill="currentColor"
        @click="$emit('close')"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <div class="flex flex-col gap-1">
        <div class="font-semibold" data-test="removeWidget.title">
          {{ registration.Name ? registration.Name : "Untitled" }}
        </div>
        <div class="flex gap-2 text-sm text-gray-600">
          <div class="flex items-center gap-1">
            <!-- Heroicon name: outline/phone -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>
            <div data-test="removeWidget.phone">{{ registration.ReferralPhone }}</div>
          </div>
          <div class="flex items-center gap-1">
            <!-- Heroicon name: outline/paper-airplane -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
              />
            </svg>
            <div data-test="removeWidget.email">{{ registration.ReferralEmail }}</div>
          </div>
          <div v-if="registration.ReferralWebsite" class="flex items-center gap-1">
            <!-- Heroicon name: outline/computer-desktop -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3 text-gray-400"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
              />
            </svg>
            <div data-test="removeWidget.website">{{ registration.ReferralWebsite }}</div>
          </div>
        </div>
      </div>
      <p class="my-5">Are you sure you wish to remove this widget?</p>
      <FadeTranslate>
        <p v-if="deleteError" class="my-4 text-red-500 text-sm m-0" data-test="removeWidget.error">
          {{ deleteError }}
        </p>
      </FadeTranslate>
      <div slot="footer" class="flex justify-end">
        <button
          type="primary"
          class="inline-flex items-center rounded-md border border-transparent bg-deepsea-full px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-deepsea-full/75 focus:outline-none min-w-[69px]"
          data-test="removeWidget.delete"
          :disabled="loading"
          @click="newDeleteRegistration"
        >
          <span v-if="!loading">Confirm</span>
          <svg
            v-else
            class="animate-spin h-5 w-5 text-white mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FadeTranslate from "@/components/transitions/FadeTranslate.vue";

export default {
  components: {
    FadeTranslate
  },
  props: {
    registrationIndex: Number
  },
  data() {
    return {
      serverError: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters("widgets", ["getRegistrationAtIndex"]),
    registration() {
      return this.getRegistrationAtIndex(this.registrationIndex);
    },
    agentNo() {
      return this.$store.state.widgets.registration.AgentNo;
    }
  },
  methods: {
    ...mapActions("widgets", ["setRegistration", "deleteRegistration"]),
    async newDeleteRegistration() {
      this.loading = true;
      try {
        await this.deleteRegistration({
          index: this.registrationIndex,
          Id: this.registration.Id,
          agentNo: this.agentNo
        });
        this.deleteError = null;
        this.$emit("close");
      } catch (e) {
        this.deleteError = e.message;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
