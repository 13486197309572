<template>
  <div>
    <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4 rounded" data-test="userGoalBox">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg
            class="h-5 w-5 text-yellow-400 mt-[5px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3 flex-grow">
          <div class="flex justify-between space-x-2 w-full items-center">
            <h3
              class="text-yellow-800 text-lg leading-none font-bold"
              data-test="detailAlert.title"
            >
              Actions Required
            </h3>
            <div
              class="text-sm bg-yellow-200 px-2 py-1 rounded text-yellow-800"
              data-test="detailAlert.dueIn"
            >
              <span v-if="timeLeft > 0" data-test="detailAlert.dueSoon">
                Due in {{ timeLeft }} {{ timeLeftUnit }}
              </span>
              <span v-else-if="timeLeft !== 0" data-test="detailAlert.pastDue">
                Due {{ timeLeft * -1 }} {{ timeLeftUnit }} ago
              </span>
              <span v-else data-test="detailAlert.dueToday">
                DUE TODAY
              </span>
            </div>
          </div>
          <p class="text-sm text-yellow-700 mt-1.5" data-test="detailAlert.activation">
            Needs activation before
            {{ $store.getters["policy/allData"].effectiveDate | shortDate }}
          </p>
          <div class="text-sm mt-2 text-yellow-700 space-y-1" data-test="userGoalTask">
            <div v-if="showSignButton" class="flex items-center">
              <div class="w-5 h-5 flex items-center justify-center">
                <div class="bg-yellow-700 w-1.5 h-1.5 rounded-full flex-shrink-0" />
              </div>
              <button
                class="inline-block text-sm border !text-yellow-800 border-yellow-700 px-4 py-2 rounded font-semibold hover:bg-yellow-700 hover:!text-white"
                data-test="detailAlert.docSigning"
                @click.prevent="$emit('sign')"
              >
                Document Signing
              </button>
            </div>

            <div v-if="!$store.getters['policy/isPaid']" class="flex items-center">
              <div class="w-5 h-5 flex items-center justify-center">
                <div class="bg-yellow-700 w-1.5 h-1.5 rounded-full flex-shrink-0" />
              </div>
              <button
                class="inline-block text-sm border !text-yellow-800 border-yellow-700 px-4 py-2 rounded font-semibold hover:bg-yellow-700 hover:!text-white"
                data-test="detailAlert.payment"
                @click.prevent="$emit('pay')"
              >
                Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateFiltersMixin } from "@/components/policy-wizard/utils/mixins/formatting";
export default {
  mixins: [DateFiltersMixin],
  data() {
    return {
      signaturesComplete: false
    };
  },

  computed: {
    showSignButton() {
      const isAgentSignatureRequired = this.$store.getters["policy/isAgentSignatureRequired"];
      const hasAgentSigned = this.$store.getters["policy/hasAgentSigned"];
      const isInsuredSignatureRequired = this.$store.getters["policy/isInsuredSignatureRequired"];
      const hasInsuredSigned = this.$store.getters["policy/hasInsuredSigned"];

      const showIfAgentAndNotSigned = isAgentSignatureRequired && !hasAgentSigned;

      const showIfInsuredAndNotSigned =
        !this.$internal && isInsuredSignatureRequired && !hasInsuredSigned;

      return this.$store?.getters["userdata/party"] === 1
        ? showIfAgentAndNotSigned
        : showIfInsuredAndNotSigned;
    },
    timeLeft() {
      const ms = Date.parse(this.$store.getters["policy/allData"].effectiveDate) - Date.now();
      return Math.ceil(ms / 1000 / 60 / 60 / 24);
    },
    timeLeftUnit() {
      return Math.abs(this.timeLeft) === 1 ? "day" : "days";
    }
  }
};
</script>
