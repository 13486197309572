import Axios from "axios";
import { authAxios } from "../auth";
import { Constants } from "@/config";

let cancel;

/** @type {import("vuex").ActionTree} */
export const actions = {
  async lookup({ commit }, addr) {
    if (typeof cancel === "function") {
      cancel("cancelled previous request");
    }

    try {
      const { data } = await Axios.get("https://autocomplete-api.smartystreets.com/suggest", {
        cancelToken: new Axios.CancelToken((c) => {
          cancel = c;
        }),
        params: {
          "auth-id": 19033897084078356,
          prefix: addr,
          suggestions: 10,
          geolocate: true,
          geolocate_precision: "state",
          prefer: "FL"
        }
      });

      if (Array.isArray(data.suggestions)) {
        commit("SET_STATE", {
          addresses: data.suggestions
        });
      }

      return data.suggestions;
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled", error);
      }
    }
  },
  async clear({ commit }) {
    commit("SET_STATE", {
      addresses: []
    });
  },
  async loadAgentOptionTypes({ commit }) {
    const { data } = await authAxios.get(Constants.AGENCY_LOOKUP_TYPE_URL);
    commit("SET_PROP", {
      path: "options.agentTypes",
      value: data
    });

    return data;
  }
};

export default actions;
