<template>
  <div class="animation">
    <lottie
      v-if="defaultOptions.animationData"
      :options="defaultOptions"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script>
/**
 * https://airbnb.io/lottie/#/web
 */
import Lottie from "vue-lottie";
import Axios from "axios";

const fileData = {};

export default {
  components: {
    Lottie: Lottie
  },
  props: {
    loop: {
      type: [String, Boolean],
      default: false
    },
    path: {
      type: String,
      default: "/static/lottie/check.json"
    }
  },
  data() {
    return {
      /** @type {import('lottie-web').AnimationConfigWithData} */
      defaultOptions: {
        renderer: "svg",
        loop: true,
        autoplay: true,
        // animationData: animationData, // the animation data
        animationData: null,
        rendererSettings: {
          // context: canvasContext, // the canvas context
          // scaleMode: "",
          clearCanvas: false,
          progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
          hideOnTransparent: false // Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
        }
      },
      animationSpeed: 1,
      BASE_URL: process.env.BASE_URL || "",
      /** @type {import('lottie-web').AnimationItem} */
      anim: null
    };
  },
  watch: {
    animationSpeed(v) {
      this.anim.setSpeed(v);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const { data } = fileData[this.path] || (await Axios.get(`${this.BASE_URL}${this.path}`));
      fileData[this.path] = {
        data
      };

      this.defaultOptions.animationData = data;
      this.defaultOptions.loop = this.loop !== false;
    },

    handleAnimation: function(anim) {
      this.anim = anim;
      this.onSpeedChange();
    },

    stop: function() {
      this.anim.stop();
    },

    play: function() {
      this.anim.removeEventListener("enterFrame", this.onEnter);
      this.anim.addEventListener("enterFrame", this.onEnter);
      this.anim.play();
    },

    pause: function() {
      this.anim.pause();
    },

    onSpeedChange: function() {
      this.anim.setSpeed(this.animationSpeed);
    },

    onEnter(a) {
      console.log(a);
    }
  }
};
</script>

<style lang="scss" scoped>
.animation {
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  margin: auto;
}
</style>
