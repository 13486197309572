import Axios from "axios";
import Vue from "vue";
import { authAxios } from ".";
import { Constants } from "../../../config";
import jwt_decode from "jwt-decode";

// shows bootstrap looking error modal when insured user's link has expired
const errorHandler = async (error) => {
  // create special modal with message that allows policyholder
  // to regenerate a link if needed

  if (error.response?.data?.message === "The link has expired.")
    await Vue.prototype.$msgbox({
      title: "ERROR",
      message: `The link has expired. Please click <a href="/#/policyholder/generate" class="text-sky-600 underline font-bold" target="_blank">here</a> to generate a new one.`,
      dangerouslyUseHTMLString: true,
      showConfirmButton: false,
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false
    });
  else
    await Vue.prototype.$msgbox({
      title: "ERROR",
      message:
        error.response?.data?.message ??
        error.response?.data?.payload?.message ??
        error.response?.data ??
        error
    });

  return Promise.reject(error.response?.data?.message ?? error.response?.data ?? error);
};

/**
 * auth/validateWithDeepLink({ deepLink, phone, zip })
 * @type {import("vuex").ActionTree<neptune.policyholder.IAuthState, neptune.IRootState>}
 */
const actions = {
  async validateAgentDeepLink({ commit }, deepLink) {
    try {
      // Clear auth state before validating and logging in
      commit("CLEAR_AUTH");

      /** @type {import('axios').AxiosResponse<neptune.auth.IDeeplinkAuthorizationResponse>} */
      const { data, status } = await Axios.post(Constants.POSEIDON_AGENT_LOGIN_URL, {
        /**
         * !IMPORTANT! We must encodeURI the deeplink due to weird router path issues
         */
        Token: encodeURIComponent(deepLink)
      });

      const token = data.payload.jwt;
      const { AgentNo, Email } = jwt_decode(token);

      commit("SET_PROP", {
        path: "validateReturn",
        value: data.payload
      });

      commit("SET_PROP", {
        path: "token",
        value: deepLink
      });

      commit("SET_PROP", {
        path: "data",
        value: { AgentId: AgentNo, Email }
      });

      commit("SET_TOKEN", token);
      /**
       * Assign policy number, documents, anything else loaded when logged in
       must go to appropriate models
       */
      const docs = data.payload?.policy?.policyDocStatus?.documentsWithSignatures;
      const policyNo = data.payload?.policy;

      if (docs) {
        commit(
          "document/SET_PROP",
          {
            path: "documents",
            value: docs
          },
          {
            root: true
          }
        );
      }

      if (policyNo) {
        commit(
          "policy/SET_PROP",
          {
            path: "policyNo",
            value: policyNo
          },
          {
            root: true
          }
        );
      }

      return {
        data,
        status
      };
    } catch (error) {
      this._vm.$ada.setSensitiveMetaFields({ auth_token: null });
      await errorHandler(error);
      return Promise.reject(error);
    }
  },
  async validateWithDeepLink({ commit }, { deepLink, phone, zip }) {
    try {
      // Clear auth state before validating and logging in
      commit("CLEAR_AUTH");

      /** @type {import('axios').AxiosResponse<neptune.auth.IDeeplinkAuthorizationResponse>} */
      const { data, status } = await Axios.post(Constants.POSEIDON_LOGIN_URL, {
        /**
         * !IMPORTANT! We must encodeURI the deeplink due to weird router path issues
         */
        Token: encodeURIComponent(deepLink),
        Phone: String(phone).replace(/[^0-9]/gi, "") || "",
        Zip: zip || ""
      });

      commit("SET_PROP", {
        path: "accountType",
        value: "policyholder"
      });

      commit("SET_PROP", {
        path: "policyholderURL",
        value: window.location.href
      });

      commit("SET_PROP", {
        path: "validateReturn",
        value: data.payload
      });

      commit("SET_PROP", {
        path: "deepLink",
        value: deepLink
      });

      commit("SET_TOKEN", data.payload.jwt);

      /**
       * Assign policy number, documents, anything else loaded when logged in
       must go to appropriate models
       */
      const docs = data.payload?.policy?.policyDocStatus?.documentsWithSignatures;
      const policyNo = data.payload?.policy?.policy?.policyNo;
      const policyList = data.payload?.policies;
      if (docs) {
        commit(
          "document/SET_PROP",
          {
            path: "documents",
            value: docs
          },
          {
            root: true
          }
        );
      }

      if (policyList) {
        commit(
          "policy/SET_PROP",
          {
            path: "policyList",
            value: policyList
          },
          {
            root: true
          }
        );
      }

      if (policyNo) {
        commit(
          "policy/SET_PROP",
          {
            path: "policyNo",
            value: policyNo
          },
          {
            root: true
          }
        );
      }

      return {
        data,
        status
      };
    } catch (error) {
      this._vm.$ada.setSensitiveMetaFields({ auth_token: null });
      await errorHandler(error);
      return Promise.reject(error);
    }
  },
  async loginWithToken({ commit, state }, paramToken) {
    if (!paramToken && !state.token) {
      return;
    }

    const token = paramToken ?? state.token;

    // clear accountType before authorizing token
    commit("SET_PROP", {
      path: "accountType",
      value: null
    });

    try {
    // authorize token
    const { data } = await Axios.get(Constants.AUTHORIZE_TOKEN_URL, {
      params: {
        token
      }
    });

    commit("SET_TOKEN", token);

    commit("SET_PROP", {
      path: "data",
      value: data
    });

    return data;
    } catch (e) {
      commit("SET_TOKEN", null);
      throw new Error(e);
    }
  },

  async redeemResource({ commit }, resourceId) {
    try {
      const response = await Axios.get(Constants.REDEEM_RESOURCE_URL.replace("{{resourceId}}", resourceId));
      if (!response.data?.UnderlyingJwt) {
        return;
      }

      const { UnderlyingJwt: token } = response.data;
      commit("SET_TOKEN", token);

      return response.data;
    } catch (e) {
      commit("SET_TOKEN", null);
      throw new Error(e);
    }
  },

  async sendEmail(_, { email, phone, zip, policyNo }) {
    try {
      const result = Axios.post(Constants.REQUEST_EMAIL_URL, {
        email,
        phone,
        policyNumber: policyNo,
        zip
      });
      return result.data;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getSummary({ commit, state }) {
    const {
      validateReturn: {
        policy: {
          policy: { policyNo }
        }
      }
    } = state;

    const {
      data: { payload: data }
    } = await authAxios.get(Constants.GET_CONFIRMATION_URL.replace("{{policyNo}}", policyNo));

    commit("SET_PROP", {
      path: "summary",
      value: data
    });
  },

  async getInsuredToken({ rootGetters }, policyNo) {
    const po = rootGetters["policy/allData"].policyNo || policyNo;
    const {
      data: {
        payload: { link }
      }
    } = await authAxios.get(`https://dev-psdn-api.neptuneflood.com/api/v1/policies/${po}/link`, {
      params: {
        isBind: true
      }
    });

    return link.replace(/.+\/new\//, "");
  },
  logout({ commit }) {
    commit("CLEAR_AUTH");
  }
};

export default actions;
